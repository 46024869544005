import * as Yup from 'yup'
import { I18n } from '../../../../../features/translation/types'

const NOT_ALLOWED = 'Value not allowed'

export const validationSchema = (i18n: I18n) =>
  Yup.object().shape({
    birthDate: Yup.date().required(i18n.required),
    line1: Yup.string().required(i18n.required),
    city: Yup.string().required(i18n.required),
    state: Yup.string().required(i18n.required),
    race: Yup.string().required(i18n.required),
    ethnicity: Yup.string().required(i18n.required),
    gender: Yup.string().required(i18n.required),
    zipCode: Yup.string()
      .min(5, i18n.invalid_zip_code_error_message)
      .max(5, i18n.invalid_zip_code_error_message)
      .matches(/^(?!0{5})/, NOT_ALLOWED)
      .required(i18n.required),
      phone: Yup.string()
      .min(10, i18n.invalid_phone_error_message)
      .max(10, i18n.invalid_phone_error_message)
      .matches(/^(?!0{10})/, NOT_ALLOWED)
      .matches(/^(?!0+[1-9])/, NOT_ALLOWED)
      .required(i18n.required),
  })
