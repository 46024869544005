import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { PatientTest } from '../../types/patientTest'
import {
  convertArrayBufferToBlobUrl,
  convertBase64ToBlobUrl,
} from '../../libs/files'

interface TestDetailsSlice {
  isLoading: boolean
  imageIsLoading: boolean
  testOrderDetails: PatientTest | null
  testPdf: string
  testImage: string
  testStatus: string
  testOrderList: any[] //holding this list here for now - may need to move to test history
}

const initialStore: TestDetailsSlice = {
  isLoading: false,
  imageIsLoading: false,
  testOrderDetails: null,
  testPdf: '',
  testImage: '',
  testStatus: '',
  testOrderList: [],
}

const testDetailsSlice = createSlice({
  name: 'testDetails',
  initialState: initialStore,
  reducers: {
    loading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setImageLoading(store, { payload }: { payload: boolean }) {
      store.imageIsLoading = payload
    },
    setTestOrderDetails(store, { payload }: { payload: PatientTest }) {
      store.testOrderDetails = payload
      store.testStatus = payload.status || ''
    },
    setTestOrderList(store, { payload }: { payload: any }) {
      store.testOrderList = payload
    },
    setTestPdf(store, { payload }: { payload: string }) {
      store.testPdf = payload
    },
    setTestImage(store, { payload }: { payload: string }) {
      store.testImage = payload
    },
  },
})

const {
  setTestOrderDetails,
  setTestOrderList,
  loading,
  setImageLoading,
  setTestPdf,
  setTestImage,
} = testDetailsSlice.actions

export const getOrderStatus = () => async (dispatch: Dispatch) => {
  dispatch(loading(true))
  const { data } = await api.getLatestOrderDetails()

  if (data) {
    dispatch(setTestOrderDetails(data))
  }

  dispatch(loading(false))
}

export const getOrderDetails = (id: string) => async (dispatch: Dispatch) => {
  dispatch(loading(true))
  const { data } = await api.getOrderDetailsById(id)

  if (data) {
    dispatch(setTestOrderDetails(data))
  }

  dispatch(loading(false))
}

export const getOrderResults = () => async (dispatch: Dispatch) => {
  dispatch(loading(true))
  const pagination = {
    pageNumber: 0,
    pageSize: 10,
  }
  const { data } = await api.getOrderResults(pagination)

  if (data) {
    dispatch(setTestOrderList(data))
  }

  dispatch(loading(false))
}

export const downloadLabReportPDF =
  (testId: string) => async (dispatch: Dispatch) => {
    dispatch(setImageLoading(true))
    const data = await api.downloadLabReportPDF(testId)
    dispatch(setImageLoading(false))
    if (data) {
      const img = new Image()
      img.src = URL.createObjectURL(data)
      dispatch(setTestPdf(img.src))
      return img.src
    }
  }

export const getTestImage = (id: string) => async (dispatch: any) => {
  dispatch(setImageLoading(true))
  const { data } = await api.getTestImage(id)
  if (data) {
    dispatch(setTestImage(data.scanImageData))
  }
  dispatch(setImageLoading(false))
}

export const testDetailsReducer = testDetailsSlice.reducer
export const testDetailsReducerName = testDetailsSlice.name

interface RootStore {
  [testDetailsReducerName]: typeof initialStore
}
export const selectTestDetailsLoading = ({ testDetails }: RootStore) =>
  testDetails.isLoading

export const selectTestStatus = ({ testDetails }: RootStore) =>
  testDetails.testStatus

export const selectOrderDetails = ({ testDetails }: RootStore) =>
  testDetails.testOrderDetails

export const selectOrderList = ({ testDetails }: RootStore) =>
  testDetails.testOrderList

export const selectOrderPDF = ({ testDetails }: RootStore) =>
  testDetails.testPdf

export const selectTestImage = ({ testDetails }: RootStore) =>
  testDetails.testImage

export const selectImageLoading = ({ testDetails }: RootStore) =>
  testDetails.imageIsLoading
