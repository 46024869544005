import { styled } from '@mui/styles'
import React from 'react'
import { Loading } from '../../../ui'

export const ShopLoading = () => {
  return (
    <LoadingWrapper>
      <Loading size={100} />
    </LoadingWrapper>
  )
}

const LoadingWrapper = styled('div')({
  height: 'calc(100vh - 64px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
