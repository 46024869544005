import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import api from '../../api'
import { selectLanguageContent } from '../../features/translation'
import { Button } from '../../ui'
import { Success } from '../../ui/atoms/success-checkmark-animation/checkmark-animation'
import { paths } from '../paths'

export const OrderCompletePage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)

  const refreshToken = async () => {
    try {
      const result = await api.refreshIdToken()
      if (result.isOK && result.data && result.data.idToken) {
        sessionStorage.setItem('id_token', result.data.idToken as string)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const complete = () => {
    refreshToken()
    navigate(paths.app.dashboard())
  }

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Success />
        <Typography variant="h6" className={classes.processText}>
          {i18n['ecommerce.order_complete_title']}
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          {i18n['ecommerce.order_complete_subtitle']}
        </Typography>
        <Box className={classes.btnWrapper}>
          <Button onClick={complete} className={classes.btnStyle}>
            {i18n['ecommerce.return_home_button']}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '500px',
  },
  btnWrapper: {
    display: 'flex',
    backgroundColor: '#FFF',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '32px 0px',
    position: 'relative',
    width: '100%',
    zIndex: 10,
    [theme.breakpoints.down(600)]: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      padding: '12px 0px 24px',
      width: '100%',
    },
  },
  btnStyle: {
    margin: '10px auto',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 124,
    justifyContent: 'center',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    textAlign: 'center',
    width: 200,
    marginBottom: '-50px',
  },
  processText: {
    fontSize: 32,
    fontWeight: 500,
    color: '#505358',
    marginTop: 32,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#757575',
  },
}))
