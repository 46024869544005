import { makeStyles } from '@mui/styles'
// import { KeyboardDatePicker } from '@material-ui/pickers'
import DatePicker from '@mui/lab/DatePicker'

import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../features/translation'
import { cleanStringForAria } from '../../../libs/utils'

export const DatePickerComponent = ({
  onChange,
  date,
  errorMessage,
  onBlur,
}: {
  onChange: (newDate: Date | null) => void
  date: string | null
  onBlur: () => void
  errorMessage: string
}) => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  return (
    <DatePicker
      id={cleanStringForAria(i18n.birthday, 'id')}
      InputLabelProps={{
        shrink: true,
        className: classes.dateLabel,
      }}
      InputProps={{
        className: classes.dateInput,
      }}
      className={classes.dateWrapper}
      label={i18n.birthday}
      onChange={onChange}
      format="MM/dd/yyyy"
      placeholder="mm/dd/yyyy"
      onBlur={onBlur}
      value={date}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      required
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  )
}
const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
  dateLabel: {
    fontSize: 18,
  },
  dateWrapper: {
    width: '100%',
  },
  dateInput: {
    '&:before': {
      borderBottomColor: 'rgb(121, 141, 173)',
    },
  },
})
