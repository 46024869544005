import { getCurrentUser } from '../../../../libs/utils'
import { lsClient } from '../../../../ls-client'
import { store } from '../../../../redux'

export class UserService {
  getSelectedTenant() {
    return lsClient.getUserLSByKey('tenantId')
  }

  getUserData() {
    const user = store.getState().profile
    return {
      email: getCurrentUser(),
      nationalHealthId: user.data.nationalHealthId,
    }
  }

  getSettings() {
    return store.getState().network.settings
  }
}
