import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import { Box, styled } from '@mui/material'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { lsClient } from '../../ls-client'
import { paths } from '../../pages/paths'
import { Typography } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { selectLanguageContent } from '../translation'
import { useFlowControl } from './use-flow-control'

export interface FlowsListProps {}

export const FlowsList = (props: FlowsListProps) => {
  const { state, actions } = useFlowControl()
  const navigate = useNavigate()
  const selectedIds = sessionStorage.getItem('workflowListIds')
  const i18n = useSelector(selectLanguageContent)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const startFlow = (flow: any) => {
    lsClient.setUserLS('flowId', flow.id)
    lsClient.setUserLS('flowTitle', flow.name.replaceAll('’', "'"))
    actions.startFlow(flow.id)
  }

  const handleClose = () => {
    lsClient.removeUserKeyLS('flowId')
    lsClient.removeUserKeyLS('flowTitle')
    lsClient.removeUserKeyLS('flowExecution')
    sessionStorage.removeItem('workflowListIds')
    navigate(paths.app.dashboard())
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleClose}
      progressColor="secondary"
      color="#505358"
    >
      <ContentWrapper>
        <BannerTitle>{i18n.health_services_title}</BannerTitle>
        <BannerDesc>{i18n.health_services_text}</BannerDesc>
        <ListWrapper>
          {state.flowsList.map((flow: any) => {
            if (selectedIds && !selectedIds.includes(flow.id)) return null

            return (
              <FlowContainer onClick={() => startFlow(flow)} key={flow.id}>
                <ListItem>
                  <div>
                    <Title>{flow.name}</Title>
                    <Body>{flow.description}</Body>
                  </div>
                  <ChevronRightSharpIcon sx={{ color: '#757575' }} />
                </ListItem>
              </FlowContainer>
            )
          })}
        </ListWrapper>
      </ContentWrapper>
    </ModuleHeader>
  )
}

const FlowContainer = styled(Box)(({ theme }) => ({
  'borderRadius': '10px',
  'backgroundColor': '#fff',
  'width': 'calc(50% - 16px)',
  'border': '1px solid #aaa',
  'cursor': 'pointer',
  '&:hover': {
    backgroundColor: '#ddd',
  },
  [theme.breakpoints.down(960)]: {
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  [theme.breakpoints.down(600)]: {
    width: 'calc(100% - 2px)',
  },
}))

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 800,
  display: 'flex',
  flexDirection: 'column',
  margin: '48px auto',
  [theme.breakpoints.down(960)]: {
    width: '90vw',
    margin: '24px auto',
  },
}))

const ListWrapper = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
  marginTop: 24,
  [theme.breakpoints.down(600)]: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    marginBottom: 48,
  },
}))

const ListItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '32px',
  maxWidth: '660px',
  [theme.breakpoints.down(960)]: {
    padding: '16px',
  },
}))

const BannerTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 36,
  color: '#282D37',
})

const BannerDesc = styled(Typography)({
  fontWeight: 500,
  fontSize: 16,
  color: '#757575',
})

const Title = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  color: '#505358',
})

const Body = styled(Typography)({
  fontWeight: 500,
  fontSize: 14,
  color: '#898989',
})
