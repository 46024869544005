export function randomString(length = 32) {
  const bytes = new Uint8Array(length)
  const result = []
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'

  const { crypto } = window
  const random = crypto.getRandomValues(bytes)
  for (let i = 0; i < random.length; i += 1) result.push(charset[random[i] % charset.length])

  return result.join('')
}

export const TELEHEALTH_SHOPIFY_ITEM = 'Telehealth Consult'
