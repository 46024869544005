import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Typography } from '../../ui'
import { selectLanguageContent } from '../../features/translation'
import { SubHeaderGetTested } from '../../features/application'
import { useStyles, Wrapper } from '../../ui/templates/app-layout'
import { ReactComponent as Verified } from '../../assets/icons/verified.svg'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'

export const GuardianCompletePage = () => {
  const childClasses = childStyles()
  const classes = useStyles()
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)
  const { fullName: userName } = useSelector(selectProfileData)

  const goNext = () => {
    // navigate(paths.accountDetails())
  }

  const handleBack = () => {
    history.back()
  }

  return (
    <Wrapper>
      <SubHeaderGetTested title={i18n.legal_guardian_required} onHandleBack={handleBack} />
      <Box className={childClasses.testWrapper} style={{ flex: 1 }}>
        <Box className={childClasses.testingContent}>
          <Verified className={childClasses.verified} />
          <Box className={childClasses.contentContainer}>
          <Box className={childClasses.infoText}>
              Thank You
          </Box>
            <Box className={classes.optionsWrapper}>
              <Box className={childClasses.optionWrapper}>
                <Typography component='div' align='center'>
                On the next screen you will be asked to provide information about <strong>{`${userName}`}</strong>,
                the person who is taking the test.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Button onClick={goNext} variant='contained' className={childClasses.continueButton}>
        {i18n['continue_button']}
      </Button>
      </Wrapper>
  )
}


const SPACE_BETWEEN = 'space-between'

const childStyles = makeStyles((theme) => ({
  verified: {
    height: '250px',
    color: '#6d6d6d',
  },
  infoText: {
    fontSize: 26,
    color: '#7B7C7D',
    alignSelf: 'center',
    textAlign: 'center',
  },
  content: {
    padding: '0px 0px !important',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    margin: '0 auto',
    minHeight: 200,
  },
  testWrapper: {
    maxWidth: 540,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  testingContent: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 50px)',
    [theme.breakpoints.down(767)]: {
      padding: '0 15px',
    },
  },
  optionWrapper: {
    padding: '20px 15px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    margin: '0 auto 20px',
    maxWidth: 400,
    color: '#7B7C7D',
    '&:first-child': {
      cursor: 'pointer',
    },
  },
  continueButton: {
    margin: '0 auto',
    maxHeight: 45,
    marginBottom: 10,
    maxWidth: 300,
  },
}))