import { I18n } from '../../features/translation/types'
import { NetworkSettings } from '../network/type'

export enum ReturnToLabStepType {
  START = 'start',
  CHOOSE_LOCATION = 'choose-location',
}

export interface ReturnToLabProps {
  i18n: I18n
  networkSettings: NetworkSettings | null
  handleNext: () => void
  handleBack: () => void
}
