import { getAtSeparatedDate } from '../../../libs/date'
import { testResultsV2ReducerName } from './model'
import { TestResultsV2State } from './types'

interface RootState {
  [testResultsV2ReducerName]: TestResultsV2State
}
export const selectTestResults = ({ testResultsV2 }: RootState) =>
  testResultsV2.list
export const selectIsDone = ({ testResultsV2 }: RootState) =>
  testResultsV2.isDone
export const selectIsLoading = ({ testResultsV2 }: RootState) =>
  testResultsV2.isLoading

export const selectTestName = (state: RootState) => {
  return (
    state.testResultsV2.activeItem?.details.orderableTest?.customName || '-'
  )
}
export const selectTestLabVendorName = (state: RootState) => {
  return state.testResultsV2.activeItem?.details.labVendor?.name || null
}
export const selectTestOrderDate = (state: RootState) => {
  if (!state.testResultsV2.activeItem?.created) return null
  return getAtSeparatedDate(
    state.testResultsV2.activeItem.created,
    'MM/dd/yyyy p'
  )
}
export const selectTestCollectionDate = (state: RootState) => {
  if (!state.testResultsV2.activeItem?.collectionDate) return null
  return getAtSeparatedDate(
    state.testResultsV2.activeItem.collectionDate,
    'MM/dd/yyyy p'
  )
}
export const selectTestShipName = (state: RootState) => {
  return state.testResultsV2.activeItem?.details.shippingCarrier?.name || null
}
export const selectTestStatus = (state: RootState) => {
  return state.testResultsV2.activeItem?.statusDisplayText || '-'
}
export const selectTestPickupAddress = ({ testResultsV2 }: RootState) => {
  const { error, activeItem, isLoading } = testResultsV2
  if (error || isLoading || !activeItem) return ''
  if (!activeItem.details.pickupDetails?.address) return ''

  const { address1, address2, city, state, zip } =
    activeItem.details.pickupDetails.address
  return [address1, address2, city, state, zip].filter(Boolean).join(', ')
}
export const selectTestResult = ({ testResultsV2 }: RootState) => {
  if (testResultsV2.activeItem?.status === 'COMPLETED') {
    return testResultsV2.activeItem.result
  }
  return null
}
export const selectTestAppointmentTime = ({ testResultsV2 }: RootState) => {
  if (!testResultsV2.activeItem?.details.appointment?.appointmentStart) {
    return null
  }
  return getAtSeparatedDate(
    testResultsV2.activeItem.details.appointment.appointmentStart,
    'EEEE MM/dd/yyyy p'
  )
}
export const selectTestLocation = ({ testResultsV2 }: RootState) => {
  return testResultsV2.activeItem?.details.appointment?.locationName || null
}
export const selectTestAddress = ({ testResultsV2 }: RootState) => {
  const { error, activeItem, isLoading } = testResultsV2
  if (error || isLoading || !activeItem) return null
  if (!activeItem.details.facility) return null

  const {
    homeAddressAddressOne,
    homeAddressAddressTwo,
    homeAddressAddressCity,
    homeAddressState,
    homeAddressZip,
  } = activeItem.details.facility
  return [
    homeAddressAddressOne,
    homeAddressAddressTwo,
    homeAddressAddressCity,
    homeAddressState,
    homeAddressZip,
  ]
    .filter(Boolean)
    .join(', ')
}
export const selectTestLabVendorPdf = ({ testResultsV2 }: RootState) => {
  const { error, activeItem, isLoading } = testResultsV2
  if (error || isLoading || !activeItem) return null
  if (!activeItem.details.labVendor?.isRequisitionSupported) return null
  return activeItem.requisitionBase64 || null
}
