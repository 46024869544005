import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HomeIcon from '@mui/icons-material/Home'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { styled } from '@mui/material'
import { ReactComponent as OnDelivery } from '../../../assets/icons/onDelivery.svg'
import SanityImage from '../../sanity/sanity-image'
import { SanityKeys } from '../../sanity/types'

const MUIIconStyle = { fill: 'inherit', fontSize: 20 }

export const Images = () => ({
  PreparingImage: (
    <SmallStyledSanityImage
      imageKey={SanityKeys.StatusCard_PrepairingVisitSummary}
    />
  ),
  InReviewImage: (
    <SmallStyledSanityImage imageKey={SanityKeys.StatusCard_InReview} />
  ),
  UpcomingImage: (
    <StyledSanityImage imageKey={SanityKeys.StatusCard_SyncAudioScheduled} />
  ),
  CancelledImage: (
    <StyledSanityImage
      imageKey={SanityKeys.StatusCard_PrescriptionCannotBeFilled}
    />
  ),
  CallMissedImage: (
    <StyledSanityImage imageKey={SanityKeys.StatusCard_ErrorMissedVisit} />
  ),
  NoTreatmentImage: (
    <StyledSanityImage imageKey={SanityKeys.StatusCard_NoTreatment} />
  ),
  PlanReadyImage: (
    <StyledSanityImage imageKey={SanityKeys.StatusCard_SummaryReady} />
  ),
  PickupReadyImage: (
    <StyledSanityImage imageKey={SanityKeys.StatusCard_PickUpAtPharmacy} />
  ),
  DeliveryImage: (
    <StyledSanityImage imageKey={SanityKeys.StatusCard_DeliveryOnTheWay} />
  ),
  ExpiredImage: <StyledSanityImage imageKey={SanityKeys.StatusCard_Expired} />,
  AltPharmLocImage: (
    <StyledSanityImage imageKey={SanityKeys.StatusCard_NotPreferredPharmacy} />
  ),
  ForDelivery: <LocalShippingIcon style={MUIIconStyle} />,
  EnRoute: <OnDelivery style={{ fill: 'inherit' }} />,
  Delivered: <HomeIcon style={MUIIconStyle} />,
  Complete: <CheckCircleIcon style={MUIIconStyle} />,
})

const StyledSanityImage = styled(SanityImage)(({ theme }) => ({
  height: 148,
  [theme.breakpoints.down(960)]: {
    height: 112,
  },
  [theme.breakpoints.down(600)]: {
    height: 64,
  },
}))

const SmallStyledSanityImage = styled(SanityImage)(({ theme }) => ({
  height: 84,
  [theme.breakpoints.down(600)]: {
    height: 64,
  },
}))

export const progressSteps = [
  {
    key: 'doorDash',
    image: Images().ForDelivery,
  },
  {
    key: 'onDelivery',
    image: Images().EnRoute,
  },
  {
    key: 'delivered',
    image: Images().Delivered,
  },
  {
    key: 'complete',
    image: Images().Complete,
  },
]
