import { SxProps } from '@mui/material'
import MUITypography from '@mui/material/Typography'
import React, { CSSProperties, PropsWithChildren } from 'react'

interface TypographyProps extends PropsWithChildren {
  variant?:
    | 'body1'
    | 'body2'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'inherit'
  color?: 'primary' | 'secondary' | 'initial' | 'inherit'
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  component?: React.ElementType
  className?: string
  sx?: SxProps
  style?: CSSProperties
}
export const Typography: React.FC<TypographyProps> = ({
  children,
  variant = 'body1',
  color = 'initial',
  align = 'left',
  component = 'p',
  className,
  sx,
  style,
  ...dataAttributes
}) => {
  return (
    <MUITypography
      variant={variant}
      component={component}
      color={color}
      align={align}
      style={style}
      sx={sx}
      className={className}
      {...dataAttributes}
    >
      {children}
    </MUITypography>
  )
}
