import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import { TestResultsList } from './components/test-results-list'
import { getTestResults } from './model/model'

export const TestResultsPage = () => {
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const navigate = useNavigate()
  const handleBack = () => navigate(paths.app.dashboard())
  const { isMobile } = useMUITheme()
  React.useEffect(() => {
    dispatch(getTestResults())
  }, [dispatch])

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      color="#505358"
      border={!isMobile}
    >
      <TestResultsList />
    </ModuleHeader>
  )
}
