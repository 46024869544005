import { Box } from '@mui/material'
import { ClassNameMap, makeStyles } from '@mui/styles'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { I18n } from '../../../features/translation/types'
import { ErrorField } from '../filled-error'
import { TextField, TextFieldProps } from '../text-field/text-field'
import { Typography } from '../typography'

type Segment = 'month' | 'day' | 'year'

const isDayValid = (day: number | undefined, month?: number, year?: number) => {
  if (!day) return false
  if (month) {
    return new Date(year || 2020, month, 0).getDate() >= day
  }
  return day <= 31
}

export const DateTextField = (
  props: TextFieldProps & {
    disabled?: boolean
    helperText?: string
    handleChange: (value: string) => void
    monthLabel?: string
    dayLabel?: string
    yearLabel?: string
    separator?: string
    required?: boolean
    i18n: I18n
  }
) => {
  const {
    name,
    label,
    handleChange,
    error,
    helperText,
    value,
    onBlur,
    disabled,
    monthLabel = 'MM',
    dayLabel = 'DD',
    yearLabel = 'YYYY',
    separator = '/',
    required = false,
    i18n,
  } = props
  const classes = useIconStyles()
  const [values, setValues] = useState<string[]>(['', '', ''])
  const [dayError, setDayError] = useState('')
  const [yearError, setYearError] = useState('')

  const monthRef = useRef<HTMLInputElement>(null)
  const dayRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (value && value !== 'Invalid date') {
      try {
        setValues(value.split('/'))
      } catch {
        console.log('Error setting date')
      }
    }
  }, [])

  const checkForDayError = (newValue: Array<string>) => {
    if (
      newValue[1] &&
      newValue[1].length === 2 &&
      newValue[2] &&
      newValue[2].length === 4 &&
      !isDayValid(
        Number.parseInt(newValue[1], 10),
        Number.parseInt(newValue[0], 10),
        Number.parseInt(newValue[2], 10)
      )
    ) {
      if (newValue[1] === '29') {
        setYearError(i18n.invalid_date)
        setDayError('')
      } else setDayError(i18n.invalid_date)
    } else {
      setDayError('')
      setYearError('')
    }
  }

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    segment: Segment
  ) => {
    if (
      ((segment === 'month' || segment === 'day') &&
        e.target.value &&
        e.target.value.length > 2) ||
      (segment === 'year' && e.target.value && e.target.value.length > 4)
    ) {
      return
    }
    const newValue = [...values]
    if (segment === 'month') {
      newValue[0] = e.target.value
        .replace(
          /[^0-9]/g,
          '' // To allow only numbers
        )
        .replace(
          /^([2-9])$/g,
          '0$1' // To handle 3 > 03
        )
      if (
        (newValue[0] && Number.parseInt(newValue[0], 10) > 12) ||
        newValue[0] === '00'
      )
        return null
      setValues(newValue)
      if (newValue[0].length === 2) {
        //Verify existing day value remains valid if month has changed
        checkForDayError(newValue)
        dayRef.current?.focus()
        dayRef.current?.setSelectionRange(0, 2)
      }
    } else if (segment === 'day') {
      newValue[1] = e.target.value
        .replace(
          /[^0-9]/g,
          '' // To allow only numbers
        )
        .replace(
          /^([4-9])$/g,
          '0$1' // To handle 4 > 04
        )
      if (
        newValue[1].length === 2 &&
        !isDayValid(
          Number.parseInt(newValue[1], 10),
          Number.parseInt(newValue[0], 10),
          Number.parseInt(newValue[2], 10)
        )
      )
        return
      if (dayError) setDayError('')
      setValues(newValue)
      if (newValue[1].length === 2) {
        yearRef.current?.focus()
        yearRef.current?.setSelectionRange(0, 4)
      }
    } else {
      newValue[2] = e.target.value.replace(
        /[^0-9]/g,
        '' // To allow only numbers
      )
      //Verify existing day value remains valid if month has changed
      checkForDayError(newValue)
      if (Number.parseInt(newValue[2], 10) > new Date().getFullYear()) {
        return
      }
      setValues(newValue)
    }
    handleChange(newValue.join(separator))
  }
  const handleFocus = (segment: Segment) => {
    if (segment === 'month') {
      monthRef.current?.setSelectionRange(0, 2)
    } else if (segment === 'day') {
      dayRef.current?.setSelectionRange(0, 2)
    }
  }
  const handleBlur = (segment: Segment) => {
    const newValue = [...values]
    if (segment === 'month' && monthRef.current?.value.length === 1) {
      newValue[0] = `0${monthRef.current.value}`
      setValues(newValue)
    } else if (segment === 'day' && dayRef.current?.value.length === 1) {
      newValue[1] = `0${dayRef.current.value}`
      setValues(newValue)
    }
    handleChange(newValue.join(separator))
  }

  return (
    <div className={dayError || error ? classes.showingError : undefined}>
      <Typography
        className={
          dayError || error || yearError
            ? `${classes.label} error`
            : classes.label
        }
      >
        <>
          {label}
          {required && ' *'}
        </>
      </Typography>
      <div className={classes.fieldsLine}>
        <div className={classes.smallField}>
          <FieldWrapper
            disabled={Boolean(disabled)}
            error={Boolean(error) && !dayError && !yearError}
          >
            <TextField
              inputRef={monthRef}
              variant="filled"
              className={`${classes.root} small`}
              label={monthLabel}
              value={values[0] || ''}
              onChange={(e) => onChange(e, 'month')}
              type={'text'}
              name={name}
              autoComplete="bday-month"
              onFocus={() => handleFocus('month')}
              onBlur={() => handleBlur('month')}
              disabled={disabled}
              error={Boolean(error) && !dayError && !yearError}
              inputProps={{
                inputMode: 'numeric',
              }}
            />
          </FieldWrapper>
          {((error && helperText) || dayError || yearError) && (
            <div className={classes.dayError}>
              <ErrorField error={dayError || yearError || helperText || ''} />
            </div>
          )}
        </div>
        <Separator
          separator={separator}
          classes={classes}
          disabled={disabled}
        />
        <div className={classes.smallField}>
          <FieldWrapper
            disabled={Boolean(disabled)}
            error={(Boolean(error) || Boolean(dayError)) && !yearError}
          >
            <TextField
              inputRef={dayRef}
              variant="filled"
              className={`${classes.root} small`}
              label={dayLabel}
              value={values[1] || ''}
              onChange={(e) => onChange(e, 'day')}
              type={'text'}
              name={name}
              autoComplete="bday-day"
              onBlur={() => handleBlur('day')}
              onFocus={() => handleFocus('day')}
              error={(Boolean(error) || Boolean(dayError)) && !yearError}
              disabled={disabled}
              inputProps={{
                inputMode: 'numeric',
              }}
            />
          </FieldWrapper>
        </div>
        <Separator
          separator={separator}
          classes={classes}
          disabled={disabled}
        />
        <FieldWrapper
          disabled={Boolean(disabled)}
          error={(Boolean(error) && !dayError) || Boolean(yearError)}
        >
          <TextField
            inputRef={yearRef}
            variant="filled"
            className={classes.root}
            label={yearLabel}
            value={values[2] || ''}
            onChange={(e) => onChange(e, 'year')}
            type={'text'}
            name={name}
            autoComplete="bday-year"
            onBlur={onBlur}
            disabled={disabled}
            error={(Boolean(error) && !dayError) || Boolean(yearError)}
            inputProps={{
              inputMode: 'numeric',
            }}
          />
        </FieldWrapper>
      </div>
    </div>
  )
}

const FieldWrapper = (props: {
  children: ReactNode
  error: boolean
  disabled: boolean
}) => {
  const { children, error, disabled } = props
  const classes = useIconStyles()

  const getClasses = () => {
    let filledClass = `${classes.fieldContainer}`
    if (error) {
      filledClass += ` ${classes.inError}`
    }
    if (disabled) {
      filledClass += ` ${classes.disabled}`
    }

    return filledClass
  }

  return (
    <Box className={classes.fieldWrapper}>
      <Box className={getClasses()}>{children}</Box>
    </Box>
  )
}

const Separator = (props: {
  separator: string
  classes: ClassNameMap
  disabled: boolean | undefined
}) => {
  const { separator, classes, disabled } = props

  return (
    <Typography
      className={disabled ? `${classes.separator} disabled` : classes.separator}
    >
      {separator}
    </Typography>
  )
}

const useIconStyles = makeStyles((theme) => ({
  root: {
    '& ::-ms-reveal': {
      display: 'none',
    },
    '& input': {
      textAlign: 'center',
    },
    '& label': {
      'width': '100%',
      'textAlign': 'center',
      'transform': 'translate(0px, 20px) scale(1)',
      'fontSize': 16,
      'color': '#6E6E6E',
      'fontWeight': 500,
      'maxWidth': '100%',
      '&.MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(0px, 10px) scale(1)',
        fontSize: 12,
        fontWeight: 400,
      },
      '&.MuiFormLabel-root.Mui-disabled': {
        color: 'rgba(0, 0, 0, 0.38)',
      },
    },
  },
  label: {
    'marginTop': '-11px',
    'marginBottom': 4,
    'fontSize': 12,
    'fontWeight': 500,
    'color': '#6E6E6E',
    '&.error': {
      color: theme.palette.error.main,
    },
  },
  smallField: {
    width: '25%',
    [theme.breakpoints.down(600)]: {
      width: '35%',
    },
  },
  iconBtn: {
    'width': 12,
    'height': 12,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: 'rgb(0, 60, 114)',
    },
  },
  dayError: {
    position: 'absolute',
  },
  fieldsLine: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  separator: {
    'color': '#79747E',
    'fontSize': 24,
    '&.disabled': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
  fieldContainer: {
    display: 'flex',
    padding: '1px',
    width: 'calc(100% - 4px)',
    border: '1px solid #79747E',
    borderRadius: '4px',
  },
  showingError: {
    marginBottom: 16,
  },
  inError: {
    border: '1px solid',
    borderColor: theme.palette.error.main,
  },
  valueLabel: {
    minWidth: 'fit-content',
    alignSelf: 'center',
    marginRight: 24,
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: '12px',
  },
  disabled: {
    borderStyle: 'dashed !important',
    borderColor: '#79747E !important',
  },
}))
