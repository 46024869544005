import { ColorWithHex } from '../../types/general'

interface TranslationString {
  translations: Record<string, string>
  validatedLanguages: string[]
}
export interface Outcome {
  code: null
  coding: {
    system: null
    code: null
    description: null
  }
  description: TranslationString | null
  id: string
  image: string
  links: []
  name: TranslationString | null
  noDisplay: boolean
  report: null
  shortName: null
  tenantId: string
  orderableTestIdList: string[]
  title: TranslationString | null
  type: string
}

export interface OutcomeTestDetails {
  _id: string
  price: number
  imageURL: string
  customTestName: string
  shortDescription: string
  scanImageUrl: string
}
export interface StartQuestionnairePayload {
  email: string
  accountId: string
  questionnaireID: string
}
export interface ResumeQuestionnairePayload {
  executionId: string
}

export interface QuestionnaireContract {
  question?: {
    id: string
    name: null | string
    question: {
      id: string
      name: TranslationString | null
      shortName: string
      description: TranslationString | null
      enableAlpha: boolean
      help: TranslationString | null
      helpDescription: TranslationString | null
      introduction: null
      readOnly: boolean
      required: boolean
      coding: {
        system: null
        code: null
        description: null
      }
      tenantId: string
      created: null
      updated: null
      createdBy: null
      tags: null
      status: 'ACTIVE'
      autoAnswerOption: null | Record<string, string>
      min: number | null
      max: number | null
      maxFeet: number | null
      maxInches: number | null
      defaultValue: null
      step: number
      type: QuestionType
      options?: Option[]
      // options?: number[] | Option[]
      multiSelect: boolean
      containsNone: boolean
      precision: AllowedPrecisions
    }
    outcome: Outcome | null
    priority: null
    ruleStatements: []
    rules: StrangeRule[]
    defaultNextQuestionId: string
    defaultRoute: {
      defaultNextQuestionId: string
      coding: null
    }
  }
  complete: boolean
  executionId: string
}

export interface QuestionnaireColor {
  color: QuestionnaireColorType | undefined
  colorHex: ColorWithHex | undefined
}

export enum QuestionnaireColorType {
  primary = 'primary',
  secondary = 'secondary',
  custom = 'custom',
}

export interface Question {
  id: string
  name: TranslationString | null
  shortName: string
  description: TranslationString | null
  enableAlpha: boolean
  image: string | null
  help: TranslationString | null
  helpDescription: TranslationString | null
  introduction: null
  readOnly: boolean
  required: boolean
  coding: {
    system: null
    code: null
    description: null
  }
  tenantId: string
  created: null
  updated: null
  createdBy: null
  tags: null
  status: 'ACTIVE'
  autoAnswerOption: null | Record<string, string>
  min: number | null
  max: number | null
  label: string | null
  maxFeet: number | null
  maxInches: number | null
  defaultValue: null
  step: number
  type: QuestionType
  options?: Option[]
  // options?: number[] | Option[]
  multiSelect: boolean
  unit: QuestionUnits
  containsNone: boolean
  precision: AllowedPrecisions
}

type QuestionUnits = 'temperature' | 'days' | 'pounds' | null

export interface QuestionnaireProgress {
  currentQuestion: number
  totalQuestions: number
}

interface StrangeRule {
  nextQuestionId: string
  coding: null
  rules: {
    type: 'multiple'
    questionId: string
    expectedAnswer: 'Female' | 'Male'
    operation: 'CONTAINS'
  }[]
}

export interface Option {
  value: TranslationString
  score: null
  coding: {
    system: null
    code: null
    description: null
  }
}

export enum QuestionType {
  Multiple = 'multiple',
  Numeric = 'numeric',
  Date = 'date',
  Scale = 'scale',
  LargeText = 'large-text',
  SmallText = 'small-text',
  YesNo = 'yes-no',
  Slider = 'slider',
  Height = 'height',
}

export interface GetResultContractPayload {
  executionId: string
  accountId: string
  email: string
}
export interface GetResultContract {
  result: {
    id: string
    shortName: null
    tenantId: string
    name: TranslationString
    title: TranslationString
    image: string | null
    description: TranslationString
    coding: null | {
      system: string
      code: string
      description: string
    }
    report: null
    links: []
    code: null | string
    type: string
  }
}

export interface NextQuestionPayload {
  questionId: string
  executionId: string
  answer: string[] | string | number
  type: QuestionType
  accountId: string
  email: string
}

export interface MultipleChoiceAnswer {
  answer: string
  nodeId: string | null
  comment: string | null
  type: QuestionType
}

export type AllowedPrecisions = 'YEARS' | null | 'WEEKS' | 'DAYS' | 'MONTHS'

export interface DispositionScreen {
  title: string
  body: string
  image: () => JSX.Element
}

export type CurrentOptions = 'phone' | 'shipping-address' | 'current-address'
