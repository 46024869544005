import { makeStyles } from '@mui/styles'

export const faqStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  pageTitle: {
    'color': '#282D37',
    'fontSize': 36,
    'fontWeight': 600,
    '&.noMargin': {
      marginBottom: 0,
    },
    [theme.breakpoints.down(600)]: {
      '&.noPadding': {
        paddingLeft: 0,
      },
      '&.noMargin': {
        marginBottom: 0,
      },
    },
  },
  searchContainer: {
    width: '100%',
    margin: '24px 0px',
    [theme.breakpoints.down(600)]: {
      margin: '32px 0px 16px',

      width: 'calc(100% - 40px)',
    },
  },
  mobileContactWrapper: {
    position: 'fixed',
    padding: '24px 0px',
    width: '100vw',
    backgroundColor: '#FFF',
    bottom: 0,
    left: 0,
  },
  titleWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pageLink: {
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
  },
  noContent: {
    width: '100%',
    margin: 'auto',
    fontSize: 24,
  },
  loading: {
    marginTop: 150,
  },
  faqList: {
    marginTop: 78,
    margin: '78px auto 0px',
    width: '100%',
    maxWidth: '800px',
    [theme.breakpoints.down(960)]: {
      width: '90vw',
    },
    [theme.breakpoints.down(600)]: {
      marginTop: 16,
    },
  },
  faqDetailTitle: {
    fontWeight: 600,
    fontSize: 16,
    color: '#505358',
    padding: '12px 0px',
  },
  faqDetailDesription: {
    'marginLeft': '0px',
    'marginTop': '-12px',
    'width': '90%',
    '& p': {
      margin: '0px !important',
    },
    '& span': {
      fontWeight: '400 !important',
      fontSize: '14px !important',
      color: '#898989 !important',
    },
  },
  newHelpCenter: {
    borderTop: '1px solid #E0E0E0',
    marginBottom: 64,
    maxWidth: 800,
    gap: 16,
    width: '100%',
    minHeight: 'fit-content',
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: '95vw',
    },
  },
  expandIcon: {
    color: '#757575',
  },
  listWrapper: {
    marginTop: '-1px',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
  },
}))
