import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../../api'
import { NOTIFICATION_TYPES } from '../../../constants'
import { showNotification } from '../../../features/notifications/model'
import { I18n } from '../../../features/translation/types'
import { handleError } from '../../../libs/helpers'
import { Dependent } from './types'

interface DependentsSlice {
  dependents: Dependent[]
  isLoading: boolean
}
const initialStore: DependentsSlice = {
  dependents: [],
  isLoading: false,
}

export const dependentSlice = createSlice({
  name: 'dependents',
  initialState: initialStore,
  reducers: {
    setDependents(store, { payload }: { payload: Dependent[] }) {
      store.dependents = payload
    },
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
  },
})

const { setLoading, setDependents } = dependentSlice.actions

export const getDependents =
  (memberId: string, callback?: () => void) => async (dispatch: any) => {
    const { isOK, data } = await api.getDependents(memberId)

    if (isOK && data) {
      if (callback) {
        callback()
      }
      dispatch(setDependents(data))
    }
  }

export const inviteDependent =
  (payload: { email: string; dependentId: string }, callback?: () => void) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const { isOK, data } = await api.inviteDependent(payload)
    dispatch(setLoading(false))
    if (isOK && data) {
      if (callback) {
        callback()
      }
    }
  }

export const dependentsReducer = dependentSlice.reducer
export const dependentsReducerName = dependentSlice.name

interface RootStore {
  [dependentsReducerName]: typeof initialStore
}

export const selectDependentsIsLoading = ({ dependents }: RootStore) =>
  dependents.isLoading

export const selectDependents = ({ dependents }: RootStore) =>
  dependents.dependents
