import { makeStyles } from '@mui/styles'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getSavedDeviceValues,
  userHasDeviceCookie,
} from '../../../../../features/cookies/cookie-helper'
import { selectLanguageContent } from '../../../../../features/translation'
import { forgetMFADevice } from '../../../../../features/user/model/userTenantSlice'
import { Button, Typography } from '../../../../../ui'
import { paths } from '../../../../paths'
import { selectProfileData } from '../../../../profile/model'
import { SecuritySettingsType, SettingsTabType } from '../../../type'

export const ForgetDevice = () => {
  // const username = localStorage.getItem('email') || ''
  // const cookieName = `${btoa(username)}_safe_device`
  const classes = useStyles()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies()
  const profile = useSelector(selectProfileData)
  const rememberedDevice = userHasDeviceCookie(profile.email)

  if (!rememberedDevice) {
    navigate(
      paths.settingsTab(
        SettingsTabType.SECURITY,
        SecuritySettingsType.PASSWORD
      )
    )
  }

  const forgetDevice = () => {
    const callback = () => {
      removeCookie(`${btoa(profile.email)}_safe_device`, { path: '/' })
      navigate(
        paths.settingsTab(
          SettingsTabType.SECURITY,
          SecuritySettingsType.PASSWORD
        )
      )
    }
    if (!rememberedDevice) return
    const accessToken = sessionStorage.getItem('access_token')
    const deviceParams = getSavedDeviceValues(profile.email)
    const apiParams = {
      ...deviceParams,
      accessToken,
      username: profile.email,
    }
    dispatch(forgetMFADevice(apiParams, callback, i18n))
  }

  return (
    <div className={classes.forgetWrapper}>
      <Typography>{i18n.forget_this_device_disclaimer}</Typography>
      <Button
        onClick={forgetDevice}
        className={classes.confirmButton}
        disabled={!rememberedDevice}
      >
        {i18n.forget_this_device}
      </Button>
    </div>
  )
}

const useStyles = makeStyles((_theme) => ({
  forgetWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 26,
  },
  confirmButton: {
    maxWidth: 'fit-content',
    alignSelf: 'flex-end',
  },
}))
