import { AccountTab } from './tabs/account/index'
import { EmergencyContact } from './tabs/account/sections/emergency-contact'
import { DependentsTab } from './tabs/dependents'
import { SecurityTab } from './tabs/security'
import {
  AccountSettingsType,
  SecuritySettingsType,
  SettingsTabs,
  SettingsTabType,
} from './type'

export const settingsTabs: SettingsTabs = {
  [SettingsTabType.ACCOUNT]: {
    label_key: 'accout_settings_section',
    content: AccountTab,
    defaultSection: AccountSettingsType.PERSONAL_INFORMATION,
  },
  [SettingsTabType.SECURITY]: {
    label_key: 'security_settings_section',
    content: SecurityTab,
    defaultSection: SecuritySettingsType.PASSWORD,
  },
  // [SettingsTabType.SUPPORT]: {
  //   label_key: 'support_settings_section',
  //   content: EmergencyContact,
  //   defaultSection: AccountSettingsType.PERSONAL_INFORMATION,
  // },
  // [SettingsTabType.LEGAL_NOTICES]: {
  //   label_key: 'legal_notices_settings_section',
  //   content: DependentsTab,
  //   defaultSection: AccountSettingsType.PERSONAL_INFORMATION,
  // },
}
