import { Box, useTheme } from '@mui/material'
import AddCommentIcon from '@mui/icons-material/AddComment'
import StarRateIcon from '@mui/icons-material/StarRate'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as StarOutline } from '../../../assets/icons/star-outline.svg'
import { selectEncounter } from '../../../features/encounter/model'
import { selectLanguageContent } from '../../../features/translation'
import { lsClient } from '../../../ls-client'
import { Button, TextField, Typography } from '../../../ui'
import { Wrapper } from '../../../ui/templates/app-layout'
import { paths } from '../../paths'
import { ProviderAvatar } from '../components/providerAvatar'
import { telehealthStyles } from '../components/styles'

export const RateYourExperiencePage = (props: any) => {
  const { handleNext } = props
  const theme = useTheme()
  const classes = telehealthStyles()
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)
  const memberCancelled = lsClient.getUserLSByKey('memberCancelled')
  const [rating, setRating] = useState(0)
  const [hover, setHover] = useState(-1)
  const [message, setMessage] = useState('')
  const [addComment, setAddComment] = useState(false)
  const stars = [1, 2, 3, 4, 5]
  const [doctorName, setDoctorName] = useState('')
  const encounterRecord = useSelector(selectEncounter)

  useEffect(() => {
    setDoctorName(
      `${encounterRecord?.provider?.firstName} ${encounterRecord?.provider?.lastName}`
    )
  }, [encounterRecord])

  const onChangeMessage = (e: any) => {
    const text = e.target.value
    setMessage(text)
  }

  const hasComment = () => {
    setMessage('')
    setAddComment(!addComment)
  }

  const skipStep = () => {
    lsClient.setUserLS('ratedProvider', 'yes')
    navigate(paths.telehealth('complete'))
  }

  return (
    <Wrapper>
      <Box onClick={skipStep} className={classes.skip}>
        <Typography variant="body1">{i18n.skip}</Typography>
      </Box>
      <Box>
        <Typography className={classes.experienceHeader} variant="h2">
          {memberCancelled
            ? i18n.telehealth_member_left_title
            : i18n.telehealth_clinician_left_title}
        </Typography>
      </Box>
      <Box className={classes.imageOutline}>
        <ProviderAvatar />
      </Box>
      <Box className={classes.mt1}>
        <Typography
          variant="h6"
          align="center"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {doctorName.includes('undefined')
            ? i18n.how_was_expirience
            : i18n.how_was_expirience_with.replace(`%@`, doctorName)}
        </Typography>
      </Box>
      <Box
        onMouseLeave={() => setHover(-1)}
        className={classes.ratingContainer}
      >
        {stars.map((s) => (
          <React.Fragment key={`rating-${s}`}>
            {s > (hover > 0 ? hover : rating) ? (
              <Box
                className={classes.starOutline}
                onClick={() => setRating(s)}
                onMouseOver={() => setHover(s)}
              >
                <StarOutline fill={theme.palette.primary.main} />
              </Box>
            ) : (
              <StarRateIcon
                className={classes.star}
                onClick={() => setRating(s)}
                onMouseOver={() => setHover(s)}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <Box className={classes.addComment} onClick={hasComment}>
        {addComment ? (
          <>
            <AddCommentIcon style={{ marginRight: 16 }} />
            <Typography variant="body1">
              {i18n.remove_comment_placeholder}
            </Typography>
          </>
        ) : (
          <>
            <AddCommentIcon style={{ marginRight: 16 }} />
            <Typography variant="body1">{i18n.comment_placeholder}</Typography>
          </>
        )}
      </Box>
      <Box className={classes.addCommentContainer}>
        {addComment ? (
          <TextField
            variant="outlined"
            label={i18n.comment_placeholder}
            type="textarea"
            InputLabelProps={{ shrink: true }}
            onChange={onChangeMessage}
            placeholder={i18n.telehealth_feedback_placeholder}
            isMultiline={true}
            rows={5}
            value={message}
          />
        ) : (
          <Typography variant="body2">
            {i18n.telehealth_feedback_placeholder}
          </Typography>
        )}
      </Box>

      <div className={classes.buttonWrapper}>
        <Button
          color="primary"
          className={classes.actionBtn}
          onClick={handleNext}
          disabled={rating === 0}
        >
          {i18n.submit_button}
        </Button>
      </div>
    </Wrapper>
  )
}
