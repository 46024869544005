import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { ListItemTitle, ListWrapper } from '../../libs/shared-components'
import { PageTitle } from '../../ui/templates/generic-screens/helpers/styled-components'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import { healthRecordTypeMap } from './page-mapping'
import { ContentWrapper } from './shared/components/shared-components'
import { HealthRecordType } from './type'

export const HealthRecordListPage = () => {
  const navigate = useNavigate()

  const i18n = useSelector(selectLanguageContent)

  const handleback = () => {
    navigate(paths.app.dashboard())
  }

  const handleClick = (type: HealthRecordType) => {
    navigate(paths.healthRecordType(type))
  }

  return (
    <ModuleHeader
      leftContentAction={handleback}
      leftContentTitle={i18n.back_button}
      color="#505358"
    >
      <ContentWrapper>
        <PageTitle>{i18n.health_record}</PageTitle>
        {Object.values(healthRecordTypeMap(i18n)).map((recordType) => (
          <ListWrapper
            key={recordType.type}
            onClick={() => handleClick(recordType.type)}
          >
            <ListItemTitle>{recordType.label}</ListItemTitle>
            <div style={{ color: '#898989' }}>
              <ChevronRightRoundedIcon color="inherit" />
            </div>
          </ListWrapper>
        ))}
      </ContentWrapper>
    </ModuleHeader>
  )
}
