import * as Yup from 'yup'
import { I18n } from '../../translation/types'

// eslint-disable-next-line import/no-anonymous-default-export
export default (i18n: I18n) =>
  Yup.object().shape({
    email: Yup.string()
      .email(i18n.invalid_email_error_message)
      .required(i18n.required),
    password: Yup.string().required(i18n.required),
  })
