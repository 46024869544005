import { Address } from '../../../types/user'

export enum StoreStepType {
  PRODUCTS = 'products',
  PRODUCT_DETAIL = 'product-detail',
  CART = 'cart',
  CHECKOUT = 'checkout',
  ORDER_PROCESSING = 'order-processing',
  ORDER_HISTORY = 'order-history',
  ORDER_HISTORY_DETAIL = 'order-history-detail',
}

export enum UserDataMethods {
  SHIPPING_METHOD = 'shipping-method',
  SHIPPING_ADDRESS = 'shipping-address',
  PAYMENT_METHOD = 'payment-method',
  BILLING_ADDRESS = 'billing-address',
  DELIVERY_TIP = 'delivery-driver-tip',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  CONFIRM_ADDRESS = 'confirm-address',
}

export interface AddressFormValues {
  line1: string
  line2: string
  city: string
  state: string
  country: string
  zip: string
}

export interface CreditCardFormValues {
  cardNumber: string
  cvc: string
  expiration: string
  cardName: string
  zip: string
}

export interface PaymentMethod {
  paymentType: PaymentType | undefined
  cardData?: CardData
  insuranceValue?: string //?
  setAsDefault: boolean
}

export interface OrderAddress {
  address: Address
  setAsDefault: boolean
}

export interface ShippingMethod {
  shippingMethod: ShippingOptions | undefined
}

export interface DeliveryTip {
  tip: number | undefined
}

export enum ShippingOptions {
  DOORDASH = 'DOORDASH',
  STANDARD_SHIPPING = 'STANDARD',
  EXPRESS_SHIPPING = 'EXPRESS_SHIPPING',
  PRIORITY_SHIPPING = 'PRIORITY_SHIPPING',
  FREE_SHIPPING = 'FREE_SHIPPING',
}

export enum PaymentType {
  CREDIT_CARD = 'CREDIT_CARD',
  APPLE_PAY = 'APPLE_PAY',
  INSURANCE = 'INSURANCE',
}

export interface CardData {
  cardNumber: string
  cvc: number
  expiration: string
  cardName: string
  zipCode: number
}
