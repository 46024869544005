import { sign } from 'crypto'
import styled from '@emotion/styled'
import { Button, Dialog, Toolbar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SignUpFaq } from '../../../../../pages/faq/signup-faq'
import { getSignupType } from '../../../../../pages/register/model/registerSlice'
import { OnboardingType } from '../../../../../pages/register/model/type'
import { selectDocTerm } from '../../../../terms'
import { clearDocTerm, fetchTermByKey } from '../../../../terms/model/main'
import { selectDocLoading } from '../../../../terms/model/selectors'
import { selectLanguageContent } from '../../../../translation'
import { SignupHeader } from '../signup-header'
import { SignupTerms } from './termsButtonDialog'

interface SignupTermsProps {
  isOpen: boolean
  closeDialog: () => void
}

type FooterVerbiageKey = 'TermsOfService' | 'PrivacyPolicy' | ''

export const RegisterFAQs = (props: SignupTermsProps) => {
  const { isOpen, closeDialog } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [verbiageKey, setVerbiageKey] = useState<FooterVerbiageKey>('')
  const [verbiageOpen, setVerbiageOpen] = useState(false)
  const signupType = useSelector(getSignupType)
  const isInvite = Boolean(
    signupType === OnboardingType.DEPENDENT ||
      signupType === OnboardingType.INVITE
  )

  const openTerms = (key: FooterVerbiageKey) => {
    setVerbiageKey(key)
    setVerbiageOpen(true)
  }

  const closeTerms = () => {
    setVerbiageKey('')
    dispatch(clearDocTerm())
    setVerbiageOpen(false)
  }

  return (
    <Dialog open={isOpen} fullScreen>
      <SignupHeader />
      <SignUpFaq onClose={closeDialog} />
      {!isInvite && (
        <Toolbar className={classes.toolBar} disableGutters={true}>
          <Typography className={classes.copyring}>
            Powered by Safe Health Systems
          </Typography>
          <RightContentWrapper className={classes.bottomContent}>
            <Button
              aria-label="Terms of Use"
              onClick={() => openTerms('TermsOfService')}
            >
              <Typography className={classes.bottomContentItem}>
                Terms of Use
              </Typography>
            </Button>
            <Button
              aria-label="Privacy Policy"
              onClick={() => openTerms('PrivacyPolicy')}
            >
              <Typography className={classes.bottomContentItem}>
                Privacy Policy
              </Typography>
            </Button>
            <a
              href="mailto:support@safehealth.me"
              style={{ textDecoration: 'none' }}
            >
              <Typography className={classes.bottomContentItem}>
                Contact Us
              </Typography>
            </a>
          </RightContentWrapper>
        </Toolbar>
      )}
      <SignupTerms
        isOpen={verbiageOpen}
        verbiageKey={verbiageKey}
        closeDialog={closeTerms}
      />
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pageWrapper: {
    minWidth: '600px',
    padding: '24px 0px',
    [theme.breakpoints.down(600)]: {
      minWidth: '84vw',
    },
  },
  detailContent: {
    color: '#545454',
  },
  detailContainer: {
    marginTop: '24px',
    padding: '16px 24px',
    height: 'calc(92vh - 200px)',
    overflow: 'auto',
  },
  infoText: {
    margin: '24px 0',
    fontWeight: 500,
    fontSize: '30px',
    textAlign: 'left',
    [theme.breakpoints.down(600)]: {
      fontSize: '24px',
      margin: '0px',
    },
  },
  toolBar: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#FFF',
    flexDirection: 'column',
    justifyContent: 'center',
    boxShadow: 'none',
    padding: '32px 0',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.down(600)]: {
      padding: '16px 0',
    },
  },
  copyring: {
    fontSize: 16,
    lineHeight: '22px',
    color: '#7E7E7E',
  },
  bottomContent: {
    display: 'flex',
    padding: '0 5px',
    boxSizing: 'border-box',
    justifyContent: 'center',
    marginTop: 8,
    width: '100%',
    maxWidth: 500,
    [theme.breakpoints.up('sm')]: {
      gap: 25,
    },
  },
  bottomContentItem: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1rem',
    // minWidth: '100px',
    lineHeight: '22px',
    color: '#7E7E7E',
    textAlign: 'center',
    [theme.breakpoints.down(300)]: {
      fontSize: '.8rem',
      minWidth: '80px',
    },
  },
  bottomToolbar: {
    marginBottom: '1rem',
  },
}))

const RightContentWrapper = styled.div`
  display: flex;
  align-items: center;
`
