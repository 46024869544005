import MUICheckbox from '@mui/material/Checkbox'
import React from 'react'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { getColor } from '../../../libs/utils'
import { ColorWithHex } from '../../../types/general'

interface RoundedCheckboxProps {
  checked: boolean
  color: ColorWithHex
  onChange?: (e: React.ChangeEvent) => void
}
export const RoundedCheckbox = ({
  checked = false,
  onChange,
  color,
}: RoundedCheckboxProps) => {
  const { theme } = useMUITheme()
  const configuredColor = getColor(color, theme)
  return (
    <MUICheckbox
      sx={{
        'margin': '2px 17px 0px -8px',
        '&:before': {
          content: '""',
          display: 'inline-flex',
          width: 16,
          height: 16,
          borderRadius: '50%',
          border: '2px solid',
          borderColor: checked ? configuredColor : '#505358',
          flexShrink: 0,
          backgroundColor: '#FFF',
        },
        '&:after': checked
          ? {
              content: '""',
              position: 'absolute',
              borderRadius: '50%',
              border: '5px solid',
              borderColor: configuredColor,
            }
          : {},
        '&.MuiCheckbox-colorSecondary:hover': {
          backgroundColor: '#fff',
        },
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      }}
      disableRipple
      checked={checked}
      onChange={onChange}
    />
  )
}
