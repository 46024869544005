import axios from 'axios'

export interface SanityStoreType {
  loading: boolean
  imagesFetched: boolean
  account: string
  appImages: SanityImageType[]
  callbacks: { cb: (imgKey: string) => void; imageKey: string }[]
}

const sanityStore: SanityStoreType = {
  loading: false,
  imagesFetched: false,
  account: '',
  appImages: [],
  callbacks: [],
}

export interface SanityImageType {
  url?: string
  mimeType?: string
  imageKey?: string
}
const SANITY_API_ENDPOINT = (projecId = '', dataSet = '', perspective = 'published') =>
  `https://${projecId}.api.sanity.io/v2021-10-21/data/query/${dataSet}?perspective=${perspective}&query=`
const SANITY_APP_IMAGES_QUERY = (account = '') =>
  `*[_type%20==%20"appImageContent"%20%26%26%20account->name%20==%20"${account}"]{name,_id,account,%27appImages%27:%20appImages[]->{name,%20%27_ref%27:%20image._ref,%20%27url%27:%20image->image.asset->url,%20%27mimeType%27:%20image->image.asset->mimeType,%20%27imageKey%27:imageType->name}}`

const SanityApiStore = () => {
  const isLoading = () => sanityStore.loading === true

  const setAccount = (account: string) => {
    sanityStore.account = account
  }
  const setAppImages = (appImages: SanityImageType[]) => {
    sanityStore.appImages = appImages
  }
  const getAppImages = () => sanityStore.appImages
  const setLoading = (isLoading: boolean) => {
    sanityStore.loading = isLoading
  }

  const setImagesFetched = () => {
    sanityStore.imagesFetched = true
  }

  const getImages = async () => {
    if (getAppImages().length > 0 || isLoading() || sanityStore.imagesFetched) {
      return
    }

    setLoading(true)

    try {
      const response: any = await axios.get(
        `${SANITY_API_ENDPOINT(
          process.env.REACT_APP_SANITY_PROJECT_ID,
          process.env.REACT_APP_SANITY_DATASET,
          process.env.REACT_APP_SANITY_PERSPECTIVE
        )}${SANITY_APP_IMAGES_QUERY(sanityStore.account)}`
      )
      setAppImages(response.data.result[0].appImages)
      setLoading(false)
      setImagesFetched()
      runCallbacks()
      return sanityStore.appImages
    } catch {
      setLoading(false)
      setImagesFetched()
    }
  }

  const getImageCallback = (imgKey: string, cFunc: any) => {
    if (getAppImages().length <= 0)
      sanityStore.callbacks.push({ cb: cFunc, imageKey: imgKey })
    else {
      cFunc(getImageByKey(imgKey))
    }
  }

  const runCallbacks = () => {
    sanityStore.callbacks.forEach((cb) => {
      cb.cb(getImageByKey(cb.imageKey))
    })
  }

  const getImageByKey = (imgKey: string) => {
    const imgUrl: any =
      getAppImages().find(
        ({ imageKey }: SanityImageType) => imageKey === imgKey
      ) || ''
    return imgUrl.url
  }

  return {
    setAccount,
    getImages,
    getImageCallback,
    getImageByKey,
    isLoading,
  }
}

export default SanityApiStore
