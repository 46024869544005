import { Box } from '@mui/material'
import React from 'react'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Typography } from '../../../../ui'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { CardProps } from './types'

export const GradientCard = (props: CardProps) => {
  const { button, radius, handleClick } = props
  const classes = dashboardCardStyles()

  let gradientStyle = 'linear-gradient(to right, #00C93C, #00C6DD)'
  if (
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    button.cardGradientColor.length > 0 &&
    !button.cardGradientColor.includes(null)
  ) {
    gradientStyle = `linear-gradient(to right, ${button.cardGradientColor[0]},  ${button.cardGradientColor[1]})`
  }
  return (
    <Box
      key={button.cardTitle}
      className={classes.gradientButtonContainer}
      sx={{
        background: gradientStyle,
        borderRadius: radius,
      }}
      onClick={handleClick}
    >
      <Box className={`${classes.dashboardButtonCard} gradient`}>
        <Typography className={classes.gradientButtonTitle}>
          <HTMLTextComponent HTMLString={button.cardTitle} />
        </Typography>
        {button.cardBody && (
          <Typography className={classes.gradientButtonBody}>
            <HTMLTextComponent HTMLString={button.cardBody} />
          </Typography>
        )}
      </Box>
    </Box>
  )
}
