import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Dialog } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import moment from 'moment-timezone'
import React, { useEffect, useRef, useState } from 'react'
import Countdown from 'react-countdown'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Webcam from 'react-webcam'
import { selectLanguageContent } from '../../../features/translation'
import { lsClient } from '../../../ls-client'
import { TestResult } from '../../../types/tests'
import { Button, Typography } from '../../../ui'
import { testInstructionStyles } from '../../_styles/testInstructionStyle'
import { paths } from '../../paths'
import { selectProfileData } from '../../profile/model'
import {
  selectCassetteNumber,
  selectIsSelfAssessmentMode,
  selectQR2DKitId,
  selectScanImageDetails,
  selectTestIds,
  setElapsedTimeOnSubmission,
} from '../../register-a-test-module/model'
import { SelfReportingDialog } from '../instructionComponents/selfReportingDialog'
import { TimeExpiredWithImageDialog } from '../instructionComponents/timeExpiredWithImageCapturedDialog'
import {
  selectLoading,
  selectUploadError,
  setUploadError,
  submitTestScan,
} from '../model'
import { CollectionProps } from '../types'

interface CaptureProps extends CollectionProps {
  timerValue: number
  timerActive: boolean
  setCameraActive: (state: boolean) => void
  setSessionExpired: (state: boolean) => void
  setSupportDialogOpen: (state: boolean) => void
  matches: boolean
}

type CaptureMode = 'portrait' | 'landscape' | undefined

export const Scanner = (props: CaptureProps) => {
  const {
    timerValue,
    timerActive,
    setCameraActive,
    setSessionExpired,
    current,
    matches,
  } = props
  const webcamRef = React.useRef<any>(null)
  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch()
  const classes = testInstructionStyles()
  const i18n = useSelector(selectLanguageContent)
  const isloading = useSelector(selectLoading)
  const uploadError = useSelector(selectUploadError)
  const imageGuides = useSelector(selectScanImageDetails)
  const testIds = useSelector(selectTestIds)
  const { _id: userId } = useSelector(selectProfileData)
  const cassette =
    useSelector(selectCassetteNumber) || lsClient.getUserLSByKey('code') || ''
  const testQRId =
    useSelector(selectQR2DKitId) || lsClient.getUserLSByKey('kitId') || ''
  const sku = lsClient.getUserLSByKey('skuId')
  const imageGuidesRef = useRef<HTMLImageElement>(null)
  const imageGuideIsLandscape =
    imageGuidesRef.current &&
    imageGuidesRef.current.width > imageGuidesRef.current.height
  const [landscape, setLandscape] = useState<boolean | null>(null)

  const isSelfAssessmentMode = useSelector(selectIsSelfAssessmentMode)
  const [selfAssessment, setSelfAssessment] = useState<TestResult>('unset')
  const [selfAssessmentDialogOpen, setSelfAssessmentDialogOpen] =
    useState(false)
  const scanTimerStart = lsClient.getUserLSByKey('scan_timer_start')
  const elapsedTime =
    Math.round(
      moment.duration(moment(new Date()).diff(scanTimerStart)).asSeconds()
    ) || 0
  const [remainingTime, setRemainingTime] = useState(timerValue - elapsedTime)
  const [hasImage, setHasImage] = useState(false)
  const [image, setImage] = useState<'' | undefined>(undefined)
  const [capturing, setCapturing] = useState(false)
  const [timeExpiredWithStoredImage, setTimeExpiredWithStoredImage] =
    useState(false)
  const [cameraReady, setCameraReady] = useState(false)
  const [isNativeDevice, setIsNativeDevice] = useState(false)
  const [captureMode, setCaptureMode] = useState<CaptureMode>(undefined)

  useEffect(() => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsNativeDevice(true)
    }
  }, [])

  useEffect(() => {
    if (imageGuidesRef.current) {
      setLandscape(imageGuideIsLandscape)
    }
  }, [imageGuideIsLandscape, imageGuidesRef.current])

  useEffect(() => {
    setRemainingTime(timerValue - elapsedTime)
  }, [timerValue, isNativeDevice, hasImage, timerActive])

  const rescan = () => {
    dispatch(setUploadError(''))
    setHasImage(false)
  }

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (current.timeInSeconds === 0) return null
    if (completed && image === '') {
      setCameraActive(false)
      setSessionExpired(true)
      return <span>Time Expired</span>
    }
    if (completed && image !== '') {
      setTimeExpiredWithStoredImage(true)
      return <span>Time Expired</span>
    }
    // Render a countdown
    let color = matches || hasImage ? '#FFF' : theme.palette.primary.main
    if (minutes === 0 && seconds < 10) {
      color = 'red'
    }
    return (
      <Typography
        align="center"
        style={{
          color,
          fontSize: matches || hasImage ? 15 : 44,
          fontWeight: 500,
        }}
      >
        {minutes}:{seconds < 10 ? '0' + seconds : seconds}
      </Typography>
    )
  }

  const getConstraints = () => {
    if (!isNativeDevice) {
      return {
        width: 500,
        height: 500,
        facingMode: 'user',
      }
    }
    return {
      width: window.screen.availHeight,
      height: window.screen.availWidth,
      facingMode: { exact: 'environment' },
    }
  }

  const getImageClass = () => {
    if (!isNativeDevice && !landscape) {
      return classes.guides
    }
    if (!isNativeDevice && landscape) {
      return classes.landscapeGuides
    }
    if (isNativeDevice && landscape && !matches) {
      return `${classes.landscapeGuides} nativeDevice`
    }
    if (isNativeDevice && landscape && matches) {
      return `${classes.landscapeGuides} rotate`
    }
    if (isNativeDevice && !landscape && matches) {
      return `${classes.guides} nativeDevice`
    }
  }

  const getCameraClass = () => {
    if (!isNativeDevice && !landscape) {
      return classes.webcamContainer
    }
    if (!isNativeDevice && landscape) {
      return `${classes.webcamContainer} landscape`
    }
    if (isNativeDevice) {
      return `${classes.webcamContainer} nativeDevice`
    }
  }

  const closeSelfAssessment = () => {
    setSelfAssessmentDialogOpen(false)
    if (!timeExpiredWithStoredImage) {
      setHasImage(true)
    }
  }

  const capture = React.useCallback(() => {
    setCapturing(true)
    let imageSrc
    if (landscape && !isNativeDevice) {
      setCaptureMode('landscape')
      imageSrc = webcamRef.current.getScreenshot({ width: 3960, height: 2480 })
    } else if (isNativeDevice && matches) {
      setCaptureMode('portrait')
      imageSrc = webcamRef.current.getScreenshot({
        width: window.screen.availWidth * 3,
        height: window.screen.availHeight * 3,
      })
    } else if (isNativeDevice && !matches) {
      setCaptureMode('landscape')
      imageSrc = webcamRef.current.getScreenshot({
        width: window.screen.availHeight * 3,
        height: window.screen.availWidth * 3,
      })
    } else {
      imageSrc = webcamRef.current.getScreenshot({
        width: 3960,
        height: 3960,
      })
    }
    setImage(imageSrc)
    setCapturing(false)
    setHasImage(true)
    lsClient.setUserLS('scan_capture_time', moment(new Date()).toString())
  }, [webcamRef, landscape, isNativeDevice, matches])

  const handleSubmitScan = () => {
    if (isSelfAssessmentMode) {
      setHasImage(false)
      setSelfAssessmentDialogOpen(true)
    } else {
      submitScan()
    }
  }

  const submitScan = () => {
    if (!image) return
    dispatch(setUploadError(''))
    const startTime = lsClient.getUserLSByKey('scan_timer_start')
    const captureTime = lsClient.getUserLSByKey('scan_capture_time')
    const elapsedTime = moment
      .duration(moment(captureTime).diff(startTime))
      .asSeconds()
    dispatch(setElapsedTimeOnSubmission(elapsedTime))

    function DataURIToBlob(dataURI: string) {
      const splitDataURI = dataURI.split(',')
      const byteString = splitDataURI[0].includes('base64')
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], { type: mimeString })
    }

    const file = DataURIToBlob(image)
    dispatch(
      submitTestScan(
        testIds,
        file,
        userId,
        elapsedTime,
        cassette,
        testQRId,
        sku,
        i18n,
        isSelfAssessmentMode ? selfAssessment : null
      )
    )
    sessionStorage.removeItem('skip')
    navigate(paths.uploadProcessing())
  }

  const ImageCapture = () => {
    return (
      <>
        {!matches && timerActive && current.timeInSeconds > 0 && (
          <Box className={classes.desktopTimerBox}>
            <Countdown
              autoStart={true}
              date={Date.now() + remainingTime * 1000}
              renderer={renderer}
            />
          </Box>
        )}
        <Box className={getCameraClass()}>
          {matches && (
            <div>
              <ArrowBackIcon className={classes.backIcon} color='primary' />
            </div>
          )}
          {imageGuides && (
            <img
              ref={imageGuidesRef}
              src={imageGuides.imageURL}
              className={cameraReady ? getImageClass() : getImageClass()}
            />
          )}
          {!hasImage && (
            <Webcam
              audio={false}
              ref={webcamRef}
              onUserMedia={() => setCameraReady(true)}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              videoConstraints={getConstraints()}
            />
          )}
        </Box>
        {matches && timerActive && current.timeInSeconds > 0 && (
          <Box className={classes.mobileScannerTimerBox}>
            <Countdown
              autoStart={true}
              date={Date.now() + remainingTime * 1000}
              renderer={renderer}
            />
          </Box>
        )}
        <Box
          className={
            isNativeDevice && !matches
              ? `${classes.buttonContainer} nativeCamera`
              : classes.buttonContainer
          }
        >
          <Box className={classes.lgButtonBox}>
            <Button
              className={classes.confirmButton}
              onClick={capture}
              disabled={capturing || !cameraReady}
            >
              {current.buttonTitle}
            </Button>
          </Box>
        </Box>
      </>
    )
  }

  const UseImage = () => {
    return (
      <div className={classes.imageCaptureDialog}>
        <Box className={classes.imageDialogContent}>
          <Box className={classes.dialogImageWrapper}>
            <img
              src={image}
              alt="My Test Image"
              className={
                captureMode === 'landscape'
                  ? `${classes.capturedImage} landscape`
                  : classes.capturedImage
              }
            />
            {uploadError && (
              <Typography color="primary" align="center">
                {uploadError}
              </Typography>
            )}
          </Box>
        </Box>
        {current.timeInSeconds > 0 && (
          <Box className={classes.mobileScannerTimerBox}>
            <Countdown
              autoStart={true}
              date={Date.now() + remainingTime * 1000}
              renderer={renderer}
            />
          </Box>
        )}
        <Box
          className={`${classes.buttonWrapper} ${classes.mt1} noBottomMargin`}
        >
          <Box className={classes.lgButtonBox}>
            <Button
              className={`${classes.confirmButton}`}
              onClick={handleSubmitScan}
              disabled={isloading}
            >
              {!uploadError ? i18n.looks_good : i18n.dv_button_retry}
            </Button>
            <Button
              id="previous"
              className={classes.confirmButton}
              onClick={rescan}
              variant="outlined"
              disabled={isloading}
            >
              {i18n.scan_cassete_rescan}
            </Button>
          </Box>
        </Box>
      </div>
    )
  }

  return (
    <Box
      className={
        isNativeDevice
          ? `${classes.cameraPage} nativeDevice`
          : classes.cameraPage
      }
    >
      {!hasImage && <ImageCapture />}
      <Dialog open={Boolean(hasImage)}>
        <UseImage />
      </Dialog>

      <SelfReportingDialog
        open={selfAssessmentDialogOpen}
        selfAssessment={selfAssessment}
        setSelfAssessment={setSelfAssessment}
        submitScan={submitScan}
        closeSelfAssessment={closeSelfAssessment}
      />
      <TimeExpiredWithImageDialog
        open={timeExpiredWithStoredImage}
        handleSubmitScan={handleSubmitScan}
        setTimeExpiredWithStoredImage={setTimeExpiredWithStoredImage}
        image={image}
      />
    </Box>
  )
}
