import { Route, Routes } from 'react-router-dom'
import { ApplicationLayout } from './features/application'
import { SignUpLayout } from './features/application/templates/signup/signup-application-layout'
import { FlowsList } from './features/flowcontrol/flow-list'
import { HSBeInTouch } from './features/health-service-lite/pages/be-in-touch'
import { ConfirmEmailPage } from './pages/confirm-email/page'
import { ContactSupportPage } from './pages/contact-support/page'
import { DashboardPage } from './pages/dashboard/page'
import { DependentListPage } from './pages/dependents/dependents-list'
import { Error404Page } from './pages/error404/page'
import { FaqPage } from './pages/faq/page'
import { Offramp } from './pages/flow-control/Offramp/offramp'
import { GuardianAgreementPage } from './pages/guardian/guardian-agreementPage'
import { GuardianCompletePage } from './pages/guardian/guardian-completePage'
import { GuardianNotificationPage } from './pages/guardian/guardian-notificationPage'
import { GuardianInfoPage } from './pages/guardian/guardianInfoPage'
import { UploadFailedPage } from './pages/instructions/instructionComponents/uploadFailedPage'
import { UploadProcessingPage } from './pages/instructions/instructionComponents/uploadProcessingPage'
import { UploadSuccessfulPage } from './pages/instructions/instructionComponents/uploadSuccessfulPage'
import { InstructionsPage } from './pages/instructions/page'
import { InviteVerifyPage } from './pages/invite-web-registration/notification-views/inviteVerifyPage'
import { InviteMessagingPage } from './pages/invite-web-registration/notification-views/messagesWithAppBanner'
import { MFASetup } from './pages/login/mfa/chooseMFA'
import { MFAChallengePage } from './pages/login/mfa/mfa'
import { SetupMFAOptionPage } from './pages/login/mfa/setupMFAOption'
import { LoginPage } from './pages/login/page'
import { MyPassportPage } from './pages/my-passport/myPassportPage'
import { ForgotPasswordPage } from './pages/password-reset/forgotPasswordPage'
import { ResetConfirmationPage } from './pages/password-reset/resetConfirmationPage'
import { ResetPasswordSuccessfulPage } from './pages/password-reset/resetSuccessful'
import { paths } from './pages/paths'
import { QuestionnaireV2Page } from './pages/questionnaireV2/questionnaireV2Page'
import { AccessCode } from './pages/register/components/accessCode'
import { RegisterStepForm } from './pages/register/page'
import { RegisterTest } from './pages/register-a-test-module/register-test'
import { ResourcesPage } from './pages/resources/page'
import { ResourceDetailsPage } from './pages/resources/resourceDetailsPage'
import { ResourceLinkedPage } from './pages/resources/resourceDirectLink'
import { ReturnToLab } from './pages/return-to-lab-module/return-to-lab'
import { SettingsPage } from './pages/settings'
import { OrderCompletePage } from './pages/shopify-store/order-complete'
import { ShopifyClientWrapper } from './pages/shopify-store/shopify-client-wrapper'
import { SymptomCheckerIntroductionPage } from './pages/symptom-checker-introduction/symptomCheckerIntroPage'
import { SystemMessagesItem } from './pages/system-messages/item'
import { TelehealthIntakePage } from './pages/telehealth/telehealthIntakePage'
import { TelehealthPage } from './pages/telehealth/telehealthPage'
import { TermsAndCondsPageWapper } from './pages/terms-and-conds/termsWithHeaderBarWrapper'
import { TestDetailsPage } from './pages/test-details/page'
import { TestResultsPage } from './pages/test-results/page'
import { CarePlanHistory } from './pages/treatment-plan/care-plan-history'
import { TreatmentPlanPage } from './pages/treatment-plan/page'
import { WelcomePage } from './pages/welcome-page/welcomePage'
import { FlowEngineWrapper } from './ui/templates/modules/flow-engine-screen'
import { HealthRecordListPage } from './pages/health-record/health-record-list-page'
import { HealthRecordDetailPage } from './pages/health-record/health-record-detail-page'

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={paths.app.prefix()} element={<ApplicationLayout />}>
        <Route path={paths.app.dashboard()} element={<DashboardPage />} />
        <Route path={paths.app.myPassport()} element={<MyPassportPage />} />
      </Route>

      <Route path={paths.beInTouch()} element={<HSBeInTouch />} />

      <Route path={paths.contactSupport()} element={<ContactSupportPage />} />

      <Route path={paths.dependents()} element={<DependentListPage />} />

      <Route path={paths.faqs()} element={<FaqPage />} />
      <Route path={paths.faqsDetails()} element={<FaqPage />} />
      <Route path={paths.flowEngineModule()} element={<FlowEngineWrapper />} />
      <Route path={paths.flowList()} element={<FlowsList />} />
      <Route path={paths.flowOfframp()} element={<Offramp />} />

      <Route
        path={paths.guardianAgreement()}
        element={<GuardianAgreementPage />}
      />
      <Route
        path={paths.guardianComplete()}
        element={<GuardianCompletePage />}
      />
      <Route path={paths.guardianInfo()} element={<GuardianInfoPage />} />
      <Route
        path={paths.guardianNotification()}
        element={<GuardianNotificationPage />}
      />

      <Route
        path={paths.healthRecordList()}
        element={<HealthRecordListPage />}
      />
      <Route
        path={paths.healthRecordType()}
        element={<HealthRecordDetailPage />}
      />

      <Route path={paths.instructions()} element={<InstructionsPage />} />
      <Route
        path={paths.inviteEmailVerification()}
        element={<InviteVerifyPage />}
      />
      <Route path={paths.inviteMessaging()} element={<InviteMessagingPage />} />

      <Route path={paths.orderComplete()} element={<OrderCompletePage />} />

      <Route path={paths.registerTest()} element={<RegisterTest />} />
      <Route path={paths.resources()} element={<ResourcesPage />} />
      <Route path={paths.resourceDetails()} element={<ResourceDetailsPage />} />
      <Route path={paths.resourcesById()} element={<ResourceLinkedPage />} />
      <Route path={paths.returnToLab()} element={<ReturnToLab />} />

      <Route path={paths.settingsTab()} element={<SettingsPage />} />
      <Route path={paths.settingsTabOption()} element={<SettingsPage />} />

      <Route path={paths.shopifyStore()} element={<ShopifyClientWrapper />} />
      <Route
        path={paths.symptomCheckerV2()}
        element={<QuestionnaireV2Page />}
      />
      <Route
        path={paths.symptomCheckerById()}
        element={<QuestionnaireV2Page />}
      />
      <Route
        path={paths.symptomCheckerIntro()}
        element={<SymptomCheckerIntroductionPage />}
      />
      <Route
        path={paths.symptomCheckerIntroById()}
        element={<SymptomCheckerIntroductionPage />}
      />
      <Route
        path={paths.systemMessageItem()}
        element={<SystemMessagesItem />}
      />

      <Route path={paths.telehealth()} element={<TelehealthPage />} />
      <Route
        path={paths.telehealthIntake()}
        element={<TelehealthIntakePage />}
      />
      <Route
        path={paths.termsAndConds()}
        element={<TermsAndCondsPageWapper />}
      />
      <Route
        path={paths.termsAndCondsDetails()}
        element={<TermsAndCondsPageWapper />}
      />
      <Route path={paths.testResults()} element={<TestResultsPage />} />
      <Route path={paths.testResultDetails()} element={<TestDetailsPage />} />
      <Route path={paths.treatmentPlan()} element={<TreatmentPlanPage />} />
      <Route
        path={paths.treatmentPlanHistory()}
        element={<CarePlanHistory />}
      />

      <Route path={paths.uploadFailed()} element={<UploadFailedPage />} />
      <Route
        path={paths.uploadProcessing()}
        element={<UploadProcessingPage />}
      />
      <Route
        path={paths.uploadSuccessful()}
        element={<UploadSuccessfulPage />}
      />

      <Route path={paths.welcome()} element={<WelcomePage />} />

      <Route path="/" element={<SignUpLayout />}>
        <Route path={paths.forgotPassword()} element={<ForgotPasswordPage />} />
        <Route
          path={paths.resetConfirmation()}
          element={<ResetConfirmationPage />}
        />
        <Route
          path={paths.resetSuccessful()}
          element={<ResetPasswordSuccessfulPage />}
        />
        <Route path={paths.accessCode()} element={<AccessCode />} />
        <Route path={paths.confirmEmail()} element={<ConfirmEmailPage />} />

        <Route path={paths.mfAuth()} element={<MFAChallengePage />} />
        <Route path={paths.setupMFA()} element={<MFASetup />} />
        <Route path={paths.setupMFAType()} element={<SetupMFAOptionPage />} />

        <Route path={paths.registerTab()} element={<RegisterStepForm />} />
        <Route path={paths.login()} element={<LoginPage />} />
      </Route>
      <Route path="*" element={<Error404Page />} />
    </Routes>
  )
}
