import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATION_TYPES } from '../../constants'
import { handleMessage } from '../../libs/helpers'
import { useSwipe } from '../../libs/hooks/useSwipe'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { HTMLTextComponent } from '../../libs/shared-components'
import { TELEHEALTH_SHOPIFY_ITEM } from '../../libs/string'
import { shopifyStoreStyles } from '../../pages/_styles/shopifyStoreStyles'
import { paths } from '../../pages/paths'
import { Button } from '../../ui'
import { NotificationIcon } from '../notifications/model'
import { selectLanguageContent } from '../translation'
import { selectCartItemCount, setCartCount } from './model/shopifyStoreSlice'
import { StoreStepType } from './model/types'
import {
  AddToCartParameters,
  LoadProductDetailsParameters,
} from './SAFE-ecommerce-service/EcommerceSDK'
import { Quantity } from './shopify-ui-items'
import { ShopLoading } from './shopify-ui-items/storeLoading'

export const ProductDetail = (props: { sdk: any; itemCount: number }) => {
  const { sdk, itemCount } = props
  const classes = shopifyStoreStyles()
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)
  const dispatch = useDispatch()
  const cartItems = useSelector(selectCartItemCount)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const swipeHandlers = useSwipe({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
  })
  const [productDetailsState, setProductDetailsState] = useState(
    sdk.stateManager.currentProductDetailsViewState()
  )
  const [quantity, setQuantity] = useState(
    productDetailsState.viewData.quantity
  )
  const isTablet = useMUITheme({ breakpoint: 960 }).isMobile
  const [isLoading, setIsloading] = useState(false)
  const isMobile = useMUITheme().isMobile
  let isObserverSetup = false
  const productId = new URLSearchParams(window.location.search).get('id') || ''
  useEffect(() => {
    setIsloading(true)
    setupObserver()
    setTimeout(() => {
      loadProductDetails(productId, false)
    }, 250)
  }, [])

  useEffect(() => {
    if (productDetailsState.cartItemsCount !== cartItems) {
      dispatch(setCartCount(productDetailsState.cartItemsCount))
    }
  }, [productDetailsState])

  function setupObserver() {
    if (isObserverSetup) {
      return
    }
    sdk.stateManager.observeProductDetailsViewState(
      (productDetailsViewState: any) => {
        setProductDetailsState(productDetailsViewState)
      },
      (error: any) => {
        console.log('Error observing product details view state: ', error)
      }
    )
    isObserverSetup = true
  }

  function loadProductDetails(id: string, refreshCache: boolean) {
    let parameters = new LoadProductDetailsParameters(id, refreshCache, null)
    setIsloading(true)
    sdk.productManager
      .loadProductDetails(parameters)
      .then((response: any) => {
        setIsloading(false)
      })
      .catch((error: any) => {
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        console.log(error)
        setIsloading(false)
      })
  }

  function addToCart() {
    const productVariantId = productDetailsState.viewData.productVariantId
    const parameters = new AddToCartParameters(
      productId,
      productVariantId,
      quantity
    )

    setIsloading(true)
    sdk.cartManager
      .addToCart(parameters)
      .then((response: any) => {
        setIsloading(false)
        dispatch(setCartCount(cartItems + quantity))
        handleMessage(
          i18n['ecommerce.messages.added_to_cart'],
          NOTIFICATION_TYPES.V2_SUCCESS,
          undefined,
          undefined,
          NotificationIcon.SHOPPING_CART
        )
        if (productDetailsState.opensCartOnAdd) {
          navigate(paths.shopifyStore(StoreStepType.CART))
        }
      })
      .catch((error: any) => {
        console.log(error)
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        setIsloading(false)
      })
  }

  const handleSwipe = (direction: 'left' | 'right') => {
    if (direction === 'right') {
      if (selectedImageIndex === productDetailsState.images.length - 1) {
        setSelectedImageIndex(0)
      } else {
        setSelectedImageIndex((prev) => prev + 1)
      }
    } else if (direction === 'left') {
      if (selectedImageIndex === 0) {
        setSelectedImageIndex(productDetailsState.images.length - 1)
      } else {
        setSelectedImageIndex((prev) => prev - 1)
      }
    }
  }

  const handleChangeQuantity = (type: 'decrease' | 'increase') => {
    if (type === 'decrease') {
      if (quantity === 1) return
      setQuantity((prev: any) => prev - 1)
    } else setQuantity((prev: any) => prev + 1)
  }

  const viewImage = (i: number) => {
    setSelectedImageIndex(i)
  }

  if (isLoading) return <ShopLoading />

  return (
    <Box className={classes.contentWrapper}>
      <div className={classes.imageLimit}>
        <Box className={classes.imageWrapper} {...swipeHandlers}>
          {productDetailsState.images &&
            productDetailsState.images.length > 0 && (
              <img
                src={productDetailsState.images[selectedImageIndex]}
                alt={productDetailsState.productName}
                className={classes.img}
              />
            )}
          {isMobile &&
            productDetailsState.images &&
            productDetailsState.images.length > 1 && (
              <div className={classes.imgThumbnailContainer}>
                {productDetailsState.images.map((image: string, i: number) => (
                  <div
                    key={image}
                    className={
                      selectedImageIndex === i
                        ? `${classes.imageThumbnailWrapper} selected`
                        : classes.imageThumbnailWrapper
                    }
                    onClick={() => viewImage(i)}
                  ></div>
                ))}
              </div>
            )}
        </Box>
        {!isMobile &&
          productDetailsState.images &&
          productDetailsState.images.length > 1 && (
            <div className={classes.imgThumbnailContainer}>
              {productDetailsState.images.map((image: string, i: number) => (
                <div
                  key={image}
                  className={
                    selectedImageIndex === i
                      ? `${classes.imageThumbnailWrapper} selected`
                      : classes.imageThumbnailWrapper
                  }
                  onClick={() => viewImage(i)}
                >
                  <img
                    src={image}
                    alt={productDetailsState.productName}
                    className={
                      selectedImageIndex === i
                        ? `${classes.imgThumbnail} selected`
                        : classes.imgThumbnail
                    }
                  />
                </div>
              ))}
            </div>
          )}
      </div>
      <Box className={classes.content}>
        <div>
          <Typography className={classes.testName}>
            {productDetailsState.productName}
          </Typography>
          <Typography className={classes.altName}>
            {productDetailsState.productVendor}
          </Typography>
        </div>

        <Typography className={classes.testPrice}>
          {productDetailsState.priceText}
        </Typography>

        {isTablet && (
          <>
            <Quantity
              showTitle={true}
              i18n={i18n}
              productId={productDetailsState.identifier}
              quantity={quantity}
              size="lg"
              canIncrement={
                productDetailsState.productName === TELEHEALTH_SHOPIFY_ITEM
                  ? false
                  : productDetailsState.incrementButtonEnabled
              }
              canDecriment={productDetailsState.decrementButtonEnabled}
              handleChangeQuantity={handleChangeQuantity}
            />
            <Box className={classes.lgButtonBox}>
              <Button
                onClick={addToCart}
                className={classes.confirmButton}
                disabled={!productDetailsState.addButtonEnabled}
              >
                {i18n['ecommerce.add_to_cart_button']}
              </Button>
            </Box>
          </>
        )}
        {!isTablet && (
          <Quantity
            showTitle={true}
            i18n={i18n}
            productId={productDetailsState.identifier}
            quantity={quantity}
            size="lg"
            canIncrement={
              productDetailsState.productName === TELEHEALTH_SHOPIFY_ITEM
                ? false
                : productDetailsState.incrementButtonEnabled
            }
            canDecriment={productDetailsState.decrementButtonEnabled}
            handleChangeQuantity={handleChangeQuantity}
          />
        )}
        {productDetailsState.productDescriptionHTML && (
          <div>
            <Box className={classes.assetBox}>
              <Box className={classes.htmlContainer}>
                <HTMLTextComponent
                  HTMLString={productDetailsState.productDescriptionHTML}
                />
              </Box>
            </Box>
          </div>
        )}
        {!isTablet && (
          <Box className={classes.lgButtonBox}>
            <Button
              onClick={addToCart}
              className={classes.confirmButton}
              disabled={!productDetailsState.addButtonEnabled}
            >
              {i18n['ecommerce.add_to_cart_button']}
            </Button>
            {productDetailsState.viewData.allProductsCount > 1 && (
              <Button
                onClick={() =>
                  navigate(paths.shopifyStore(StoreStepType.PRODUCTS))
                }
                variant="outlined"
                className={classes.confirmButton}
              >
                {i18n['ecommerce.keep_shopping_button']}
              </Button>
            )}
          </Box>
        )}
        {/* {!productDetailsState.hidesBottomButtons && (
          // **Has story AC, but not in current web design
          <Box className={`${classes.lgButtonBox} dualButtons`}>
            <Button
              onClick={() =>
                navigate(paths.shopifyStore(StoreStepType.CART))
              }
              className={classes.confirmButton}
              // disabled={!productDetailsState.addButtonEnabled}
            >
              {i18n['ecommerce.view_cart_button']}
            </Button>
          </Box>
        )} */}
      </Box>
    </Box>
  )
}
