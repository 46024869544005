import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

interface ContentLayoutProps extends PropsWithChildren {
  as?: 'div' | 'form'
  onSubmit?: (e: React.FormEvent) => void
  textAlign?: 'center' | 'left'
  justifyItems?: 'center' | 'initial'
  className?: string
}
export const ContentLayout: React.FC<ContentLayoutProps> = ({
  children,
  as: Component = 'div',
  onSubmit,
  textAlign = 'left',
  justifyItems = 'initial',
  className,
}) => {
  const classes = useStyles({ textAlign, justifyItems })
  return (
    <Component
      onSubmit={onSubmit}
      className={classNames(classes.root, className)}
    >
      {children}
    </Component>
  )
}

const useStyles = makeStyles({
  root: {
    maxWidth: 550,
    width: '100%',
    margin: '0 auto',
    padding: '0 25px',
    display: 'grid',
    gap: '16px',
    paddingBottom: '3rem',
    textAlign: ({ textAlign }: ContentLayoutProps) => textAlign,
    justifyItems: ({ justifyItems }: ContentLayoutProps) => justifyItems,
    boxSizing: 'border-box',
  },
})
