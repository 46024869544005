/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Favicon from '../../assets/icons/favicon.jpg'
import { handleError, isLoggedIn } from '../../libs/helpers'
import { paths } from '../../pages/paths'
import {
  setSessionId,
  setTelehealthStatus,
  updateDirectCall,
} from '../../pages/telehealth/model'
import { Typography } from '../../ui'
import { beamsClient } from './beamsClient'
import { resetPushNotificationStore, selectNotification } from './model'
import { EventDescription, EventId } from './types'

beamsClient.start()

export const addDeviceInterest = (interestId: string) => {
  beamsClient.addDeviceInterest(interestId)
}

export const removeDeviceInterest = (interestId: string) => {
  beamsClient.removeDeviceInterest(interestId)
}

export const clearDeviceInterests = () => {
  beamsClient.clearDeviceInterests()
}

export const BeamsMessaging = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loggedIn = isLoggedIn()
  const notificationData = useSelector(selectNotification)
  const callImage = sessionStorage.getItem('favicon') || Favicon
  const answerCall = () => {
    if (!loggedIn) {
      dispatch(
        handleError({
          message:
            'You must be signed in to answer this call. Please sign in now.',
        })
      )
    } else {
      const data = {
        _id: notificationData.data.sessionId,
        history: [
          {
            eventId: EventId.DC_MA,
          },
        ],
      }
      dispatch(updateDirectCall(data))
      dispatch(resetPushNotificationStore())
      navigate(paths.telehealth('session'))
    }
  }
  const declineCall = () => {
    const data = {
      _id: notificationData.data.sessionId,
      eventId: EventId.DC_MR,
    }
    dispatch(updateDirectCall(data))
    dispatch(resetPushNotificationStore())
    // if (loggedIn) navigate(paths.app.dashboard())
  }
  useEffect(() => {
    if (
      notificationData.data.eventDescrption ===
        EventDescription.NotifyMemberAboutSession ||
      notificationData.data.eventDescrption ===
        EventDescription.NotifyMemberThatProviderIsReady ||
      notificationData.data.eventDescrption ===
        EventDescription.NotifyMemberThatProviderIsCalling
    ) {
      if (notificationData.data.eventId === EventId.joinsession) {
        dispatch(setTelehealthStatus('ProviderJoined'))
      } else if (notificationData.data.eventId === EventId.P_ST) {
        dispatch(setTelehealthStatus('ProviderReviewing'))
      } else if (notificationData.data.eventId === EventId.P_EN) {
        dispatch(setTelehealthStatus('Ended'))
      } else if (notificationData.data.eventId === EventId.DC_PM) {
        if (notificationData?.data?.sessionId) {
          dispatch(setSessionId(notificationData.data.sessionId))
        }
        dispatch(setTelehealthStatus('Restarting'))
      }
      // if (loggedIn) navigate(paths.telehealth('waiting-room'))
    }
  }, [notificationData])
  return (
    <>
      {(notificationData.data.eventDescrption ===
        EventDescription.NotifyMemberAboutSession ||
        notificationData.data.eventDescrption ===
          EventDescription.NotifyMemberThatProviderIsCalling) && (
        <Box className={classes.pushWrapper}>
          <div className={classes.pushContainer}>
            <div className={classes.ml1}>
              <Typography className={classes.pushTitle} variant="h2">
                {notificationData?.data.providerName}
              </Typography>
              <div className={classes.infoWrapper}>
                <img
                  src={notificationData?.notification?.icon || callImage}
                  className={classes.image}
                />
                <Typography className={classes.pushBody} variant="body1">
                  {notificationData?.notification.title}
                </Typography>
              </div>
            </div>
            <div className={classes.infoWrapper}>
              <CloseIcon
                style={{
                  backgroundColor: '#F53333',
                  color: '#FFF',
                  marginLeft: 16,
                }}
                className={classes.iconsOutlined}
                onClick={declineCall}
              />
              <CheckRoundedIcon
                style={{
                  backgroundColor: '#009104',
                  color: '#FFF',
                  marginLeft: 8,
                }}
                className={classes.iconsOutlined}
                onClick={answerCall}
              />
            </div>
          </div>
        </Box>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  pushWrapper: {
    position: 'absolute',
    top: 32,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(600)]: {
      justifyContent: 'center',
    },
  },
  pushContainer: {
    backgroundColor: 'black',
    height: 100,
    width: 450,
    zIndex: 10001,
    borderRadius: 13,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '32px',
    [theme.breakpoints.down(600)]: {
      width: '90vw',
      marginRight: 0,
    },
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginRight: 16,
  },
  ml1: {
    marginLeft: 16,
  },
  pushTitle: {
    color: '#FFF',
    fontSize: 22,
    [theme.breakpoints.down(600)]: {
      'display': '-webkit-box',
      'maxWidth': '22ch',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      'overflow': 'hidden',
    },
  },
  pushBody: {
    color: '#FFF',
    fontSize: 13,
    [theme.breakpoints.down(600)]: {
      'display': '-webkit-box',
      'maxWidth': '36ch',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      'overflow': 'hidden',
    },
  },
  image: {
    height: 16,
  },
  iconsOutlined: {
    borderRadius: '100%',
    fontSize: '28px',
    padding: 8,
    color: '#505358',
    cursor: 'pointer',
  },
}))
