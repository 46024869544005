import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import React from 'react'
import { Link as RRLink } from 'react-router-dom'
import { paths } from '../../../../pages/paths'

type AnonymousHeaderProps = {
  title: string
  backPath?: string
}
export const AnonymousHeader: React.FC<AnonymousHeaderProps> = ({
  backPath = paths.app.dashboard(),
  title,
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.mainTopTitle}>
      <RRLink to={backPath} className={classes.link}>
        <CallReceivedIcon className={classes.arrowBack} />
      </RRLink>
      <Box className={classes.forgotTitle}>{title}</Box>
    </Box>
  )
}

const useStyles = makeStyles({
  link: {
    display: 'flex',
  },
  arrowBack: {
    cursor: 'pointer',
    transform: 'rotate(45deg)',
    color: 'rgb(0, 60, 114)',
    marginLeft: 24,
  },
  mainTopTitle: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 8,
    paddingTop: 8,
    paddingRight: 54,
    marginBottom: 40,
    color: 'rgb(0, 60, 114)',
    borderBottom: '1px solid #C9C9C9',
  },
  forgotTitle: {
    fontSize: 22,
    margin: '0 auto',
  },
})
