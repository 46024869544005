import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import React from 'react'
import { ErrorField, Typography } from '../../index'
import { TextField, TextFieldProps } from './text-field'

export const FilledTextField = (
  props: TextFieldProps & {
    helperText?: string
    isNumberField?: boolean
    showNumericIncrement?: boolean
    valueLabel?: string | null
    increase?: () => void
    decrease?: () => void
  }
) => {
  const {
    error,
    helperText,
    valueLabel,
    isNumberField,
    showNumericIncrement,
    increase,
    decrease,
    disabled,
  } = props
  const classes = useStyles()

  const getClasses = () => {
    let filledClass = `${classes.fieldContainer}`
    if (error) {
      filledClass += ` ${classes.inError}`
    }
    if (disabled) {
      filledClass += ` ${classes.disabled}`
    }

    return filledClass
  }

  return (
    <Box className={classes.fieldWrapper}>
      <Box className={getClasses()}>
        <TextField
          variant="filled"
          {...props}
          noHelperText
          inputProps={{
            inputMode: isNumberField ? 'numeric' : undefined,
          }}
        />
        {isNumberField && showNumericIncrement && (
          <>
            {valueLabel && (
              <Typography className={classes.valueLabel}>
                {valueLabel}
              </Typography>
            )}
            <div className={classes.numberIncrement}>
              <ArrowDropUpIcon
                onClick={increase}
                style={{ color: '#79747E' }}
              />
              <ArrowDropDownIcon
                onClick={decrease}
                style={{ color: '#79747E' }}
              />
            </div>
          </>
        )}
      </Box>
      {error && helperText && <ErrorField error={helperText} />}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    display: 'flex',
    padding: '1px',
    width: 'calc(100% - 4px)',
    border: '1px solid #79747E',
    borderRadius: '4px',
  },
  inError: {
    border: '1px solid',
    borderColor: theme.palette.error.main,
  },
  valueLabel: {
    minWidth: 'fit-content',
    alignSelf: 'center',
    marginRight: 24,
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: '12px',
  },
  disabled: {
    borderStyle: 'dashed !important',
    borderColor: '#79747E !important',
  },
  numberIncrement: {
    marginTop: '4px',
    marginRight: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
}))
