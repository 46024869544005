/* eslint-disable dot-notation */
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FlowEngineModules } from '../../../features/flowcontrol/types'
import { useFlowControl } from '../../../features/flowcontrol/use-flow-control'
import { selectLanguageContent } from '../../../features/translation'
import { paths } from '../../../pages/paths'
import { RegisterTestStepType } from '../../../pages/register-a-test-module/types'
import {
  ConsentScreen,
  GenericScreen,
  IntroScreen,
  TimeBasedMessageScreen,
} from '../generic-screens'
import { getIcon } from '../generic-screens/helpers/iconList'

export const FlowEngineWrapper = () => {
  const { state, actions } = useFlowControl()
  const inputs = state.currentModule.config
  const i18n = useSelector(selectLanguageContent)
  const navigate = useNavigate()
  const { type } = useParams<{ type: FlowEngineModules }>()

  const handleDeclineConsent = () => {
    actions.next({ out: 'consent declined', consented: false })
  }

  const pageProps = {
    title: inputs['Title'],
    message: inputs['Message'],
    contentImage: inputs['Background Image'],
    buttonText: inputs['Button Text'],
    backgroundColor: inputs['Background Color'],
    backgroundImage: inputs['Background Image'],
    icon: getIcon(inputs['Icon'], inputs['Background Color']),
    handleNext: () => actions.next(),
    delay: inputs['Delay'] ? Number.parseInt(inputs['Delay'], 10) : undefined,
  }

  const consentProps = {
    title: inputs['Title'],
    contentIntro: inputs['Content Intro'],
    content: inputs['Content'],
    signatureMessage: inputs['Signature Message'],
    consentText: inputs['Consent Text'],
    declineText: inputs['Decline Text'],
    handleNext: () => actions.next(),
    handleDecline: handleDeclineConsent,
    i18n,
  }

  const scanTestCode = () => {
    navigate(paths.registerTest(RegisterTestStepType.SCAN_CODE))
  }

  const pages = [
    {
      type: FlowEngineModules.INTRO,
      content: <IntroScreen {...pageProps} />,
    },
    {
      type: FlowEngineModules.NEXT,
      content: <GenericScreen {...pageProps} />,
    },
    {
      type: FlowEngineModules.REGISTER_TEST,
      content: <IntroScreen {...pageProps} handleNext={scanTestCode} />,
    },
    {
      type: FlowEngineModules.PROCESSING,
      content: <TimeBasedMessageScreen {...pageProps} />,
    },
    {
      type: FlowEngineModules.CONSENT,
      content: <ConsentScreen {...consentProps} />,
    },
  ]
  const renderStepContent = () => {
    const page = _.find(pages, { type })

    //todo: handle errors
    if (!page)
      return (
        <GenericScreen
          title={i18n.flow_engine_generic_error}
          buttonText={i18n.return_home_button}
          handleNext={() => navigate(paths.app.dashboard())}
        />
      )
    return <>{page.content}</>
  }

  return renderStepContent()
}
