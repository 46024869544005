import { Box, Dialog } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { HTMLTextComponent } from '../../libs/shared-components'
import { PageLoading, Typography } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { selectNetworkSettings } from '../network/model'
import {
  getConsents,
  selectConsentError,
  selectConsents,
  selectConsentsFetched,
  selectLoading,
} from './model/model'
import { Term } from './model/type'

export const TermsAndCondsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loading = useSelector(selectLoading)
  const consents = useSelector(selectConsents)
  const consentError = useSelector(selectConsentError)
  const consentsFetched = useSelector(selectConsentsFetched)
  const [showMore, setShowMore] = useState(false)
  const [firstNotice, setFirstNotice] = useState<Term | undefined>(undefined)
  const [isFirstNoticeLong, setIsFirstNoticeLong] = useState(false)
  const classes = useStyles({ isFirstNoticeLong })
  const [selectedId, setSelectedId] = useState('')
  const [termsDetails, setTermsDetails] = useState<Term>()
  const networkSettings = useSelector(selectNetworkSettings)
  const i18n = useSelector(selectLanguageContent)

  const getTermsDetail = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return consents?.find((t) => t.title === selectedId)
  }

  useEffect(() => {
    if (networkSettings?.tenantID && !consentsFetched) {
      dispatch(getConsents(networkSettings.tenantID))
    }
    if (consents.length > 0) {
      const introductionText = consents.filter((term) => term.header)
      setFirstNotice(introductionText[0])
    }
  }, [consents, networkSettings])

  useEffect(() => {
    if (firstNotice) {
      setIsFirstNoticeLong(firstNotice.value.split('</p>').length > 4)
    }
  }, [firstNotice])

  useEffect(() => {
    if (selectedId) {
      setTermsDetails(getTermsDetail())
    }
  }, [selectedId])

  const introductionToTermsDisplay = () => {
    if (consentError) {
      return (
        <Typography
          variant="body1"
          align="center"
          style={{ margin: '32px auto', maxWidth: '320px' }}
        >
          {consentError}
        </Typography>
      )
    }

    if (!firstNotice) return null
    const toggleMore = (show: boolean) => {
      if (!isFirstNoticeLong) return

      setShowMore(show)
    }
    return (
      <>
        <Typography variant="h2" className={classes.title}>
          {firstNotice.title}
        </Typography>
        {showMore ? (
          <Box onClick={() => toggleMore(false)}>
            <div
              dangerouslySetInnerHTML={{ __html: firstNotice.value }}
              className={classes.noSelect}
            />
            {isFirstNoticeLong && (
              <Typography className={classes.less}>less</Typography>
            )}
          </Box>
        ) : (
          <Box
            className={classes.lessContainer}
            onClick={() => toggleMore(true)}
          >
            <div
              dangerouslySetInnerHTML={{ __html: firstNotice.value }}
              className={classes.termsLess}
            />
            {isFirstNoticeLong && (
              <Typography className={classes.more}>more</Typography>
            )}
          </Box>
        )}
      </>
    )
  }
  return (
    <>
      <Box className={classes.content}>
        {loading ? (
          <PageLoading />
        ) : (
          <div className={classes.termsList}>
            {introductionToTermsDisplay()}
            {consents
              .filter(
                (term) =>
                  !term.header && term.key !== 'ProctorTermsandConditions'
              )
              .map((consent) => (
                <div
                  key={consent.title}
                  className={classes.listWrapper}
                  onClick={() => setSelectedId(consent.title)}
                >
                  <Typography variant="h6" className={classes.termsTitle}>
                    {consent.title}
                  </Typography>
                  <ExpandMoreIcon className={classes.expandIcon} />
                </div>
              ))}
          </div>
        )}
      </Box>
      <Dialog open={Boolean(selectedId)} fullScreen>
        <FAQDetails
          i18n={i18n}
          termsDetails={termsDetails}
          close={() => setSelectedId('')}
        />
      </Dialog>
    </>
  )
}

const FAQDetails = (props: {
  i18n: I18n
  termsDetails: Term | undefined
  close: () => void
}) => {
  const { i18n, termsDetails, close } = props
  const classes = useStyles({ isFirstNoticeLong: false })
  return (
    <ModuleHeader
      leftContentAction={close}
      leftContentTitle={i18n.back_button}
      color="#505358"
    >
      <Box className={classes.detailContainer}>
        <Typography variant="h2" className={classes.title}>
          {termsDetails?.title}
        </Typography>
        {termsDetails?.value ? (
          <HTMLTextComponent HTMLString={termsDetails.value} />
        ) : (
          <Typography>{i18n.no_data_loaded_error_message}</Typography>
        )}
      </Box>
    </ModuleHeader>
  )
}

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '120px auto',
    textAlign: 'center',
  },
  pageWrapper: {
    width: '100vw',
  },
  backButton: {
    position: 'absolute',
    marginLeft: '-10px',
    height: 42,
    width: 42,
    [theme.breakpoints.down(300)]: {
      height: 24,
      width: 24,
    },
  },
  detailBackButton: {
    position: 'absolute',
    marginTop: '-5px',
    marginLeft: '-10px',
    height: 42,
    width: 42,
    [theme.breakpoints.down(600)]: {
      marginLeft: '9px',
    },
    [theme.breakpoints.down(300)]: {
      height: 24,
      width: 24,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    paddingBottom: '32px',
  },
  detailHeader: {
    margin: '24px auto',
    width: '100%',
    maxWidth: '500px',
    marginTop: 42,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    paddingBottom: '9px',
    borderBottom: '1px solid #C4C4C4',
  },
  termsList: {
    marginTop: 72,
    margin: '0px auto',
    width: '100%',
    maxWidth: '800px',
    [theme.breakpoints.down(600)]: {
      marginTop: 24,
    },
  },
  title: {
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '1rem',
    },
  },
  detailTitle: {
    fontWeight: 500,
    margin: '0px auto',
    color: '#505358',
    [theme.breakpoints.down(600)]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '1rem',
    },
  },
  longDetailTitle: {
    fontWeight: 500,
    margin: '0px auto',
    color: '#505358',
    [theme.breakpoints.down(600)]: {
      paddingTop: '4px',
      fontSize: '1rem',
    },
  },
  termsLess: {
    '& span': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    'userSelect': 'none',
    '& p:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3))': {
      display: 'none',
    },
    '& p:nth-child(3)': {
      'display': '-webkit-box',
      'maxWidth': '12ch',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      'overflow': 'hidden',
    },
  },
  more: {
    position: 'relative',
    bottom: 39,
    marginLeft: '16ch',
    marginBottom: '-24px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
  },
  less: {
    marginBottom: '24px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
  },
  noSelect: {
    'userSelect': 'none',
    '& span': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
  },
  lessContainer: {
    userSelect: 'none',
    borderBottom: '1px solid #E0E0E0',
    marginBottom: ({ isFirstNoticeLong }: { isFirstNoticeLong: boolean }) =>
      isFirstNoticeLong ? '10px' : '0px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '800px',
  },
  accordion: {
    '&.MuiPaper-outlined': {
      borderTop: 'none',
      borderBottom: '1px solid #E0E0E0',
      borderLeft: 'none',
      borderRight: 'none',
    },
    '&.MuiAccordion-rounded:last-child': {
      borderRadius: '0px',
    },
  },
  details: {
    'userSelect': 'none',
    'wordWrap': 'break-word',
    '& span': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    [theme.breakpoints.down(320)]: {
      '& p': {
        maxWidth: '85vw',
      },
    },
  },
  summary: {
    '&.Mui-expanded > .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(90deg)',
    },
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
    color: '#898989',
  },
  termsTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#505358',
  },
  content: {
    width: '100%',
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down(960)]: {
      maxWidth: '600px',
      margin: '0px auto',
    },
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
  },
  detailContent: {
    color: '#545454',
  },
  detailContainer: {
    maxWidth: '800px',
    overflow: 'auto',
    margin: '105px auto 48px',
    [theme.breakpoints.down(960)]: {
      maxWidth: '600px',
    },
    [theme.breakpoints.down(600)]: {
      padding: '96px 0px',
      width: '90%',
      margin: '0px auto',
    },
  },
  listWrapper: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    borderBottom: '1px solid #C4C4C4',
  },
}))
