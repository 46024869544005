import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { PatientTest } from '../../types/patientTest'

interface OutcomeResultSlice {
  isLoading: boolean
  outcomeResult: PatientTest | null
  outcomeResponseCode: string
  outcomeResponseMessage: string

  patientTestStatus: string
}
const initialStore: OutcomeResultSlice = {
  isLoading: false,
  outcomeResult: null,
  outcomeResponseCode: '',
  outcomeResponseMessage: '',
  patientTestStatus: '',
}

export const outcomeResultSlice = createSlice({
  name: 'outcomeResult',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setOutcomeResult(store, { payload }: { payload: any }) {
      store.outcomeResult = payload
      store.outcomeResponseCode = payload.metadata.responseCode
      store.outcomeResponseMessage = payload.metadata.responseMessage || ''
      store.patientTestStatus = payload.status
    },
    resetOutcomeStore: () => initialStore,
  },
})

export const { resetOutcomeStore } = outcomeResultSlice.actions

const { setLoading, setOutcomeResult } = outcomeResultSlice.actions

export const getDiagnosticResult =
  (id: string, elapsedTime: number) => async (dispatch: any, getStore: any) => {
    dispatch(setLoading(true))

    const { data } = await api.getDiagnosticResult(id, elapsedTime)

    if (data) {
      dispatch(setLoading(false))
      dispatch(setOutcomeResult(data))
    }
  }

export const outcomeResultReducer = outcomeResultSlice.reducer
export const outcomeResultReducerName = outcomeResultSlice.name
interface RootStore {
  [outcomeResultReducerName]: typeof initialStore
}

export const selectDiagnosticResult = ({ outcomeResult }: RootStore) =>
  outcomeResult.outcomeResult

export const selectDiagnosticOutcomeResponseCode = ({
  outcomeResult,
}: RootStore) => outcomeResult.outcomeResponseCode

export const selectDiagnosticOutcomeResponseMessage = ({
  outcomeResult,
}: RootStore) => outcomeResult.outcomeResponseMessage

export const selectDiagnosticTestStatus = ({ outcomeResult }: RootStore) =>
  outcomeResult.patientTestStatus

export const selectDiagnosticResultLoading = ({ outcomeResult }: RootStore) =>
  outcomeResult.isLoading
