import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Theme } from '@mui/material'
import { ClassNameMap, makeStyles } from '@mui/styles'
import React, { PropsWithChildren } from 'react'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { XLResponsiveContentWrapper } from '../../../libs/shared-components'
import { getColor } from '../../../libs/utils'
import { ColorWithHex } from '../../../types/general'
import { Typography } from '../../atoms'
import { ProgressBar } from '../../atoms/progress-bar'

interface ModuleHeaderProps extends PropsWithChildren {
  leftContentTitle?: string
  leftContentAction?: () => void
  leftActionIcon?: React.ReactNode
  centerContentTitle?: string
  centerTitleStyleOptions?: Record<string, unknown>
  centerContentImage?: string
  centerContentAction?: () => void
  rightContentTitle?: string
  rightContentAction?: () => void
  rightActionIcon?: React.ReactNode
  secondRightContentTitle?: string
  secondRightContentAction?: () => void
  secondRightActionIcon?: React.ReactNode
  progress?: number
  progressColor?: ColorWithHex
  color?: ColorWithHex
  border?: boolean
}

interface ElementProps extends ModuleHeaderProps {
  classes: ClassNameMap
  theme: Theme
}

export function ModuleHeader(props: ModuleHeaderProps) {
  const { children, border, progress, progressColor } = props
  const classes = useStyles()
  const { isMobile, theme } = useMUITheme()
  return (
    <div className={classes.wrapper}>
      <div
        className={
          border || !isMobile ? `${classes.topBar} border` : classes.topBar
        }
      >
        <XLResponsiveContentWrapper>
          <Box className={classes.mainTopTitle}>
            <LeftContent {...props} classes={classes} theme={theme} />
            <CenterContent {...props} classes={classes} theme={theme} />
            <RightContent {...props} classes={classes} theme={theme} />
          </Box>
        </XLResponsiveContentWrapper>
        <ProgressBar progress={progress} progressColor={progressColor} />
      </div>
      <div className={classes.childWrapper}>{children}</div>
    </div>
  )
}

const LeftContent = (props: ElementProps) => {
  const {
    leftContentTitle,
    leftContentAction,
    classes,
    color = 'primary',
    theme,
  } = props

  return (
    <div className={classes.actionWrapper}>
      <Box
        sx={{
          marginLeft: {
            sm: '48px',
            xs: '5vw',
          },
        }}
      >
        <Box
          onClick={leftContentAction}
          sx={{ color: getColor(color, theme) }}
          className={
            leftContentAction
              ? `${classes.cursorWrapper} hasAction`
              : classes.cursorWrapper
          }
        >
          {leftContentAction && <ArrowBackIcon color="inherit" />}
          <Typography color="inherit" className={classes.actionText}>
            {leftContentTitle}
          </Typography>
        </Box>
      </Box>
    </div>
  )
}

const CenterContent = (props: ElementProps) => {
  const {
    centerContentTitle,
    centerTitleStyleOptions,
    centerContentImage,
    centerContentAction,
    classes,
    color = 'primary',
    theme,
  } = props

  return (
    <div className={`${classes.actionWrapper} center`}>
      <Box
        sx={{ color: getColor(color, theme) }}
        onClick={centerContentAction}
        className={
          centerContentAction
            ? `${classes.cursorWrapper} hasAction`
            : classes.cursorWrapper
        }
      >
        {centerContentTitle && (
          <Typography
            color="inherit"
            className={classes.actionText}
            style={centerTitleStyleOptions}
          >
            {centerContentTitle}
          </Typography>
        )}
        {centerContentImage && (
          <img src={centerContentImage} className={classes.headerImage} />
        )}
      </Box>
    </div>
  )
}

const RightContent = (props: ElementProps) => {
  const {
    rightContentTitle,
    rightContentAction,
    rightActionIcon,
    secondRightContentTitle,
    secondRightContentAction,
    secondRightActionIcon,
    classes,
    color = 'primary',
    theme,
  } = props

  if (!(rightContentTitle || rightContentAction)) return null

  return (
    <div className={`${classes.actionWrapper} right`}>
      <Box
        sx={{
          marginRight: {
            sm: '48px',
            xs: '5vw',
          },
        }}
      >
        <Box
          sx={{ color: getColor(color, theme) }}
          className={
            secondRightContentAction
              ? `${classes.cursorWrapper} hasAction spacer`
              : `${classes.cursorWrapper} spacer`
          }
          onClick={secondRightContentAction}
        >
          <>
            <Typography color="inherit" className={classes.actionText}>
              {secondRightContentTitle}
            </Typography>
            {secondRightActionIcon}
          </>
        </Box>
        <Box
          sx={{ color: getColor(color, theme) }}
          className={`${classes.cursorWrapper} hasAction`}
          onClick={rightContentAction}
        >
          <Typography color="inherit" className={classes.actionText}>
            {rightContentTitle}
          </Typography>
          {rightActionIcon ? (
            <span className={classes.rightIcon}>{rightActionIcon}</span>
          ) : (
            <CloseIcon color="inherit" />
          )}
        </Box>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  topBar: {
    'width': '100%',
    'position': 'fixed',
    'top': 0,
    'backgroundColor': '#FFF',
    'zIndex': 101,
    '&.border': {
      borderBottom: '1px solid #efefef',
    },
  },
  actionWrapper: {
    'minWidth': '33.3%',
    'display': 'flex',
    '&.center': {
      justifyContent: 'center',
    },
    '&.right': {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down(600)]: {
      alignItems: 'flex-end',
    },
  },
  cursorWrapper: {
    'display': 'flex',
    'alignItems': 'center',
    'gap': 8,
    '&.hasAction': {
      cursor: 'pointer',
    },
    '&.spacer': {
      marginRight: 40,
    },
  },
  actionText: {
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
  mainTopTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    [theme.breakpoints.down(600)]: {
      alignItems: 'flex-end',
      height: '58px',
      paddingBottom: 6,
    },
  },
  headerImage: {
    maxHeight: 24,
    margin: '0px auto',
    justifySelf: 'center',
    [theme.breakpoints.down(300)]: {
      maxWidth: 175,
      margin: '0px auto 5px',
    },
  },
  progress: {
    width: '100%',
    margin: '0px auto',
  },
  childWrapper: {
    width: '100%',
    paddingTop: '65px',
  },
}))
