import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { handleError } from '../../libs/helpers'
import { CreditCard } from './types'
import { setSelectedCreditCard } from '../checkout/model'

interface paymentMethodsSlice {
  isLoading: boolean
  isSubmitting: boolean
  creditCards: CreditCard[]
}

const initialStore: paymentMethodsSlice = {
  isLoading: false,
  isSubmitting: false,
  creditCards: [],
}

export const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setSubmitting(store, { payload }: { payload: boolean }) {
      store.isSubmitting = payload
    },
    setCreditCards(store, { payload }: { payload: CreditCard[] }) {
      store.creditCards = payload
    },
  },
})

const { setLoading, setCreditCards, setSubmitting } =
  paymentMethodsSlice.actions

export const getCreditCards = () => async (dispatch: any, getStore: any) => {
  const { selectedCreditCard } = getStore().checkout
  dispatch(setLoading(true))

  const { languageContent } = getStore().translation
  const { data, error } = await api.getPaymentCreditCardsRequest()

  if (error) {
    // try to get translated error by key from localization file or render received error string
    dispatch(handleError(null, languageContent[error] || error))
    return dispatch(setLoading(false))
  }

  if (data) {
    dispatch(setCreditCards(data))
    if (!selectedCreditCard && data.length)
      dispatch(setSelectedCreditCard(data[0]))
  }

  dispatch(setLoading(false))
}

export const addPaymentCreditCard =
  (
    cardNumber: string,
    expDate: string,
    cvc: string,
    ownerName: string,
    zipCode: string
  ) =>
  async (dispatch: any, getState: any) => {
    dispatch(setLoading(true))

    const userInfo = getState().profile?.data

    // // test data
    // const params = {
    //   'address_city': 'test_city',
    //   'address_country': 'test_country',
    //   'address_line1': 'test_address1',
    //   'address_line2': 'test_address2',
    //   'address_state': 'test_state',
    //   'address_zip': 'test_zip',
    //   'currency': 'usd',
    //   'cvc': '123',
    //   'exp_month': '12',
    //   'exp_year': '23',
    //   'name': 'User Name',
    //   'number': '4242424242424242',
    // }

    const splitExpDate = expDate.split('/')
    const params = {
      address_city: userInfo?.city || '',
      address_country: 'usa',
      address_line1: userInfo?.line1 || '',
      address_line2: '',
      address_state: userInfo?.state || '',
      address_zip: userInfo?.zip || '',
      currency: 'usd',
      cvc,
      exp_month: splitExpDate[0],
      exp_year: splitExpDate[1],
      name: ownerName,
      number: cardNumber.replace(/\s/g, ''),
    }

    const res = await api.createCustomerWithCard(params)

    if (res.isOK) {
      // await dispatch(getPaymentMethods())
      return true
    }

    console.log('Error on adding card')

    dispatch(setLoading(false))
    return false
  }

export const deletePaymentMethod = (id: string) => async (dispatch: any) => {
  dispatch(setSubmitting(true))

  const res = await api.deletePaymentMethodRequest(id)
  if (res.isOK) {
    await dispatch(getCreditCards())
  }

  dispatch(setSubmitting(false))
}

export const paymentMethodsReducer = paymentMethodsSlice.reducer

export const paymentMethodsReducerName = paymentMethodsSlice.name

interface RootStore {
  [paymentMethodsReducerName]: typeof initialStore
}

export const selectIsLoading = ({ paymentMethods }: RootStore) =>
  paymentMethods.isLoading

export const selectIsCardSubmitting = ({ paymentMethods }: RootStore) =>
  paymentMethods.isSubmitting

export const selectCreditCards = ({ paymentMethods }: RootStore) =>
  paymentMethods.creditCards
