import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../../api'
import { GetStore, Payload } from '../../../libs/redux-types'
import { SystemMessage } from '../types'

export const initialStore = {
  list: [] as SystemMessage[],
  isLoading: false,
  isLoadingPage: false,
  messagesPerPage: 20,
  total: 0,
  page: 1,
  isDone: false,
}

export const systemMessagesSlice = createSlice({
  name: 'systemMessages',
  initialState: initialStore,
  reducers: {
    startLoading(store) {
      store.isLoading = true
    },
    setSystemMessages(
      store,
      { payload }: Payload<{ result: SystemMessage[]; allItemsCount: number }>
    ) {
      store.isLoading = false
      store.list = payload?.result
      store.total = payload?.allItemsCount
      store.page = 1
      store.isDone = false
    },
    setListMore(
      store,
      { payload }: Payload<{ result: SystemMessage[]; page: number }>
    ) {
      store.isLoadingPage = false
      store.page = payload.page
      store.list = [...store.list, ...payload.result]
      if (payload.result.length === 0) store.isDone = true
    },
    startPageLoading(store) {
      store.isLoadingPage = true
    },
  },
})

const { actions } = systemMessagesSlice

export const systemMessagesReducerName = systemMessagesSlice.name
export const systemMessagesReducer = systemMessagesSlice.reducer

export const getSystemMessages =
  () =>
  async (
    dispatch: Dispatch,
    getStore: GetStore<typeof systemMessagesReducerName, typeof initialStore>
  ) => {
    const { messagesPerPage } = getStore().systemMessages
    dispatch(actions.startLoading())
    const { data } = await api.getSystemMessages({
      skip: 0,
      limit: messagesPerPage,
    })
    dispatch(actions.setSystemMessages(data))
  }

export const fetchMoreSystemMessages =
  () =>
  async (
    dispatch: Dispatch,
    getStore: GetStore<typeof systemMessagesReducerName, typeof initialStore>
  ) => {
    const page = getStore().systemMessages.page + 1

    dispatch(actions.startPageLoading())
    const { messagesPerPage } = getStore().systemMessages
    const { data } = await api.getSystemMessages({
      skip: page,
      limit: messagesPerPage,
    })
    dispatch(actions.setListMore({ result: data.result, page }))
  }
