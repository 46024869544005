import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import { styled, Typography } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { NOTIFICATION_TYPES } from '../../constants'
import { ShopifyCart } from '../../features/shopify/cart'
import { ShopifyCheckout } from '../../features/shopify/checkout'
import { selectCartItemCount } from '../../features/shopify/model/shopifyStoreSlice'
import { StoreStepType } from '../../features/shopify/model/types'
import { OrderDetail } from '../../features/shopify/order-detail'
import { OrderHistory } from '../../features/shopify/order-history'
import { ProductDetail } from '../../features/shopify/product-detail'
import { ShopifyListPage } from '../../features/shopify/product-list'
import { SHEcommerceCollectionType } from '../../features/shopify/SAFE-ecommerce-service/EcommerceSDK'
import { ecommerceService } from '../../features/shopify/SAFE-ecommerce-service/services'
import { ShopLoading } from '../../features/shopify/shopify-ui-items/storeLoading'
import { selectLanguageContent } from '../../features/translation'
import { handleMessage } from '../../libs/helpers'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { XLResponsiveContentWrapper } from '../../libs/shared-components'
import { lsClient } from '../../ls-client'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import { OrderProcessingPage } from './order-processing'

export const ShopifyModule = () => {
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)
  const dispatch = useDispatch()
  const isMobile = useMUITheme().isMobile
  const sdk = ecommerceService.getSDKService()
  const [storeIntitalized, setStoreIntitalized] = useState(false)
  const cartItems = useSelector(selectCartItemCount)
  const { type } = useParams<{ type: StoreStepType }>()
  const [shopCollection, setShopCollection] = useState('')
  const flowExecution = lsClient.getUserLSByKey('flowExecution')
  let shopTypeFromFlow: undefined | string
  const isForHistory =
    type === StoreStepType.ORDER_HISTORY ||
    type === StoreStepType.ORDER_HISTORY_DETAIL
  const showCartIcon =
    type === StoreStepType.PRODUCTS || type === StoreStepType.PRODUCT_DETAIL

  useEffect(() => {
    //Get Shop collection from Flow Control
    if (flowExecution) {
      try {
        shopTypeFromFlow =
          JSON.parse(flowExecution)?.module?.config?.[
            'Shopping Collection Type'
          ]
        if (shopTypeFromFlow) {
          setShopCollection(
            SHEcommerceCollectionType.ofDynamic(shopTypeFromFlow)
          )
        } else {
          setShopCollection(SHEcommerceCollectionType.testKits())
        }
      } catch (e) {
        setShopCollection(SHEcommerceCollectionType.testKits())
        console.log('error loading previously running flow')
      }
    } else {
      setShopCollection(SHEcommerceCollectionType.testKits())
    }

    return () => {
      setStoreIntitalized(false)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [type])

  useEffect(() => {
    if (!storeIntitalized && shopCollection && !isForHistory) {
      try {
        initializeEcommerce()
      } catch {
        console.log('FAILED')
      }
    } else if (isForHistory) {
      setStoreIntitalized(true)
    }
  }, [shopCollection])

  function initializeEcommerce() {
    sdk.customerManager
      .prepareShopSessionForFlow(shopCollection)
      .then(() => {
        sdk.cartManager.loadCart()
        setStoreIntitalized(true)
      })
      .catch((error: any) => {
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        navigate(paths.app.dashboard())
        console.log(error)
      })
  }

  const handleBack = () => {
    const singleProduct = sessionStorage.getItem('singleChoice')
    if (type === StoreStepType.PRODUCTS) {
      navigate(paths.app.dashboard())
    } else if (type === StoreStepType.CHECKOUT) {
      navigate(paths.shopifyStore(StoreStepType.CART))
    } else if (type === StoreStepType.ORDER_HISTORY) {
      navigate(paths.app.dashboard())
    } else if (type === StoreStepType.ORDER_HISTORY_DETAIL) {
      navigate(paths.shopifyStore(StoreStepType.ORDER_HISTORY))
    } else if (type === StoreStepType.PRODUCT_DETAIL && singleProduct) {
      sessionStorage.removeItem('singleChoice')
      navigate(paths.app.dashboard())
    } else {
      navigate(paths.shopifyStore(StoreStepType.PRODUCTS))
    }
  }

  const openCart = () => {
    navigate(paths.shopifyStore(StoreStepType.CART))
  }

  const steps = [
    {
      type: StoreStepType.PRODUCTS,
      content: <ShopifyListPage sdk={sdk} />,
    },
    {
      type: StoreStepType.PRODUCT_DETAIL,
      content: <ProductDetail sdk={sdk} itemCount={cartItems} />,
    },
    {
      type: StoreStepType.CART,
      content: <ShopifyCart sdk={sdk} />,
    },
    {
      type: StoreStepType.CHECKOUT,
      content: <ShopifyCheckout sdk={sdk} />,
    },
    {
      type: StoreStepType.ORDER_PROCESSING,
      content: <OrderProcessingPage sdk={sdk} />,
    },
    {
      type: StoreStepType.ORDER_HISTORY,
      content: <OrderHistory sdk={sdk} />,
    },
    {
      type: StoreStepType.ORDER_HISTORY_DETAIL,
      content: <OrderDetail sdk={sdk} />,
    },
  ]
  const renderStepContent = () => {
    const step = _.find(steps, { type })
    return <>{step?.content || steps[0].content}</>
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      rightContentTitle={
        storeIntitalized && showCartIcon
          ? i18n['ecommerce.cart_view.navigation_title']
          : undefined
      }
      rightActionIcon={
        storeIntitalized && showCartIcon ? (
          <Cart itemCount={cartItems} />
        ) : undefined
      }
      rightContentAction={
        storeIntitalized && showCartIcon ? openCart : undefined
      }
      border={!isMobile}
      color="#505358"
    >
      <ContentWrapper>
        <XLResponsiveContentWrapper>
          {storeIntitalized ? <>{renderStepContent()}</> : <ShopLoading />}
        </XLResponsiveContentWrapper>
      </ContentWrapper>
    </ModuleHeader>
  )
}

const Cart = (props: { itemCount: number }) => {
  const { itemCount } = props

  return (
    <CartIconWrapper>
      <ShoppingCartOutlinedIcon />
      {itemCount > 0 && (
        <CartCountWrapper>
          <CartCount>{itemCount}</CartCount>
        </CartCountWrapper>
      )}
    </CartIconWrapper>
  )
}

const ContentWrapper = styled('div')(({ theme }) => ({
  width: 'calc(100% - 9vw)',
  margin: '0px auto 64px',
  [theme.breakpoints.down(600)]: {
    width: '100vw',
  },
}))

const CartIconWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: 40,
  width: 35,
  [theme.breakpoints.down(600)]: {
    alignItems: 'flex-end',
  },
}))

const CartCountWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  height: 20,
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down(600)]: {
    top: 7,
  },
}))

const CartCount = styled(Typography)(({ theme }) => ({
  color: '#FFF',
  fontSize: 12,
  fontWeight: 600,
  marginLeft: 1,
  marginTop: 1,
}))
