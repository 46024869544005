export enum ChallengeType {
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  SELECT_MFA_TYPE = 'SELECT_MFA_TYPE',
}

export interface ChallengeParameters {
  CODE_DELIVERY_DELIVERY_MEDIUM: 'SMS' // ?
  CODE_DELIVERY_DESTINATION: string
  FRIENDLY_DEVICE_NAME: null
  MFAS_CAN_CHOOSE: MFAOptions[]
  USER_ID_FOR_SRP: string
}

export interface MFASetupData {
  sessionId: string | undefined
  errorMessage: ChallengeType | undefined
  challengeName: {
    value: ChallengeType
  }
  challengeParameters: ChallengeParameters
}

export interface DeviceMetadata {
  deviceGroupKey: string
  deviceKey: string
}

export enum MFAOptions {
  SMS = 'sms',
  TOTP = 'totp',
  undefined = 'undefined',
}

export enum AccountSelectionOptions {
  VERIFY_PHONE = 'verify',
}
