import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { handleError } from '../../libs/helpers'
import { lsClient } from '../../ls-client'
import { CreditCard } from '../payment-methods/types'

interface CheckoutSlice {
  selectedCreditCard: CreditCard | null
  legalNotes: any
  selectedFacility: any
  coupon: any
  isInsuranceSelected: boolean
  insuranceDiscount: any
  selectedAppointmentDate: any
  insuranceResponsibility: any
  appointmentId: any
}

const initialStore: CheckoutSlice = {
  selectedCreditCard: null,
  legalNotes: null,
  selectedFacility: null,
  coupon: null,
  selectedAppointmentDate: false,
  insuranceDiscount: null,
  isInsuranceSelected: false,
  insuranceResponsibility: null,
  appointmentId: null,
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: initialStore,
  reducers: {
    setSelectedCreditCard(store, { payload }: { payload: CreditCard }) {
      store.selectedCreditCard = payload
    },
    setSelectedFacility(store, { payload }: { payload: boolean }) {
      store.selectedFacility = payload
    },
    setSelectedAppointmentDate(store, { payload }: { payload: any }) {
      store.selectedAppointmentDate = payload
    },
    setLegalNotes(store, { payload }: { payload: boolean }) {
      store.legalNotes = payload
    },
    setCoupon(store, { payload }: { payload: any }) {
      store.coupon = payload
    },
    setInsuranceIsSelected(store) {
      store.isInsuranceSelected = true
    },
    setInsuranceResponsibility(store, { payload }: { payload: any }) {
      store.insuranceResponsibility = payload
    },
    setAppointmentId(store, { payload }: { payload: any }) {
      store.appointmentId = payload
    },
    resetCoupon(store) {
      store.coupon = null
    },
  },
})

export const {
  setLegalNotes,
  setSelectedFacility,
  setSelectedCreditCard,
  setCoupon,
  resetCoupon,
  setSelectedAppointmentDate,
  setInsuranceIsSelected,
  setInsuranceResponsibility,
  setAppointmentId,
} = checkoutSlice.actions

export const storeSelectedFacility = (data: any) => async (dispatch: any) => {
  dispatch(setSelectedFacility(data))
  lsClient.checkout.setSelectedFacilityLS(data)
}

export const getLegalNotes = () => async (dispatch: any, getStore: any) => {
  const language = getStore().translation.selectedLanguage
  const legalNotes = await api.getLegalNotesRequest(language)
  if (legalNotes) {
    dispatch(setLegalNotes(legalNotes))
  }
}

export const placeOrder =
  (type?: string) => async (dispatch: any, getStore: any) => {
    const testKitDetails = getStore().selectTestKitResult?.testKitDetails
    const {
      selectedCreditCard: paymentMethod,
      coupon,
      isInsuranceSelected,
      insuranceResponsibility,
      appointmentId,
    } = getStore().checkout
    const { languageContent } = getStore().translation

    const orderingProviderId = await api.getOrderingProviderByTenant()

    if (orderingProviderId?.error) {
      // try to get translated error by key from localization file or render received error string
      dispatch(
        handleError(
          null,
          languageContent[orderingProviderId.error] || orderingProviderId.error
        )
      )
      return false
    }

    // get insurance Responsibility
    const insuranceRespPrice =
      isInsuranceSelected && insuranceResponsibility?.payerResponsibilty
        ? insuranceResponsibility?.payerResponsibilty
        : 0

    // price of test
    const testPrice = testKitDetails?.price
    const taxesAndFees = 0
    const discount = coupon?.discount || 0
    const totalPrice =
      testPrice + taxesAndFees - discount > 0
        ? testPrice + taxesAndFees - discount - insuranceRespPrice
        : 0

    const params = {
      paymentDetails: {
        amount: totalPrice,
        currency: 'usd',
        customerId: paymentMethod?.customerId || '',
        creditCardId: paymentMethod?.id || '',
      },
      orderableTestIds: [testKitDetails._id || ''],
      orderingProviderId: orderingProviderId?.data?._id || '',
      paymentMode: totalPrice ? 'CreditCard' : 'Free',
      appointmentId: appointmentId || '',
    }

    const { success, error } = await api.sendOrderRequest(params)

    if (error) {
      // try to get translated error by key from localization file or render received error string
      dispatch(handleError(null, languageContent[error] || error))
      return false
    }

    if (success) {
      return true
    } else {
      dispatch(handleError(error))
    }

    return false
  }

export const applyCoupon = (couponCode: string) => async (dispatch: any) => {
  const { data, isOK } = await api.applyCouponRequest({ couponCode })
  if (isOK && data.isValid) {
    dispatch(setCoupon(data))
    return true
  }
  return false
}

export const getInsuranceResponsibility =
  (testId: string) => async (dispatch: any) => {
    // const { data } = await api.getOrderingProviderByTenant()
    // const payload = {
    //   providerId: data._id,
    //   orderableIds: [testId],
    // }
    // const res = await api.getInsuranceResponsibilityRequest(payload)

    dispatch(
      setInsuranceResponsibility({
        payerResponsibilty: 0,
      })
    )
  }

export const checkoutMounted = () => (dispatch: any) => {
  const checkoutData = lsClient.checkout.getCheckoutDataLS()

  dispatch(setSelectedFacility(checkoutData?.selectedFacility || null))
  dispatch(
    setSelectedAppointmentDate(checkoutData?.selectedAppointmentDate || null)
  )
  dispatch(setAppointmentId(checkoutData?.appointmentId || null))
}

export const checkoutReducer = checkoutSlice.reducer
export const checkoutReducerName = checkoutSlice.name

interface RootStore {
  [checkoutReducerName]: typeof initialStore
}

export const selectLegalNotes = ({ checkout }: RootStore) => checkout.legalNotes

export const selectSelectedCreditCard = ({ checkout }: RootStore) =>
  checkout.selectedCreditCard

export const selectSelectedFacility = ({ checkout }: RootStore) =>
  checkout.selectedFacility

export const selectCoupon = ({ checkout }: RootStore) => checkout.coupon

export const selectAppointmentId = ({ checkout }: RootStore) =>
  checkout.appointmentId

export const selectSelectedAppointmentDate = ({ checkout }: RootStore) =>
  checkout.selectedAppointmentDate

export const selectInsuranceIsSelected = ({ checkout }: RootStore) =>
  checkout.isInsuranceSelected

export const selectInsuranceResponsibility = ({ checkout }: RootStore) =>
  checkout.insuranceResponsibility
