export interface NotificationData {
  time_to_live: number
  notification: {
    title: string | null
    body: string | null
    icon: string | null
    deep_link: string | null
    hide_notification_if_site_has_focus: boolean
  }
  data: {
    sessionId: string | null
    providerName: string | null
    eventDescrption: EventDescription | null
    eventId: EventId | null
  }
}

export enum EventDescription {
  NotifyMemberThatProviderIsReady = 'NotifyMemberThatProviderIsReady',
  NotifyMemberAboutSession = 'NotifyMemberAboutSession',
  NotifyMemberThatSessionIsComplete = 'NotifyMemberThatSessionIsComplete',
  NotifyMemberThatProviderIsCalling = 'NotifyMemberThatProviderIsCalling',
}

export enum EventId {
  joinsession = 'joinsession', // Provider has started the video call. Member should join
  P_C = 'P_C', // Provider Complete session
  P_ST = 'P_ST', // Provider is reviewing, session has been dequeued
  DC_MR = 'DC_MR', // Member Declined to answer incoming Provider call
  DC_MA = 'DC_MA', // Member Answered call
  DC_MD = 'DC_MD', // Member Ended call
  DC_PM = 'DC_PM', // Provider Direct call to member
  P_EN = 'P_EN', // Provider Ended call
}
