import { Box } from '@mui/material'
import React from 'react'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Button, Typography } from '../../../../ui'
import { getButtonVariant } from '../../../../ui/atoms/button/button'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { CardProps } from './types'

export const StandardLargeCard = (props: CardProps) => {
  const { button, radius, handleClick } = props
  const classes = dashboardCardStyles()

  return (
    <Box
      className={classes.largeCardWrapper}
      sx={{
        borderRadius: radius,
      }}
      onClick={button.enabled ? undefined : () => handleClick()}
    >
      <Box
        className={classes.imageContainer}
        sx={{
          backgroundImage: `url(${button.cardImageUrl})`,
          borderTopRightRadius: radius,
          borderTopLeftRadius: radius,
        }}
      />
      <Box className={classes.dashboardButtonCard}>
        <div>
          <Typography className={classes.dashboardButtonTitle}>
            <HTMLTextComponent HTMLString={button.cardTitle} />
          </Typography>
          <Typography className={classes.dashboardButtonBody}>
            <HTMLTextComponent HTMLString={button.cardBody} />
          </Typography>
        </div>
        {button.buttonEnabled && (
          <div className={classes.buttonWrapper}>
            <Button
              style={{
                backgroundColor:
                  button.buttonColor && button.buttonStyle === 0
                    ? button.buttonColor
                    : 'unset',
                borderColor: button.buttonColor ? button.buttonColor : 'unset',
                color: button.buttonLabelColor
                  ? button.buttonLabelColor
                  : 'unset',
              }}
              onClick={handleClick}
              className={classes.confirmButton}
              variant={getButtonVariant(button.buttonStyle)}
            >
              {button.buttonLabel}
            </Button>
          </div>
        )}
      </Box>
    </Box>
  )
}
