import { styled } from '@mui/material'
import React, { useState } from 'react'
import { I18n } from '../../../features/translation/types'
import { formatDate } from '../../../libs/helpers'
import {
  ListWrapper,
  NativeButton,
  NativeButtonWrapper,
} from '../../../libs/shared-components'
import { Select } from '../../../ui'
import { PageTitle } from '../../../ui/templates/generic-screens/helpers/styled-components'
import {
  ListedDetailView,
  StandardDetailView,
} from '../shared/components/shared-components'
import {
  AlcoholUseOptions,
  Allergy,
  Condition,
  DrugUseOptions,
  FamilyHistory,
  Medication,
  SexualPreferenceOptions,
  SmokingStatusOptions,
  socialHistoryTitles,
  SocialHistoryType,
  Vaccination,
} from '../type'

export const AllergyDetail = (props: { allergy: Allergy; i18n: I18n }) => {
  const listItems = [
    {
      title: props.i18n.patient_records_onset_date,
      value: formatDate(props.allergy.onsetDate, false, undefined, 'MMM. d, y'),
    },
    {
      title: props.i18n.patient_records_reaction,
      value: props.allergy.reaction?.displayName || props.i18n.UNKNOWN,
    },
    {
      title: props.i18n.patient_records_severity,
      value: props.allergy.reaction?.severity || props.i18n.UNKNOWN,
    },
  ]

  return (
    <StandardDetailView
      detailTitle={props.allergy.allergyCode.display}
      secondaryText={props.allergy.clinicalStatus}
      listDetails={listItems}
    />
  )
}

export const MedicationDetail = (props: {
  medication: Medication
  i18n: I18n
}) => {
  const listItems = [
    {
      title: props.i18n.patient_records_medication_dose,
      value: props.medication.dosage?.dose || props.i18n.UNKNOWN,
    },
    {
      title: props.i18n.patient_records_medication_strength,
      value: props.medication.dosage?.strength || props.i18n.UNKNOWN,
    },
    {
      title: props.i18n.patient_records_medication_form,
      value: props.medication.form || props.i18n.UNKNOWN,
    },
    {
      title: props.i18n.patient_records_severity,
      value: props.medication.dosage?.doseTiming || props.i18n.UNKNOWN,
    },
    {
      title: props.i18n.patient_records_medication_start_date,
      value: formatDate(
        props.medication.effectiveStartDate,
        false,
        undefined,
        'MMM. d, y'
      ),
    },
  ]

  return (
    <StandardDetailView
      detailTitle={props.medication.medicationCode.display}
      secondaryText={props.medication.status}
      listDetails={listItems}
    />
  )
}

export const VaccinationDetail = (props: {
  vaccination: Vaccination
  i18n: I18n
}) => {
  const listItems = [
    {
      title: props.i18n.patient_records_vaccination_date,
      value: formatDate(
        props.vaccination.administeredDate,
        false,
        undefined,
        'MMM. d, y'
      ),
    },
    {
      title: props.i18n.patient_records_location_of_service,
      value:
        props.vaccination.performer?.[0]?.actor?.display?.value ||
        props.i18n.UNKNOWN,
    },
    {
      title: props.i18n.patient_records_manufacture,
      value: props.vaccination.manufacturer.name || props.i18n.UNKNOWN,
    },
  ]

  return (
    <StandardDetailView
      detailTitle={props.vaccination.vgName}
      secondaryText={`${props.i18n.patient_records_medication_dose} ${props.vaccination.doseNumber}`}
      listDetails={listItems}
    />
  )
}

export const ConditionDetail = (props: {
  condition: Condition
  i18n: I18n
}) => {
  const listItems = [
    {
      title: props.i18n.patient_records_onset_date,
      value: formatDate(
        props.condition.onsetDate,
        false,
        undefined,
        'MMM. d, y'
      ),
    },
  ]

  props.condition.notes?.forEach((note) => {
    const noteDetail = {
      title: props.i18n.patient_records_notes,
      value: note.message,
    }
    listItems.push(noteDetail)
  })

  return (
    <StandardDetailView
      detailTitle={props.condition.conditionCode?.display}
      secondaryText={props.condition.clinicalStatus}
      listDetails={listItems}
    />
  )
}

export const FamilyMemberDetail = (props: {
  history: FamilyHistory
  i18n: I18n
}) => {
  return (
    <ListedDetailView
      detailTitle={props.history.name}
      secondaryText={props.history.relationShip?.display || ''}
      listDetails={props.history.conditionList}
      handleClick={() => {}}
    />
  )
}

const getSelectOptions = (optionkey: string, i18n: I18n) => {
  return {
    value: i18n[optionkey],
    label: i18n[optionkey],
  }
}

export const SocialHistoryDetail = (props: {
  detailTitle: string
  currentValue: string
  i18n: I18n
  selectedParam: SocialHistoryType
  handleUpdate: (value: string, selectedParam: SocialHistoryType) => void
}) => {
  const { detailTitle, currentValue, i18n, selectedParam, handleUpdate } = props
  const [value, setValue] = useState(currentValue)
  const getOptions = () => {
    switch (detailTitle) {
      case i18n[socialHistoryTitles.smoking]: {
        return Object.values(SmokingStatusOptions).map((o) =>
          getSelectOptions(o, i18n)
        )
      }
      case i18n[socialHistoryTitles.alcohol]: {
        return Object.values(AlcoholUseOptions).map((o) =>
          getSelectOptions(o, i18n)
        )
      }
      case i18n[socialHistoryTitles.drug]: {
        return Object.values(DrugUseOptions).map((o) =>
          getSelectOptions(o, i18n)
        )
      }
      case i18n[socialHistoryTitles.sexualOrientation]: {
        return Object.values(SexualPreferenceOptions).map((o) =>
          getSelectOptions(o, i18n)
        )
      }
      default:
        return []
    }
  }

  const handleChangeSelected = (e: any) => {
    const newValue = e.target.value
    setValue(newValue)
  }

  return (
    <>
      <PageTitle>{detailTitle}</PageTitle>
      <ListWrapper>
        <SelectWrapper>
          <Select
            options={getOptions()}
            value={value}
            onChange={handleChangeSelected}
          />
        </SelectWrapper>
      </ListWrapper>
      {value !== currentValue && (
        <NativeButtonWrapper>
          <NativeButton onClick={() => handleUpdate(value, selectedParam)}>
            Update
          </NativeButton>
        </NativeButtonWrapper>
      )}
    </>
  )
}

const SelectWrapper = styled('div')({
  'display': 'flex',
  'alignItems': 'center',
  'width': '100%',
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiInput-formControl': {
    'margin': 0,
    'width': '100%',
    '&::before': {
      display: 'none',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#FFF',
    },
  },
  '& .MuiInputBase-input': {
    width: '100%',
    color: '#505358',
    fontSize: 16,
    fontWeight: 600,
  },
  '& .Mui-focused': {
    'backgroundColor': '#FFF',
    '&::after': {
      display: 'none',
    },
  },
})
