import '../../../fonts.css'
import { Box, Typography } from '@mui/material'
import { makeStyles, ClassNameMap } from '@mui/styles'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import React from 'react'
import { I18n } from '../../../features/translation/types'
import { hexToRGBA } from '../../../libs/helpers'
import { NetworkSettings } from '../../network/type'
import { SHADOW } from '../constants'

const examplePrescriptions = [
  {
    title: 'Paxlovid',
    type: 'prescription',
  },
  {
    title: 'Paxlovid-b',
    type: 'prescription',
  },
  {
    title: 'Flowflex Home Test',
    type: 'test',
  },
  {
    title: 'Flowflex Home Test-b',
    type: 'test',
  },
]

interface ExamplePrescription {
  title: string
  type: string
}

export const CareService = ({
  i18n,
  networkSettings,
}: {
  i18n: I18n
  networkSettings: NetworkSettings | null
}) => {
  const pillBackgound = networkSettings?.secondaryColor
    ? hexToRGBA(networkSettings.secondaryColor, 0.2)
    : hexToRGBA('#01C888', 0.2)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = useStyles({ radius, pillBackgound })

  return (
    <Box className={classes.careServiceWrapper}>
      <Box className={classes.careService}>
        <Box className={classes.careServiceTitleSection}>
          <Typography className={classes.careServiceTitle}>
            Recent Care Services (example data)
          </Typography>
          <MoreHorizIcon />
        </Box>
        <Box className={classes.careServiceTreatments}>
          {examplePrescriptions.map((p: ExamplePrescription) => (
            <Treatment p={p} i18n={i18n} classes={classes} key={p.title} />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

const Treatment = (props: {
  p: ExamplePrescription
  i18n: I18n
  classes: ClassNameMap
}) => {
  const { p, classes } = props

  return (
    <Box className={classes.treatmentWrapper}>
      <Box className={classes.treatmentInner}>
        <Typography className={classes.treatmentTitle}>{p.title}</Typography>
        <Box className={classes.treatmentTypeWrapper}>
          <Typography className={classes.treatmentType}>
            {p.type === 'prescription' ? 'Rx' : 'CDx'}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  careServiceWrapper: {
    'backgroundColor': '#FFF',
    'width': '100%',
    'maxWidth': '528px',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    'margin': '24px 0px',
    'borderRadius': ({ radius }: { radius: number; pillBackgound: string }) =>
      radius,
  },
  careService: {
    padding: '32px',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
    },
  },
  careServiceTitleSection: {
    marginBottom: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '4px',
    },
  },
  careServiceTitle: {
    fontSize: '32px',
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  careServiceTreatments: {
    'display': 'flex',
    'maxWidth': '85vw',
    'overflowY': 'scroll',
    '-webkit-mask-image':
      'linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 3%, rgba(255,255,255,1) 10%)',
    'maskImage':
      'linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 3%, rgba(255,255,255,1) 10%)',
  },
  treatmentWrapper: {
    marginRight: '24px',
    backgroundColor: '#F3F3F3',
    borderRadius: ({ radius }: { radius: number; pillBackgound: string }) =>
      radius,
    [theme.breakpoints.down('md')]: {
      marginRight: '8px',
    },
  },
  treatmentInner: {
    display: 'flex',
    width: 'max-content',
    padding: '16px',
    [theme.breakpoints.down('md')]: {
      padding: '8px',
    },
  },
  treatmentTitle: {
    fontSize: '20px',
    width: 'fit-content',
    color: '#757575',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  treatmentTypeWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    borderRadius: ({ radius }: { radius: number; pillBackgound: string }) =>
      radius,
    backgroundColor: ({
      pillBackgound,
    }: {
      radius: number
      pillBackgound: string
    }) => pillBackgound,
  },
  treatmentType: {
    fontSize: '16px',
    padding: '0px 12px',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      padding: '0px 6px',
      fontSize: '12px',
    },
  },
}))
