import { Link as MUILink } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning-icon.svg'
import { selectLanguageContent } from '../../../features/translation'
import { capitalizeString } from '../../../libs/utils'
import { Button, Typography } from '../../../ui'
import { onboardingStyles } from '../../_styles/registrationStyles'
import {
  getEligibilityAttempt,
  selectEligibilityErrorMessage,
  setEligibilityTry,
} from '../model/registerSlice'

export const EligibilityError = (props: {
  onClose: () => void
  onCancel: () => void
}) => {
  const { onClose, onCancel } = props
  const classes = onboardingStyles()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const message = useSelector(selectEligibilityErrorMessage)
  const eligibilityAttempt = useSelector(getEligibilityAttempt)
  const showCancelAsLink =
    message === 'Code you entered does not exist!' ||
    message === 'User/Token Not Found'

  const displayError = () => {
    switch (message) {
      case 'User Already Enrolled':
      case 'Internal error: User Already Enrolled': {
        return (
          <>
            <Typography
              className={classes.errorTitle}
              color="primary"
              variant="h1"
            >
              {i18n.user_already_enrolled_title}
            </Typography>
          </>
        )
      }
      case 'Code you entered does not exist!': {
        return (
          <>
            <Typography
              className={classes.errorTitle}
              color="primary"
              variant="h1"
            >
              {i18n.invalid_access_code}
            </Typography>
            <Typography
              className={classes.errorBody}
              style={{ alignSelf: 'center' }}
            >
              {i18n.invalid_access_code_instruction}
            </Typography>
          </>
        )
      }
      case 'User access declined':
      case 'Internal error: User access declined': {
        dispatch(setEligibilityTry(6))
        return (
          <>
            <Typography
              className={classes.errorTitle}
              color="primary"
              variant="h1"
            >
              User access declined
            </Typography>
          </>
        )
      }
      case 'Unable to retrieve token':
      case 'User/Token Not Found':
      case 'Internal error: User/Token Not Found':
      default: {
        if (eligibilityAttempt === 6) {
          return (
            <>
              <Typography
                className={classes.errorTitle}
                color="primary"
                variant="h1"
              >
                {i18n.attempt_limit_exceeded}
              </Typography>
              <Typography
                className={classes.errorBody}
                style={{ alignSelf: 'center' }}
              >
                {i18n.try_again_in_fifteen_minutes}
              </Typography>
            </>
          )
        }
        return (
          <>
            <Typography
              className={classes.errorTitle}
              color="primary"
              variant="h1"
            >
              {i18n.unable_to_verify_account}
            </Typography>
            <Typography
              className={classes.errorBody}
              style={{ alignSelf: 'center' }}
            >
              {i18n.unable_to_verify_account_instruction}
            </Typography>
          </>
        )
      }
    }
  }

  return (
    <div className={classes.stepContent}>
      <div className={classes.warningWrapper} style={{ marginTop: 120 }}>
        <WarningIcon className={classes.warningIcon} />
      </div>
      <div className={classes.content}>
        {displayError()}
        <div className={classes.dialogButtonWrapper}>
          {eligibilityAttempt !== 6 && (
            <Button
              color="primary"
              className={classes.actionBtn}
              onClick={() => onClose()}
            >
              {i18n.try_again}
            </Button>
          )}
          {showCancelAsLink && eligibilityAttempt !== 6 ? (
            <div className={classes.cancelLinkWrapper}>
              <MUILink
                role="button"
                className={classes.cancelLink}
                underline="none"
                onClick={() => onCancel()}
              >
                {capitalizeString(i18n.cancel_button || '')}
              </MUILink>
            </div>
          ) : (
            <Button
              color="primary"
              variant={eligibilityAttempt === 6 ? 'contained' : 'outlined'}
              className={classes.actionBtn}
              onClick={() => onCancel()}
            >
              {eligibilityAttempt === 6 ? i18n.ok : i18n.cancel_button}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
