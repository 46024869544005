import { createSlice, Dispatch } from '@reduxjs/toolkit'
import api from '../../../api'
import { GetStore } from '../../../libs/redux-types'
import {
  TestResultItemNew,
  TestResultsContract,
  TestResultsItemContract,
  TestResultsState,
} from '../types'

const initialState: TestResultsState = {
  isLoading: false,
  list: {
    items: [],
    pageSize: 10,
    total: 0,
  },
  activeItem: null,
  error: '',
  isDone: false,
}
export const testResultsSlice = createSlice({
  name: 'testResults',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setList: (state, { payload }) => {
      state.isLoading = false
      state.list = payload
      state.isDone = false
    },
    setListMore: (state, { payload: { list, page } }) => {
      // state.list = [...state.list, ...list]
      // if (list.length === 0) state.isDone = true
    },
    setActiveItem: (state, { payload }) => {
      state.isLoading = false
      state.activeItem = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

const { setList, setLoading, setActiveItem, setListMore } =
  testResultsSlice.actions

export const getTestResults =
  (page: number, pageSize: number, sort: string, direction: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    const res = (await api.getTestResults({
      page,
      pageSize,
      sort,
      direction,
    })) as TestResultsContract

    if (res.data) {
      dispatch(setList(res.data))
    }
    dispatch(setLoading(false))
  }

export const getTestResultsMore =
  () =>
  async (
    dispatch: Dispatch,
    getState: GetStore<typeof testResultsReducerName, TestResultsState>
  ) => {
    // const res = (await api.getTestResults({ page, pageSize: 20, sort: 'created', direction: 'desc' })) as TestResultsContract
    // dispatch(setListMore({ list: res.data, page }))
  }

export const getTestResultsItem =
  (itemId: string) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    const res: TestResultsItemContract = (await api.getTestResultItem(
      itemId
    )) as TestResultsItemContract
    // if (error.response) dispatch(setError(error.response.data.Message))
    dispatch(setActiveItem(res.data))
  }

export const testResultsReducerName = testResultsSlice.name
export const testResultsReducer = testResultsSlice.reducer
