import { EncounterPharmacy } from '../../pages/treatment-plan/helper'
import { ConsultPrescription } from '../health-service-lite/types'

export const formatPharmacyData = (data: any) => {
  if (!data) return
  return {
    pharmacy: {
      name: data.name,
      line1: data.address?.lineAddress1,
      line2: data.address?.lineAddress2,
      city: data.address?.city,
      state: data.address?.state,
      zip: data.address?.zip,
    },
    pharmacy_phone: data.contactDetails?.mobilePhoneNumber,
  }
}

export const joinPharmaciesAndPrescript = (
  pharm: EncounterPharmacy[],
  prescript: ConsultPrescription[]
): EncounterPharmacy[] | null => {
  const pharmacies: EncounterPharmacy[] = []
  let successfulMatch = false
  if (!pharm || !prescript) return null

  pharm.forEach((p: EncounterPharmacy) => {
    pharmacies.push({ ...p, prescriptions: [] })
  })

  if (pharmacies.length === 0) return null
  prescript?.forEach((t: ConsultPrescription) => {
    const pharm = pharmacies.findIndex((p) => p.ncpdpId === t.ncpdpId)
    if (pharm === -1) return null
    successfulMatch = true
    pharmacies[pharm].prescriptions.push(t)
  })

  return successfulMatch ? pharmacies : null
}
