import { ListItem, ListItemText, Theme, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { userHasDeviceCookie } from '../../../../features/cookies/cookie-helper'
import { selectLanguageContent } from '../../../../features/translation'
import { MFAOptions } from '../../../../features/user/types/mfa'
import { Select, Typography } from '../../../../ui'
import { selectNetworkSettings } from '../../../network/model'
import { paths } from '../../../paths'
import { selectProfileData } from '../../../profile/model'
import { settingsStyles } from '../../styles'
import { SecuritySettingsType, SettingsTabType } from '../../type'
import { SetupMFA } from './mfa-setup/index'
import { securitySettingsMenuItems } from './sections/mapping'

export const SecurityTab = () => {
  const classes = settingsStyles()
  const i18n = useSelector(selectLanguageContent)
  const { type, selection } = useParams<{
    type?: SecuritySettingsType
    selection: MFAOptions
  }>()
  const navigate = useNavigate()
  const profile = useSelector(selectProfileData)
  const currentNetwork = useSelector(selectNetworkSettings)
  const [selected, setSelected] = useState<SecuritySettingsType>(
    type || SecuritySettingsType.PASSWORD
  )
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  useEffect(() => {
    if (!type) return
    setSelected(type)
  }, [type])

  const renderContent = () => {
    const Content = selection
      ? SetupMFA
      : securitySettingsMenuItems[selected].content

    if (!Content) return null

    return <Content />
  }

  const shouldHideInMenu = (o: string) => {
    if (
      o === SecuritySettingsType.FORGET_DEVICE &&
      !userHasDeviceCookie(profile.email)
    ) {
      return true
    }
    if (
      o === SecuritySettingsType.MFA &&
      !(
        currentNetwork?.mfaSettings?.enableSMSVerification &&
        currentNetwork.mfaSettings?.enableGoogleAuth
      )
    ) {
      return true
    }
    return false
  }

  const getMobileMenuList = () => {
    const menuItems = Object.keys(securitySettingsMenuItems).map((option) => {
      if (shouldHideInMenu(option)) {
        return {
          label: 'hidden',
          value: '',
        }
      }

      const value =
        securitySettingsMenuItems[option as SecuritySettingsType].label_key
      return {
        label: i18n[value],
        value: option,
      }
    })
    return menuItems.filter((m) => m.label !== 'hidden')
  }

  const fullSizeView = () => (
    <div className={classes.settingsList}>
      {Object.keys(securitySettingsMenuItems).map((option) => {
        if (shouldHideInMenu(option)) return null
        return (
          <ListItem
            key={`account-${option}`}
            selected={selected === (option as SecuritySettingsType)}
            onClick={() =>
              navigate(paths.settingsTab(SettingsTabType.SECURITY, option))
            }
            className={classes.item}
          >
            <ListItemText
              primary={
                i18n[
                  securitySettingsMenuItems[option as SecuritySettingsType]
                    .label_key
                ]
              }
            />
          </ListItem>
        )
      })}
    </div>
  )

  const mobileView = () => (
    <div className={classes.settingsList}>
      <Select
        value={selected}
        onChange={(e) =>
          navigate(
            paths.settingsTab(SettingsTabType.SECURITY, e.target.value)
          )
        }
        options={getMobileMenuList()}
      />
    </div>
  )

  return (
    <div className={classes.tabWrapper}>
      {isMobile ? null : fullSizeView()}
      <div className={classes.activeTab}>
        {!selection && (
          <Typography className={classes.title} variant="h2">
            {i18n[securitySettingsMenuItems[selected].page_title_key]}
          </Typography>
        )}
        {renderContent()}
      </div>
    </div>
  )
}
