import { Loading } from '../../../atoms'
import { Success } from '../../../atoms/success-checkmark-animation/checkmark-animation'
import { StyledIcon } from './styled-components'

//todo need naming convention for animated vs. static images
const animatedIcons = (color?: string) =>
  ({
    animated_check: () => <Success color={color && '#FFF'} />,
    animated_loading: () => (
      <div
        style={{
          color: color ? '#FFF' : undefined,
        }}
      >
        <Loading size={100} color={color ? 'inherit' : 'primary'} />
      </div>
    ),
    loading: () => (
      <div
        style={{
          margin: 48,
          color: color ? '#FFF' : undefined,
        }}
      >
        <Loading size={100} color={color ? 'inherit' : 'primary'} />
      </div>
    ),
  } as { [key: string]: () => void })

export const getIcon = (iconName: string, iconColor: string) => {
  if (!iconName) return null
  return animatedIcons(iconColor)[iconName] ? (
    animatedIcons(iconColor)[iconName]()
  ) : (
    <StyledIcon
      sx={{ color: iconColor ? '#FFF' : undefined }}
      color={iconColor ? undefined : 'primary'}
    >
      {iconName}
    </StyledIcon>
  )
}
