/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, Theme, useMediaQuery } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { StepType } from '../../constants'
import { selectLanguageContent } from '../../features/translation'
import { isLoggedIn } from '../../libs/helpers'
import { Typography } from '../../ui'
import { onboardingStyles } from '../_styles/registrationStyles'
import { InvitePages } from '../invite-web-registration/model/type'
import { getNetworkSettings, selectNetworkSettings } from '../network/model'
import { NetworkSettings } from '../network/type'
import { paths } from '../paths'
import { selectProfileData, updateUserData } from '../profile/model'
import {
  completeEligibleOnboarding,
  getCurrentStep,
  getSignupType,
  resetRegisterForm,
  selectFoundAddress,
  selectRegistrationFormData,
  setActive,
} from './model/registerSlice'
import { FormValues, OnboardingType, RegisterStepProps } from './model/type'
import { getValidationSchema } from './model/validationSchemas'
import {
  Confirmation,
  CreatePassword,
  Eligibility,
  ShippingAddress,
  SignUp,
  VerifyAddress,
  VerifyPhoneNumber,
} from './steps'
import { AccountInformation } from './steps/account-information'

export const RegisterStepForm = () => {
  const classes = onboardingStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loggedIn = isLoggedIn()
  const formValues: FormValues = useSelector(selectRegistrationFormData)
  const userDetails = useSelector(selectProfileData)
  const currentNetwork = useSelector(selectNetworkSettings)
  const signupType = useSelector(getSignupType)
  const i18n = useSelector(selectLanguageContent)
  const currentStep = useSelector(getCurrentStep)
  const foundAddress = useSelector(selectFoundAddress)
  const { type } = useParams<{ type: StepType }>()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))
  const isInvite = Boolean(
    signupType === OnboardingType.INVITE ||
      signupType === OnboardingType.DEPENDENT
  )

  const {
    collectDateOfBirth,
    collectSex,
    collectRace,
    collectEthicity,
    collectStreetAddress,
    collectCity,
    collectState,
    collectZip,
    accountId,
  } = (currentNetwork as NetworkSettings) || {}

  const tenantId =
    localStorage.getItem('tenantId') ||
    sessionStorage.getItem('tenantId') ||
    currentNetwork?.tenantID ||
    ''

  // Override Safari cached load on browser back action
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload()
    }
  }

  const sendWelcomeKit = (callback?: () => void) => {
    const data = {
      tenantId,
      email: userDetails.email,
      is3rdParty: true,
      isDependent: Boolean(formik.values[StepType.SIGN_UP].dependentId),
    }
    dispatch(completeEligibleOnboarding(data, callback))
  }

  const inviteSignupComplete = () => {
    const finish = () => {
      sessionStorage.setItem('registerComplete', '1')
      window.location.href = paths.inviteMessaging(InvitePages.THANK_YOU)
    }
    if (currentNetwork?.benefitSettings?.employerBenefit) {
      sendWelcomeKit(finish)
    } else finish()
  }

  useEffect(() => {
    //skip steps if tenant settings do not collect values
    if (
      type === StepType.SHIPPING_ADDRESS &&
      currentNetwork &&
      !collectStreetAddress &&
      !collectCity &&
      !collectState &&
      !collectZip
    ) {
      formik.submitForm()
    } else if (
      type === StepType.ACC_INFO &&
      currentNetwork &&
      !collectDateOfBirth &&
      !collectSex &&
      !collectRace &&
      !collectEthicity
    ) {
      if (
        !collectStreetAddress &&
        !collectCity &&
        !collectState &&
        !collectZip
      ) {
        formik.submitForm()
      } else if (currentStep) {
        dispatch(setActive(currentStep + 1))
      }
    }
  }, [type, currentNetwork])

  useEffect(() => {
    if (tenantId && !currentNetwork) {
      dispatch(getNetworkSettings(tenantId))
    }
  }, [currentNetwork, tenantId])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [currentStep])

  if (currentStep === undefined) {
    if (signupType === OnboardingType.SELF) {
      loggedIn ? navigate(paths.welcome()) : navigate(paths.login())
    } else {
      const registerCompleteMarker = sessionStorage.getItem('registerComplete')
      registerCompleteMarker
        ? navigate(paths.inviteMessaging(InvitePages.THANK_YOU))
        : navigate(paths.inviteMessaging(InvitePages.SESSION_INVALID))
    }
  }

  const eligibilityNext = () => {
    if (signupType === OnboardingType.SELF) handleNext()
    else {
      dispatch(setActive(3))
      navigate(paths.registerTab(StepType.CREATE_PASSWORD))
    }
  }

  const onSubmit = async () => {
    if (!userDetails._id) return
    const accountDetailsValues = formValues[StepType.ACC_INFO]
    const shippingAddress = formValues[StepType.SHIPPING_ADDRESS].address
    const updatedUser = {
      ...userDetails,
      birthDate: accountDetailsValues.dob,
      race: accountDetailsValues.race,
      gender: accountDetailsValues.sexAtBirth,
      ethnicity: accountDetailsValues.ethnicity,
      shippingAddress,
    }

    const registerCallback = () => {
      if (currentNetwork?.benefitSettings?.employerBenefit) {
        sendWelcomeKit()
      }
      dispatch(resetRegisterForm())
      navigate(paths.welcome())
    }
    dispatch(
      updateUserData(
        updatedUser,
        false,
        isInvite ? inviteSignupComplete : registerCallback
      )
    )
  }

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    validationSchema: getValidationSchema(i18n, type || '', currentNetwork),
    enableReinitialize: true,
    validateOnMount: true,
  })

  const stepProps = {
    handleNext,
    handleBack,
    mode: 'APPLICATION',
    formik,
    type,
    accountId,
    tenantId,
    isInvite,
  } as RegisterStepProps

  const steps = [
    {
      label: i18n.sign_up,
      type: StepType.ELIGIBILITY,
      content: <Eligibility {...stepProps} handleNext={eligibilityNext} />,
      allowBack: signupType === OnboardingType.SELF,
      skip: false,
    },
    {
      label: i18n.sign_up,
      type: StepType.SIGN_UP,
      content: <SignUp {...stepProps} />,
      allowBack: false,
      skip: isInvite,
    },
    {
      label: i18n.verify_email,
      type: StepType.CONFIRM_EMAIL,
      content: <Confirmation {...stepProps} type={StepType.SIGN_UP} />,
      allowBack: true,
      skip: isInvite,
    },
    {
      label: i18n.create_a_password_header,
      type: StepType.CREATE_PASSWORD,
      content: <CreatePassword {...stepProps} />,
      allowBack: false,
    },
    {
      label: i18n.verify_phone_number,
      type: StepType.ENTER_PHONE_NUMBER,
      content: <VerifyPhoneNumber {...stepProps} />,
      allowBack: false,
      skip: false,
    },
    {
      label: i18n.verify_phone_number,
      type: StepType.VERIFY_PHONE_NUMBER,
      content: (
        <Confirmation {...stepProps} type={StepType.ENTER_PHONE_NUMBER} />
      ),
      allowBack: true,
      skip: false,
    },
    {
      label: i18n.account_information,
      type: StepType.ACC_INFO,
      content: <AccountInformation {...stepProps} />,
      allowBack: false,
      skip: false,
    },
    {
      label: i18n.shipping_address_title,
      type: StepType.SHIPPING_ADDRESS,
      content: <ShippingAddress {...stepProps} />,
      allowBack: true,
      skip: false,
    },
    {
      label: i18n.verify_address_header,
      type: StepType.VERIFY_ADDRESS,
      content: <VerifyAddress {...stepProps} />,
      allowBack: foundAddress.isAddressFound,
      skip: false,
    },
  ]

  useEffect(() => {
    if (type && currentNetwork) formik.validateForm()
  }, [type, currentNetwork])

  function handleNext() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (currentStep !== undefined) {
      dispatch(setActive(currentStep + 1))
    }
  }

  function handleBack() {
    if (currentStep !== 0 && !currentStep) return false
    if (currentStep === 0) {
      dispatch(resetRegisterForm())
      navigate(paths.accessCode())
    } else {
      const prevStep = steps[currentStep - 1]
      dispatch(setActive(currentStep - 1))

      window.scrollTo({ top: 0, behavior: 'smooth' })
      navigate(paths.registerTab(prevStep.type))
    }
  }

  const renderStepContent = () => {
    const step = _.find(steps, { type })
    const index = _.findIndex(steps, { type })

    if (currentStep !== undefined && currentStep !== index) {
      navigate(paths.registerTab(steps[currentStep].type))
    }
    return (
      <Box className={classes.wrapper}>
        <Box className={classes.stepContent}>
          {step?.allowBack && (
            <div className={classes.backIconWrapper} onClick={handleBack}>
              <ArrowBackIcon
                className={classes.pageLink}
                style={{ color: '#505358' }}
              />
              {!isMobile && (
                <Typography
                  className={classes.pageLink}
                  style={{ color: '#505358' }}
                >
                  {i18n.back_button}
                </Typography>
              )}
            </div>
          )}
          {step?.content || steps[0].content}
        </Box>
      </Box>
    )
  }

  return <div>{renderStepContent()}</div>
}
