import { styled } from '@mui/styles'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { lsClient } from '../../ls-client'
import { selectNetworkSettings } from '../../pages/network/model'
import { Loading } from '../../ui'
import { selectIsLoggedIn } from '../user/model/userTenantSlice'
import { FEATURES } from './feature-map'
import { FlowApi } from './flow-api'
import {
  FlowActions,
  flowActions,
  flowInitialState,
  flowReducer,
  FlowState,
} from './flow-reducer'

export interface FlowContextActions {
  next: (params?: {
    out: string
    consented?: boolean
    signature?: string
  }) => void
  startFlow: (id: string) => void
  endFLow: () => void
  setOutputs: (outputs: { [key: string]: string }) => void
  completeModule: () => void
  setLoading: (loading: boolean) => void
  resumeFlow: () => void
}
export interface FlowInterface {
  state: FlowState
  actions: FlowContextActions
}

const FlowContext = createContext({})

export interface FlowProviderProps {
  apiUrl: string
  apiUrlBuilder: string
  children: ReactNode
}

export const FlowProvider = (props: FlowProviderProps) => {
  const navigate = useNavigate()
  const networkSettings = useSelector(selectNetworkSettings)
  const loggedIn = useSelector(selectIsLoggedIn)

  const flowApi = useRef<FlowApi>()

  const [flowState, flowDispatch] = useReducer(flowReducer, flowInitialState)

  const startFlow = async (id: string) => {
    setLoading(true)
    flowDispatch(flowActions[FlowActions.START_FLOW]())

    if (flowApi.current) {
      const { start_module, flowId, flowExecutionId } =
        await flowApi.current?.startFlow(id)
      setFlowStorage({ flowId, flowExecutionId, module: start_module })
      flowDispatch(
        flowActions[FlowActions.SET_FLOW_IDS]({ flowId, flowExecutionId })
      )
      flowDispatch(flowActions[FlowActions.SET_MODULE](start_module))
      navigate(FEATURES[start_module.module_type].path(start_module.config))
      setLoading(false)
    }
  }

  const resumeFlow = async () => {
    setLoading(true)
    flowDispatch(flowActions[FlowActions.START_FLOW]())
    if (flowApi.current) {
      const { flowId, flowExecutionId, module } = getFlowStorage()
      flowApi.current.setExecutionId(flowExecutionId)
      flowApi.current.setFlowId(flowId)
      flowApi.current.setCurrentModule(module.module_id)
      flowDispatch(
        flowActions[FlowActions.SET_FLOW_IDS]({ flowId, flowExecutionId })
      )
      flowDispatch(flowActions[FlowActions.SET_MODULE](module))
      navigate(FEATURES[module.module_type].path(module.config))
      setLoading(false)
    }
  }

  const endFlow = () => {
    flowDispatch(flowActions[FlowActions.END_FLOW]())
  }

  const getFlowStorage = () => {
    let flowStorage = lsClient.getUserLSByKey('flowExecution')
    try {
      flowStorage = JSON.parse(flowStorage)
    } catch (e) {
      console.log('error loading previously running flow')
    }
    return {
      flowId: flowStorage?.flowId,
      flowExecutionId: flowStorage?.flowExecutionId,
      module: flowStorage?.module,
    }
  }
  const setFlowStorage = ({
    flowId,
    flowExecutionId,
    module,
  }: { flowId?: string; flowExecutionId?: string; module?: any } = {}) => {
    const flowStorage = {
      flowId: flowId || flowState.flowId,
      flowExecutionId: flowExecutionId || flowState.flowExecutionId,
      module: module || flowState.currentModule,
    }
    lsClient.setUserLS('flowExecution', JSON.stringify(flowStorage))
  }

  const next = async (params?: { out: string }) => {
    setLoading(true)
    const response: any = await flowApi.current?.next(params?.out)

    if (response.module_type) {
      if (response.module_type === 'EndExecution') {
        endFlow()
        navigate(FEATURES[response.module_type].path(response.config))
      }
      setFlowStorage({ module: response })
      flowDispatch(flowActions[FlowActions.SET_MODULE](response))
      navigate(FEATURES[response.module_type].path(response.config))
      setLoading(false)
    }
  }

  const setOutputs = (outputs?: { out: string; [key: string]: string }) => {
    flowDispatch(flowActions[FlowActions.SET_OUTPUT](outputs))
  }

  const completeModule = () => {
    next(flowState.currentModule.outputs)
  }

  const setLoading = (loading: boolean) => {
    flowDispatch(flowActions[FlowActions.SET_LOADING](loading))
  }

  useEffect(() => {
    if (loggedIn && networkSettings?.tenantID && networkSettings.accountId) {
      flowApi.current = new FlowApi(props.apiUrl, props.apiUrlBuilder)
      flowApi.current.getFlows().then((data) => {
        flowDispatch(flowActions[FlowActions.SET_FLOWS_LIST](data))
      })
    }
  }, [networkSettings, loggedIn])

  const value = useMemo(
    () => ({
      state: flowState,
      actions: {
        startFlow,
        next,
        endFlow,
        setOutputs,
        completeModule,
        setLoading,
        resumeFlow,
      },
    }),
    [flowState]
  )

  return (
    <FlowContext.Provider value={value}>
      {props.children}
      {flowState.isLoading ? (
        <LoadingContainer>
          <Loading size={100} />
        </LoadingContainer>
      ) : null}
    </FlowContext.Provider>
  )
}

export const useFlowControl = (): FlowInterface => {
  return useContext(FlowContext) as FlowInterface
}

const LoadingContainer = styled('div')({
  position: 'fixed',
  height: '100%',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 9999,
})
