import { Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getTreamentHistory,
  selectEncounterHistory,
  selectEncounterHistoryPage,
  selectEncounterLoading,
  selectEndOfList,
} from '../../features/encounter/model'
import { selectLanguageContent } from '../../features/translation'
import { formatDate, formatDateAndTime } from '../../libs/helpers'
import { Observer } from '../../libs/observer'
import {
  ListItemTitle,
  ListWrapper,
  MedResponsiveContentWrapper,
  PageTitle,
} from '../../libs/shared-components'
import { lsClient } from '../../ls-client'
import { Loading } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import { carePlanStyles } from './carePlanStyles'

export const CarePlanHistory = () => {
  const classes = carePlanStyles({ radius: 0 })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const profileData = useSelector(selectProfileData)
  const historyList = useSelector(selectEncounterHistory)
  const page = useSelector(selectEncounterHistoryPage)
  const endOfList = useSelector(selectEndOfList)
  const loading = useSelector(selectEncounterLoading)

  useEffect(() => {
    if (profileData._id) {
      const pagination = {
        skip: 0,
        limit: 10,
      }
      dispatch(getTreamentHistory(profileData._id, pagination, false))
    }
  }, [profileData])

  const handleBack = () => {
    navigate(paths.app.dashboard())
  }

  const getNextPage = (id: string, page: number) => {
    if (loading || !id) return
    const pagination = {
      skip: page,
      limit: 10,
    }
    dispatch(getTreamentHistory(profileData._id, pagination, true))
  }
  const handleClick = (id: string) => {
    lsClient.navigation.setBackPath('list')
    navigate(paths.treatmentPlan(id))
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      color="#505358"
    >
      <MedResponsiveContentWrapper>
        <PageTitle>{i18n.treatment_plans}</PageTitle>
        {historyList.map((d, i: number) => (
          <React.Fragment key={d._id}>
            {!endOfList && historyList.length - 5 === i && (
              <Observer
                callback={() => getNextPage(profileData._id, page + 1)}
              />
            )}

            <ListWrapper onClick={() => handleClick(d._id)}>
              <ListItemTitle style={{ width: '50%' }}>{d.workflowTitle}</ListItemTitle>
              <Typography className={`${classes.historyBody} wrap`}>
                {formatDateAndTime(d.created)}
              </Typography>
              <ChevronRightIcon />
            </ListWrapper>
          </React.Fragment>
        ))}
        {loading && <Loading />}
      </MedResponsiveContentWrapper>
    </ModuleHeader>
  )
}
