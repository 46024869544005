import { Link as MUILink, Typography } from '@mui/material'
import React from 'react'

import { onboardingStyles } from '../../../_styles/registrationStyles'

export const EasableSupport = () => {
  const classes = onboardingStyles()

  return (
      <MUILink className={classes.contactLink} onClick={() => window.open('http://www.easable.com/contact-us')}>
        <Typography align='center' className={classes.contactLink}>Contact <span className={classes.supportNameEmphasis}>easable</span> Support</Typography>
      </MUILink>
  )
}
