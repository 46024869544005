import { makeStyles, ClassNameMap } from '@mui/styles'
import SvgIcon from '@mui/material/SvgIcon'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import React from 'react'

export const IconButton = (props: {
  icon: React.ReactNode
  handleClick: () => void
  childClass?: string
}) => {
  const { icon, handleClick, childClass } = props
  const classes = useStyles()

  return (
    <SvgIcon
      onClick={() => handleClick()}
      className={`${classes.iconFilled} ${childClass}`}
    >
      {icon}
    </SvgIcon>
  )
}

const useStyles = makeStyles((theme) => ({
  iconFilled: {
    borderRadius: '100%',
    fontSize: '24px',
    padding: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.34)',
    color: 'white',
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      padding: 6,
    },
  },
}))
