import CameraAltIcon from '@mui/icons-material/CameraAlt'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import React from 'react'
import { ReactComponent as Resources } from '../../../assets/icons/article-outlined.svg'
import { ReactComponent as Calendar } from '../../../assets/icons/calendar.svg'
import { ReactComponent as FAQ } from '../../../assets/icons/faq-quiz.svg'

export const InfoCell = () => <InfoOutlinedIcon style={{ marginRight: 10 }} />
export const CalendarCell = () => <Calendar style={{ marginRight: 10 }} />
export const PDFCell = () => (
  <PictureAsPdfOutlinedIcon style={{ fontSize: 48, color: '#757575' }} />
)
export const FAQCell = () => <FAQ />
export const ResourcesCell = () => <Resources />
export const CameraCell = () => (
  <CameraAltIcon style={{ marginRight: 10, fontSize: 18 }} />
)
export const ChevronRight = () => <ChevronRightIcon color="primary" />
