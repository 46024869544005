import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { ccExpiresFormat } from '../../../../libs/helpers'
import { PageTitle } from '../../../../libs/shared-components'
import { shopifyStoreStyles } from '../../../../pages/_styles/shopifyStoreStyles'
import {
  Button,
  Checkbox,
  FilledTextField,
  MaskedInput,
  TextField,
  Typography,
} from '../../../../ui'
import { CreditCardEntry } from '../../../../ui/atoms/credit-card/credit-card-number'
import { I18n } from '../../../translation/types'
import { CreditCardFormValues, UserDataMethods } from '../../model/types'
import { getValidationSchema } from '../../model/validationSchema'
import { PaymentMethod } from '../../SAFE-ecommerce-service/EcommerceSDK'

const stripeAuth = process.env.REACT_APP_PAYMENT_KEY

const stripe = require('stripe')(stripeAuth)

export const AddNewCard = (props: {
  onChange: (method: any) => void
  sdk: any
  i18n: I18n
}) => {
  const { i18n, onChange, sdk } = props
  const classes = shopifyStoreStyles()
  const [makeDefault, setMakeDefault] = useState(false)
  const type = UserDataMethods.PAYMENT_METHOD
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [])

  const onSubmit = async (values: CreditCardFormValues) => {
    setLoading(true)
    const { cardNumber, cvc, expiration, cardName, zip } = values

    const token = await stripe.tokens.create({
      card: {
        number: '4242424242424242',
        exp_month: 10,
        exp_year: 2024,
        cvc: '314',
      },
    })
    //getToken from stripe

    const parameters = new PaymentMethod.Companion.stripe(
      token.id,
      token.card.brand,
      token.card.last4
    )
    onChange(parameters)
  }
  const formik = useFormik<CreditCardFormValues>({
    initialValues: {
      cardNumber: '',
      cvc: '',
      expiration: '',
      cardName: '',
      zip: '',
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(i18n, type),
    onSubmit,
  })

  const handleChangeExpiry = (e: any) => {
    const val = e.target.value
    const formatedVal = ccExpiresFormat(val)
    formik.setFieldValue('expiration', formatedVal)
  }

  const values = formik.values

  return (
    <div className={classes.checkoutMethodContent}>
      <PageTitle>
        {i18n['ecommerce.add_credit-card_view.navigation_title']}
      </PageTitle>
      <form className={classes.form}>
        <div className={classes.entries}>
          <div className={classes.quantityWrapper}>
            <LockOutlinedIcon style={{ color: '#757575' }} />
            <Typography className={classes.paymentStorageNotice}>
              {i18n.add_card_stored_securely_message}
            </Typography>
          </div>
          <CreditCardEntry
            name={'cardNumber'}
            autoComplete="cc-number"
            label={i18n['ecommerce.placeholders.credit_card_number_field']}
            value={formik.values.cardNumber}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={
              Boolean(formik.touched.cardNumber) &&
              Boolean(formik.errors.cardNumber)
            }
            helperText={
              formik.touched.cardNumber ? formik.errors.cardNumber : ''
            }
          />
          <div className={classes.twoFields}>
            <MaskedInput
              mask="9999"
              maskChar=""
              label={i18n['ecommerce.placeholders.cvc_field']}
              value={formik.values.cvc}
              variant="filled"
              type={'text'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.cvc && Boolean(formik.errors.cvc)}
              helperText={formik.touched.cvc ? formik.errors.cvc : ''}
              name={'cvc'}
            />
            <FilledTextField
              label={i18n['ecommerce.placeholders.expiry_field']}
              autoComplete="cc-exp"
              name={'expiration'}
              type={'text'}
              isNumberField
              value={formik.values.expiration}
              error={
                formik.touched.expiration && Boolean(formik.errors.expiration)
              }
              helperText={
                formik.touched.expiration ? formik.errors.expiration : ''
              }
              variant="filled"
              onChange={(e) => handleChangeExpiry(e)}
              onBlur={formik.handleBlur}
              inputProps={{
                inputMode: 'numeric',
              }}
            />
          </div>
          <FilledTextField
            label={i18n['ecommerce.placeholders.name_on_card_field']}
            autoComplete="cc-name"
            name={'cardName'}
            type={'text'}
            value={formik.values.cardName}
            error={formik.touched.cardName && Boolean(formik.errors.cardName)}
            helperText={formik.touched.cardName ? formik.errors.cardName : ''}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <MaskedInput
            mask="99999"
            maskChar=""
            label={i18n['ecommerce.placeholders.zip_code_field']}
            value={formik.values.zip}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.zip && Boolean(formik.errors.zip)}
            helperText={formik.touched.zip ? formik.errors.zip : ''}
            name={'zip'}
          />
          <div>
            <Checkbox
              label={i18n.set_as_default_payment_method}
              checked={makeDefault}
              onChange={() => setMakeDefault(!makeDefault)}
            />
          </div>
        </div>
        <Box className={classes.lgButtonBox}>
          <Button
            onClick={() => onSubmit(values)}
            className={classes.dialogButton}
            disabled={!formik.isValid || !formik.dirty || loading}
          >
            {i18n.checkout_update_label}
          </Button>
        </Box>
      </form>
    </div>
  )
}
