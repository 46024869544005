import { Box, Dialog, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import DOMPurify from 'dompurify'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../ui'
import { BackHeader } from '../../../../../ui/templates/back-header'
import { selectDocTerm } from '../../../../terms'
import { fetchTermByKey } from '../../../../terms/model/main'
import { selectDocLoading } from '../../../../terms/model/selectors'
import { selectLanguageContent } from '../../../../translation'
import { ModuleHeader } from '../../../../../ui/templates/modules/moduleHeader'
import { HTMLTextComponent } from '../../../../../libs/shared-components'

interface SignupTermsProps {
  isOpen: boolean
  closeDialog: () => void
  verbiageKey: string
}

const DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT_ID || ''

export const SignupTerms = (props: SignupTermsProps) => {
  const { isOpen, closeDialog, verbiageKey } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const doc = useSelector(selectDocTerm)
  const docLoading = useSelector(selectDocLoading)
  const i18n = useSelector(selectLanguageContent)
  const signupTenant = localStorage.getItem('tenantId')

  useEffect(() => {
    if (verbiageKey) {
      dispatch(
        fetchTermByKey(
          verbiageKey,
          signupTenant ? signupTenant : DEFAULT_TENANT
        )
      )
    }
  }, [verbiageKey])

  const docView = () => {
    return (
      <>
        <Box className={classes.detailContainer}>
          <Typography variant="h2">{doc?.title}</Typography>
          {doc?.value ? (
            <HTMLTextComponent HTMLString={doc.value} />
          ) : (
            <Typography>{i18n.no_data_loaded_error_message}</Typography>
          )}
        </Box>
      </>
    )
  }

  return (
    <Dialog open={isOpen} fullScreen>
      <ModuleHeader
        leftContentAction={closeDialog}
        leftContentTitle={i18n.back_button}
        color="#505358"
      >
        <Box className={classes.detailContainer}>
          {docLoading ? <Loading /> : <>{docView()}</>}
        </Box>
      </ModuleHeader>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  detailContainer: {
    maxWidth: '800px',
    overflow: 'auto',
    margin: '32px auto 48px',
    [theme.breakpoints.down(960)]: {
      maxWidth: '600px',
    },
    [theme.breakpoints.down(600)]: {
      padding: '24px 0px',
      width: '90%',
      margin: '0px auto',
    },
  },
}))
