import moment from 'moment'
import * as Yup from 'yup'
import { StepType } from '../../../constants'
import { I18n } from '../../../features/translation/types'
import {
  isValidEmailAddress,
  isValidPasswordDigit,
  isValidPasswordLength,
  isValidPasswordLower,
  isValidPasswordSpecial,
  isValidPasswordUpper,
} from '../../../libs/helpers'
import { NetworkSettings } from '../../network/type'

const NOT_ALLOWED = 'Value not allowed'

const accountInfoValidation = (
  i18n: I18n,
  currentNetwork: NetworkSettings | null
) => {
  const {
    collectDateOfBirth,
    collectDateOfBirthRequired,
    collectSex,
    collectSexRequired,
    collectRace,
    raceRequired,
    collectEthicity,
    ehtnicityRequired,
  } = currentNetwork || {}

  const dob =
    collectDateOfBirth && collectDateOfBirthRequired
      ? Yup.date()
          .nullable()
          .typeError(i18n.invalid_date)
          .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
          .test({
            name: 'furture-date',
            message: 'Date of birth cannot be a date in the future.',
            test: (value) => {
              if (!value) return true
              return new Date() > new Date(value)
            },
          })
          .max(
            moment().utc().subtract(18, 'years').local().endOf('day').toDate(),
            'User must be at least 18 years old to create an account.'
          )
          .required(i18n.required)
      : Yup.date()
          .nullable()
          .typeError(i18n.invalid_date)
          .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
          .test({
            name: 'furture-date',
            message: 'Date of birth cannot be a date in the future.',
            test: (value) => {
              if (!value) return true
              return new Date() > new Date(value)
            },
          })
          .max(
            moment().utc().subtract(18, 'years').local().endOf('day').toDate(),
            'User must be at least 18 years old to create an account.'
          )

  const sexAtBirthValidation = Yup.string()
  const sexAtBirth =
    collectSex && collectSexRequired
      ? sexAtBirthValidation.required(i18n.required)
      : sexAtBirthValidation

  const raceValidation = Yup.string()
  const race =
    collectRace && raceRequired
      ? raceValidation.required(i18n.required)
      : raceValidation

  const ethnicityValidation = Yup.string()
  const ethnicity =
    collectEthicity && ehtnicityRequired
      ? ethnicityValidation.required(i18n.required)
      : ethnicityValidation

  return Yup.object().shape({
    [StepType.ACC_INFO]: Yup.object().shape({
      dob,
      sexAtBirth,
      race,
      ethnicity,
    }),
  })
}

const phoneValidation = (
  i18n: I18n,
  currentNetwork: NetworkSettings | null
) => {
  const { collectPhoneNumber, collectPhoneNumberRequired } =
    currentNetwork || {}

  const mobileNumber =
    collectPhoneNumber && collectPhoneNumberRequired
      ? Yup.string()
          .min(10, i18n.invalid_phone_error_message)
          .max(10, i18n.invalid_phone_error_message)
          .matches(/^(?!0{10})/, NOT_ALLOWED)
          .matches(/^(?!0+[1-9])/, NOT_ALLOWED)
          .required(i18n.required)
      : Yup.string()
          .min(10, i18n.invalid_phone_error_message)
          .max(10, i18n.invalid_phone_error_message)
          .matches(/^(?!0{10})/, NOT_ALLOWED)
          .matches(/^(?!0+[1-9])/, NOT_ALLOWED)

  return Yup.object().shape({
    [StepType.ENTER_PHONE_NUMBER]: Yup.object().shape({
      mobileNumber,
    }),
  })
}

const EligibilityValidation = (
  i18n: I18n,
  currentNetwork: NetworkSettings | null
) => {
  const { verificationField01, verificationField02, verificationField03 } =
    currentNetwork?.accountVerificationScreenCriteria || {}

  const lastName = Boolean(verificationField01)
    ? Yup.string()
        .matches(/^[a-zA-Z]+$/, NOT_ALLOWED)
        .required(i18n.required)
    : Yup.string().matches(/^[a-zA-Z]+$/, NOT_ALLOWED)

  const lastFourSSN = Boolean(verificationField03)
    ? Yup.string()
        .min(4, i18n.ssn_error_message)
        .max(4, i18n.ssn_error_message)
        .required(i18n.required)
    : Yup.string()

  const dateOfBirth = verificationField02
    ? Yup.date()
        .nullable()
        .typeError(i18n.invalid_date)
        .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
        .test({
          name: 'furture-date',
          message: 'Date of birth cannot be a date in the future.',
          test: (value) => {
            if (!value) return true
            return new Date() > new Date(value)
          },
        })
        .max(
          moment().utc().subtract(18, 'years').local().endOf('day').toDate(),
          'Users must be at least 18 years old.'
        )
        .required(i18n.required)
    : Yup.date()
        .nullable()
        .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
        .typeError(i18n.invalid_date)
        .test({
          name: 'furture-date',
          message: 'Date of birth cannot be a date in the future.',
          test: (value) => {
            if (!value) return true
            return new Date() > new Date(value)
          },
        })
        .max(
          moment().utc().subtract(18, 'years').local().endOf('day').toDate(),
          'Users must be at least 18 years old.'
        )

  return Yup.object().shape({
    [StepType.ELIGIBILITY]: Yup.object().shape({
      lastName,
      dateOfBirth,
      lastFourSSN,
    }),
  })
}

const addressValidation = (
  i18n: I18n,
  currentNetwork: NetworkSettings | null
) => {
  if (!currentNetwork) return
  const {
    collectAddress,
    collectZip,
    collectZipRequired,
    collectStreetAddress,
    collectStreetAddressRequired,
    collectCity,
    collectCityRequired,
    collectState,
    collectStateRequired,
  } = currentNetwork

  const line1 =
    collectAddress && collectStreetAddress && collectStreetAddressRequired
      ? Yup.string().required(i18n.required)
      : Yup.string()

  const city =
    collectAddress && collectCity && collectCityRequired
      ? Yup.string().required(i18n.required)
      : Yup.string()

  const state =
    collectAddress && collectState && collectStateRequired
      ? Yup.string().required(i18n.required)
      : Yup.string()

  const zip =
    collectAddress && collectZip && collectZipRequired
      ? Yup.string()
          .min(5, i18n.invalid_zip_code_error_message)
          .max(5, i18n.invalid_zip_code_error_message)
          .matches(/^(?!0{5})/, NOT_ALLOWED)
          .required(i18n.required)
      : Yup.string()
          .min(5, i18n.invalid_zip_code_error_message)
          .max(5, i18n.invalid_zip_code_error_message)
          .matches(/^(?!0{5})/, NOT_ALLOWED)

  return Yup.object().shape({
    [StepType.SHIPPING_ADDRESS]: Yup.object().shape({
      address: Yup.object().shape({
        line1,
        city,
        state,
        zip,
      }),
    }),
  })
}

const signupValidationSchema = (i18n: I18n) =>
  Yup.object().shape({
    [StepType.SIGN_UP]: Yup.object().shape({
      firstName: Yup.string()
        .matches(/^[a-zA-Z]+$/, NOT_ALLOWED)
        .required(i18n.required),
      lastName: Yup.string()
        .matches(/^[a-zA-Z]+$/, NOT_ALLOWED)
        .required(i18n.required),
      email: Yup.string()
        .test('valid-email', i18n.invalid_email_error_message, (value) =>
          isValidEmailAddress(value || '')
        )
        .required(i18n.required),
    }),
  })

const PasswordValidationSchema = (i18n: I18n) =>
  Yup.object().shape({
    [StepType.CREATE_PASSWORD]: Yup.object().shape({
      password: Yup.string()
        .test('valid-length', i18n.password_requirements_warning, (value) =>
          isValidPasswordLength(value || '')
        )
        .test('valid-upper', i18n.password_requirements_warning, (value) =>
          isValidPasswordUpper(value || '')
        )
        .test('valid-lower', i18n.password_requirements_warning, (value) =>
          isValidPasswordLower(value || '')
        )
        .test('valid-digit', i18n.password_requirements_warning, (value) =>
          isValidPasswordDigit(value || '')
        )
        .test('valid-special', i18n.password_requirements_warning, (value) =>
          isValidPasswordSpecial(value || '')
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match!')
        .required(i18n.required),
    }),
  })

const insuranceValidationSchema = (i18n: I18n) =>
  Yup.object().shape({
    [StepType.INSURANCE]: Yup.object().shape({
      insuranceProviderCompany: Yup.object().nullable().required(i18n.required),
      insuranceId: Yup.string().required(i18n.required),
      groupNumber: Yup.string().required(i18n.required),
      planName: Yup.string().required(i18n.required),
      coverageDate: Yup.date()
        .nullable()
        .typeError(i18n.invalid_date)
        .required(i18n.required),
    }),
  })

const policyInfoValidationSchema = (i18n: I18n) =>
  Yup.object().shape({
    [StepType.POLICY_INFO]: Yup.object().shape({
      firstName: Yup.string().required(i18n.required),
      lastName: Yup.string().required(i18n.required),
      phone: Yup.string()
        .min(17, i18n.invalid_phone_error_message)
        .max(17, i18n.invalid_phone_error_message)
        .matches(/^(?!0{10})/, NOT_ALLOWED)
        .matches(/^(?!0+[1-9])/, NOT_ALLOWED)
        .required(i18n.required),
      dob: Yup.date()
        .nullable()
        .typeError(i18n.invalid_date)
        .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
        .test({
          name: 'furture-date',
          message: 'Date of birth cannot be a date in the future.',
          test: (value) => {
            if (!value) return true
            return new Date() > new Date(value)
          },
        })
        .max(
          moment().utc().subtract(18, 'years').local().endOf('day').toDate(),
          'User must be at least 18 years old to create an account.'
        )
        .required(i18n.required),
      relationship: Yup.string().required(i18n.required),
      address: Yup.object().shape({
        line1: Yup.string().required(i18n.required),
        line2: Yup.string(),
        city: Yup.string().required(i18n.required),
        state: Yup.string().required(i18n.required),
        zipCode: Yup.string()
          .min(5, i18n.invalid_zip_code_error_message)
          .max(5, i18n.invalid_zip_code_error_message)
          .matches(/^(?!0{5})/, NOT_ALLOWED)
          .required(i18n.required),
      }),
    }),
  })

export const getValidationSchema = (
  i18n: I18n,
  type: string,
  currentNetwork: NetworkSettings | null
) => {
  switch (type) {
    case StepType.ELIGIBILITY:
      return EligibilityValidation(i18n, currentNetwork)
    case StepType.SIGN_UP:
      return signupValidationSchema(i18n)
    case StepType.CREATE_PASSWORD:
      return PasswordValidationSchema(i18n)
    case StepType.ENTER_PHONE_NUMBER:
      return phoneValidation(i18n, currentNetwork)
    case StepType.SHIPPING_ADDRESS:
      return addressValidation(i18n, currentNetwork)
    case StepType.ACC_INFO:
      return accountInfoValidation(i18n, currentNetwork)
    case StepType.INSURANCE:
      return insuranceValidationSchema(i18n)
    case StepType.POLICY_INFO:
      return policyInfoValidationSchema(i18n)
    default:
      return null
  }
}
