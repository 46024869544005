import { SecuritySettings, SecuritySettingsType } from '../../../type'
import { ForgetDevice } from './forget-device'
import { MFA } from './mfa'
import { ChangePassword } from './password'

export const securitySettingsMenuItems: SecuritySettings = {
  [SecuritySettingsType.PASSWORD]: {
    page_title_key: 'change_your_password_title',
    label_key: 'login_password',
    content: ChangePassword,
  },
  [SecuritySettingsType.MFA]: {
    page_title_key: 'multi_factor_authentication',
    label_key: 'multi_factor_authentication',
    content: MFA,
  },
  [SecuritySettingsType.FORGET_DEVICE]: {
    page_title_key: 'forget_this_device',
    label_key: 'forget_this_device',
    content: ForgetDevice,
  },
}
