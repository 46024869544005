import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MUISelect, { SelectChangeEvent } from '@mui/material/Select'
import { makeStyles } from '@mui/styles'
import React, { ReactNode } from 'react'
import { getColor } from '../../../libs/utils'
import { ColorWithHex } from '../../../types/general'

interface SelectProps {
  onChange?: (e: SelectChangeEvent<string>, child: ReactNode) => void
  value?: string
  label?: string
  options?: { label: string; value: string }[]
  required?: boolean
  error?: string
  onBlur?: (e: React.FocusEvent) => void
  readOnly?: boolean
  disabled?: boolean
  name?: string
  color?: ColorWithHex
}
export const Select: React.FC<SelectProps> = ({
  onChange,
  value,
  label,
  options = [],
  required = false,
  error,
  onBlur,
  readOnly,
  disabled,
  name,
  color = 'primary',
}) => {
  const classes = useStyles({ color })
  return (
    <FormControl
      required={required}
      error={Boolean(error)}
      aria-readonly={readOnly}
    >
      <InputLabel className={classes.label}>{label}</InputLabel>
      <MUISelect
        name={name}
        required={required}
        onChange={onChange}
        value={value}
        className={classes.select}
        onBlur={onBlur}
        error={Boolean(error)}
        readOnly={readOnly}
        variant='filled'
        disableUnderline
        IconComponent={readOnly ? 'div' : undefined}
        disabled={disabled}
      >
        {options.map((gen) => (
          <MenuItem value={gen.value} key={gen.value}>
            {gen.label}
          </MenuItem>
        ))}
      </MUISelect>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

const NO_BORDER_BOTTOM = '0px solid #FFF'

const useStyles = makeStyles((theme) => ({
  label: {
    color: ({ color }: { color: ColorWithHex }) => getColor(color, theme),
  },
  select: {
    'background': '#FFF',
    '&.Mui-focused': {
      backgroundColor: '#FFF',
    },
    '&.MuiSelect-select': {
      '&:focus': {
        backgroundColor: '#FFF',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: '#FFF',
    },
    '&:hover': {
      '&::before': { borderBottom: NO_BORDER_BOTTOM },
      'background': '#FFF',
      '&::after': { borderBottom: NO_BORDER_BOTTOM },
    },
    '&::before': { borderBottom: NO_BORDER_BOTTOM },
    '&::after': { borderBottom: NO_BORDER_BOTTOM },
    '& .MuiSelect-root': {
      borderColor: '#FFF !important',
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },
}))
