import { QuestionnaireColor, QuestionnaireColorType } from '../../types'

export const getQuestionnaireColors = (
  colorProp: QuestionnaireColor | null
) => {
  if (!colorProp) return QuestionnaireColorType.secondary

  switch (colorProp.color) {
    case QuestionnaireColorType.primary: {
      return QuestionnaireColorType.primary
    }
    case QuestionnaireColorType.custom: {
      return colorProp.colorHex || QuestionnaireColorType.secondary
    }
    default:
    case QuestionnaireColorType.secondary: {
      return QuestionnaireColorType.secondary
    }
  }
}
