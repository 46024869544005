import './index.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Application } from './application'
import { FlowProvider } from './features/flowcontrol/use-flow-control'
import { persistor, store } from './redux'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

const flowEngineAPI = process.env.REACT_APP_FLOWENGINE_API_URL ?? ''
const flowBuilderAPI = process.env.REACT_APP_FLOWBUILDER_API_URL ?? ''
const container = document.querySelector('#root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <PersistGate loading={null} persistor={persistor}>
          <Router >
            <FlowProvider apiUrl={flowEngineAPI} apiUrlBuilder={flowBuilderAPI}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Application />
              </LocalizationProvider>
            </FlowProvider>
          </Router>
        </PersistGate>
      </CookiesProvider>
    </Provider>
  </React.StrictMode>
)
reportWebVitals()
serviceWorker.register()
