import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { testTypes } from '../../constants'
import { useDialog } from '../../features/dialog/application-dialog'
import { selectLanguageContent } from '../../features/translation'
import { getLastDropOffTime } from '../../libs/helpers'
import { useLocationService } from '../../libs/hooks/useLocation'
import { Observer } from '../../libs/observer'
import { lsClient } from '../../ls-client'
import { Loading, SearchField } from '../../ui'
import { facilityStyles } from '../_styles/facilityStyles'
import { storeSelectedFacility } from '../checkout/model'
import FacilitiesMap from '../map/FacilitiesMap'
import { paths } from '../paths'
import { ConfirmationDialog } from '../schedule-appointment/confirmationDialog'
import {
  confirmDropOff,
  selectIsLoadingConfirmation,
  setDropOffPickupLocation,
} from '../shipping/shippingTestSlice'
import { FacilityOption } from './components/FacilityOption'
import { FacilityOptionDetails } from './components/FacilityOptionDetails'
import {
  getFacilities,
  getFedExOffices,
  selectFacilitiesList,
  selectLoading,
  selectMoreLoading,
} from './model'
import { FedExAddress } from './types'

export const FacilityPage = (props: {
  pageTitle: string
  type: testTypes
  handleNext?: () => void
}) => {
  const { pageTitle, type, handleNext } = props
  const classes = facilityStyles()
  const orderId = lsClient.getUserLSByKey('orderId')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const facilities = useSelector(selectFacilitiesList)
  const isLoading = useSelector(selectLoading)
  const i18n = useSelector(selectLanguageContent)
  const loadMore = useSelector(selectMoreLoading)
  const isLoadingConfirmation = useSelector(selectIsLoadingConfirmation)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const { state, actions } = useLocationService()
  const searchRef = useRef<HTMLInputElement>(null)
  const { openDialog, closeDialog } = useDialog()
  const [facilitiesQuery, setFacilitiesQuery] = useState({
    lat: 0,
    lng: 0,
    term: '',
    pageNumber: 0,
    pageSize: 10,
  })
  const delayedSearch = useRef(
    _.debounce((value) => {
      setFacilitiesQuery((prevState) => ({
        ...prevState,
        term: value,
      }))
    }, 500)
  ).current

  const getLocations = (address: FedExAddress) => {
    if (type === testTypes.DROPOFF) {
      dispatch(getFedExOffices(address, orderId))
    } else {
      dispatch(getFacilities(facilitiesQuery))
    }
  }

  const tryAgain = () => {
    closeDialog()
    actions.getCurrentLocation('address')
  }

  const searchByZip = () => {
    closeDialog()
    searchRef.current?.focus()
  }

  useEffect(() => {
    const hasLocation = lsClient.getUserLSByKey('currentLocation')
    if (hasLocation) {
      try {
        const address = JSON.parse(hasLocation)
        dispatch(getFedExOffices(address, orderId))
      } catch {}
    } else {
      actions.getCurrentLocation('address')
    }
  }, [])

  useEffect(() => {
    if (state.foundAddress) {
      lsClient.setUserLS('currentLocation', JSON.stringify(state.foundAddress))
      dispatch(getFedExOffices(state.foundAddress, orderId))
    }
  }, [state.foundAddress])

  useEffect(() => {
    if (state.error) {
      openDialog({
        title: i18n.location_not_enabled,
        body: i18n.location_not_enabled_instructions,
        onCancel: searchByZip,
        cancelLabel: i18n.enter_zipcode_message,
        onConfirm: tryAgain,
        confirmationLabel: i18n.try_again,
      })
    }
  }, [state.error])

  const handleSearch = async (e: any) => {
    if (e.target.value.length > 5) return
    setSearchInputValue(e.target.value)
    if (e.target.value.length === 5) {
      await actions
        .getLatLangFromAddress({ zip: e.target.value })
        .then((response) => {
          if (response?.results[0].geometry)
            getLocations({
              zip: e.target.value,
              latitude: response.results[0].geometry.location.lat().toString(),
              longitude: response.results[0].geometry.location.lng().toString(),
            })
        })
    }
  }

  const cleanSearch = () => {
    setSearchInputValue('')
    delayedSearch('')
  }

  const setNextPage = () => {
    setFacilitiesQuery((prevState) => ({
      ...prevState,
      pageNumber: prevState.pageNumber + 1,
    }))
    dispatch(getFacilities(facilitiesQuery, true))
  }

  const onMarkerClick = (props: any) => {
    const { lat, lng } = props.position
    if (!facilities.length) return null
    const facility = facilities.find((item: any) => {
      return item.latitude === lat && item.longitude === lng
    })
    if (facility) {
      setSelectedItem(facility)
      setShowDetails(true)
    }
  }

  const goNextAppointment = () => {
    navigate(paths.scheduleAppointment(type, orderId, selectedItem?.key))
    dispatch(storeSelectedFacility(selectedItem))
  }

  const goNextDropOff = () => {
    setConfirmationOpen(true)
  }

  const config: any = {
    [testTypes.APPOINTMENT]: {
      title: i18n.select_facility_screen_title,
      selectButtonText: i18n.select_facility_button,
      onClickSelect: goNextAppointment,
      confirmationTitle: i18n.appointment_confirmation_title,
    },
    [testTypes.DROPOFF]: {
      title: i18n.find_drop_off_location_screen_title,
      selectButtonText: i18n.select_drop_off_location,
      onClickSelect: goNextDropOff,
      confirmationTitle: i18n.return_sample,
      confirmationDescription: i18n.drop_off_within_24_message,
    },
  }

  const handleConfirmDialog = async () => {
    setProcessing(true)
    dispatch(setDropOffPickupLocation(selectedItem))

    if (await dispatch(confirmDropOff(orderId))) {
      lsClient.removeUserKeyLS('currentLocation')
      navigate(paths.app.dashboard())
      setProcessing(false)
    }

    setConfirmationOpen(false)
    setProcessing(false)
  }

  if (processing || state.gettingLocation || isLoading)
    return <Loading size={100} style={{ marginTop: '45vh' }} />

  return (
    <div className={classes.page}>
      <Box className={classes.mapImage}>
        <FacilitiesMap
          selectedItem={selectedItem}
          facilities={facilities}
          onMarkerClick={onMarkerClick}
        />
      </Box>
      {!showDetails ? (
        <Box className={classes.facilityInfoBox}>
          <div className={classes.content}>
            <div className={classes.titleWrapper}>
              <Typography className={classes.pageTitle}>{pageTitle}</Typography>
              <Box className={classes.closeWrapper}>
                <SearchField
                  type="numeric"
                  handleChange={handleSearch}
                  placeHolderText={i18n.enter_zipcode_message}
                  searchValue={searchInputValue}
                  inputRef={searchRef}
                  clearSearchValue={() => setSearchInputValue('')}
                />
              </Box>
            </div>
            <div className={classes.locationWrapper}>
              {facilities.map((item: any, index: number) => (
                <>
                  {/*Disable autoupload scroll for dropoff locations */}
                  {/* {facilities.length - 5 === index &&
                    type !== testTypes.DROPOFF && (
                      <Observer callback={setNextPage} />
                    )} */}
                  <FacilityOption
                    item={item}
                    setShowDetails={setShowDetails}
                    setSelectedItem={setSelectedItem}
                    type={type}
                  />
                </>
              ))}
            </div>
            <Box className={classes.moreLoadingBox}>
              {loadMore ? i18n.loading : null}
            </Box>
          </div>
        </Box>
      ) : (
        <FacilityOptionDetails
          facility={selectedItem}
          i18n={i18n}
          setShowDetails={setShowDetails}
          config={config}
          type={type}
        />
      )}
      <ConfirmationDialog
        title={config[testTypes.DROPOFF].confirmationTitle}
        description={config[testTypes.DROPOFF].confirmationDescription}
        isOpen={confirmationOpen}
        info={{
          selectedLocation: selectedItem,
          date: 'Today',
          time: `Drop off by ${
            getLastDropOffTime(selectedItem?.normalHours) || '-'
          }`,
        }}
        onConfirm={handleConfirmDialog}
        onCancel={() => setConfirmationOpen(false)}
      />
    </div>
  )
}
