/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import '../../fonts.css'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { StepType } from '../../constants'
import {
  getSavedDeviceValues,
  userHasDeviceCookie,
} from '../../features/cookies/cookie-helper'
import {
  selectHealthServiceList,
  selectHealthServiceLoaded,
} from '../../features/health-service-lite/model'
import { selectLanguageContent } from '../../features/translation'
import {
  forgetMFADevice,
  getLatestResult,
} from '../../features/user/model/userTenantSlice'
import { checkRequiredData } from '../../libs/helpers'
import { lsClient } from '../../ls-client'
import { selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import {
  getUserCode,
  getUserPicture,
  selectIsUserVerified,
  selectProfileData,
} from '../profile/model'
import {
  saveDependentPrefill,
  setActive,
} from '../register/model/registerSlice'
import { TenantSelectionDialog } from '../tenant-selection/tenant-selection-page'
import {
  selectActiveTenant,
  selectTenantList,
} from '../tenant-selection/tenantSlice'
import { DashboardStyle } from './homescreen-mode-views'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const DashboardPage = () => {
  const healthServiceLoaded = useSelector(selectHealthServiceLoaded)
  const healthServiceList = useSelector(selectHealthServiceList)
  const profileData = useSelector(selectProfileData)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const networkSettings = useSelector(selectNetworkSettings)
  const activeTenant = useSelector(selectActiveTenant)
  const i18n = useSelector(selectLanguageContent)
  const tenantList = useSelector(selectTenantList)
  const [cookies, setCookie, removeCookie] = useCookies()
  const [tenantSelectionOpen, setTenantSelectionOpen] = useState(false)
  const isUserVerified = useSelector(selectIsUserVerified)

  const {
    accountId,
    email,
    settings,
    isGuardianMode,
    _id: userId,
  } = profileData
  const rememberedDevice = userHasDeviceCookie(email)
  const isFirstLogin = settings.firstTimeLogin
  const userdata = lsClient.getUserLS()
  const tenantId = userdata?.tenantId

  useEffect(() => {
    const userdata = lsClient.getUserLS()
    const tenantId = userdata?.tenantId

    if (!userId || isFirstLogin || !tenantId || !networkSettings) return

    // Check user object for missing required data
    if (!profileData.contact.mobileNumber) {
      dispatch(setActive(4))
      dispatch(saveDependentPrefill(profileData))
      navigate(paths.registerTab(StepType.ENTER_PHONE_NUMBER))
    } else if (
      (networkSettings.collectDateOfBirthRequired && !profileData.birthDate) ||
      (networkSettings.ehtnicityRequired && !profileData.ethnicity) ||
      (networkSettings.raceRequired && !profileData.race) ||
      (networkSettings.collectSexRequired && !profileData.gender)
    ) {
      dispatch(setActive(6))
      dispatch(saveDependentPrefill(profileData))
      navigate(paths.registerTab(StepType.ACC_INFO))
    } else if (
      networkSettings.collectAddressRequired &&
      !profileData.shippingAddress
    ) {
      dispatch(setActive(7))
      dispatch(saveDependentPrefill(profileData))
      navigate(paths.registerTab(StepType.SHIPPING_ADDRESS))
    }

    if (networkSettings.verifyEmail && !isUserVerified) {
      return navigate(paths.confirmEmail())
    }
    // Forget device if tenant settings do not allow
    if (
      rememberedDevice &&
      (networkSettings.mfaSettings?.enableDeviceRemembering === false ||
        networkSettings.mfaSettings?.memberDays === 0)
    ) {
      forgetDevice()
    }
  }, [networkSettings, userId])

  useEffect(() => {
    if (!email) return

    if (!tenantId && tenantList) {
      setTenantSelectionOpen(true)
      return
    }

    if (isFirstLogin) {
      // navigate(paths.setupMFA())
    } else if (
      healthServiceLoaded &&
      healthServiceList.length === 0 &&
      userdata?.code &&
      userdata.testId &&
      tenantId
    ) {
      navigate(paths.instructions())
    }
  }, [email, activeTenant, tenantList, healthServiceLoaded])

  useEffect(() => {
    if (activeTenant || (tenantId && email)) {
      dispatch(getUserCode(email, accountId))
      dispatch(getLatestResult(userId))
      dispatch(
        getUserPicture({
          accountId,
          email,
        })
      )
    }
  }, [profileData])

  const forgetDevice = () => {
    const callback = () => {
      removeCookie(`${btoa(email)}_safe_device`, { path: '/' })
    }
    if (!rememberedDevice) return
    const accessToken = sessionStorage.getItem('access_token')
    const deviceParams = getSavedDeviceValues(email)
    const apiParams = {
      ...deviceParams,
      accessToken,
      username: email,
    }
    dispatch(forgetMFADevice(apiParams, callback, i18n))
  }

  return (
    <>
      <DashboardStyle />
      <TenantSelectionDialog
        open={tenantSelectionOpen}
        dialogOpen={setTenantSelectionOpen}
      />
    </>
  )
}
