import { Box, Dialog, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../features/translation'
import { Button } from '../../../ui'
import { setForceShowControls } from '../model'

export const AudioRequired = (props: {
  open: boolean
  setAudioWarning: (state: boolean) => void
}) => {
  const { open, setAudioWarning } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)

  const close = () => {
    dispatch(setForceShowControls(false))
    setAudioWarning(false)
  }

  return (
    <Dialog open={open}>
      <Box className={classes.schedulePageHolder}>
        <Box className={classes.scheduleWrapper}>
          <Box className={classes.headerWrapper}>
            <Typography
              variant="h4"
              color="primary"
              className={classes.title}
              align="left"
            >
              Microphone Muted
            </Typography>
            <Typography variant="body1" className={classes.body}>
              You must unmute your audio feed before joining the session.
            </Typography>
          </Box>
          <Box className={classes.lgButtonBox}>
            <Button onClick={close} className={classes.confirmButton}>
              {i18n.ok_button}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '30px',
    marginBottom: '28px',
    width: '100%',
  },
  body: {
    color: '#7b7c7d',
    fontSize: '18px',
    width: '100%',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'fit-content',
    padding: '10px 24px',
    width: '100%',
    height: '46px',
    [theme.breakpoints.down(576)]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  schedulePageHolder: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F7F7F7',
    width: 400,
    height: 275,
    [theme.breakpoints.down(500)]: {
      padding: '0px',
    },
  },
  scheduleWrapper: {
    width: '100%',
    margin: '40px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
}))
