import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SimpleDialog } from '../../../features/application/templates/simple-dialog'
import { selectLanguageContent } from '../../../features/translation'
import { clearTestKitFlow } from '../../../libs/helpers'
import { paths } from '../../paths'
import {
  cancelProctorSession,
  selectProctorSessionData,
  selectProctorStatus,
} from '../../proctor/model'
import { resetUploadStore } from '../model'
import { DialogProps } from '../types'

export const CancelTestDialog = (props: DialogProps) => {
  const { open, setDialogOpen } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const session = useSelector(selectProctorSessionData)
  const proctorStatus = useSelector(selectProctorStatus)
  const i18n = useSelector(selectLanguageContent)

  const endTest = () => {
    if (!!proctorStatus) {
      dispatch(cancelProctorSession(session?.channelId, false))
    }
    dispatch(resetUploadStore())
    clearTestKitFlow()
    setDialogOpen(false)
    navigate(paths.app.dashboard())
  }

  return (
    <SimpleDialog
      open={open}
      title={i18n.abandon_test_flow_title}
      body={i18n.abandon_test_flow_message}
      onConfirm={() => setDialogOpen(false)}
      confirmationLabel={i18n.return_to_test}
      onCancel={endTest}
      cancelLabel={i18n.abandon_test}
    />
  )
}
