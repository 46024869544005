import React from 'react'
import { ModuleIntroWrapper } from '../modules/moduleIntroWrapper'
import { ModuleActionButton } from '../modules/types'
import { IntroScreenProps } from './types'

export const IntroScreen = (props: IntroScreenProps) => {
  const buttonAction = [
    {
      label: props.buttonText,
      action: props.handleNext,
      disabled: false,
    },
  ] as ModuleActionButton[]

  return (
    <ModuleIntroWrapper
      {...props}
      body={props.message}
      contentImage={props.backgroundImage}
      actions={buttonAction}
      onBack={props.handleBack}
    />
  )
}
