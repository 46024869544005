import Box from '@mui/material/Box'
import CallIcon from '@mui/icons-material/Call'
import CloseIcon from '@mui/icons-material/Close'
import LanguageIcon from '@mui/icons-material/Language'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import React, { useEffect } from 'react'
import { testTypes } from '../../../constants'
import { I18n } from '../../../features/translation/types'
import { useLocationService } from '../../../libs/hooks/useLocation'
import { formatPhoneForDisplayUS, stripHyphen } from '../../../libs/utils'
import { Button, Typography } from '../../../ui'
import { IconButton } from '../../../ui/atoms/icon-button/icon-button'
import { facilityStyles } from '../../_styles/facilityStyles'
import { hoursOfOperation, RenderFedExWorkingHours } from '../facilityHelper'

export const FacilityOptionDetails = (props: {
  facility: any
  setShowDetails: any
  i18n: I18n
  config: any
  type: testTypes
}) => {
  const classes = facilityStyles()
  const { facility, setShowDetails, i18n, config, type } = props
  const { openNativeMapFeature } = useLocationService().actions

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [facility])

  const openmap = () => {
    const address = {
      line1: facility.address1,
      city: facility.city,
      state: facility.state,
    }
    openNativeMapFeature(address)
  }

  return (
    <div className={classes.detailContent}>
      <div className={classes.iconWrapper}>
        <IconButton
          icon={<CloseIcon />}
          handleClick={() => setShowDetails(false)}
        />
      </div>
      {facility.imageUrl && (
        <Box
          className={classes.imageWrapper}
          style={{
            backgroundImage: `url(${facility.imageUrl})`,
          }}
        />
      )}
      <div className={classes.contentWrapper}>
        <Typography className={classes.locationTitle}>
          {facility.name}
        </Typography>
        <Box className={classes.locationInfo}>
          <Typography className={classes.detailLocationText}>
            {`${facility.address1}, `} <br />
            {`${facility.city},  ${facility.state} ${facility.zip.slice(0, 5)}`}
          </Typography>
          {facility.distance?.unitSpecified &&
            facility.distance?.valueSpecified && (
              <Typography
                className={`${classes.distance} ${classes.optionDescription}`}
              >
                {`${
                  Math.round(facility.distance.value * 100) / 100
                } ${facility.distance.unit.toLowerCase()}`}
              </Typography>
            )}
        </Box>
        <div className={classes.divider} />
        {facility.normalHours && facility.normalHours.length > 0 && (
          <>
            <RenderFedExWorkingHours
              officeData={facility}
              i18n={i18n}
              withDivider={false}
              textClass={classes.detailLocationText}
            />
            <div className={classes.divider} />
          </>
        )}
        {facility.phone && (
          <>
            <Box className={classes.moreInfoBox}>
              <Typography
                className={classes.detailLocationText}
                style={{ width: '100%' }}
              >
                <a
                  href={`tel:${stripHyphen(facility?.phone)}`}
                  className={`${classes.detailLocationText} noDecoration phone`}
                >
                  {formatPhoneForDisplayUS(facility?.phone)}
                  <CallIcon
                    className={classes.icon}
                    style={{ justifySelf: 'flex-end' }}
                  />
                </a>
              </Typography>
            </Box>
            <div className={classes.divider} />
          </>
        )}
        <Box className={classes.moreInfoBox}>
          <Typography className={classes.detailLocationText}>
            {`${facility.address1}`} <br />
            {`${facility.city},
            ${facility.state} ${facility.zip.slice(0, 5)} ${
              facility.country === 'US' ? `${i18n.united_states}` : ''
            }`}
          </Typography>
          <Box>
            <LocationOnIcon
              className={classes.icon}
              onClick={() => openmap()}
            />
          </Box>
        </Box>
        <div className={classes.divider} />
        {hoursOfOperation(facility.normalHours)}
        <Box className={classes.btnWrapper}>
          <Button
            onClick={config[type].onClickSelect}
            className={`${classes.confirmButton}`}
          >
            {config[type].selectButtonText}
          </Button>
        </Box>
        {facility.website ? (
          <Box className={classes.moreInfoBox}>
            <Box className={classes.detailLocationText}>{facility.website}</Box>
            <Box>
              <LanguageIcon className={classes.icon} />
            </Box>
          </Box>
        ) : null}
        {facility?.workingHour && (
          <Box className={classes.moreInfoBox}>
            <Box>
              {facility.workingHour.map((schedule: any) => (
                <Box className={classes.detailLocationText}>
                  {schedule.weekDays}
                </Box>
              ))}
            </Box>
            {facility.workingHour ? (
              <Box className={classes.timeStatus}>
                {facility.workingHour.map((schedule: any) => (
                  <Box className={classes.detailLocationText}>
                    {schedule.from && schedule.to
                      ? `${schedule.from} - ${schedule.to}`
                      : `${i18n.closed_label}`}
                  </Box>
                ))}
              </Box>
            ) : null}
          </Box>
        )}
      </div>
    </div>
  );
}
