import { Divider, styled, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import EmptyCart from '../../assets/animations/empty-cart.json'
import ApplePay from '../../assets/icons/apple-pay.png'
import { NOTIFICATION_TYPES } from '../../constants'
import { handleMessage } from '../../libs/helpers'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { shopifyStoreStyles } from '../../pages/_styles/shopifyStoreStyles'
import { paths } from '../../pages/paths'
import { Button } from '../../ui'
import { selectLanguageContent } from '../translation'
import { StoreStepType } from './model/types'
import { AdjustCartLineQuantityParameters } from './SAFE-ecommerce-service/EcommerceSDK'
import { CartItem } from './shopify-ui-items/cart-item'
import { LineItem } from './shopify-ui-items/order-summary-line-item'
import { ShopLoading } from './shopify-ui-items/storeLoading'

export const ShopifyCart = (props: { sdk: any }) => {
  const { sdk } = props
  const classes = shopifyStoreStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const [isLoading, setIsloading] = useState(false)

  const isMobile = useMUITheme().isMobile

  const [cartState, setCartState] = useState(
    sdk.stateManager.currentCartViewState()
  )
  let isObserverSetup = false

  useEffect(() => {
    setIsloading(true)
    setupObserver()
    setTimeout(() => {
      loadCart()
    }, 250)
  }, [])

  function setupObserver() {
    if (isObserverSetup) {
      return
    }
    sdk.stateManager.observeCartViewState(
      (cartViewState: any) => {
        setCartState(cartViewState)
      },
      (error: any) => {
        console.log('Error observing products view state: ', error)
      }
    )
    isObserverSetup = true
  }
  function loadCart() {
    if (isLoading) {
      return
    }
    setIsloading(true)
    sdk.cartManager
      .loadCart()
      .then((response: any) => {
        setIsloading(false)
      })
      .catch((error: any) => {
        console.log(error)
        setIsloading(false)
      })
  }

  function adjustQuantity(parameters: any) {
    setIsloading(true)
    sdk.cartManager
      .adjustQuantity(parameters)
      .then((response: any) => {
        setIsloading(false)
      })
      .catch((error: any) => {
        console.log(error)
        setIsloading(false)
      })
  }

  const handleChangeQuantity = (type: 'decrease' | 'increase', id: string) => {
    if (type === 'increase') {
      adjustQuantity(AdjustCartLineQuantityParameters.Companion.increment(id))
    } else {
      adjustQuantity(AdjustCartLineQuantityParameters.Companion.decrement(id))
    }
  }

  const getSubtotalText = () => {
    if (!cartState.lines) return ''
    let text = i18n['ecommerce.order.charges.subtotal']
    if (!isMobile) return text

    return `${text} (${cartState.lines.length}${
      cartState.lines > 1
        ? i18n['ecommerce.item_text.plural']
        : i18n['ecommerce.item_text.singular']
    })`
  }
  const goNext = () => {
    sdk.cartManager
      .reloadCartBeforeCheckout()
      .then((response: any) => {
        console.log(response)
        setIsloading(false)
        navigate(paths.shopifyStore(StoreStepType.CHECKOUT))
      })
      .catch((error: any) => {
        console.log(error)
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))

        setIsloading(false)
      })
  }

  if (isLoading) return <ShopLoading />

  if (cartState.isEmpty) {
    return (
      <ContentWrapper>
        <div className={classes.cartContent}>
          <div className={classes.emptyCart}>
            <div className={classes.emptyImageWrapper}>
              <Lottie
                animationData={EmptyCart}
                loop={true}
                className={classes.emptyCartImage}
              />
            </div>
            <div>
              <Typography className={classes.testName}>
                {i18n['ecommerce.empty_cart_title']}
              </Typography>
              <Typography className={classes.description}>
                {i18n['ecommerce.empty_cart_subtitle']}
              </Typography>
            </div>
            <div>
              <Button
                onClick={() =>
                  navigate(paths.shopifyStore(StoreStepType.PRODUCTS))
                }
                className={`${classes.cartButton} emptyCartButton`}
              >
                {i18n['ecommerce.resume_shopping_button']}
              </Button>
            </div>
          </div>
        </div>
      </ContentWrapper>
    )
  }

  return (
    <ContentWrapper>
      <div className={classes.cartContent}>
        <div className={classes.titleWrapper}>
          <Typography variant="h2" className={classes.pageTitle}>
            {i18n['ecommerce.cart_view.navigation_title']}
          </Typography>
        </div>
        <div className={classes.cartWrapper}>
          <div className={classes.inCart}>
            {cartState.lines.map((product: any) => (
              <CartItem
                key={product.productId}
                i18n={i18n}
                product={product}
                color="#F5F5F5"
                handleAdjustQuantity={handleChangeQuantity}
              />
            ))}
          </div>
          <div className={classes.cartSummaryAnchor}>
            {isMobile && (
              <Divider
                style={{ marginBottom: 16, backgroundColor: '#E0E0E0' }}
              />
            )}
            <div className={classes.cartSummary}>
              {!isMobile && (
                <Typography variant="h2" className={classes.summaryTitle}>
                  {i18n['ecommerce.order_summary_header']}
                </Typography>
              )}
              <LineItem title={getSubtotalText()} value={cartState.subtotal} />
              <LineItem
                title={i18n['ecommerce.order.charges.applied_benefits']}
                value={cartState.appliedBenefitsFee}
              />
              {!isMobile && (
                <Divider
                  style={{ marginBottom: 16, backgroundColor: '#E0E0E0' }}
                />
              )}
              <LineItem
                title={i18n['ecommerce.estimated_subtotal']}
                value={cartState.estimatedSubtotal}
                isTotal
              />
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={goNext}
                  className={classes.cartButton}
                  disabled={!cartState.canProceedToCheckout}
                >
                  {i18n['ecommerce.checkout']}
                </Button>
                {/* {cartState.displaysNativePaymentButton && (
                  <Button
                    onClick={goNext}
                    className={`${classes.cartButton} noHover`}
                    variant="outlined"
                  >
                    <img
                      src={ApplePay}
                      alt={'Use Apple Pay'}
                      className={classes.applePayIcon}
                    />
                  </Button>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  )
}

const ContentWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  margin: '0px auto 64px',
  [theme.breakpoints.down(600)]: {
    margin: '0px auto',
  },
}))
