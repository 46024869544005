import styled from '@emotion/styled'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectNetworkSettings } from '../../../pages/network/model'
import { paths } from '../../../pages/paths'

export const LightLogo = () => {
  const networkSettings = useSelector(selectNetworkSettings)
  return (
    <>
      {networkSettings && (
        <Link to={paths.app.dashboard()}>
          <LogoImage
            src={networkSettings.logoRectangleLight}
            alt="Tenant logo link to Dashboard"
          />
        </Link>
      )}
    </>
  )
}

const LogoImage = styled.img`
  max-height: 54px;
  max-width: 180px;
  @media (max-width: 600px) {
    padding-top: 4px;
  }
`
