import { createSlice, Dispatch } from '@reduxjs/toolkit'
import api from '../../api'
import { getConsents } from '../../features/terms'
import { Passport } from '../../types/user'

const initialStore = {
  isLoading: false,
  passport: {
    status: '',
    enumStatusID: 1,
    enumPassportStatusID: 1,
    clearedUntil: '',
    message: '',
    type: '',
  },
}

const dashboardPage = createSlice({
  name: 'dashboardPage',
  initialState: initialStore,
  reducers: {
    saveLoading(store, { payload }: { payload: { isLoading: boolean } }) {
      store.isLoading = payload.isLoading
    },
    setPassport(store, { payload }: { payload: Passport }) {
      store.passport = payload
    },
  },
})

const { actions } = dashboardPage

export const pageMounted =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (accountId: string, email: string) => async (dispatch: Dispatch<any>) => {
    dispatch(actions.saveLoading({ isLoading: true }))
    // dispatch(getConsents(accountId, email))
    dispatch(actions.saveLoading({ isLoading: false }))
  }

export const getPassportStatus =
  (userUUID: string) => async (dispatch: Dispatch) => {
    dispatch(actions.saveLoading({ isLoading: true }))
    const { data } = await api.getPassportStatus(userUUID)
    if (data) {
      dispatch(actions.setPassport(data))
    }

    dispatch(actions.saveLoading({ isLoading: false }))
  }

export const dashboardPageReducer = dashboardPage.reducer
export const dashboardPageReducerName = dashboardPage.name
interface RootStore {
  [dashboardPageReducerName]: typeof initialStore
}
export const selectLoading = ({ dashboardPage }: RootStore) =>
  dashboardPage.isLoading

export const selectPassport = ({ dashboardPage }: RootStore) =>
  dashboardPage.passport

export const selectPassportStatus = ({ dashboardPage }: RootStore) =>
  dashboardPage.passport.status
