import { Box, Link as MUILink, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { StepType } from '../../../constants'
import { selectLanguageContent } from '../../../features/translation'
import { lsClient } from '../../../ls-client'
import { Button, PageLoading } from '../../../ui'
import { onboardingStyles } from '../../_styles/registrationStyles'
import {
  getNetworkSettings,
  selectNetworkSettings,
  selectNetworkSettingsLoading,
} from '../../network/model'
import { NetworkSettings } from '../../network/type'
import { paths } from '../../paths'
import {
  resetRegisterForm,
  saveInvitePrefill,
  setActive,
  setSignupType,
} from '../../register/model/registerSlice'
import { OnboardingType } from '../../register/model/type'
import {
  getConsents,
  selectConsentError,
  selectConsentsFetched,
  selectLoading,
} from '../../terms-and-conds/model/model'
import {
  requestNewEmailLink,
  selectIsLoading,
  selectStatus,
  selectUser,
  setUserData,
  setVerificationStatus,
  submitEmailVerify,
} from '../model/inviteRegistrationSlice'
import {
  InvitedUser,
  InvitePages,
  ReinviteReason,
  VerificationStatus,
} from '../model/type'
import { ContactSupportFooter, InvitationPageWrapper } from './shared'
import { EasableSupport } from './shared/support-link'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const easableAccounts = process.env.REACT_APP_PBP_ACCOUNT_NAMES

export const InviteVerifyPage = () => {
  const query = useQuery()
  const classes = onboardingStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userParams = useSelector(selectUser)
  const user = useSelector(selectUser)
  const verificationStatus = useSelector(selectStatus)
  const isLoading = useSelector(selectIsLoading)
  const termsLoading = useSelector(selectLoading)
  const networkLoading = useSelector(selectNetworkSettingsLoading)
  const [longEmail, setLongEmail] = useState(false)
  const i18n = useSelector(selectLanguageContent)
  const consentsFetched = useSelector(selectConsentsFetched)
  const currentNetwork = useSelector(selectNetworkSettings)
  const consentError = useSelector(selectConsentError)
  const [supportDialogOpen, setSupportDialogOpen] = useState(false)
  const isEasableAccount = easableAccounts?.includes(userParams?.accountId)

  // Override Safari cached load on browser back action
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  window.onpageshow = function (event) {
    if (event.persisted) {
      window.location.reload()
    }
  }

  const goNext = () => {
    switch (verificationStatus) {
      case VerificationStatus.VERIFIED:
        nextStep(currentNetwork)
        break
      case VerificationStatus.EXPIRED:
        sendNewEmailLink(ReinviteReason.EXPIRED)
        break
      case VerificationStatus.PREVIOUSLY_VERIFIED_INCOMPLETE:
        sendNewEmailLink(ReinviteReason.INCOMPLETE)
        break
      default:
        break
    }
  }

  const nextStep = (res: NetworkSettings | null) => {
    if (!res) return
    if (res.enableAccountVerificationScreen && !user.dependentId) {
      dispatch(setActive(0))
      navigate(paths.registerTab(StepType.ELIGIBILITY))
    } else {
      dispatch(setActive(3))
      navigate(paths.registerTab(StepType.CREATE_PASSWORD))
    }
  }

  useEffect(() => {
    const params = query.get('redirectUrl')
    if (params) {
      localStorage.clear()
      dispatch(resetRegisterForm())
      sessionStorage.setItem('member', params)
      try {
        const memberData = JSON.parse(atob(params)) as InvitedUser
        dispatch(setUserData(memberData))
        dispatch(saveInvitePrefill(memberData))
        if (memberData.email.length > 22) {
          setLongEmail(true)
        }
        localStorage.setItem('tenantId', memberData.tenantId)
        sessionStorage.setItem('accountId', memberData.accountId)
        sessionStorage.setItem('tenantId', memberData.tenantId)
        localStorage.setItem('email', memberData.email)
        lsClient.setUserLS('tenantId', memberData.tenantId)
        dispatch(setSignupType(OnboardingType.INVITE))
        dispatch(getNetworkSettings(memberData.tenantId))
        dispatch(getConsents(memberData.tenantId))
      } catch {
        dispatch(setVerificationStatus(VerificationStatus.ERROR))
      }
    } else if (!params) {
      dispatch(setVerificationStatus(VerificationStatus.ERROR))
    }
  }, [])

  useEffect(() => {
    // Terms and Conditions must be available to continue to sign up
    if (consentsFetched && !consentError && user.email) {
      const data = {
        email: user.email,
        tenantId: user.tenantId,
        pin: user.accessCode,
      }
      dispatch(submitEmailVerify(data))
    }
    if (consentsFetched && consentError) {
      dispatch(setVerificationStatus(VerificationStatus.SIGNUP_UNAVAILABLE))
    }
  }, [consentsFetched, consentError, user])

  useEffect(() => {
    verificataionView()
  }, [verificationStatus])

  const buttonLabel = () => {
    switch (verificationStatus) {
      case VerificationStatus.VERIFIED:
        return i18n.get_started_button
      case VerificationStatus.EXPIRED:
      case VerificationStatus.PREVIOUSLY_VERIFIED_INCOMPLETE:
        return i18n.invite_send_new_link
      default:
        return i18n.get_started_button
    }
  }

  const sendNewEmailLink = (reinviteReason: ReinviteReason) => {
    const data = {
      ...userParams,
      reinviteReason,
    }
    dispatch(requestNewEmailLink(data))
  }

  const getUserMessage = () => {
    const message = i18n.invite_welcome_message
      .replace('%@', userParams.firstName)
      .split('\n')

    return (
      <>
        {message[0] && <span>{message[0]}</span>}
        <br />
        {message[1] && <span>{message[1]}</span>}
      </>
    )
  }

  const verificataionView = () => {
    switch (verificationStatus) {
      case VerificationStatus.VERIFIED: {
        return (
          <>
            <Typography
              className={classes.verifyHeader}
              color="primary"
              variant="h1"
              align="left"
            >
              {getUserMessage()}
            </Typography>
            <Typography className={classes.subText} align="left">
              {i18n.invite_get_started_message}
            </Typography>
          </>
        )
      }
      case VerificationStatus.PREVIOUSLY_VERIFIED_INCOMPLETE: {
        return (
          <>
            <Typography
              className={classes.verifyHeader}
              color="primary"
              variant="h1"
              align="left"
            >
              {i18n.invite_link_used}
            </Typography>
            <Typography className={classes.subText} align="left">
              {i18n.invite_link_used_message}
            </Typography>
          </>
        )
      }
      case VerificationStatus.PREVIOUSLY_VERIFIED_COMPLETE: {
        // .href needed so React Helmet can insert meta tag for Apple Smart Banner at page load
        if (currentNetwork?.appleAppDownloadUrl) {
          sessionStorage.setItem(
            'appleStoreId',
            currentNetwork.appleAppDownloadUrl.split('/id')[1]
          )
        }
        window.location.href = paths.inviteMessaging(InvitePages.ACCOUNT_EXISTS)
        break
      }
      case VerificationStatus.NEW_LINK_SENT: {
        navigate(paths.inviteMessaging(InvitePages.NEW_LINK_SENT))
        break
      }
      case VerificationStatus.EXPIRED: {
        return (
          <>
            <Typography
              className={classes.verifyHeader}
              color="primary"
              variant="h1"
              align="left"
            >
              {i18n.invite_link_expired}
            </Typography>
            <Typography className={classes.subText}>
              {i18n.invite_link_expired_message}
            </Typography>
          </>
        )
      }
      case VerificationStatus.ERROR: {
        return (
          <>
            <Typography
              className={classes.verifyHeader}
              color="primary"
              variant="h1"
              align="left"
            >
              {i18n.invite_generic_error}
            </Typography>

            {isEasableAccount ? (
              <EasableSupport />
            ) : (
              <>
                <Typography className={classes.subText}>
                  {i18n.invite_generic_error_message}
                </Typography>
                <ContactSupportFooter
                  setSupportDialogOpen={setSupportDialogOpen}
                  currentNetwork={currentNetwork}
                />
              </>
            )}
          </>
        )
      }
      case VerificationStatus.SIGNUP_UNAVAILABLE: {
        return (
          <>
            <Typography
              className={classes.verifyHeader}
              color="primary"
              variant="h1"
              align="left"
            >
              {i18n.invite_signup_error}
            </Typography>
            <Typography className={classes.subText}>
              {i18n.invite_signup_error_message}
            </Typography>
            {isEasableAccount ? (
              <EasableSupport />
            ) : (
              <>
                <Typography className={classes.subText}>
                  {i18n.invite_generic_error_message}
                </Typography>
                <ContactSupportFooter
                  setSupportDialogOpen={setSupportDialogOpen}
                  currentNetwork={currentNetwork}
                />
              </>
            )}
          </>
        )
      }
      case VerificationStatus.UNKNOWN:
      default: {
        return <></>
      }
    }
  }

  if (isLoading || termsLoading || networkLoading) return <PageLoading />

  return (
    <InvitationPageWrapper
      supportDialogOpen={supportDialogOpen}
      setSupportDialogOpen={setSupportDialogOpen}
    >
      <Box>
        <Box className={classes.contentContainer}>{verificataionView()}</Box>
        <div className={classes.buttonWrapper}>
          {verificationStatus !== VerificationStatus.ERROR &&
            verificationStatus !== VerificationStatus.UNKNOWN &&
            verificationStatus !== VerificationStatus.NEW_LINK_SENT &&
            verificationStatus !==
              VerificationStatus.PREVIOUSLY_VERIFIED_COMPLETE &&
            verificationStatus !== VerificationStatus.SIGNUP_UNAVAILABLE && (
              <Button
                color="primary"
                className={classes.actionBtn}
                type="button"
                style={{ marginTop: 32 }}
                onClick={() => goNext()}
              >
                {buttonLabel()}
              </Button>
            )}
        </div>
      </Box>
    </InvitationPageWrapper>
  )
}
