import { Theme, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { ClassNameMap, makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import GetTestedImage from '../../../assets/img/Take-a-Test.svg'
import { lsClient } from '../../../ls-client'
import { TestKitDetails } from '../../../types/tests'
import { Typography } from '../../../ui'
import { ModuleIntroWrapper } from '../../../ui/templates/modules/moduleIntroWrapper'
import {
  getTestKits,
  selectOrderablePanels,
  selectTestKits,
  selectTestKitsLoaded,
} from '../../order-test/model'
import { paths } from '../../paths'
import { getLabTestDetails, getTestPanelDetails } from '../model'
import { RegisterTestProps } from '../types'

export const ChooseATest = (props: RegisterTestProps) => {
  const { i18n, handleNext } = props
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(960))
  const testsLoaded = useSelector(selectTestKitsLoaded)
  const dispatch = useDispatch()
  const testKits = useSelector(selectTestKits)
  const orderablePanels = useSelector(selectOrderablePanels)
  const classes = useStyles()
  const numberOfTests = (testKits?.length || 0) + (orderablePanels?.length || 0)

  const handleClose = () => {
    navigate(paths.app.dashboard())
  }
  const getColor = (test: TestKitDetails) =>
    test?.chooseATestDetails?.backgroundColor
      ? test.chooseATestDetails.backgroundColor
      : theme.palette.primary.main

  const getTestDetails = (testData: any) => {
    if (testData.labTestOrderableId) {
      lsClient.setUserLS('testId', testData.labTestOrderableId)
      lsClient.setUserLS('testType', 'orderableTest')
      const success = () => {
        handleNext()
      }
      dispatch(getLabTestDetails(testData.labTestOrderableId, success))
    } else if (testData.labTestPanelId) {
      lsClient.setUserLS('testId', testData.labTestOrderableId)
      lsClient.setUserLS('testType', 'orderablePanel')
      const success = () => {
        handleNext()
      }
      dispatch(getTestPanelDetails(testData.labTestPanelId, success))
    }
  }

  useEffect(() => {
    dispatch(getTestKits('scan'))
  }, [])

  useEffect(() => {
    if (testsLoaded && numberOfTests === 1) {
      if (testKits?.length === 1) {
        getTestDetails(testKits[0])
      } else {
        getTestDetails(orderablePanels[0])
      }
    }
  }, [testsLoaded])

  return (
    <ModuleIntroWrapper
      contentImage={isMobile ? undefined : GetTestedImage}
      backgroundColor="#003D71"
      childPosition="center"
      contentPosition="center"
      onClose={handleClose}
      onBack={undefined}
      mobileContentPosition="unset"
    >
      <Box style={{ width: '100%' }}>
        <div className={classes.pageTwo}>
          <Typography
            variant="h4"
            className={isMobile ? `${classes.title} whiteBG` : classes.title}
          >
            {i18n.choose_a_test_title}
          </Typography>
          <Typography
            className={isMobile ? `${classes.body} whiteBG` : classes.body}
          >
            {i18n.choose_a_test_message}
          </Typography>
          <div className={classes.listWrapper}>
            {testKits.length > 0 &&
              testKits.map((test) => (
                <TestItem
                  key={test?.labTestOrderableId}
                  test={test}
                  showLargeCards={numberOfTests === 2}
                  classes={classes}
                  color={getColor(test)}
                  handleNext={getTestDetails}
                />
              ))}
            {orderablePanels.length > 0 &&
              orderablePanels.map((test) => (
                <TestItem
                  key={test?.labTestPanelId}
                  test={test}
                  showLargeCards={numberOfTests === 2}
                  classes={classes}
                  color={getColor(test)}
                  handleNext={getTestDetails}
                />
              ))}
          </div>
        </div>
      </Box>
    </ModuleIntroWrapper>
  )
}

const TestItem = (props: {
  test: TestKitDetails
  showLargeCards: boolean
  color: string
  classes: ClassNameMap
  handleNext: (test: TestKitDetails) => void
}) => {
  const { test, showLargeCards, color, classes, handleNext } = props
  if (!test) return null

  return (
    <Box
      key={test.labTestOrderableId || test.labTestPanelId}
      className={classes.testWrapper}
      onClick={() => {
        handleNext(test)
      }}
    >
      <div className={classes.imageBox} style={{ backgroundColor: color }}>
        {test.chooseATestDetails?.testKitImageURL && (
          <img
            src={test.chooseATestDetails.testKitImageURL}
            className={
              showLargeCards ? `${classes.testImg} large` : classes.testImg
            }
          />
        )}
      </div>
      <Box className={classes.testDetails}>
        <Typography
          className={
            showLargeCards ? `${classes.testName} large` : classes.testName
          }
        >
          {test.longName}
        </Typography>
        <Typography
          className={
            showLargeCards ? `${classes.testDesc} large` : classes.testDesc
          }
        >
          {test.vendorName}
        </Typography>
      </Box>
    </Box>
  )
}

const IMAGE_SHADOW = '0px 8px 16px rgba(0, 0, 0, 0.25)'

const useStyles = makeStyles((theme) => ({
  pageTwo: {
    position: 'absolute',
    top: 96,
    width: '60vw',
    [theme.breakpoints.down(960)]: {
      width: '90vw',
      top: 64,
      left: '5vw',
    },
  },
  listWrapper: {
    height: 'calc(100vh - 260px)',
    overflowX: 'auto',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    [theme.breakpoints.down(600)]: {
      gap: 16,
    },
  },
  title: {
    fontWeight: 600,
    color: '#505358',
    lineHeight: '55px',
    fontSize: 36,

    textAlign: 'inherit',
    [theme.breakpoints.down(960)]: {
      'width': '90%',
      'color': '#FFF',
      '&.whiteBG': {
        color: '#505358',
      },
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  body: {
    fontWeight: 500,
    color: '#505358',
    lineHeight: '22px',
    fontSize: 16,
    margin: '24px 0px 37px',
    maxWidth: 665,
    [theme.breakpoints.down(960)]: {
      'margin': '0px 0px 24px',
      'width': '90%',
      'color': '#FFF',
      '&.whiteBG': {
        color: '#505358',
      },
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      marginBottom: '10px',
      lineHeight: '20px',
    },
  },
  testWrapper: {
    width: 'calc(33.333333% - 32px)',
    cursor: 'pointer',
    marginBottom: 48,
    padding: '16px',
    maxHeight: 'fit-content',
    [theme.breakpoints.down(600)]: {
      width: 'calc(50% - 8px)',
      padding: '0px',
    },
  },
  imageBox: {
    height: 0,
    padding: '50% 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  testImg: {
    'height': '88px',
    '&.large': {
      height: '102px',
      [theme.breakpoints.down(960)]: {
        height: '64px',
      },
    },
    '-webkit-box-shadow': IMAGE_SHADOW,
    '-moz-box-shadow': IMAGE_SHADOW,
    'box-shadow': IMAGE_SHADOW,
    [theme.breakpoints.down(960)]: {
      height: '64px',
    },
  },
  testDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 12,
    gap: 2,
  },
  testName: {
    'fontSize': '14px',
    'lineHeight': '16px',
    'color': '#282D37',
    'fontWeight': 600,
    'width': '100%',
    '&.large': {
      fontSize: '22px',
      lineHeight: '33px',
      [theme.breakpoints.down(960)]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
    },
    [theme.breakpoints.down(960)]: {
      fontSize: '14px',
    },
  },
  testDesc: {
    'fontSize': '12px',
    'lineHeight': '16px',
    'color': '#757575',
    'fontWeight': 500,
    '&.large': {
      fontSize: '19px',
      lineHeight: '28px',
      [theme.breakpoints.down(960)]: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    [theme.breakpoints.down(960)]: {
      fontSize: '12px',
    },
  },
}))
