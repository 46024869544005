import { Box, Icon, Link, styled, SxProps } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useMUITheme } from '../../../../libs/hooks/useTheme'
import { Button, Typography } from '../../..'

export type Position = 'fixed' | 'relative' | undefined

interface GenericComponentElementProps extends PropsWithChildren {
  backgroundcolor?: string
  nativePosition?: Position
  sx?: SxProps
}

interface GenericScreenButtonProps extends GenericComponentElementProps {
  onClick: () => void
}

export const PageWrapper = (props: GenericComponentElementProps) => {
  return (
    <Box
      sx={{
        ...props.sx,
        backgroundColor: props.backgroundcolor ?? '#FFF',
        width: '100%',
      }}
    >
      {props.children}
    </Box>
  )
}

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 124,
  justifyContent: 'center',
  maxWidth: 540,
  margin: '0 auto',
  textAlign: 'center',
  [theme.breakpoints.down(600)]: {
    width: '90%',
  },
}))

export const Title = (props: GenericComponentElementProps) => {
  return (
    <TitleElement
      sx={{ ...props.sx, color: props.backgroundcolor ? '#FFF' : '#505358' }}
    >
      {props.children}
    </TitleElement>
  )
}

export const TitleElement = styled(Typography)({
  fontSize: 32,
  fontWeight: 500,
  marginTop: 48,
  marginBottom: 8,
})

export const Message = (props: GenericComponentElementProps) => {
  return (
    <MessageElement
      sx={{
        ...props.sx,
        color: props.backgroundcolor ? '#FFF' : '#505358',
      }}
    >
      {props.children}
    </MessageElement>
  )
}
export const MessageElement = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 24,
  width: '100%',
})

export const ButtonWrapper = (props: GenericComponentElementProps) => {
  const { theme } = useMUITheme()
  return (
    <ButtonWrapperElement
      sx={{
        ...props.sx,
        backgroundColor: props.backgroundcolor ? props.backgroundcolor : '#FFF',
        [theme.breakpoints.down(600)]: {
          position: props.nativePosition ?? 'fixed',
        },
      }}
    >
      {props.children}
    </ButtonWrapperElement>
  )
}

export const ButtonWrapperElement = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  padding: '32px 0px',
  position: 'relative',
  width: '100%',
  zIndex: 10,
  [theme.breakpoints.down(600)]: {
    left: 0,
    bottom: 0,
    padding: '12px 0px 40px',
    width: '100%',
  },
}))

export const Divider = styled('div')({
  width: '100%',
  borderBottom: '1px solid #D0D0D0',
})

export const GenericScreenButton = (props: GenericScreenButtonProps) => {
  return (
    <WrappedButton
      onClick={() => props.onClick()}
      sx={{
        ...props.sx,
        'borderColor': props.backgroundcolor
          ? '#FFF'
          : `${props.backgroundcolor} !important`,
        'backgroundColor': props.backgroundcolor
          ? '#FFF !important'
          : `${props.backgroundcolor} !important`,
        'color': props.backgroundcolor
          ? `${props.backgroundcolor} !important`
          : '#FFF !important',
        '&:hover': {
          borderColor: props.backgroundcolor
            ? '#FFF'
            : `${props.backgroundcolor} !important`,
        },
      }}
    >
      {props.children}
    </WrappedButton>
  )
}

export const WrappedButton = styled(Button)(({ theme }) => ({
  margin: '10px auto',
  width: '100%',
  [theme.breakpoints.down(600)]: {
    width: '90% !important',
  },
}))

export const StyledIcon = styled(Icon)({
  fontSize: '100px !important',
  margin: 48,
})

export const StyledLink = styled(Link)({
  color: '#C11717',
  marginBottom: 40,
  padding: '16px 0px',
  fontSize: 16,
  fontWeight: 600,
})

export const PageTitle = styled(Typography)({
  color: '#282D37',
  marginBottom: 32,
  fontSize: 36,
  fontWeight: 600,
})
