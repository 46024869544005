import { Box, Link as MUILink } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import RTLBox from '../../../assets/img/RTL-box.png'
import { useDialog } from '../../../features/dialog/application-dialog'
import { DialogContent } from '../../../features/dialog/types'
import { lsClient } from '../../../ls-client'
import { Button, Typography } from '../../../ui'
import { paths } from '../../paths'
import { RegisterTestProps } from '../../register-a-test-module/types'
import { ReturnToLabStepType } from '../types'

type DialogTypes = 'getLocation' | 'locationError'

export const ReturnToLabStart = (props: RegisterTestProps) => {
  const { i18n } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const { openDialog, closeDialog } = useDialog()

  const returnHome = () => {
    navigate(paths.app.dashboard())
  }
  lsClient.removeUserKeyLS('currentLocation')
  const goToLocations = () => {
    closeDialog()
    navigate(paths.returnToLab(ReturnToLabStepType.CHOOSE_LOCATION))
  }

  const dialogs = {
    getLocation: {
      title: i18n.share_your_location_title,
      body: i18n.share_your_location_message,
      onCancel: closeDialog,
      cancelLabel: i18n.not_right_now,
      onConfirm: goToLocations,
      confirmationLabel: i18n.use_my_location,
    },
  } as Record<DialogTypes, DialogContent>

  return (
    <div className={classes.content}>
      <div className={classes.imageWrapper}>
        <img src={RTLBox} alt={'Shipping Box'} className={classes.img} />
      </div>
      <Box className={classes.textContent}>
        <Typography className={classes.title}>
          {i18n.return_test_kit}
        </Typography>
        <Typography className={classes.body}>
          {i18n.please_drop_off_message}
        </Typography>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Button
          id="continue"
          className={classes.confirmButton}
          onClick={() => openDialog(dialogs.getLocation)}
          variant="contained"
        >
          {i18n.view_drop_off_locations}
        </Button>
        <MUILink
          role="button"
          color="primary"
          className={classes.pageLink}
          underline="none"
          onClick={returnHome}
        >
          {i18n.return_home}
        </MUILink>
      </Box>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxWidth: '500px',
    margin: '64px auto',
    position: 'relative',
    [theme.breakpoints.down(1200)]: {
      margin: '32px auto',
    },
    [theme.breakpoints.down(600)]: {
      margin: '16px auto',
    },
  },
  img: {
    height: 300,
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '78px 0px 48px',
  },
  title: {
    fontWeight: 600,
    color: '#282D37',
    fontSize: 36,
    alignSelf: 'flex-start',
    [theme.breakpoints.down(600)]: {
      width: '90%',
      marginLeft: '24px',
      fontSize: 32,
    },
  },
  body: {
    fontWeight: 500,
    color: '#757575',
    lineHeight: '22px',
    fontSize: 16,
    alignSelf: 'flex-start',
    [theme.breakpoints.down(600)]: {
      marginBottom: '16px',
      width: '90%',
      marginLeft: '24px',
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    height: '100%',
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 43,
    width: '100%',
    maxHeight: '250px',
    zIndex: 10,
    gap: 24,
    [theme.breakpoints.down(600)]: {
      margin: '0px auto',
      padding: '20px 0px',
      position: 'fixed',
      bottom: 0,
      left: 0,
      backgroundColor: '#FFF',
    },
    [theme.breakpoints.down(320)]: {
      padding: '0px 0px 16px',
    },
  },
  skip: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: '48px',
    [theme.breakpoints.down(600)]: {
      marginRight: '8px',
    },
  },
  button: {
    margin: 15,
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'unset',
    margin: 0,
    width: '100%',
    height: '46px',
    [theme.breakpoints.down(600)]: {
      margin: '10px auto',
      width: '90vw',
      minHeight: '46px',
    },
  },
  pageLink: {
    fontWeight: 600,
    marginBottom: 24,
    cursor: 'pointer',
  },
}))
