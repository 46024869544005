import React from 'react'
import { HSDisposition } from '../../../features/health-service-lite/types'
import { I18n } from '../../../features/translation/types'
import {
  ConfirmLocation,
  ResultEarlyExitDisposition,
} from './disposition-screens'
import { dispositionScreens } from './helpers/disposition-mapper'

export const Disposition = (props: {
  disposition: HSDisposition
  i18n: I18n
}) => {
  const { disposition, i18n } = props

  return (
    <>
      {disposition === HSDisposition['Confirm Location/Phone'] ||
      disposition === HSDisposition['Telehealth Visit'] ? (
        <ConfirmLocation
          i18n={i18n}
          dispositionType={dispositionScreens[disposition]}
        />
      ) : (
        <ResultEarlyExitDisposition
          dispositionType={dispositionScreens[disposition]}
        />
      )}
    </>
  )
}
