import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import  DatePicker, { DatePickerProps } from '@mui/lab/DatePicker'
import React from 'react'
import { ErrorField } from '..'
import { parseISO } from 'date-fns'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'

export const FilledDatePicker = ({
  onChange,
  value,
  label,
  onBlur,
  name,
  required,
  minDate,
  fullWidth,
  maxDate,
  error,
  format,
  helperText,
  disabled,
}: any & {
  value: Date | null
}) => {
  const classes = useStyles()

  const getClasses = () => {
    let filledClass = `${classes.fieldContainer}`
    if (error) {
      filledClass += ` ${classes.inError}`
    }
    if (disabled) {
      filledClass += ` ${classes.disabled}`
    }

    return filledClass
  }
  return (
    <Box className={classes.fieldWrapper}>
      <Box className={getClasses()}>
        <DatePicker
          className={
            label !== undefined
              ? classes.root
              : `${classes.root} ${classes.noLabel}`
          }
          keyboardIcon={<CalendarTodayOutlinedIcon style={{ fontSize: 20 }} />}
          label={label}
          value={value}
          format={format}
          onBlur={onBlur}
          fullWidth={fullWidth}
          inputVariant="filled"
          error={error}
          name={name}
          onChange={onChange}
          required={required}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
          helperText={false}
        />
      </Box>
      {error && helperText && <ErrorField error={helperText} />}
    </Box>
  )
}
const useStyles = makeStyles((theme) => ({
  root: {
    'width': '100%',
    '& .MuiFilledInput-root': {
      background: '#FFF',
    },
    '& .MuiFilledInput-underline:before': { borderBottom: 'none' },
    '& .MuiFilledInput-underline:after': { borderBottom: 'none' },
  },
  dateLabel: {
    fontSize: 18,
  },
  dateWrapper: {
    width: '100%',
  },
  dateInput: {
    '&:before': {
      borderBottomColor: 'rgb(121, 141, 173)',
    },
  },
  noLabel: {
    '& .MuiFilledInput-input': {
      padding: '20px 12px',
    },
  },
  fieldContainer: {
    padding: '1px',
    border: '1px solid #79747E',
    borderRadius: '4px',
  },
  inError: {
    border: '1px solid',
    borderColor: theme.palette.error.main,
  },
  disabled: {
    borderStyle: 'dashed !important',
    borderColor: '#79747E !important',
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}))
