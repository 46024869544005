import { I18n } from '../../features/translation/types'

export interface HealthRecordProps {
  i18n: I18n
  state: HealthRecordState
  type: HealthRecordType
  title: string
}

export enum HealthRecordType {
  ALLERGY = 'Allergy',
  VITAL = 'Vital',
  CONDITIONS = 'Condition',
  MEDICATIONS = 'Medication',
  VACCINATIONS = 'Vaccination',
  SOCIAL_HISTORY = 'SocialHistory',
  FAMILY_MEMBER_HISTORY = 'FamilyMemberHistory',
  PROCEDURE = 'Procedure',
}

export interface HealthRecordState {
  isLoading: boolean
  listState: {
    [HealthRecordType.ALLERGY]: Allergy[]
    [HealthRecordType.VITAL]: Vitals[]
    [HealthRecordType.CONDITIONS]: Condition[]
    [HealthRecordType.MEDICATIONS]: Medication[]
    [HealthRecordType.VACCINATIONS]: Vaccination[]
    [HealthRecordType.SOCIAL_HISTORY]: SocialHistory | undefined
    [HealthRecordType.FAMILY_MEMBER_HISTORY]: FamilyHistory[]
    [HealthRecordType.PROCEDURE]: Procedure[]
  }
  stateFetched: {
    [HealthRecordType.ALLERGY]: boolean
    [HealthRecordType.VITAL]: boolean
    [HealthRecordType.CONDITIONS]: boolean
    [HealthRecordType.MEDICATIONS]: boolean
    [HealthRecordType.VACCINATIONS]: boolean
    [HealthRecordType.SOCIAL_HISTORY]: boolean
    [HealthRecordType.FAMILY_MEMBER_HISTORY]: boolean
    [HealthRecordType.PROCEDURE]: boolean
  }
  detailId: string
}

export interface Allergy {
  id: string
  allergyCode: Code
  type: AllergyType
  allergyCategory: string
  clinicalStatus: ClinicalStatus
  verificationStatus: VerificationStatus
  onsetDate: string
  reaction: Reaction | undefined
  resolveDate: string
}

export enum AllergyType {
  FOOD = 'Food',
  MEDICATION = 'Medication',
  ENVIRONMENT = 'Environment',
  BIOLOGIC = 'Biologic',
}

export enum VerificationStatus {
  CONFIRMED = 'Confirmed',
  UNCONFIRMED = 'Unconfirmed',
  REFUTED = 'Refuted',
  ENTERED_IN_ERROR = 'Entered-In-Error',
}

export enum Severity {
  MILD = 'Mild',
  MODERATE = 'Moderate',
  SEVERE = 'Severe',
}

export enum ClinicalStatus {
  active = 'active',
  inactive = 'inactive',
  resolved = 'resolved',
}

export interface Code {
  display: string
  codeValue: string
  system: string
}

interface Reaction {
  displayName: string
  severity: Severity
}

export interface Medication {
  id: string
  medicationCode: Code
  status: ClinicalStatus
  dosage: Dosage
  form: string
  effectiveStartDate: string
  effectiveEndDate: string
  doseStartTime: string
  doseEndTime: string
  quantityValue: number
  quantityUnit: string
}

interface Dosage {
  action: string
  dose: string
  doseUnit: string
  doseTiming: string
  doseOther: string
  strength: string
}

export interface Condition {
  id: string
  conditionCode: Code
  category: Code | undefined
  clinicalStatus: ClinicalStatus
  verificationstatus: VerificationStatus
  notes: ClinicalNote[] | undefined
  dateRecorded: string
  onsetDate: string
  resolutionDate: string
  severity: Severity
  bodySite: Code
}

interface ClinicalNote {
  message: string
  sender: string
  date: string
}

export interface SocialHistory {
  [SocialHistoryType.smoking]: HistoryItem
  [SocialHistoryType.alcohol]: HistoryItem
  [SocialHistoryType.drug]: HistoryItem
  [SocialHistoryType.sexualOrientation]: HistoryItem
}

export enum SocialHistoryType {
  smoking = 'smoking',
  alcohol = 'alcohol',
  drug = 'drug',
  sexualOrientation = 'sexualOrientation',
}

export const socialHistoryTitles = {
  [SocialHistoryType.smoking]: 'patient_records_smoking_status',
  [SocialHistoryType.alcohol]: 'patient_records_alcohol_use',
  [SocialHistoryType.drug]: 'patient_records_illicit_drug_user',
  [SocialHistoryType.sexualOrientation]: 'patient_records_sexual_preference',
} as any

export interface HistoryItem {
  id: string
  dateRecorded: string
  codeDetail: Code | undefined
}

export enum SmokingStatusOptions {
  patient_records_title_smoking_status_current_every_day_smoker = 'patient_records_title_smoking_status_current_every_day_smoker',
  patient_records_title_smoking_status_current_some_day_dmoker = 'patient_records_title_smoking_status_current_some_day_dmoker',
  patient_records_title_smoking_status_former_smoker = 'patient_records_title_smoking_status_former_smoker',
  patient_records_title_smoking_status_never_smoker = 'patient_records_title_smoking_status_never_smoker',
  patient_records_title_smoking_status_smoker_unknown_current = 'patient_records_title_smoking_status_smoker_unknown_current',
  patient_records_title_smoking_status_unknown = 'patient_records_title_smoking_status_unknown',
  patient_records_title_smoking_status_heave_tobacoo_smoker = 'patient_records_title_smoking_status_heave_tobacoo_smoker',
  patient_records_title_smoking_status_light_tobacoo_smoker = 'patient_records_title_smoking_status_light_tobacoo_smoker',
}

export enum AlcoholUseOptions {
  patient_records_title_alcoholuse_current_drinker = 'patient_records_title_alcoholuse_current_drinker',
  patient_records_title_alcoholuse_current_non_drinker = 'patient_records_title_alcoholuse_current_non_drinker',
  patient_records_title_alcoholuse_stopped_drinking = 'patient_records_title_alcoholuse_stopped_drinking',
}

export enum DrugUseOptions {
  patient_records_title_drug_use_cocaine = 'patient_records_title_drug_use_cocaine',
  patient_records_title_drug_use_accidental_heroine_overdose = 'patient_records_title_drug_use_accidental_heroine_overdose',
  patient_records_title_drug_use_methamphetamine_abuse = 'patient_records_title_drug_use_methamphetamine_abuse',
  patient_records_title_drug_use_opiate_misuse = 'patient_records_title_drug_use_opiate_misuse',
  patient_records_title_drug_use_history_of_drug_abuse = 'patient_records_title_drug_use_history_of_drug_abuse',
}

export enum SexualPreferenceOptions {
  patient_records_title_sexual_orientation_lesbian_gay_homosexual = 'patient_records_title_sexual_orientation_lesbian_gay_homosexual',
  patient_records_title_sexual_orientation_strait_or_heterosexual = 'patient_records_title_sexual_orientation_strait_or_heterosexual',
  patient_records_title_sexual_orientation_bisexual = 'patient_records_title_sexual_orientation_bisexual',
  patient_records_title_sexual_orientation_unknown = 'patient_records_title_sexual_orientation_unknown',
}

export interface FamilyHistory {
  id: string
  date: string
  name: string
  relationShip: Code | undefined
  gender: string
  dob: string
  notes: ClinicalNote[] | undefined
  conditionList: Code[]
}

export interface Procedure {
  id: string
  procedureCode: string
  procedureCategory: string
  status: string
  notPerformed: boolean
  reason: string
  performedStartDate: string
  performedEndDate: string
  bodySite: string
  notes: ClinicalNote[]
  operationType: string
  practitioner: Practitioner
}

interface Practitioner {
  id: string
  name: string
}

export interface Vaccination {
  id: string
  vaccinationCode: Code[]
  procedureCategory: string
  status: string
  manufacturer: Manufacturer
  administeredDate: string
  vgName: string
  vgCode: string
  productName: string
  doseNumber: number
  performer: VaccinePerformer[]
}

interface Manufacturer {
  id: string
  name: string
  code: string
}

interface VaccinePerformer {
  actor: {
    display: {
      value: string
    }
  }
}

export interface Vitals {
  pulse: VitalsItem
  o2Saturation: VitalsItem
  bp: VitalsItem
  bmi: VitalsItem
  temperature: VitalsItem
  respiratoryRate: VitalsItem
  weight: VitalsItem
  height: VitalsItem
  effectiveDateTime: string
  practitioner: Practitioner
}

export const vitalTitles = {
  pulse: 'patient_records_pulse',
  o2Saturation: 'patient_records_o2_saturation',
  bp: 'patient_records_bp',
  bmi: 'patient_records_bmi',
  temperature: 'patient_records_temperature',
  respiratoryRate: 'patient_records_respitory_rate',
  weight: 'patient_records_weight',
  height: 'patient_records_height',
} as any

export interface VitalsItem {
  id: string
  vitalCode: Code | undefined
  vitalQuantity: Code | undefined
  status: VitalStatus
  vitalComponent: VitalComponent[] | undefined
  unit: string
}

export enum VitalComponentCodes {
  systolic_diastolic = 'mm[Hg]',
}

interface VitalComponent {
  vitalCode: Code | undefined
  vitalQuantity: Code | undefined
}

enum VitalStatus {
  Registered = 'Registered',
  Partial = 'Partial',
  Preliminary = 'Preliminary',
  Final = 'Final',
  Amended = 'Amended',
  Corrected = 'Corrected',
  Appended = 'Appended',
  Cancelled = 'Cancelled',
  EnteredInError = 'EnteredInError',
  Unknown = 'Unknown',
}
export enum VitalsUnits {
  bpm = 'beats/minute',
  percent = '%',
  mmHg = 'mmHg',
  bmi_metric = 'kg/m2',
  temp_f = 'F',
  temp_c = 'C',
  breaths_minute = 'breaths/minute',
  weight_metric = 'kg',
  weight_us = 'lbs',
  height_metric = 'cm',
  height_us = 'in',
}
