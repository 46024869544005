import '../../../fonts.css'
import { Avatar, Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { I18n } from '../../../features/translation/types'

export const UserItem = ({
  i18n,
  userAvatar,
  firstName,
  isMobile,
}: {
  i18n: I18n
  userAvatar: string
  firstName: string
  isLoading?: boolean
  isMobile?: boolean
}) => {
  const classes = useStyles()
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }

  const message = i18n.welcome_user_message.replace('%@', firstName)
  return (
    <>
      <Box className={classes.userContainer}>
        <Avatar className={classes.avatar} src={userAvatar} />
        <Box className={classes.userInfo}>
          <Typography variant="h2" className={classes.welcome}>
            {message}
          </Typography>
          {!isMobile && <div className={classes.divider} />}
          <Typography variant="h1" className={classes.date}>
            {new Date().toLocaleDateString('en-GB', options)}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  userContainer: {
    display: 'flex',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
  },
  userInfo: {
    margin: '0px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  welcome: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: '#505358',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '1.25rem',
    },
  },
  divider: {
    width: '2px',
    height: '24px',
    backgroundColor: '#898989',
    margin: '0px 16px',
  },
  avatar: {
    width: 48,
    height: 48,
  },
  date: {
    fontSize: '1.5rem',
    fontWeight: 500,
    color: '#898989',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '.888rem',
    },
  },
}))
