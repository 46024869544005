import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { ReactChild } from 'react'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { Typography } from '../../atoms'
import { Button } from '../../atoms/button/button'
import { IconButton } from '../../atoms/icon-button/icon-button'
import { ModuleActionButton } from './types'

type MobileContentPosition = 'unset' | 'absolute' | 'fixed' | 'relative'

type Position = 'start' | 'center' | 'end'

interface ModuleIntroWrapperProps {
  contentImage?: string
  backgroundColor?: string
  title?: string | ReactChild
  body?: string | ReactChild
  actions?: ModuleActionButton[]
  onBack?: () => void
  onClose?: () => void
  children?: ReactChild
  contentPosition?: Position
  childPosition?: Position
  mobileContentPosition?: MobileContentPosition
}

export function ModuleIntroWrapper(props: ModuleIntroWrapperProps) {
  const {
    title,
    body,
    actions,
    contentImage,
    backgroundColor,
    onBack,
    onClose,
    children,
    contentPosition = 'center',
    childPosition = 'start',
    mobileContentPosition = 'absolute',
  } = props
  const hasImage = Boolean(contentImage)
  const classes = useStyles({ mobileContentPosition, hasImage })
  const { isMobile } = useMUITheme({ breakpoint: 960 })
  const { theme } = useMUITheme()

  const MobileView = () => (
    <div
      className={classes.mobileImage}
      style={{ backgroundImage: `url(${contentImage})` }}
    >
      <Content />
    </div>
  )

  const DesktopView = () => (
    <>
      <div
        className={classes.desktopImage}
        style={{ backgroundImage: `url(${contentImage})` }}
      />
      <Content />
    </>
  )

  const Content = () => (
    <div className={classes.childWrapper}>
      {onBack && (
        <IconButton
          icon={<ArrowBackIcon />}
          handleClick={onBack}
          childClass={classes.backIcon}
        />
      )}
      <Box
        sx={{
          [theme.breakpoints.down(960)]: {
            position: mobileContentPosition,
          },
        }}
        className={classes.contentWrapper}
      >
        <div
          className={classes.content}
          style={{
            justifyContent:
              contentPosition === 'center'
                ? contentPosition
                : `flex-${contentPosition}`,
          }}
        >
          {children && childPosition === 'start' && <>{children}</>}
          {title && (
            <Typography
              sx={{
                [theme.breakpoints.down(960)]: {
                  color: hasImage ? '#FFF !important' : '#505358',
                },
              }}
              variant="h4"
              className={classes.title}
            >
              {title}
            </Typography>
          )}
          {body && (
            <Typography
              sx={{
                [theme.breakpoints.down(960)]: {
                  marginBottom: '16px',
                  color: hasImage ? '#FFF  !important' : '#505358',
                },
              }}
              className={classes.body}
            >
              {body}
            </Typography>
          )}
          {children && childPosition === 'center' && <>{children}</>}
          <Box className={classes.buttonContainer}>
            {actions?.map((b, i) => (
              <Button
                key={`${b.label} - ${i}`}
                className={`${classes.button} ${b.variant}`}
                variant={isMobile ? 'outlined' : b.variant}
                onClick={b.action}
                disabled={b.disabled}
                style={b.style}
              >
                {b.label}
              </Button>
            ))}
          </Box>
          {children && childPosition === 'end' && <>{children}</>}
        </div>
      </Box>
      {onClose && (
        <IconButton
          icon={<CloseIcon />}
          handleClick={onClose}
          childClass={classes.closeIcon}
        />
      )}
    </div>
  )

  return (
    <div className={classes.wrapper}>
      {isMobile ? <MobileView /> : <DesktopView />}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#FFF',
    display: 'flex',
  },
  mobileImage: {
    minHeight: '100vh',
    width: '100vw',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0% 50%',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down(500)]: {
      backgroundPosition: '0% 100%',
    },
  },
  desktopImage: {
    minHeight: '100vh',
    width: '35vw',
    minWidth: '350px',
    maxWidth: '960px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 0%',
    backgroundSize: 'cover',
  },
  childWrapper: {
    display: 'flex',
    padding: '0px 64px',
    minWidth: '500px',
    [theme.breakpoints.down(1200)]: {
      padding: '0px 32px',
    },
    [theme.breakpoints.down(960)]: {
      padding: '0px',
      minWidth: 'unset',
    },
  },
  image: {
    margin: '0 auto',
    justifySelf: 'center',
  },
  iconFilled: {
    'borderRadius': '100%',
    'fontSize': '28px',
    'padding': 6,
    'backgroundColor': 'rgba(0, 0, 0, 0.34)',
    'color': 'white',
    'cursor': 'pointer',
    '&.left': {
      marginLeft: '-48px',
      transform: 'rotate(-180deg)',
      [theme.breakpoints.down(960)]: {
        marginLeft: '-12px',
      },
    },
    [theme.breakpoints.down(960)]: {
      fontSize: '14px',
      padding: 6,
    },
  },
  backIcon: {
    position: 'absolute',
    left: 32,
    top: 32,
  },
  closeIcon: {
    position: 'absolute',
    right: 32,
    top: 32,
  },
  content: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    margin: '64px auto',
    [theme.breakpoints.down(960)]: {
      width: '90%',
      maxWidth: '500px',
      margin: '0px auto',
    },
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    [theme.breakpoints.down(960)]: {
      bottom: 0,
      width: '100vw',
    },
  },
  title: {
    fontWeight: 600,
    color: '#505358',
    lineHeight: '55px',
    fontSize: 36,

    textAlign: 'inherit',
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  body: {
    fontWeight: 500,
    color: '#505358',
    lineHeight: '22px',
    fontSize: 16,
    marginBottom: '32px',
    maxWidth: 500,
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      marginBottom: '10px',
      lineHeight: '20px',
    },
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '500px',
    [theme.breakpoints.down(960)]: {
      marginBottom: 24,
    },
  },
  button: {
    margin: '8px 0px',
    padding: '10px 48px',
    [theme.breakpoints.down(960)]: {
      maxWidth: 'unset',
      width: '100%',
    },
  },
}))
