/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import styled from '@emotion/styled'
import CallIcon from '@mui/icons-material/Call'
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import PinDrop from '@mui/icons-material/PinDrop'
import { Box, Link as MUILink, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import createDOMPurify from 'dompurify'
import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as AlertRed } from '../../assets/icons/alert-red.svg'
import { ReactComponent as AlertYellow } from '../../assets/icons/alert-yellow.svg'
import { ReactComponent as CheckGreen } from '../../assets/icons/check-green.svg'
import { ReactComponent as PinCircle } from '../../assets/icons/pin-circle.svg'
import { formatDate, formatDateAndTime } from '../../libs/helpers'
import { useLocationService } from '../../libs/hooks/useLocation'
import { capitalizeString, formatPhoneForDisplayUS } from '../../libs/utils'
import {
  ClinicianNotes,
  EncounterPharmacy,
} from '../../pages/treatment-plan/helper'
import { Button, LogoImage } from '../../ui'
import { ConsultPrescription } from '../health-service-lite/types'
import { I18n } from '../translation/types'
import { formatPharmacyData, joinPharmaciesAndPrescript } from './helper'
import { TestDetailFields } from './types'
import { useMUITheme } from '../../libs/hooks/useTheme'

const Divider = styled.div`
  margin: 48px 0;
  width: 100%;
  border-bottom: solid 1px #e0e0e0;
`

const SectionBreak = styled.div`
  margin: 8px auto 16px;
  width: 75%;
  border-bottom: solid 1px #7e7e7e;
`

const DOMPurify = createDOMPurify(window)

export const addLinkWithinString = (
  value: string,
  linkText: string,
  action: () => void,
  stringClass?: string,
  linkClass?: string,
  addLineBreaks?: number
) => {
  if (!value) return null

  let text = ['']

  if (value.includes('%@')) {
    text = value.split('%@')
  } else if (!value.includes('%@') && value.includes(linkText)) {
    text = value.split(linkText)
  } else return <Typography className={stringClass}>{value}</Typography>

  const insertBreaks = (length: number) => {
    const breaks = []
    for (let i = 0; i < length; i++) {
      breaks.push(<br />)
    }
    return breaks
  }

  return (
    <Typography className={stringClass}>
      {text[0]} {addLineBreaks && insertBreaks(addLineBreaks)}
      <MUILink underline="none" className={linkClass} onClick={() => action()}>
        {linkText}
      </MUILink>
      {text[1]}
    </Typography>
  )
}

export const TextField = (props: {
  field: TestDetailFields
  i18n: any
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.testName}>
        {i18n[field.translationName]}
      </Typography>
      <Typography className={classes.info}>
        {field.valueIsKey ? i18n[test[field.id]] : test[field.id]}
      </Typography>
      {field.divider && <Divider />}
    </>
  )
}

export const TitleField = (props: {
  field: TestDetailFields
  i18n: any
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.testName}>
        {i18n[field.translationName] || field.plainTextName}
      </Typography>
      {field.divider && <Divider />}
    </>
  )
}

export const SmallTextField = (props: {
  field: TestDetailFields
  i18n: any
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  return (
    <div className={classes.summaryBox}>
      <Typography className={classes.info}>
        {(i18n[field.translationName] || field.plainTextName) &&
          `${i18n[field.translationName] || field.plainTextName}: `}
        {field.valueIsKey ? i18n[test[field.id]] : test[field.id]}
      </Typography>
      {field.divider && <Divider />}
    </div>
  )
}

export const SmallTextArrayField = (props: {
  field: TestDetailFields
  i18n: any
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  const handleNewLines = (text: string, i: number) => {
    if (!text) return null
    const keyAppend = new Date().getTime()
    if (text.includes('\n')) {
      const returnedLine = text.split('\n')
      return (
        <React.Fragment key={`${keyAppend}-${i}`}>
          {returnedLine.map((l: string) => {
            if (!l.trim()) return null
            return (
              <Typography
                key={`${l}-${keyAppend}-sub${i}`}
                variant="subtitle2"
                className={classes.info}
              >
                {l}
              </Typography>
            )
          })}
        </React.Fragment>
      )
    }
    return (
      <Typography
        key={`${text}-${keyAppend}-${i}`}
        variant="subtitle2"
        className={classes.info}
      >
        {text}
      </Typography>
    )
  }
  return (
    <div className={classes.summaryBox}>
      <Typography variant="subtitle2" className={classes.info}>
        {(i18n[field.translationName] || field.plainTextName) &&
          `${i18n[field.translationName] || field.plainTextName}: `}
      </Typography>
      {test[field.id]?.length > 0 &&
        test[field.id].map((l: string, i: number) => handleNewLines(l, i))}
      {field.divider && <Divider />}
    </div>
  )
}

export const ListFieldText = (props: {
  field: TestDetailFields
  i18n: any
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  const { isMobile } = useMUITheme()
  return (
    <div
      className={classes.summaryBox}
      style={{ width: isMobile ? '100%' : '50%' }}
    >
      <Typography
        variant="subtitle2"
        className={classes.info}
        style={{ fontWeight: 600 }}
      >
        {(i18n[field.translationName] || field.plainTextName) &&
          `${i18n[field.translationName] || field.plainTextName}`}
      </Typography>
      <Typography
        variant="subtitle2"
        className={classes.info}
        style={{ fontWeight: 500 }}
      >
        {field.valueIsKey ? i18n[test[field.id]] : test[field.id]}
      </Typography>
      {field.divider && <Divider />}
    </div>
  )
}

export const ClinicianNotesArray = (props: {
  field: TestDetailFields
  i18n: any
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  return (
    <div className={classes.summaryBox}>
      <Typography
        variant="subtitle2"
        className={classes.info}
        style={{ fontWeight: 600 }}
      >
        {(i18n[field.translationName] || field.plainTextName) &&
          `${i18n[field.translationName] || field.plainTextName}`}
      </Typography>
      <div className={classes.notesWrapper}>
        {test[field.id]?.map((note: ClinicianNotes) => (
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ fontWeight: 500 }}
          >
            {note.text}
          </Typography>
        ))}
      </div>
      {field.divider && <Divider />}
    </div>
  )
}

export const DateField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  const { isMobile } = useMUITheme()

  return (
    <div
      className={classes.summaryBox}
      style={{ width: isMobile ? '100%' : '50%' }}
    >
      {!field.inline && (
        <Typography className={classes.info} style={{ fontWeight: 600 }}>
          {i18n[field.translationName] || field.plainTextName}
        </Typography>
      )}
      <Typography variant="subtitle2" className={classes.info}>
        {field.inline &&
          `${i18n[field.translationName] || field.plainTextName}`}{' '}
        {formatDate(test[field.id], !field.noTime, field.dateTimeSeparator, field.dateFormat)}
      </Typography>
      {field.divider && <Divider />}
    </div>
  )
}

export const PhamacyListField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  const listField = {
    id: 'pharmacy',
    type: 'address',
    plainTextName: '',
    divider: false,
  } as TestDetailFields

  return (
    <div className={classes.summaryBox}>
      {test[field.id]?.map((p: EncounterPharmacy, i: number) => {
        const formattedData = formatPharmacyData(p)

        return (
          <React.Fragment
            key={`pharmacy-${formattedData?.pharmacy?.name}-${i}`}
          >
            <AddressField i18n={i18n} field={listField} test={formattedData} />
          </React.Fragment>
        )
      })}
    </div>
  )
}

export const AddressField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  const { openNativeMapFeature } = useLocationService().actions
  if (!test[field.id]?.line1 || !test[field.id]?.city) return null

  let link = `${test[field.id].line1}`

  let url = `http://maps.google.com/?q=${test[field.id].line1}`
  if (test[field.id].homeAddressAddressTwo) {
    url += `%20${test[field.id].line2}`
    link += `${test[field.id].line2} `
  }
  url += `%20${test[field.id].city}`
  link += ` ${test[field.id].city}, `
  if (test[field.id].state) {
    url += `%2C${test[field.id].state}`
    link += `${test[field.id].state}`
  }
  if (test[field.id].zip) {
    url += `%20${test[field.id].zip}`
    link += ` ${test[field.id].zip}`
  }

  const openMap = () => {
    const address = {
      ...test[field.id],
    }
    openNativeMapFeature(address)
  }

  return (
    <div className={classes.summaryBox}>
      <Typography className={classes.testName}>
        {i18n[field.translationName] || field.plainTextName}
      </Typography>
      <Typography
        variant="subtitle2"
        className={classes.info}
        style={{ fontWeight: 700, color: '#7B7C7D' }}
      >
        {test[field.id].name}
      </Typography>
      {/* <MUILink
        role="button"
        className={classes.addressContainer}
        onClick={() => openMap()}
      > */}
      {field.inline ? (
        <Box className={classes.addressContainer}>
          <Typography variant="subtitle2" className={classes.link}>
            {link}
          </Typography>
          <PinCircle />
        </Box>
      ) : (
        <Box className={classes.addressWrapper}>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ fontWeight: 500, color: '#757575' }}
          >
            {test[field.id].line1} <br />
            {test[field.id].line2 && (
              <>
                {test[field.id].line2} <br />
              </>
            )}
            {test[field.id].city}, {test[field.id].state} {test[field.id].zip}
            {test.pharmacy_phone && (
              <>
                <br />
                {formatPhoneForDisplayUS(test.pharmacy_phone)}
              </>
            )}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ fontWeight: 500, color: '#757575', fontSize: 12 }}
          >
            {i18n.pharmacy_pickup_instruction}
          </Typography>
          <div className={classes.viewBox} onClick={() => openMap()}>
            <Typography variant="subtitle2" className={classes.link}>
              {i18n.view}
            </Typography>
            <ChevronRightSharpIcon color="primary" />
          </div>
          {/* <PinCircle /> */}
        </Box>
      )}
      {/* </MUILink> */}
      {field.divider && <Divider />}
    </div>
  )
}

export const FacilityLocation = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  const { openNativeMapFeature } = useLocationService().actions

  const openMap = () => {
    const address = {
      ...test[field.id],
    }
    openNativeMapFeature(address)
  }

  return (
    <div className={classes.addressWrapper}>
      <MUILink role="button" color="primary" onClick={() => openMap()}>
        <Box className={classes.addressContainer}>
          <div>
            <div className={classes.locationNameWrapper}>
              <Typography style={{ fontWeight: 600 }} color="primary">
                {test[field.id].locationName}
              </Typography>
              <PinDrop />
            </div>
            <Typography variant="subtitle2" className={classes.info}>
              {test[field.id].line1} <br />
              {test[field.id].line2 && (
                <>
                  {test[field.id].line2} <br />
                </>
              )}
              {test[field.id].city}, {test[field.id].state} {test[field.id].zip}
            </Typography>
          </div>
        </Box>
      </MUILink>
      {test[field.id].locationPhone && (
        <div className={classes.locationNameWrapper}>
          <Typography style={{ fontWeight: 500, fontSize: 14 }} color="primary">
            {test[field.id].locationPhone}
          </Typography>
          <CallIcon color="primary" style={{ fontSize: 18 }} />
        </div>
      )}
      {/* <RenderFedExWorkingHours
        officeData={test[field.id]}
        i18n={i18n}
        withDivider={true}
        textClass={classes.info}
      /> */}
      {field.divider && <Divider />}
    </div>
  )
}

export const LinkField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  return (
    <>
      <MUILink
        role="button"
        color="primary"
        component={Link}
        underline="none"
        to={
          field.linkIsDetailView
            ? `${field.linkPath}/${test._id}`
            : field.linkPath
        }
        className={classes.rescheduleLink}
      >
        <Box className={classes.rescheduleContainer}>
          {field.startIcon?.()}
          <Typography className={classes.testName}>
            {i18n[field.translationName]}
          </Typography>
        </Box>
        {field.endIcon?.()}
      </MUILink>
      {field.divider && <Divider />}
    </>
  )
}

export const PhoneField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  if (!test.pharmacy_phone) return null

  const handleAudioCall = () => {
    window.open(`tel:1${test.pharmacy_phone}`, '_self')
  }
  return (
    <div className={classes.summaryBox}>
      <Typography className={classes.testName}>
        {i18n[field.translationName] || field.plainTextName}
      </Typography>
      <Box className={classes.rescheduleContainer} onClick={handleAudioCall}>
        {field.startIcon?.()}
        <Typography className={classes.info}>
          {formatPhoneForDisplayUS(test.pharmacy_phone)}
        </Typography>
      </Box>
      {field.endIcon?.()}
      {field.divider && <Divider />}
    </div>
  )
}

export const ButtonField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  if (!test[field.enable]) return <></>

  return (
    <Button
      color="primary"
      variant="outlined"
      component={Link}
      to={
        field.linkIsDetailView
          ? `${field.linkPath}/${test._id}`
          : field.linkPath
      }
      className={classes.rescheduleLink}
    >
      {field.plainTextName || i18n[field.translationName]}
    </Button>
  )
}

export const CredentialHeaderField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  return (
    <Box className={classes.credentialBox}>
      <Typography className={classes.credential}>
        TEST <br /> CREDENTIAL
      </Typography>
      <LogoImage className={classes.credentialImage} />
      {field.divider && <Divider />}
    </Box>
  )
}

export const ResultField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  const result = () => {
    const testResult = test?.result || test?.metadata?.outcome
    switch (testResult) {
      case 'POSITIVE': {
        return (
          <Box className={classes.resultBox} style={{ borderColor: '#FF0000' }}>
            <Typography
              variant="subtitle2"
              className={classes.resultText}
              style={{ color: '#FF0000' }}
            >
              {test[field.id] && capitalizeString(test[field.id])}
            </Typography>
            <AlertRed />
          </Box>
        )
      }
      case 'NEGATIVE': {
        return (
          <Box className={classes.resultBox} style={{ borderColor: '#009104' }}>
            <Typography
              variant="subtitle2"
              className={classes.resultText}
              style={{ color: '#009104' }}
            >
              {test[field.id] && capitalizeString(test[field.id])}
            </Typography>
            <CheckGreen />
          </Box>
        )
      }
      case 'INVALID':
      case 'UNKNOWN':
      case 'ERROR':
      case 'INCONCLUSIVE': {
        return (
          <Box className={classes.resultBox} style={{ borderColor: '#FA9C13' }}>
            <Typography
              variant="subtitle2"
              className={classes.resultText}
              style={{ color: '#FA9C13' }}
            >
              {test[field.id] && capitalizeString(test[field.id])}
            </Typography>
            <AlertYellow />
          </Box>
        )
      }
      default: {
        return (
          <Box className={classes.resultBox} style={{ borderColor: '#FA9C13' }}>
            <Typography
              variant="subtitle2"
              className={classes.resultText}
              style={{ color: '#FA9C13' }}
            >
              No result found
            </Typography>
            <AlertYellow />
          </Box>
        )
      }
    }
  }

  return (
    <>
      <Typography className={classes.testName}>
        {capitalizeString(i18n[field.translationName])}
      </Typography>
      {result()}
      {field.divider && <Divider />}
    </>
  )
}

export const SummaryField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  return (
    <Box className={classes.summaryBox}>
      <Typography className={classes.credential}>
        {capitalizeString(field.plainTextName || i18n[field.translationName]) ||
          ''}{' '}
      </Typography>
      {test[field.id] && (
        <span
          className={classes.summaryInfo}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(test[field.id]),
          }}
        />
      )}
    </Box>
  )
}

export const FVFPrescriptionList = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  return (
    <Box className={classes.summaryBox}>
      {test.prescriptions?.map((p: ConsultPrescription, i: number) => (
        <React.Fragment key={`${p.name}-${i}`}>
          <Typography className={classes.info}>{p.name}</Typography>
          <ul>
            {typeof p.quantity === 'number' && (
              <li>
                <Typography className={classes.info}>
                  <span style={{ fontWeight: 500 }}>Quantity</span>:{' '}
                  {p.quantity}
                </Typography>
              </li>
            )}
            {(typeof p.days_supply === 'number' ||
              typeof p.daysSupply === 'number') && (
              <li>
                <Typography className={classes.info}>
                  <span style={{ fontWeight: 500 }}>Days Supply</span>:{' '}
                  {p.days_supply || p.daysSupply}
                </Typography>
              </li>
            )}
            {/* {p.sig && (
                <li>
                  <Typography className={classes.info}>
                    <span style={{ fontWeight: 500 }}>Sig</span>: {p.sig}
                  </Typography>
                </li>
              )} */}
            {p.strength && (
              <li>
                <Typography className={classes.info}>
                  <span style={{ fontWeight: 500 }}>Drug Strength</span>:{' '}
                  {p.strength}
                </Typography>
              </li>
            )}
            {typeof p.refills === 'number' && (
              <li>
                <Typography className={classes.info}>
                  <span style={{ fontWeight: 500 }}>Refills</span>: {p.refills}
                </Typography>
              </li>
            )}
          </ul>
        </React.Fragment>
      ))}
    </Box>
  )
}

export const SAFEPrescriptionList = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  const pharmacies = joinPharmaciesAndPrescript(
    test.pharmacies,
    test.prescriptions
  )

  return (
    <Box className={classes.summaryBox}>
      {pharmacies && pharmacies.length > 0 ? (
        //Show grouped prescriptions with their respective pharmacies
        <>
          {pharmacies.map((p, i) => {
            const listField = {
              id: 'pharmacy',
              type: 'address',
              plainTextName: '',
              divider: false,
            } as TestDetailFields

            const formattedData = formatPharmacyData(p)
            return (
              <>
                <div className={classes.prescriptionsWrapper}>
                  <div className={classes.prescriptionsColumns}>
                    <Typography className={classes.testName}>
                      Medication
                    </Typography>
                    <div className={classes.summaryBox}>
                      {p.prescriptions.length > 0 &&
                        p.prescriptions.map((t, i: number) => (
                          <React.Fragment key={`${t.name}-${i}`}>
                            <Typography className={classes.medication}>
                              {t.name}
                            </Typography>
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                  <Divider />
                  <div className={classes.prescriptionsColumns}>
                    <Typography className={classes.testName}>
                      Pharmacy
                    </Typography>
                    <AddressField
                      i18n={i18n}
                      field={listField}
                      test={formattedData}
                    />
                  </div>
                </div>
                {pharmacies.length > 1 && pharmacies.length !== i + 1 && (
                  <SectionBreak />
                )}
              </>
            )
          })}
        </>
      ) : (
        //Show prescriptions and pharmacies independently if joining has failed
        <>
          {test.prescriptions?.map((p: ConsultPrescription, i: number) => (
            <React.Fragment key={`${p.name}-${i}`}>
              <Typography className={classes.info}>
                {p.name} {p.strength && `(${p.strength})`}
              </Typography>
            </React.Fragment>
          ))}
          {test.pharmacies?.map((p: EncounterPharmacy, i: number) => {
            const listField = {
              id: 'pharmacy',
              type: 'address',
              plainTextName: '',
              divider: false,
            } as TestDetailFields

            const formattedData = formatPharmacyData(p)
            return (
              <AddressField
                i18n={i18n}
                field={listField}
                test={formattedData}
              />
            )
          })}
        </>
      )}
    </Box>
  )
}

export const TestField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  return (
    <Box className={classes.vendorBox}>
      <Box>
        <Typography variant="subtitle2" className={classes.summaryHeader}>
          {test?.testName}
        </Typography>
        <Typography variant="subtitle2" className={classes.vendor}>
          {test?.labVendor}
        </Typography>
      </Box>
      <Box>
        <img src={test.testImage} style={{ height: '70px' }} />
      </Box>
    </Box>
  )
}

const SPACE_BETWEEN = 'space-between'
const useStyles = makeStyles((theme) => ({
  summaryBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0px',
  },
  summaryHeader: {
    color: '#505358',
    fontWeight: 600,
    fontSize: 14,
  },
  summaryInfo: {
    color: '#000000',
    fontSize: 14,
  },
  vendorBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vendor: {
    color: '#757575',
  },
  credentialBox: {
    minHeight: '74px',
    width: '100%',
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    alignItems: 'center',
  },
  credential: {
    fontWeight: 600,
    fontSize: 16,
    color: '#54565B',
  },
  alert: {
    height: 14,
    width: 14,
  },
  resultBox: {
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    alignItems: 'center',
    margin: '16px 0px',
    borderRadius: '12px',
    border: 'solid 1px',
    padding: '2px 10px',
    width: '95%',
  },
  testName: {
    color: '#505358',
    fontSize: 20,
    fontWeight: 600,
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
    },
  },
  rescheduleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
  },
  rescheduleLink: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    margin: '20px auto',
    [theme.breakpoints.down(600)]: {
      margin: '8px auto',
    },
  },
  addressContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: SPACE_BETWEEN,
  },
  info: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    color: '#757575',
  },
  resultText: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
  },
  medication: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  credentialImage: {
    maxHeight: '48px',
    [theme.breakpoints.down(600)]: {
      maxHeight: '32px',
    },
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  viewBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  prescriptionsWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  prescriptionsColumns: {
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  locationNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  notesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
}))
