import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFlowControl } from '../../features/flowcontrol/use-flow-control'
import { StoreStepType } from '../../features/shopify/model/types'
import { ShopLoading } from '../../features/shopify/shopify-ui-items/storeLoading'
import { lsClient } from '../../ls-client'
import { selectNetworkSettings } from '../network/model'
import { resetQuestionnaireV2Store } from '../questionnaireV2/questionnaireV2Slice'
import { ShopifyModule } from './shopify-wrapper'
import { selectProfileData } from '../profile/model'

export const ShopifyClientWrapper = () => {
  const networkSettings = useSelector(selectNetworkSettings)
  const [storeCanLoad, setStoreCanLoad] = useState(false)
  const profileData = useSelector(selectProfileData)
  const { type } = useParams<{ type: StoreStepType }>()
  const { state } = useFlowControl()
  const dispatch = useDispatch()

  useEffect(() => {
    // Clear Prior modules if Flow Engine executing
    // ToDo: create method to clear prior modules as flow engine moves successfully to next.
    if (state.isFlowExecuting) {
      lsClient.removeUserKeyLS('VCquestionnaireId')
      dispatch(resetQuestionnaireV2Store())
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [type])

  useEffect(() => {
    if (networkSettings?.tenantID && profileData.nationalHealthId) {
      setStoreCanLoad(true)
    }
  }, [networkSettings, profileData])

  return (
    <>
      {storeCanLoad ? (
        <ShopifyModule />
      ) : (
        <div style={{ width: '100%', margin: '64px auto' }}>
          <ShopLoading />
        </div>
      )}
    </>
  )
}
