import { initialStore, systemMessagesReducerName } from './main'

interface RootState {
  [systemMessagesReducerName]: typeof initialStore
}

export const selectIsLoading = ({ systemMessages }: RootState) => systemMessages.isLoading
export const selectList = ({ systemMessages }: RootState) => systemMessages.list
export const selectIsLoadingPage = ({ systemMessages }: RootState) => systemMessages.isLoadingPage
export const selectIsDone = ({ systemMessages }: RootState) => systemMessages.isDone
