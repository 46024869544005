import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { Dispatch } from 'redux'
import api from '../../api'
import { I18n } from '../../features/translation/types'
import { lsClient } from '../../ls-client'
import { UploadKitScanSlice } from './types'

const initialStore: UploadKitScanSlice = {
  isLoading: false,
  submitPatientTestLoading: false,
  isSuccess: false,
  kitUploadResult: {},
  patientTestId: '',
  uploadError: '',
  image: '',
  isRetryImage: false,
}

export const uploadKitScanSlice = createSlice({
  name: 'uploadKitScan',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setSubmitPatientTestLoading(store, { payload }: { payload: boolean }) {
      store.submitPatientTestLoading = payload
    },
    setScanUploadResult(store, { payload }: { payload: any }) {
      store.isSuccess = true
      store.kitUploadResult = payload
    },
    setPatientTestId(store, { payload }: { payload: any }) {
      store.patientTestId = payload
    },
    setUploadError(store, { payload }: { payload: any }) {
      store.uploadError = payload
    },
    setIsRetyImage(store, { payload }: { payload: boolean }) {
      store.isSuccess = false
      store.isRetryImage = payload
    },
    resetUploadStore: () => initialStore,
  },
})

export const { resetUploadStore, setUploadError, setIsRetyImage } =
  uploadKitScanSlice.actions

const {
  setLoading,
  setScanUploadResult,
  setSubmitPatientTestLoading,
  setPatientTestId,
} = uploadKitScanSlice.actions

export const submitTestScan =
  (
    labTestOrderableIds: string[],
    image: any,
    userId: string,
    elapsedTime: any,
    cassette: string,
    testQRId: string,
    sku: string,
    i18n: I18n,
    selfAssessment?: string | null
  ) =>
  async (dispatch: any, getStore: any) => {
    dispatch(setLoading(true))

    const payload = {
      data: {
        orderId: testQRId,
        metadata: {
          imageType: 'jpg',
          skuId: sku,
        },
      },
    }

    const res = await api.getUploadURL(payload)
    if (res?.data) {
      const uploadID = res.data.metadata?.uploadID
      if (res?.isOK) {
        const response = await axios.put(res.data.preSignedURL, image, {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'image/jpeg',
          },
        })

        if (response?.statusText === 'OK') {
          dispatch(setLoading(true))
          dispatch(setScanUploadResult(uploadID))
          dispatch(
            submitPatientTest(
              res?.data,
              userId,
              labTestOrderableIds,
              elapsedTime,
              cassette,
              testQRId,
              i18n,
              selfAssessment
            )
          )
        } else {
          dispatch(setLoading(false))
          dispatch(setUploadError(i18n.server_error_txt))
        }
      }
    } else {
      dispatch(setUploadError(i18n.server_error_txt))
    }
  }

export const submitPatientTest =
  (
    scanData: any,
    userId: string,
    labTestOrderableIds: string[],
    elapsedTime: any,
    cassette: string,
    testQRId: string,
    i18n: I18n,
    selfAssessment?: string | null
  ) =>
  async (dispatch: Dispatch, getStore: any) => {
    dispatch(setSubmitPatientTestLoading(true))
    const TENANT_ID = lsClient.getUserLSByKey('tenantId')
    const questionnaireExecutionId =
      lsClient.getUserLSByKey('VCquestionnaireId')
    const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
    const testId = lsClient.getUserLSByKey('testId')
    const patientTest = {
      _id: testQRId,
      userId,
      questionnaireExecutionId,
      healthServiceId,
      tenantId: TENANT_ID,
      collectionDate: new Date().toISOString(),
      orderId: scanData.metadata?.uploadID,
      elapsedTimeOnSubmissionTimer: elapsedTime,
      metadata: {
        imageType: scanData.metadata?.file.split('.')[1],
        casseteLotNumber: cassette,
        labTestOrderableId: testId,
        selfAssessmentOutcome: selfAssessment ? selfAssessment : null,
      },
      orderableTestIds: labTestOrderableIds,
      tags: ['VER_1.0.0', 'OS_Web'],
    }

    if (selfAssessment) {
      patientTest.tags.push('SA')
    }

    const params = {
      tenantID: TENANT_ID,
      data: patientTest,
    }

    const { data } = await api.submitPatientTest(params)
    if (data) {
      dispatch(setLoading(false))
      dispatch(setSubmitPatientTestLoading(false))
      lsClient.setUserLS('patientTestId', data)
      dispatch(setPatientTestId(data))
    } else {
      dispatch(setLoading(false))
      dispatch(setUploadError(i18n.server_error_txt))
    }
  }

export const cancelTestApi = (id: string) => async (dispatch: any) => {
  dispatch(setLoading(true))

  const res = await api.cancelTest(id)
  if (res?.data) {
    console.log('cancelled')
  }
}

export const uploadKitScanReducer = uploadKitScanSlice.reducer
export const uploadKitScanReducerName = uploadKitScanSlice.name
interface RootStore {
  [uploadKitScanReducerName]: typeof initialStore
}

export const selectLoading = ({ uploadKitScan }: RootStore) =>
  uploadKitScan.isLoading

export const selectScanUploadResultSuccess = ({ uploadKitScan }: RootStore) =>
  uploadKitScan.isSuccess

export const selectkitUploadResult = ({ uploadKitScan }: RootStore) =>
  uploadKitScan.kitUploadResult

export const selectSubmitPatientTestLoading = ({ uploadKitScan }: RootStore) =>
  uploadKitScan.submitPatientTestLoading

export const selectPatientTestId = ({ uploadKitScan }: RootStore) =>
  uploadKitScan.patientTestId

export const selectUploadError = ({ uploadKitScan }: RootStore) =>
  uploadKitScan.uploadError

export const selectIsRetryImage = ({ uploadKitScan }: RootStore) =>
  uploadKitScan.isRetryImage
