import * as Yup from 'yup'
import { I18n } from '../../../../../features/translation/types'

export const validationSchema = (i18n: I18n) =>
  Yup.object().shape({
    // birthDate: Yup.date().required(i18n.required),
    line1: Yup.string()
      .matches(/^(?!\s+$)/, i18n.value_not_allowed)
      .required(i18n.required),
    city: Yup.string()
      .matches(/^(?!\s+$)/, i18n.value_not_allowed)
      .required(i18n.required),
    state: Yup.string().required(i18n.required),
    // race: Yup.string().required(i18n.required),
    // ethnicity: Yup.string().required(i18n.required),
    // gender: Yup.string().required(i18n.required),
    zip: Yup.string()
      .min(5, i18n.invalid_zip_code_error_message)
      .max(5, i18n.invalid_zip_code_error_message)
      .matches(/^(?!0{5})/, i18n.value_not_allowed)
      .required(i18n.required),
    phone: Yup.string()
      .min(10, i18n.invalid_phone_error_message)
      .max(10, i18n.invalid_phone_error_message)
      .matches(/^(?!0{10})/, i18n.value_not_allowed)
      .matches(/^(?!0+[1-9])/, i18n.value_not_allowed)
      .required(i18n.required),
  })
