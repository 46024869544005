import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { Faq, FAQQueryModel } from './types'

interface FaqSlice {
  faqLoading: boolean
  faqList: Faq[]
}

const initialStore: FaqSlice = {
  faqLoading: false,
  faqList: [],
}

export const faqSlice = createSlice({
  name: 'faq',
  initialState: initialStore,
  reducers: {
    setFAQsLoading(store, { payload }: { payload: boolean }) {
      store.faqLoading = payload
    },
    setFAQs(store, { payload }: { payload: any }) {
      store.faqList = payload.items
      store.faqLoading = false
    },
  },
})

const { setFAQsLoading, setFAQs } = faqSlice.actions

export const getFAQsList = () => async (dispatch: Dispatch, getStore: any) => {
  const language = getStore().language
  dispatch(setFAQsLoading(true))
  const { data } = await api.getFaqsByLanguage(language)
  dispatch(setFAQsLoading(false))
  if (data) {
    dispatch(setFAQs(data))
  }
}

export const getHelpCenter =
  (params: FAQQueryModel) => async (dispatch: Dispatch, getStore: any) => {
    dispatch(setFAQsLoading(true))
    const { data } = await api.getHelpCenter(params)
    dispatch(setFAQsLoading(false))

    if (data) {
      const items = [] as any
      data.forEach((entry: any) => {
        items.push(entry.items[0])
      })
      dispatch(setFAQs({items}))
    }
  }

export const selectFAQsLoading = ({ faq }: RootStore) => faq.faqLoading

export const selectFAQsList = ({ faq }: RootStore) => faq.faqList

export const faqReducer = faqSlice.reducer

export const faqReducerName = faqSlice.name

interface RootStore {
  [faqReducerName]: typeof initialStore
}
