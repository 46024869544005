import { DeprecatedThemeOptions } from '@mui/material/styles'

const AvenirRegular =
  'Avenir Next, Avenir Regular, Avenir Next Rounded Regular, serif'
const AvenirMedium =
  'Avenir Next Medium, Avenir Medium, Avenir Next Rounded Medium'

export const defaultTheme: DeprecatedThemeOptions = {
  palette: {
    primary: {
      main: '#003C71',
      contrastText: '#fff',
      light: '#fff',
      dark: '#003C71',
    },
    secondary: {
      main: '#CED0CE',
      contrastText: '#fff',
      light: '#fff',
      dark: '#2E5266',
    },
    error: {
      main: '#f44336',
      contrastText: '#fff',
    },
    warning: {
      main: '#FFC12A',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiMenu: {
      list: {
        padding: '4px 0',
      },
      paper: {
        borderRadius: 8,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 16,
        fontFamily: AvenirRegular,
        color: '#757575',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 16,
      },
      paperFullScreen: {
        borderRadius: 0,
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: AvenirRegular,
        color: '#505358',
      },
      body2: {
        fontFamily: AvenirRegular,
        color: '#505358',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#003C71',
      },
    },
    MuiTab: {
      root: {
        fontSize: 16,
        padding: '6px 24px',
        borderBottom: '1px solid #7E7E7E',
      },
      textColorPrimary: {
        'color': '#7E7E7E',
        'fontWeight': 500,
        '&.Mui-selected': {
          fontWeight: 600,
        },
      },
    },
  },
  typography: {
    fontFamily: AvenirRegular,
    h1: {
      color: '#505358',
      fontFamily: AvenirMedium,
      fontSize: 30,
    },
    h2: {
      color: '#505358',
      fontFamily: AvenirMedium,
      fontSize: 24,
    },
    h3: {
      color: '#505358',
      fontFamily: AvenirRegular,
      fontSize: 20,
    },
    body1: {
      color: '#505358',
    },
    body2: {
      color: '#505358',
    },
  },
}
