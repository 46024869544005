export interface Faq {
  answer: string
  question: string
  sequenceNo: number
}

export interface FAQQueryModel {
  tenantId: string
  language: string
  TenantFaqType: TenantFaqType
  // skip: number | string
  // limit: number | string
}

export enum TenantFaqType {
  HELP = 0,
  REGISTRATION = 1,
}
