import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Dialog } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SafeLogo from '../../assets/SafeHealthLogo.png'
import { selectLanguageContent } from '../../features/translation'
import { isLoggedIn } from '../../libs/helpers'
import { lsClient } from '../../ls-client'
import { Content, useStyles, Wrapper } from '../../ui/templates/app-layout'
import {
  getUsersAvailableTenants,
  selectTenantList,
  setActiveTenant,
} from './tenantSlice'
import { SelectableTenant } from './types'

interface TenantSelectionDialogProps {
  open: boolean
  dialogOpen: (state: boolean) => void
}

export const TenantSelectionDialog = (props: TenantSelectionDialogProps) => {
  const { open, dialogOpen } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const childClasses = childStyles()
  const tenants = useSelector(selectTenantList)

  React.useEffect(() => {
    if (!tenants && isLoggedIn()) {
      dispatch(getUsersAvailableTenants())
    }
  }, [])

  const selectTenant = (tenant: string) => {
    lsClient.setUserLS('tenantId', tenant)
    dispatch(setActiveTenant(tenant))
    dialogOpen(false)
  }

  return (
    <Dialog open={open} fullScreen>
      <Wrapper>
        <Wrapper>
          <Content className={classes.contentWrapper}>
            <img
              src={SafeLogo}
              alt="Safe Health Systems Logo"
              className={childClasses.logoImage}
            />
            <Box style={{ width: '95%', marginTop: 20 }}>
              <Box className={childClasses.message}>
                {i18n.select_tenant_description_message}
              </Box>
              <Box className={classes.optionsWrapper}>
                {tenants?.map((t: SelectableTenant) => (
                  <Box
                    onClick={() => selectTenant(t._id)}
                    className={childClasses.tenantContainer}
                    key={t._id}
                    id={t._id}
                    aria-label={`Select Tenant ${t.title}`}
                  >
                    <img
                      src={t.logoURL ? t.logoURL : SafeLogo}
                      alt="Safe Health Systems Logo"
                      className={childClasses.tenantImage}
                    />
                    <Box className={childClasses.tenantName}>{t.title}</Box>
                    <ChevronRightIcon
                      color="primary"
                      style={{ fontSize: 32 }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Content>
        </Wrapper>
      </Wrapper>
    </Dialog>
  )
}

const childStyles = makeStyles((theme) => ({
  tenantName: {
    maxWidth: 235,
  },
  message: {
    margin: '60px auto',
    fontFamily: 'Avenir Next Rounded Medium',
    width: 320,
    fontSize: 20,
    color: '#7B7C7D',
    alignSelf: 'center',
    textAlign: 'center',
  },
  tenantContainer: {
    fontFamily: 'Avenir Next Rounded Regular',
    fontWeight: 'bold',
    color: '#525458',
    padding: '20px 15px',
    borderRadius: 10,
    display: 'flex',
    fontSize: 16,
    justifyContent: 'space-between',
    border: '1px solid #E6E7EA',
    margin: '0 auto 20px',
    maxWidth: 400,
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  logoImage: {
    paddingTop: 50,
    width: 150,
    [theme.breakpoints.down(450)]: {
      width: 130,
    },
  },
  tenantImage: {
    'display': '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    'width': 50,
    [theme.breakpoints.down(450)]: {
      width: 25,
    },
  },
}))
