import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { lsClient } from '../../ls-client'
import { createEncounterRecord, EncounterType } from '../encounter/model'
import {
  ConsultData,
  HealthService,
  HSDisposition,
  HSType,
  Service,
} from './types'

interface HealthServiceLite {
  healthServiceList: HealthService[]
  healthService: HealthService
  activeConsult: Service
  disposition: HSDisposition | null
  hasTelehealth: boolean
  isLoading: boolean
  healthServiceLoaded: boolean
  firstViewConsult: ConsultData | null
  treatmentPlan: any
}

const initialStore: HealthServiceLite = {
  healthServiceList: [],
  healthService: {
    id: '',
    userId: '',
    description: '',
    disposition: undefined,
    virtualConsults: [],
    connectedTests: [],
    telehealthConsults: [],
    prescriptions: [],
    lastUpdatedStep: null,
    status: undefined,
    updatedAt: '',
    createdAt: '',
  },
  activeConsult: {
    id: '',
    type: '',
    updatedAt: '',
    createdAt: '',
    discriminator: 'Unset',
  },
  disposition: null,
  hasTelehealth: false,
  isLoading: false,
  healthServiceLoaded: false,
  firstViewConsult: null,
  treatmentPlan: undefined,
}

export const healthServiceLiteSlice = createSlice({
  name: 'healthServiceLite',
  initialState: initialStore,
  reducers: {
    setHealthServiceList(store, { payload }: { payload: HealthService[] }) {
      store.healthServiceList = payload
    },
    setHealthService(store, { payload }: { payload: HealthService }) {
      store.healthService = payload
    },
    setActiveService(store, { payload }: { payload: Service }) {
      store.activeConsult = payload
    },
    setHSLoaded(store, { payload }: { payload: boolean }) {
      store.healthServiceLoaded = payload
    },
    setIsLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setNextStep(
      store,
      {
        payload,
      }: {
        payload: { disposition: HSDisposition | null; hasTelehealth: boolean }
      }
    ) {
      store.disposition = payload.disposition
      store.hasTelehealth = payload.hasTelehealth
    },
    setFVFConsult(store, { payload }: { payload: ConsultData }) {
      store.firstViewConsult = payload
    },
    setTreatmentPlan(store, { payload }: { payload: any }) {
      store.treatmentPlan = payload
    },
    resetHSStore: () => initialStore,
  },
})

const {
  setHealthServiceList,
  setHealthService,
  setActiveService,
  setIsLoading,
  setNextStep,
  resetHSStore,
  setFVFConsult,
} = healthServiceLiteSlice.actions

export const { setHSLoaded, setTreatmentPlan } = healthServiceLiteSlice.actions

export const getHealthServiceList =
  (userId: string, status: string, email: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    const { data, error } = await api.getHealthServiceList(
      userId,
      status,
      email
    )

    if (error) {
      dispatch(setIsLoading(false))
    }

    if (data) {
      if (data.length === 0) {
        dispatch(setHSLoaded(true))
        dispatch(setIsLoading(false))
      } else {
        dispatch(setHealthServiceList(data))
        dispatch(setHSLoaded(true))
        dispatch(setIsLoading(false))
      }
    }
  }

export const getNextStep =
  (serviceId: string, callback?: () => void) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    const { data, error } = await api.getNextStep(serviceId)

    if (error) {
      dispatch(setIsLoading(false))
      if (callback) callback()
    }

    if (data) {
      dispatch(setNextStep(data))
      dispatch(setIsLoading(false))
      if (callback) callback()
    }
  }

export const createHealthService =
  (params: any, onSuccess: (id: string) => void) => async (dispatch: any) => {
    const { data } = await api.createHealthService(params)

    if (data) {
      const workflowId =
        lsClient.getUserLSByKey('flowId') ||
        '4e0d3752-b7c1-4b94-8d71-e5b21bb21735'
      const workflowTitle =
        lsClient.getUserLSByKey('flowTitle') ||
        'Covid-19 Telehealth Consultation'
      const encounterParams = {
        tenantId: params.tenantId,
        accountId: params.accountId,
        member: {
          _id: params.UserId,
        },
        healthServiceId: data.id,
        workflowId,
        workflowTitle,
        type: EncounterType.SYNC_CONSULT_PHONE,
      }
      dispatch(setHealthService(data))
      dispatch(createEncounterRecord(encounterParams))
      onSuccess(data.id)
    }
  }

export const addHS =
  (serviceId: string, params: any, type: HSType, onSuccess?: () => void) =>
  async (dispatch: Dispatch) => {
    const { data } = await api.addHS(serviceId, params, type)

    if (data) {
      dispatch(setActiveService(data))
      if (onSuccess) onSuccess()
    }
  }

export const completeHealthService =
  (serviceId: string, params: any, onSuccess?: () => void) =>
  async (dispatch: Dispatch) => {
    const { data } = await api.completeHealthService(serviceId, params)

    if (data) {
      dispatch(resetHSStore())
      if (onSuccess) {
        onSuccess()
      }
    }
  }

export const createExternalConsult =
  (body: any, serviceId: string, onSuccess: () => void) =>
  async (dispatch: any) => {
    const { data } = await api.createFirstViewFinancialConsult(body)

    if (data) {
      const params = {
        id: data.consultId,
        type: 'Screening',
      }

      dispatch(addHS(serviceId, params, 'telehealthConsults'))
      onSuccess()
    }
  }

export const getExternalConsult =
  (serviceId: string) => async (dispatch: any) => {
    const { data } = await api.getFirstViewFinancialConsult(serviceId)

    if (data) {
      dispatch(setFVFConsult(data))
    }
  }

export const getTreatmentPlan =
  (consultId: string) => async (dispatch: any) => {
    const { data } = await api.getSAFETreatmentPlan(consultId)

    if (data) {
      dispatch(setFVFConsult(data))
    }
  }

export const confirmTreatmentPlan =
  (params: any, consultId: string, onSuccess: () => void) => async () => {
    const { data } = await api.confirmTreatmentPlan(params, consultId)

    if (data) {
      onSuccess()
    }
  }

export const declineTreatmentPlan =
  (params: any, consultId: string, onSuccess: () => void) =>
  async (dispatch: any) => {
    const { isOK } = await api.declineTreatmentPlan(params, consultId)
    const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
    const paramsForHS = {
      status: 'Completed',
    }
    if (isOK) {
      dispatch(completeHealthService(healthServiceId, paramsForHS, onSuccess))
    }
  }

export const selectHealthServiceList = ({ healthServiceLite }: RootStore) =>
  healthServiceLite.healthServiceList

export const selectHealthService = ({ healthServiceLite }: RootStore) =>
  healthServiceLite.healthService

export const selectActiveConsult = ({ healthServiceLite }: RootStore) =>
  healthServiceLite.activeConsult

export const selectHealthServiceLoaded = ({ healthServiceLite }: RootStore) =>
  healthServiceLite.healthServiceLoaded

export const selectDisposition = ({ healthServiceLite }: RootStore) =>
  healthServiceLite.disposition

export const selectFVFConsult = ({ healthServiceLite }: RootStore) =>
  healthServiceLite.firstViewConsult

export const selectFVFTreatmentPlan = ({ healthServiceLite }: RootStore) =>
  healthServiceLite.firstViewConsult

export const selectHealthServiceIsLoading = ({
  healthServiceLite,
}: RootStore) => healthServiceLite.isLoading

export const healthServiceLiteReducer = healthServiceLiteSlice.reducer

export const healthServiceLiteReducerName = healthServiceLiteSlice.name

interface RootStore {
  [healthServiceLiteReducerName]: typeof initialStore
}
