import * as Yup from 'yup'
import { I18n } from '../../../../../../features/translation/types'

const contactFormValidation = (i18n: I18n) =>
  Yup.object().shape({
    firstName: Yup.string().required(i18n.required),
    lastName: Yup.string().required(i18n.required),
    email: Yup.string()
      .email(i18n.invalid_email_error_message)
      .required(i18n.required),
    phone: Yup.string()
      .min(10, i18n.invalid_phone_error_message)
      .max(10, i18n.invalid_phone_error_message)
      .matches(/^(?!0{10})/, i18n.value_not_allowed)
      .matches(/^(?!0+[1-9])/, i18n.value_not_allowed)
      .required(i18n.required),
    relationship: Yup.string().required(i18n.required),
  })

export default contactFormValidation
