import { initialStore, termsReducerName } from './main'

interface RootStore {
  [termsReducerName]: typeof initialStore
}

export const selectHasDisagreedConsents = ({ terms }: RootStore) => {
  return terms.hasDisagreedConsents
}
export const selectTerms = ({ terms }: RootStore) => terms.terms
export const selectDocTerm = ({ terms }: RootStore) => terms.docTerm
export const selectAllLegalNotices = ({ terms }: RootStore) => terms.unfilteredTerms
export const selectDocLoading = ({ terms }: RootStore) => terms.docLoading
