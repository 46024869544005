import { Box, styled } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectNetworkSettings,
  selectNetworkSettingsLoading,
} from '../../../../pages/network/model'
import { getSignupType } from '../../../../pages/register/model/registerSlice'
import { OnboardingType } from '../../../../pages/register/model/type'
import { LogoImage } from '../../../../ui'

export const SignupHeader = () => {
  const networkSettings = useSelector(selectNetworkSettings)
  const networkLoading = useSelector(selectNetworkSettingsLoading)

  const signupType = useSelector(getSignupType)
  const isInvite = Boolean(
    signupType === OnboardingType.DEPENDENT ||
      signupType === OnboardingType.INVITE
  )

  if (isInvite) return <Box style={{ minHeight: 100 }} />

  return (
    <>
      {!networkSettings && networkLoading ? (
        <Box style={{ minHeight: 135 }} />
      ) : (
        <LogoWrapper>
          <LogoImage />
        </LogoWrapper>
      )}
    </>
  )
}

export const LogoWrapper = styled('div')(({ theme }) => ({
  padding: 48,
  [theme.breakpoints.down('xs')]: {
    padding: 32,
  },
}))
