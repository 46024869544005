export function convertArrayBufferToBlobUrl(
  arrayBuffer: File,
  mime = 'image/png'
) {
  let blobParts = []
  if (Array.isArray(arrayBuffer)) blobParts = arrayBuffer
  else blobParts.push(arrayBuffer)
  return URL.createObjectURL(new Blob(blobParts, { type: mime }))
}

export function convertBase64ToBlobUrl(b64Data: string, mime = 'image/png') {
  const sliceSize = 512
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: mime })
  return URL.createObjectURL(blob)
}