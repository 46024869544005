import React from 'react'
import ContentLoader from 'react-content-loader'
import { Theme, useMediaQuery } from '@mui/material'

export const FormSkeletonLoader = () => {
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const renderDesktop = () => (
        <>
            {/* Fields */}
            <rect x="0" y="40" rx="3" ry="3" width="calc(48%)" height="50" />
            <rect x="52%" y="40" rx="3" ry="3" width="calc(48%)" height="50" />
            <rect x="0" y="112" rx="3" ry="3" width="calc(48%)" height="50" />
            <rect x="52%" y="112" rx="3" ry="3" width="calc(48%)" height="50" />
            <rect x="0" y="184" rx="3" ry="3" width="calc(48%)" height="50" />

            {/*Button*/}
            <rect x="0" y="275" rx="8" ry="8" width="92" height="46" />
        </>
    )

    const renderMobile = () => (
        <>
            {/* Fields */}
            <rect x="0" y="40" rx="3" ry="3" width="100%" height="50" />
            <rect x="0" y="112" rx="3" ry="3" width="100%" height="50" />
            <rect x="0" y="184" rx="3" ry="3" width="100%" height="50" />
            <rect x="0" y="256" rx="3" ry="3" width="100%" height="50" />
            <rect x="0" y="328" rx="3" ry="3" width="100%" height="50" />

            {/*Button*/}
            <rect x="0" y="415" rx="8" ry="8" width="92" height="50" />
        </>
    )

    return (
        <ContentLoader
            height={!matches ? 338 : 500}
            width="100%"
            speed={0.8}
            viewBox="0 0 100% 100%"
        >
            {!matches ? renderDesktop() : renderMobile()}
        </ContentLoader>
    )
}
