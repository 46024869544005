import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Box, styled } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import {
  getHealthRecordList,
  getVaccinationList,
  selectDetialId,
  selectFetched,
  selectHealthRecord,
  selectStateLoading,
  setDetailId,
} from './model'
import { healthRecordTypeMap } from './page-mapping'
import { ContentWrapper } from './shared/components/shared-components'
import { HealthRecordProps, HealthRecordType } from './type'

export const HealthRecordDetailPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { type } = useParams<{ type: HealthRecordType }>()
  const canAddNew = type !== HealthRecordType.SOCIAL_HISTORY

  const i18n = useSelector(selectLanguageContent)
  const profileData = useSelector(selectProfileData)
  const dataFetched = useSelector(selectFetched)
  const listState = useSelector(selectHealthRecord)
  const isLoading = useSelector(selectStateLoading)
  const detailId = useSelector(selectDetialId)

  const handleback = () => {
    if (detailId) {
      dispatch(setDetailId(''))
    } else navigate(paths.healthRecordList())
  }

  useEffect(() => {
    if (type && profileData.nationalHealthId && !dataFetched[type]) {
      if (type === HealthRecordType.VACCINATIONS) {
        dispatch(getVaccinationList(profileData.nationalHealthId, type))
      } else dispatch(getHealthRecordList(profileData.nationalHealthId, type))
    }
  }, [type, profileData])

  const healthRecordProps = {
    i18n,
    state: {
      isLoading,
      listState,
      detailId,
    },
    type,
  } as HealthRecordProps

  const renderContent = () => {
    if (!type) return null
    const step = healthRecordTypeMap(i18n, healthRecordProps)[type]
    return <Box>{step?.content}</Box>
  }
  return (
    <HeaderWrapper>
      <ModuleHeader
        leftContentAction={handleback}
        leftContentTitle={i18n.back_button}
        // rightContentTitle={
        //   canAddNew ? healthRecordTypeMap(i18n)[type].addEditLabel : undefined
        // }
        // rightContentAction={canAddNew ? () => {} : undefined}
        // rightActionIcon={
        //   canAddNew ? (
        //     <AddRoundedIcon style={{ marginTop: 2 }} color="primary" />
        //   ) : null
        // }
        color="#505358"
      >
        <ContentWrapper>{renderContent()}</ContentWrapper>
      </ModuleHeader>
    </HeaderWrapper>
  )
}

export const HeaderWrapper = styled('div')(({ theme }) => ({
  'width': '100%',
  '& :nth-child(3)': {
    '& .hasAction > p': {
      color: theme.palette.primary.main,
    },
  },
}))
