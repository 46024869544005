import {
  Box,
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import React from 'react'
import { ErrorField } from '..'

export const FilledSelect = ({
  required,
  error,
  label,
  value,
  fullWidth,
  name,
  onChange,
  onBlur,
  helperText,
  options,
  disabled,
}: SelectProps &
  FormControlProps & {
    options: { value: string; label: string }[]
    helperText?: string
  }) => {
  const classes = useStyles()

  const getClasses = () => {
    let filledClass = `${classes.fieldContainer}`
    if (error) {
      filledClass += ` ${classes.inError}`
    }
    if (disabled) {
      filledClass += ` ${classes.disabled}`
    }

    return filledClass
  }

  return (
    <Box className={classes.fieldWrapper}>
      <Box className={getClasses()}>
        <FormControl required={required} error={error} className={classes.form}>
          <InputLabel className={classes.label}>{label}</InputLabel>
          <Select
            className={classes.selectField}
            value={value}
            fullWidth={fullWidth}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            variant="filled"
            disabled={disabled}
            disableUnderline
          >
            {options.map((option, index) => (
              <MenuItem key={`option-${index}`} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {error && helperText && <ErrorField error={helperText} />}
    </Box>
  )
}

const NO_BORDER_BOTTOM = '0px solid #FFF'

const useStyles = makeStyles((theme) => ({
  label: {
    'fontSize': '16px',
    'zIndex': 1,
    '&.MuiInputLabel-shrink': {
      'transform': 'translate(12px, 8px) scale(0.75)',
      'transform-origin': 'top left',
    },
  },
  form: {
    width: '100%',
  },
  selectField: {
    'background': '#FFF',
    '&.Mui-focused': {
      backgroundColor: '#FFF',
    },
    '&.MuiSelect-select': {
      '&:focus': {
        backgroundColor: '#FFF',
      },
    },
    '&.Mui-disabled': {
      backgroundColor: '#FFF',
    },
    '&:hover': {
      '&::before': { borderBottom: NO_BORDER_BOTTOM },
      'background': '#FFF',
    },
    '&::before': { borderBottom: NO_BORDER_BOTTOM },
    '&::after': { borderBottom: NO_BORDER_BOTTOM },
  },
  iconBtn: {
    'width': 12,
    'height': 12,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: 'rgb(0, 60, 114)',
    },
  },
  disabled: {
    borderStyle: 'dashed !important',
    borderColor: '#79747E !important',
  },
  fieldContainer: {
    padding: '1px',
    border: '1px solid #79747E',
    borderRadius: '4px',
  },
  error: {
    color: '#FF0000',
    fontSize: '12px',
    marginTop: '3px',
    marginLeft: '8px',
  },
  inError: {
    border: '1px solid',
    borderColor: theme.palette.error.main,
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}))
