import { createSlice } from '@reduxjs/toolkit'
import { NotificationData } from './types'

interface PushNotificationSlice {
  data: NotificationData
  showNotification: boolean
}
const initialStore: PushNotificationSlice = {
  data: {
    time_to_live: 30,
    notification: {
      title: null,
      body: null,
      icon: null,
      deep_link: null,
      hide_notification_if_site_has_focus: false,
    },
    data: {
      sessionId: null,
      providerName: null,
      eventDescrption: null,
      eventId: null,
    },
  },
  showNotification: false,
}

export const pushNotificationSlice = createSlice({
  name: 'pushNotification',
  initialState: initialStore,
  reducers: {
    setNotification(store, { payload }: { payload: any }) {
      store.data = payload
      store.showNotification = true
    },
    setShowNotification(store, { payload }: { payload: boolean }) {
      store.showNotification = payload
    },
    resetPushNotificationStore: () => initialStore,
  },
})
export const {
  setNotification,
  setShowNotification,
  resetPushNotificationStore,
} = pushNotificationSlice.actions

export const pushNotificationReducer = pushNotificationSlice.reducer
export const pushNotificationReducerName = pushNotificationSlice.name

interface RootStore {
  [pushNotificationReducerName]: typeof initialStore
}

export const selectNotification = ({ pushNotification }: RootStore) =>
  pushNotification.data

export const selectShowNotification = ({ pushNotification }: RootStore) =>
  pushNotification.showNotification
