import { createSlice } from '@reduxjs/toolkit'
import { getProfileData } from '../../../pages/profile/model'

const initialStore = {
  isDrawerOpen: false,
}

const application = createSlice({
  name: 'application',
  initialState: initialStore,
  reducers: {
    drawerOpened(store) {
      store.isDrawerOpen = true
    },
    drawerClosed(store) {
      store.isDrawerOpen = false
    },
  },
})

const { actions } = application
export const { drawerClosed, drawerOpened } = actions

export const applicationReducer = application.reducer
export const applicationReducerName = application.name

export const applicationMounted = () => (dispatch: any) => {
  dispatch(getProfileData())
}

interface RootStore {
  [applicationReducerName]: typeof initialStore
}
export const selectIsDrawerOpen = ({ application }: RootStore) =>
  application.isDrawerOpen
