export interface NetworkSettings {
  accountVerificationScreenCriteria: AccountVerificationSettings
  accountId: string
  addressOne: string
  addressTwo: string
  adminContactEmail: string
  adminContactFirstName: string
  adminContactLastName: string
  allowSkipableCollectionInstructions: boolean
  androidAppDownloadUrl: string
  appleAppDownloadUrl: string
  appName: string
  appThemeColor: string
  benefitSettings: BenefitSettings
  buttonStyle: 0 | 1
  bypassMLModel: boolean
  city: string
  collectAddress: boolean
  collectAddressRequired: boolean
  collectStreetAddress: boolean
  collectStreetAddressRequired: boolean
  collectCity: boolean
  collectCityRequired: boolean
  collectState: boolean
  collectStateRequired: boolean
  collectZip: boolean
  collectZipRequired: boolean
  collectDateOfBirth: boolean
  collectDateOfBirthRequired: boolean
  collectEthicity: boolean
  collectPhoneNumber: boolean
  collectPhoneNumberRequired: boolean
  collectRace: boolean
  collectSex: boolean
  collectSexRequired: boolean
  companyName: string
  country: string
  created: string
  dashboardCardConfiguration: DashboardCardConfig
  dashboardSettings: DashboardSettings
  disableSignUp: boolean
  displayUserMetadataOnCheckerResultScreen: boolean
  ehtnicityRequired: boolean
  emailLogo: string
  enableAccessCodes: boolean
  enableAccountVerificationScreen: boolean
  enableAdherenceAlerts: boolean
  enableAdherenceAlertsControl: boolean
  enableChooseATestScreen: boolean
  enableExternalBilling: boolean
  enableFaceIdAndTouchId: boolean
  enableHealthRecordModule: boolean
  enableImportHealthRecord: boolean
  enableProctorFeature: boolean
  enableProctorFeatureUI: boolean
  enableProfileSectionDashboard: boolean
  enableRegistrationFAQ: boolean
  enableSnackbar: boolean
  enableTwoFactorAuthentication: boolean
  enableVirtualConsult: boolean
  enableZendesk: boolean
  enabledLabVendorsList: LabVendor[]
  errorColor: string
  facebook: string
  favicon: string
  footerConfiguration: FooterConfigurationItems | null
  homeScreenBannerUrl: string
  homeScreenStyle: string
  importHealthRecordSettings: ImportHealthRecordSettings
  instagram: string
  isEmergencyContactInfoEnabled: boolean
  isPublicHealthReportingEnabled: boolean
  isSAFEPassportEnabled: boolean
  labOrderCollectInsuranceInformation: boolean
  labOrderCoverPatientResponsibility: boolean
  labOrderRequireSignature: boolean
  labOrderShowDedicatedAgreementScreen: boolean
  labSettings: LabSettings
  linkedin: string
  loginPopupImage: string
  logoRectangleDark: string
  logoRectangleLight: string
  logoSquareDark: string
  logoSquareLight: string
  medicContactCAQH: string
  medicContactEmail: string
  medicContactFax: string
  medicContactFirstName: string
  medicContactGender: string
  medicContactLastName: string
  medicContactMiddleName: string
  medicContactNPI: string
  medicContactOfficePhone: string
  medicContactSuffix: string
  medicContactTitle: string
  menuItems: MenuItems
  mfaSettings: MFASettings
  modified: string
  modifier: string
  name: string
  networkLogoInThemeColor: string
  onboardingCollectInsuranceInformation: boolean
  onboardingRequireSignature: boolean
  onboardingShowDedicatedAgreementScreen: boolean
  primaryColor: string
  primaryVideoID: string
  privacyEmail: string
  raceRequired: boolean
  reminderNotificationSettings: ReminderNotificationSettings
  reportingTimezone: string
  requireGuardianMode: boolean
  requireIdVerification: boolean
  secondaryColor: string
  shareStatus: boolean
  showClearanceStatusHomeScreen: boolean
  showContentNewsfeed: boolean
  showHomeScreenBackgroundColor: boolean
  showIdVerificationTab: boolean
  showLastAssessmentDate: boolean
  showMessenger: boolean
  showPatientRecordInAdminConsole: boolean
  showPreRegistrationWidget: boolean
  showQRCode: boolean
  showTestResultOutcome: boolean
  showVCAssessmentOutcome: boolean
  showWelcomeScreen: boolean
  state: string
  status: number
  successColor: string
  supportEmail: string
  supportPhone: string
  symptomsCheckInterval: number
  telehealthConfiguration: TelehealthOptions
  tenantDisplaySettings: boolean
  tenantID: string
  tenantPricingOrCommission: boolean
  tenantType: number
  timeoutDuration: number
  uploadProfileImage: boolean
  verifyEmail: boolean
  verifyEmailRequired: boolean
  verifyIdCard: boolean
  verifyIdCardRequired: boolean
  verifyPhone: boolean
  verifyPhoneRequired: boolean
  verifyVaccinationSettings: VerifyVaccinationSettings
  warningColor: string
  website: string
  welcomeScreenImage?: string
  welcomeScreenSettings: WelcomeScreenSettings
  zip: string
  _id: string
}

export interface DashboardCardConfig {
  dashboardCards: DashboardCard[]
}
interface BenefitSettings {
  benefitProvider: BenefitProviders
  employerBenefit: boolean
  exceedBenefit: boolean
  insuranceBenefit: boolean
  employerBenefitCardSettings: EmployerBenefitCard
}

interface MenuItems {
  enableTakeATest: boolean
}

interface VerifyVaccinationSettings {
  enableVaccinationAppleHealthKit: boolean
  enableVaccinationAttestation: boolean
  enableVaccinationConfirmWithProvider: boolean
  enableVaccinationImportCredentials: boolean
  enableVaccinationRecord: boolean
  enableVaccinationRecordControl: boolean
  enableVaccinationScanCredentials: boolean
  enableVaccinationSettingsControl: boolean
  enableVaccinationShare: boolean
  enableVaccinationViaPatientPortal: boolean
  vaccinationAppleHealthKitSubTitle: string
  vaccinationAppleHealthKitTitle: string
  vaccinationAttestationSubTitle: string
  vaccinationAttestationTitle: string
  vaccinationConfirmWithProviderSubTitle: string
  vaccinationConfirmWithProviderTitle: string
  vaccinationImportCredentialsSubTitle: string
  vaccinationImportCredentialsTitle: string
  vaccinationScanCredentialsSubTitle: string
  vaccinationScanCredentialsTitle: string
  vaccinationViaPatientPortalSubTitle: string
  vaccinationViaPatientPortalTitle: string
}

enum BenefitProviders {
  first_view = 'first_view',
}
interface AccountVerificationSettings {
  verificationField01: 0 | 1
  verificationField02: 0 | 1
  verificationField03: 0 | 1
}

export interface MFASettings {
  enableDeviceRemembering: boolean
  enableGoogleAuth: boolean
  enableOneTimePassword: boolean
  enableSMSVerification: boolean
  memberDays: number
}

interface FooterConfigurationItems {
  menuItem01: FooterConfigurationOptions
  menuItem02: FooterConfigurationOptions
  menuItem03: FooterConfigurationOptions
  menuItem04: FooterConfigurationOptions
}

export enum FooterConfigurationOptions {
  HOME = 'HOME',
  // CARE_PLANS = 'CARE_PLANS', //Not implemented
  DEPENDENTS = 'DEPENDENTS',
  ORDER = 'ORDER',
  CONTACT = 'CONTACT',
  HELP_CENTER = 'HELP_CENTER',
  // VACCINATIONS = 'VACCINATIONS', //Not implemented
  TEST_HISTORY = 'TEST_HISTORY',
  // ORDER_HISTORY = 'ORDER_HISTORY', //Not implemented
  RESOURCES = 'RESOURCES',
  SETTINGS = 'SETTINGS',
}

interface DashboardSettings {
  dashboardButtons: DashboardButton[]
  dashboardChartItems: ChartItem[]
  displayAIHistogramCharts: boolean
  enableTestOrders: boolean
  enableTestHistory: boolean
}

export enum TelehealthOptions {
  NONE = 'NONE',
  STEADY_MD = 'STEADY_MD',
  SAFE_TELEHEALTH = 'SAFE_TELEHEALTH',
  FIRST_VIEW_FINANCIAL = 'FIRST_VIEW_FINANCIAL',
}

interface ChartItem {
  dashboardChartId: string
  detail: {
    created: string
    creator: string
    enabledForAll: boolean
    isFilterable: boolean
    modified: string
    modifier: string
    mongoChartId: string
    name: string
    sequence: number
    _id: string
  }
  enabled: boolean
}

interface LabVendor {
  vendorId: string
  vendorKey: string
  vendorName: string
  labSettings: {
    accountId: string
    receivingApplication: string
    sendingApplication: string
    receivingFacility: string
    sendingFacility: string
  }
}

interface ImportHealthRecordSettings {
  importHealthRequireAgreementScreen: boolean
  importHealthRequireIDVerification: boolean
  importHealthRequireSignature: boolean
}

interface LabSettings {
  quest: any
}

interface ReminderNotificationSettings {
  maximumNumberOfSnooze: number
  notificationMessage: string
  notificationTime: string
  timeBetweenSnooze: number
}

interface WelcomeScreenSettings {
  welcomeScreenButton: DashboardButton
  welcomeScreenSkipButton: DashboardButton
}

export interface ButtonOption {
  title: string
  style: 0 | 1 | 2 | 3
  visible: boolean
  enabled: boolean
  action: ButtonAction
}

interface ButtonAction {
  _id: DashboardCardActionOptions
  value: string
  description: string
}

export interface DashboardButton extends ButtonOption {
  colors: [string | null, string | null]
  imageUrl: string
  cardTitle: string
  cardBody: string
}

export type ButtonStyle = 0 | 1 | 2

export enum ButtonType {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

export interface ButtonVariant {
  0: 'contained'
  1: 'outlined'
  2: 'text'
}

export interface DashboardCard {
  buttonAction: ButtonAction | null
  buttonColor: string
  buttonEnabled: boolean
  buttonLabel: string | null
  buttonLabelColor: string | null
  buttonStyle: 0 | 1 | 2
  cardBackgroundColor: string
  cardBody: string | null
  cardGradientColor: [string | null, string | null]
  cardImageUrl: string | null
  cardTitle: string | null
  carouselItems: CarouselItem[] | null
  enabled: boolean
  expirationEnabled: boolean
  expirationTimeDays: number | null
  redirectUrl: string | null
  workflowIds: string[]
  style: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11
}

export interface ButtonStyles {
  0: 'Solid'
  1: 'Outline'
  2: 'Gradient'
  3: 'Background Image'
  4: 'Standard Large'
  5: 'Standard Medium'
  6: 'Carousel'
  7: 'Carousel Large'
  8: 'Transparent'
  9: 'Benefit Limit - Solid'
  10: 'Passport'
}

export interface CarouselItem {
  itemButtonAction: ButtonAction
  itemImageUrl: string
  itemTitle: string
  link?: CarouselLink
  itemRedirectUrl?: string
  workflowIds?: string[]
}

interface CarouselLink {
  itemResourceId: string
  itemResourceLinkTitle: string
  type: number
}

export enum DashboardCardActionOptions {
  BenefitLimit = 'BenefitLimit',
  CarePlans = 'CarePlans',
  OrderTestKit = 'OrderTestKit',
  PurchaseHistory = 'PurchaseHistory',
  RedirectToMobileScreen = 'RedirectToMobileScreen',
  RedirectToUrl = 'RedirectToUrl',
  TestHistory = 'TestHistory',
  Vaccinations = 'Vaccinations',
  Workflow = 'Workflow',
}

export enum DashboardCardActionValues {
  BenefitLimit = 'BenefitLimit',
  CarePlans = 'CarePlans',
  GetTested = 'GetTested',
  HealthService = 'HealthService',
  OrderTestKit = 'OrderTestKit',
  PurchaseHistory = 'PurchaseHistory',
  RedirectToUrl = 'RedirectToUrl',
  Resources = 'Resources',
  SymptomChecker = 'SymptomChecker',
  TestHistory = 'TestHistory',
  Vaccinations = 'Vaccinations',
  Workflow = 'Workflow',
}

interface EmployerBenefitCard {
  backgroundColor: string
  description: string
  title: string
}
