import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import api from '../../../api'
import { parseJwt } from '../../../libs/utils'
import { selectIsLoggedIn } from '../../user/model/userTenantSlice'

export function ApplicationRefreshToken() {
  const refreshTimerId = useRef(0)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  useEffect(() => {
    function runRefreshJob() {
      const idToken = sessionStorage.getItem('id_token')
      if (!idToken || !isLoggedIn) return () => clearTimeout(refreshTimerId.current)

      const jwt = parseJwt(idToken)
      if (!jwt?.exp) return

      const tokenExpTime = jwt.exp * 1000 - 1000 * 60 * 3 // Token expiration time minus 3 min
      setTimeout(async () => {
        try {
          const result = await api.refreshIdToken()
          if (result.isOK && result.data && result.data.idToken) {
            sessionStorage.setItem('id_token', result.data.idToken as string)
            runRefreshJob()
          }
        } catch (error) {
          console.log(error)
        }
      }, tokenExpTime - Date.now())
    }

    runRefreshJob()
    return () => clearTimeout(refreshTimerId.current)
  }, [isLoggedIn])

  return null
}
