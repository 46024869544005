import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Theme,
  useMediaQuery,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { ReactChild, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { capitalizeString } from '../../libs/utils'
import { lsClient } from '../../ls-client'
import { PatientTest } from '../../types/patientTest'
import { Button, PageLoading, Typography } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import {
  collectionResultFields,
  multiPathogenResultFields,
  optionsFields,
  patientTestResultButtonOptions,
  TestDetailFields,
  testDetailFields,
  transformTestData,
} from './helpers/testDetailsMapper'
import {
  AddressField,
  ButtonField,
  CredentialHeaderField,
  DateField,
  Divider,
  LinkField,
  NextStepsField,
  PDFField,
  ResultField,
  ResultIcon,
  SummaryField,
  TestField,
  TestImageField,
  TextField,
  TitleAndDescField,
} from './helpers/testFields'
import {
  getOrderDetails,
  getOrderStatus,
  selectOrderDetails,
  selectTestDetailsLoading,
} from './model'

type StepType = 'steps' | 'details' | 'options' | 'options-invalid'

export const TestDetailsPage = () => {
  const { resultId } = useParams<{ resultId: string }>()
  const dispatch = useDispatch()
  const childClasses = childStyles()
  const navigate = useNavigate()
  const latestDetails = useSelector(selectOrderDetails)
  const i18n = useSelector(selectLanguageContent)
  const isLoading = useSelector(selectTestDetailsLoading)
  const patientTestId = lsClient.getUserLSByKey('patientTestId')
  const [testDetails, setTestDetails] = useState<any>({})
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  const isCollectionOutcome = resultId === patientTestId

  useEffect(() => {
    if (latestDetails) {
      setTestDetails(transformTestData(latestDetails))
    }
  }, [latestDetails])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (resultId === 'latest') {
      dispatch(getOrderStatus())
    } else dispatch(getOrderDetails(resultId || ''))
  }, [])

  if (isLoading) return <PageLoading size={100} />

  const handleBack = () => {
    if (resultId === 'latest') {
      navigate(paths.app.dashboard())
    } else navigate(paths.testResults())
  }

  const onExit = () => {
    lsClient.removeUserKeyLS('patientTestId')
    navigate(paths.app.dashboard())
  }

  const displayFields = (fields: TestDetailFields[], test: PatientTest) => {
    if (!fields) return
    const steps = [] as any
    fields.forEach((field: TestDetailFields, i: number) => {
      if (field.id && field.hideIfNoValue && !test[field.id]) return null
      if (field.type === 'text') {
        steps.push(
          <TextField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'test') {
        steps.push(
          <TestField
            key={`${i}-${field.type}`}
            test={testDetails}
            field={field}
          />
        )
      } else if (field.type === 'dateAndTime') {
        steps.push(
          <DateField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'result') {
        steps.push(
          <ResultField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'address') {
        steps.push(
          <AddressField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'link') {
        steps.push(
          <LinkField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'button') {
        steps.push(
          <ButtonField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'credential') {
        steps.push(
          <CredentialHeaderField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={testDetails}
          />
        )
      } else if (field.type === 'titleAndDescription') {
        steps.push(
          <TitleAndDescField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={testDetails}
          />
        )
      } else if (field.type === 'nextSteps') {
        steps.push(
          <NextStepsField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={testDetails}
          />
        )
      } else if (field.type === 'pdf') {
        steps.push(
          <PDFField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={testDetails}
          />
        )
      } else if (field.type === 'testImage') {
        steps.push(
          <TestImageField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={testDetails}
            isMobile={isMobile}
          />
        )
      } else if (field.type === 'summary') {
        steps.push(
          <SummaryField
            key={`${i}-${field.type}`}
            i18n={i18n}
            field={field}
            test={test}
          />
        )
      } else if (field.type === 'group') {
        steps.push(
          <Box
            className={childClasses.optionWrapper}
            key={`${i}-${field.type}`}
          >
            <Box className={childClasses.testDescription}>
              {displayFields(field.fields, test)}
            </Box>
          </Box>
        )
      } else if (field.type === 'splitGroup') {
        steps.push(
          <Box
            className={`${childClasses.paperless} halfWidth`}
            key={`${i}-${field.type}`}
          >
            {displayFields(field.fields, test)}
          </Box>
        )
      } else if (field.type === 'groupNoPaper') {
        steps.push(
          <Box className={childClasses.paperless} key={`${i}-${field.type}`}>
            {displayFields(field.fields, test)}
          </Box>
        )
      }
    })
    return steps
  }

  const getFields = () => {
    if (testDetails.status === 'COMPLETED') {
      return collectionResultFields[
        testDetails.testResultsSummary?.[0].patientTestResult
      ]?.fields
    }
    return testDetailFields[testDetails.status]?.fields
  }

  const getSteps = (type: StepType) => {
    if (type === 'steps') {
      return displayFields(
        getFields(),
        testDetails.testResultsSummary?.[0] || testDetails
      )
    }
    if (type === 'details') {
      return displayFields(
        optionsFields[testDetails.status]?.details,
        testDetails
      )
    }
    if (type === 'options-invalid' || testDetails.result === 'ERROR') {
      return displayFields(optionsFields.INVALID.options, testDetails)
    }
    return displayFields(
      optionsFields[testDetails.status]?.options,
      testDetails
    )
  }

  return (
    <ModuleHeader
      leftContentTitle={isCollectionOutcome ? undefined : i18n.back_button}
      leftContentAction={isCollectionOutcome ? undefined : handleBack}
      rightContentTitle={i18n.exit_button}
      rightContentAction={onExit}
      border={!isMobile}
      color="#505358"
    >
      <Box className={childClasses.innerWrapper}>
        <div className={childClasses.titleWrapper}>
          <Typography className={childClasses.pageTitle}>
            {i18n.test_results_screen_title}
          </Typography>
        </div>
        {testDetails?.testResultsSummary?.length > 1 ? (
          //Multi-Pathogen
          <>
            {testDetails.result !== 'INVALID' ? (
              <MultiPathogenValidResult
                testDetails={testDetails}
                isMobile={isMobile}
                i18n={i18n}
                displayFields={displayFields}
                details={getSteps('details')}
                options={getSteps('options')}
              />
            ) : (
              <SingleResult
                testDetails={testDetails}
                isMobile={isMobile}
                i18n={i18n}
                steps={getSteps('steps')}
                details={getSteps('details')}
                options={getSteps('options-invalid')}
              />
            )}
          </>
        ) : (
          //Rapid test
          <>
            {' '}
            {testDetails?.status && (
              <SingleResult
                testDetails={testDetails}
                isMobile={isMobile}
                i18n={i18n}
                steps={getSteps('steps')}
                details={getSteps('details')}
                options={getSteps('options')}
              />
            )}
          </>
        )}
        <Box className={childClasses.buttonWrapper}>
          {/* {isCollectionOutcome && testDetails.result !== 'INVALID' && (
            <Box className={childClasses.paperless}>
              {displayFields(
                patientTestResultButtonOptions.fields,
                testDetails
              )}
            </Box>
          )} */}
          <Button
            color="primary"
            onClick={onExit}
            className={childClasses.button}
          >
            {i18n.return_home}
          </Button>
        </Box>
      </Box>
    </ModuleHeader>
  )
}

const MultiPathogenValidResult = (props: {
  testDetails: any
  isMobile: boolean
  displayFields: (fields: any, testResult: any) => ReactChild
  details: ReactChild | undefined
  options: ReactChild | undefined
  i18n: I18n
}) => {
  const { testDetails, isMobile, details, options, i18n, displayFields } = props
  const classes = childStyles()
  return (
    <>
      {testDetails?.testResultsSummary?.map((testResult: any) => (
        <Accordion
          key={testResult.orderableTestName}
          TransitionProps={{ timeout: 250 }}
          elevation={0}
          variant="outlined"
          className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          >
            <div className={classes.multiPathResultWrapper}>
              <ResultIcon result={testResult.patientTestResult} />
              <div className={classes.outcomeWrapper}>
                <Typography className={classes.multiPathResult}>
                  {capitalizeString(testResult.patientTestResult)}
                </Typography>
                {!isMobile && (
                  <div
                    style={{
                      height: 32,
                      borderRight: 'solid 1px #E0E0E0',
                    }}
                  />
                )}
                <Typography className={classes.multiPathTitle}>
                  {testResult.orderableTestName}
                </Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {multiPathogenResultFields[testResult.patientTestResult] &&
              displayFields(
                multiPathogenResultFields[testResult.patientTestResult].fields,
                testResult
              )}
          </AccordionDetails>
        </Accordion>
      ))}
      {details && (
        <>
          <Typography className={`${classes.pageTitle} options`}>
            {i18n.test_result_more_details}
          </Typography>
          <Divider />
          {details}
        </>
      )}
      {options && (
        <>
          <Typography className={`${classes.pageTitle} options`}>
            {i18n.test_result_resources}
          </Typography>
          {options}
        </>
      )}
    </>
  )
}

const SingleResult = (props: {
  testDetails: any
  isMobile: boolean
  steps: ReactChild
  details: ReactChild | undefined
  options: ReactChild | undefined
  i18n: I18n
}) => {
  const { testDetails, isMobile, steps, details, options, i18n } = props
  const classes = childStyles()

  return (
    <>
      <Box className={classes.resultWrapper}>
        <Box className={classes.testDescription}>
          <div className={classes.multiPathResultWrapper}>
            <ResultIcon
              result={
                testDetails.result ? testDetails.result : testDetails.status
              }
            />
            <div className={classes.outcomeWrapper}>
              {testDetails.result ? (
                <Typography className={classes.multiPathResult}>
                  {capitalizeString(testDetails.result)}
                </Typography>
              ) : (
                <Typography className={classes.multiPathResult}>
                  {i18n.in_progress}
                </Typography>
              )}
              {!isMobile && (
                <div
                  style={{
                    height: 32,
                    borderRight: 'solid 1px #E0E0E0',
                  }}
                />
              )}
              <Typography className={classes.multiPathTitle}>
                {testDetails.testName}
              </Typography>
            </div>
          </div>
          <Divider />
          {steps}
        </Box>
      </Box>
      {details && (
        <>
          <Typography className={`${classes.pageTitle} options`}>
            {i18n.test_result_more_details}
          </Typography>
          <Divider style={{ marginTop: 8 }} />
          {details}
        </>
      )}
      {options && (
        <>
          <Typography className={`${classes.pageTitle} options`}>
            {i18n.test_result_resources}
          </Typography>
          {options}
        </>
      )}
    </>
  )
}
const SPACE_BETWEEN = 'space-between'

const childStyles = makeStyles((theme) => ({
  paperless: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'flex-start',
    'width': '100%',
    'cursor': 'pointer',
    '&.halfWidth': {
      flexWrap: 'wrap',
      margin: '16px 0px',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 24,
      [theme.breakpoints.down(600)]: {
        gap: 0,
        rowGap: 32,
      },
    },
  },
  titleWrapper: {
    margin: '78px 0px 32px',
    [theme.breakpoints.down(600)]: {
      margin: '24px 0px',
    },
  },
  pageTitle: {
    'fontSize': 36,
    'fontWeight': 600,
    'color': '#282D37',
    '&.options': {
      fontSize: 24,
    },
  },
  testDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  outcomeWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 0,
    },
  },
  optionWrapper: {
    minHeight: '44px',
    padding: '24px 16px',
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    borderTop: '1px solid #E6E7EA',
    borderBottom: '1px solid #E6E7EA',
    margin: '16px auto',
    alignItems: 'center',
    backgroundColor: 'white',
    [theme.breakpoints.down(600)]: {
      margin: '0px auto 16px',
    },
  },
  resultWrapper: {
    padding: 32,
    borderRadius: 8,
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    border: '1px solid #E6E7EA',
    margin: '16px auto',
    [theme.breakpoints.down(600)]: {
      padding: 16,
    },
  },
  innerWrapper: {
    alignItems: 'center',
    maxWidth: '800px',
    width: '100%',
    margin: '0px auto',
    [theme.breakpoints.down(960)]: {
      width: '90%',
    },
  },
  accordion: {
    'borderRadius': '8px',
    'marginBottom': 24,
    '&::before': {
      opacity: 0,
    },
    '&.MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },
    [theme.breakpoints.down(600)]: {
      marginBottom: 16,
    },
  },
  details: {
    borderTop: '1px solid #E0E0E0',
    margin: '0px 10px',
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    alignItems: 'flex-start',
    padding: '8px 0px 16px',
    [theme.breakpoints.down(320)]: {
      '& p': {
        maxWidth: '85vw',
      },
    },
  },
  expandIcon: {
    [theme.breakpoints.up(600)]: {
      marginRight: 32,
    },
  },
  multiPathResultWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    padding: 24,
    [theme.breakpoints.down(600)]: {
      padding: 8,
    },
  },
  multiPathTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#757575',
  },
  multiPathResult: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#505358',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    margin: '16px 0px 80px',
    width: '100%',
    maxHeight: '250px',
    zIndex: 10,
    [theme.breakpoints.down(600)]: {
      alignItems: 'center',
      width: '100%',
      margin: '0 auto',
      padding: '20px 0px',
    },
    [theme.breakpoints.down(320)]: {
      padding: '0px 0px 16px',
    },
  },
  button: {
    width: 'fit-content',
    padding: '8px 48px',
    [theme.breakpoints.down(600)]: {
      width: '100%',
      padding: '8px 0px',
    },
  },
}))
