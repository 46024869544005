import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { HTMLTextComponent, PageTitle } from '../../libs/shared-components'
import { lsClient } from '../../ls-client'
import { Loading, SearchField } from '../../ui'
import { paths } from '../paths'
import { getHelpCenter, selectFAQsList, selectFAQsLoading } from './faqSlice'
import { faqStyles } from './faqStyles'
import { TenantFaqType } from './types'

export const FaqList = (props: { tenantId: string; type: TenantFaqType }) => {
  const { tenantId, type } = props
  const classes = faqStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)
  const isLoading = useSelector(selectFAQsLoading)
  const faqsList = useSelector(selectFAQsList)
  const [searchInputValue, setSearchInputValue] = useState('')
  const [filteredList, setFilteredList] = useState(faqsList)
  const [isExpanded, setIsExpanded] = useState('')
  const { isMobile } = useMUITheme()

  useEffect(() => {
    if (!tenantId) return
    const payload = {
      tenantId,
      language: 'en',
      TenantFaqType: type,
    }
    dispatch(getHelpCenter(payload))
  }, [tenantId])

  useEffect(() => {
    setFilteredList(faqsList)
  }, [faqsList])

  const expandFAQ = (id: string) => {
    if (!id || id === isExpanded) {
      setIsExpanded('')
    } else setIsExpanded(id)
  }

  const noFAQs = () => (
    <Typography color="primary" align="center" className={classes.noContent}>
      {i18n.no_result_found}
    </Typography>
  )
  const clearSearch = () => {
    setFilteredList(faqsList)
    setSearchInputValue('')
  }

  const handleSearch = (e: any) => {
    if (!e.target.value) setFilteredList(faqsList)
    setSearchInputValue(e.target.value)
    if (e.target.value.length > 2) {
      const filtered = faqsList.filter((f) =>
        f.question.toLowerCase().includes(e.target.value.toLowerCase())
      )
      setFilteredList(filtered)
    }
  }

  const openSupport = () => {
    lsClient.navigation.setBackPath('/faqs')
    navigate(paths.contactSupport())
  }

  return (
    <div className={classes.faqList}>
      <div className={classes.titleWrapper}>
        <PageTitle style={{ marginBottom: 0 }}>
          {i18n.help_center_screen_title}
        </PageTitle>
        {!isMobile && (
          <Link
            role="button"
            color="primary"
            className={classes.pageLink}
            onClick={() => openSupport()}
          >
            {i18n.feedback_screen_title}
          </Link>
        )}
      </div>
      <Box className={classes.searchContainer}>
        <SearchField
          type="text"
          handleChange={handleSearch}
          placeHolderText={i18n.search_help_center}
          searchValue={searchInputValue}
          clearSearchValue={clearSearch}
        />
      </Box>
      {isLoading ? (
        <Box className={classes.loading}>
          <Loading />
        </Box>
      ) : (
        <div className={classes.newHelpCenter}>
          {filteredList.length > 0 ? (
            filteredList.map((faq, i) => (
              <div className={classes.listWrapper}>
                <Accordion
                  key={`${faq.question}-${i}`}
                  onClick={() => expandFAQ(faq.question)}
                  TransitionProps={{ timeout: 250 }}
                  elevation={0}
                  className={classes.root}
                  expanded={faq.question === isExpanded}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.faqDetailTitle}>
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.faqDetailDesription}>
                      <HTMLTextComponent HTMLString={faq.answer} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
          ) : (
            <>{noFAQs()}</>
          )}
        </div>
      )}
      {isMobile && (
        <div className={classes.mobileContactWrapper}>
          <div style={{ width: 'fit-content', margin: '0px auto' }}>
            <Link
              role="button"
              color="primary"
              className={classes.pageLink}
              onClick={() => openSupport()}
            >
              {i18n.feedback_screen_title}
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}
