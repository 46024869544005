export interface Term {
  actions: []
  agreement: boolean
  confirmationConsentBtnLabel: 'OK'
  document: boolean
  empty: boolean
  key: string
  needConsent: boolean
  subtype: null
  title: string
  value: string
}

export enum HasDisagreedConsents {
  UNKNOWN,
  TRUE,
  FALSE,
}
