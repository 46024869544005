import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextField } from '../../../ui'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  setCanContiune,
  updateAnswer,
} from '../questionnaireV2Slice'

export const LargeTextQuestion = () => {
  const dispatch = useDispatch()
  const question = useSelector(selectCurrentQuestion)
  const existingAnswer = useSelector(selectExistingAnswer)
  const [value, setValue] = useState('')

  useEffect(() => {
    if (existingAnswer) {
      setValue(existingAnswer.value)
      dispatch(updateAnswer(existingAnswer.value))
    }
  }, [question, existingAnswer])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateAnswer(e.target.value))
    setValue(e.target.value)
    if (!e.target.value) {
      dispatch(setCanContiune(false))
    }
  }
  return (
    <TextField
      isMultiline
      onChange={handleChange}
      value={value}
      color="secondary"
    />
  )
}
