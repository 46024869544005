import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { XLResponsiveContentWrapper } from '../../libs/shared-components'
import { lsClient } from '../../ls-client'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import { FaqList } from './faq-list'
import { TenantFaqType } from './types'

export const FaqPage = () => {
  const i18n = useSelector(selectLanguageContent)
  const navigate = useNavigate()
  const networkSettings = useSelector(selectNetworkSettings)
  const [isDetail, setIsDetail] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const onBack = () => {
    const backpath = lsClient.navigation.getBackPath()
    if (isDetail) {
      navigate(paths.faqs())
      setIsDetail(false)
    } else if (backpath) {
      lsClient.navigation.removeBackPath()
      navigate(backpath)
    } else {
      navigate(paths.app.dashboard())
    }
  }

  return (
    <ModuleHeader
      leftContentAction={onBack}
      leftContentTitle={i18n.back_button}
      color="#505358"
    >
      <XLResponsiveContentWrapper>
        <FaqList
          tenantId={networkSettings?.tenantID || ''}
          type={TenantFaqType.HELP}
        />
      </XLResponsiveContentWrapper>
    </ModuleHeader>
  )
}
