import moment from 'moment'
import * as Yup from 'yup'
import { I18n } from '../../../../../../features/translation/types'
import { NetworkSettings } from '../../../../../network/type'

const validationSchema = (
  i18n: I18n,
  currentNetwork: NetworkSettings | null
) => {
  if (!currentNetwork) return

  const {
    collectDateOfBirth,
    collectDateOfBirthRequired,
    collectSex,
    collectSexRequired,
    collectRace,
    raceRequired,
    collectEthicity,
    ehtnicityRequired,
  } = currentNetwork

  const firstName = Yup.string().required(i18n.required)
  const lastName = Yup.string().required(i18n.required)
  const email = Yup.string()
    .email(i18n.invalid_email_error_message)
    .required(i18n.required)

  const dob =
    collectDateOfBirth && collectDateOfBirthRequired
      ? Yup.date()
          .nullable()
          .typeError(i18n.invalid_date)
          .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
          .test({
            name: 'furture-date',
            message: 'Date of birth cannot be a date in the future.',
            test: (value) => {
              if (!value) return true
              return new Date() > new Date(value)
            },
          })
          .max(
            moment().utc().subtract(18, 'years').local().endOf('day').toDate(),
            'Users must be at least 18 years old.'
          )
          .required(i18n.required)
      : Yup.date()
          .nullable()
          .min(moment().subtract(150, 'years').toDate(), i18n.invalid_date)
          .typeError(i18n.invalid_date)
          .test({
            name: 'furture-date',
            message: 'Date of birth cannot be a date in the future.',
            test: (value) => {
              if (!value) return true
              return new Date() > new Date(value)
            },
          })
          .max(
            moment().utc().subtract(18, 'years').local().endOf('day').toDate(),
            'Users must be at least 18 years old.'
          )

  const genderValidation = Yup.string()
  const gender =
    collectSex && collectSexRequired
      ? genderValidation.required(i18n.required)
      : genderValidation

  const raceValidation = Yup.string()
  const race =
    collectRace && raceRequired
      ? raceValidation.required(i18n.required)
      : raceValidation

  const ethnicityValidation = Yup.string()
  const ethnicity =
    collectEthicity && ehtnicityRequired
      ? ethnicityValidation.required(i18n.required)
      : ethnicityValidation

  return Yup.object().shape({
    firstName,
    lastName,
    email,
    dob,
    gender,
    race,
    ethnicity,
  })
}

export default validationSchema
