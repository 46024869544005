import * as cells from './outcomeCells'

export const dispositionScreens = {
  'Screen 1': {
    title: 'COVID-19 negative',
    body: 'You did not test positive for COVID-19, are not currently displaying symptoms, and therefore do not qualify for further clinical assessment today. However, based on your responses you are still at high-risk for complications of COVID-19. Be sure to be informed, prepared, and ready to act should symptoms emerge later, or you test positive. If you start to develop symptoms, please select the "Get Covid Care" button from the dashboard.',
    image: cells.AnimatedCheckMark,
  },
  'Screen 2': {
    title: 'COVID-19 positive',
    body: 'You tested positive for COVID-19, but are not currently displaying symptoms, and therefore do not qualify for further clinical assessment today. Please follow local guidelines for isolation procedures to help prevent the spread of COVID-19. If you start to develop symptoms, please select the "Get Covid Care" button from the dashboard.',
    image: cells.AnimatedCheckMark,
  },
  'Screen 3': {
    title: 'COVID-19 negative',
    body: 'Because you did not test positive for COVID-19, and are not currently displaying symptoms, you do not qualify for further clinical assessment today. If you start to develop symptoms, please select the "Get Covid Care" button from the dashboard.',
    image: cells.AnimatedCheckMark,
  },
  'Screen 4': {
    title: 'COVID-19 positive',
    body: 'You tested positive for COVID-19 and are currently displaying symptoms. Although you do not qualify for further clinical assessment from this app, we suggest contacting your primary care provider to receive the appropriate COVID-19 care that you need. Please follow local guidelines for isolation procedures to help prevent the spread of COVID-19. We hope that you feel better soon!',
    image: cells.AnimatedCheckMark,
  },
  'Screen 5': {
    title: 'COVID-19 negative',
    body: 'You tested negative for COVID-19, and therefore do not qualify for further clinical assessment from this app. However, because you are displaying symptoms, we suggest contacting your primary care provider to receive the care that you need. We hope that you feel better soon!',
    image: cells.AnimatedCheckMark,
  },
  'Screen 6': {
    title: 'COVID-19 positive',
    body: 'You tested positive for COVID-19 but are not displaying symptoms, and therefore do not qualify for further clinical assessment from this app. We suggest contacting your primary care provider to receive the appropriate COVID-19 care that you need. Please follow local guidelines for isolation procedures to help prevent the spread of COVID-19. We hope that you feel better soon!',
    image: cells.AnimatedCheckMark,
  },
  'Screen 7': {
    title: 'COVID-19 negative',
    body: 'Because you did not test positive for COVID-19, and are not currently displaying symptoms, you do not qualify for further clinical assessment today. If you start to develop symptoms, please contact your primary care provider to receive appropriate care.',
    image: cells.AnimatedCheckMark,
  },
  'Seek Medical Attention': {
    title: 'Seek Medical Attention',
    body: 'Your responses indicate that you may have concerning symptoms. Given that these could pose a risk to your health, we strongly recommend that you seek in-person care immediately from your primary care clinician, nearest urgent care, or emergency room.',
    image: cells.AlertCell,
  },
  'Confirm Location/Phone': {
    title: 'Almost Done!',
    body: 'Let’s confirm that we have your preferred phone number and location.',
    image: cells.Empty,
  },
  'Telehealth Visit': {
    title: 'Almost Done!',
    body: 'Let’s confirm that we have your preferred phone number and location.',
    image: cells.Empty,
  },
  'More Testing Needed': {
    title: 'More Testing Needed',
    body: 'Your responses indicate that you may need more testing.',
    image: cells.AlertCell,
  },
}
