import { Box, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  FirstPageRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  LastPageRounded,
} from '@mui/icons-material'
import { PaginationProps } from '@mui/lab'
import React from 'react'
import { useDispatch } from 'react-redux'
import { CustomSelect } from '../../../../atoms/select'
import { calculateStatus } from './helpers'
import { PaginationAction } from './type'

export const CustomPagination = (
  props: PaginationProps & {
    page: number
    rowsPerPage: number
    count: number
    rowsPerPageOptions: number[]
    onChangePage: (e: number) => void
    onChangeRowsPerPage: (event: React.ChangeEvent<any>) => void
  }
) => {
  const classes = useStyles()
  const {
    showFirstButton,
    showLastButton,
    count,
    rowsPerPage,
    rowsPerPageOptions = [10, 25, 50],
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = props

  const dispatch = useDispatch()

  const pageCount = Math.ceil(count / rowsPerPage) - 1 || 0

  const pageSizeOptions = rowsPerPageOptions.map((v) => ({
    label: v.toString(),
    value: v,
  }))

  const firstPageButton =
    showFirstButton &&
    dispatch(
      createPageButton({
        type: PaginationAction.FIRST_PAGE,
        page,
        pageCount,
        onChangePage,
      })
    )

  const prevPageButton = dispatch(
    createPageButton({
      type: PaginationAction.PREV_PAGE,
      page,
      pageCount,
      onChangePage,
    })
  )

  const nextPageButton = dispatch(
    createPageButton({
      type: PaginationAction.NEXT_PAGE,
      page,
      pageCount,
      onChangePage,
    })
  )

  const lastPageButton =
    showLastButton &&
    dispatch(
      createPageButton({
        type: PaginationAction.LAST_PAGE,
        page,
        pageCount,
        onChangePage,
      })
    )

  const pageSizeSelect = (
    <Box className={classes.lineWithGap}>
      <Typography className={classes.paginationText}>Rows per page:</Typography>
      <CustomSelect
        className={classes.styledSelect}
        value={rowsPerPage}
        options={pageSizeOptions}
        onChange={(e: any) => onChangeRowsPerPage(e)}
        variant="outlined"
      />
    </Box>
  )

  const paginationButtons = (
    <Box className={classes.lineWithGap}>
      <>
        {firstPageButton}
        {prevPageButton}
        {nextPageButton}
        {lastPageButton}
      </>
    </Box>
  )

  if (!count) return null

  return (
    <Box display="flex" alignItems="center" gap="25px">
      {pageSizeSelect}
      <Typography className={classes.paginationText}>
        {calculateStatus({ page, rowsPerPage, count })}
      </Typography>
      {paginationButtons}
    </Box>
  );
}

const createPageButton =
  (config: {
    type: PaginationAction
    page: number
    pageCount: number
    onChangePage: (e: number) => void
  }) =>
  () => {
    const { type, page, pageCount, onChangePage } = config

    let updatedPage = page
    let disabled = false
    let icon = null

    const isNextDisabled = page === pageCount
    const isPrevDisabled = !page

    switch (type) {
      case PaginationAction.FIRST_PAGE:
        updatedPage = 0
        disabled = isPrevDisabled
        icon = <FirstPageRounded />
        break
      case PaginationAction.PREV_PAGE:
        updatedPage -= 1
        disabled = isPrevDisabled
        icon = <KeyboardArrowLeftRounded />
        break
      case PaginationAction.NEXT_PAGE:
        updatedPage += 1
        disabled = isNextDisabled
        icon = <KeyboardArrowRightRounded />
        break
      case PaginationAction.LAST_PAGE:
        updatedPage = pageCount
        disabled = isNextDisabled
        icon = <LastPageRounded />
        break
      default:
        return
    }

    return (
      <IconButton
        size="small"
        disabled={disabled}
        onClick={() => onChangePage(updatedPage)}
      >
        {icon}
      </IconButton>
    )
  }

const useStyles = makeStyles({
  styledSelect: {
    '& > div': {
      padding: '5px 0 5px 10px',
    },
  },
  lineWithGap: {
    display: 'flex',
    gridGap: '7px',
  },
  paginationText: {
    fontSize: 14,
    lineHeight: '28px',
  },
})
