import { Badge, IconButton, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import React from 'react'
import { Link } from 'react-router-dom'
import { paths } from '../../../pages/paths'

export const MessengerIconButton = ({ light }: { light?: boolean }) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <IconButton
      color="inherit"
      component={Link}
      to={paths.app.systemMessages()}
      size="large">
      <Badge
        overlap="rectangular"
        classes={{ anchorOriginTopRightRectangular: classes.badgeContent }}
      >
        <NotificationsNoneIcon />
        {/* <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <title>icon/color/message</title>
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="icon/color/message"
              fill={light ? '#FFF' : theme.palette.primary.main}
            >
              <path
                d="M12,1 C18.074908,1 23,5.92015801 23,11.9899819 C23,17.9452854 18.2522852,22.8125293 12.2936152,22.9760319 L12.2936152,22.9760319 L12.207,22.977 L12.1166211,22.9932723 L12,23 L2,23 C1.10885783,23 0.662798593,21.9223678 1.29324132,21.2925453 L1.29324132,21.2925453 L3.555,19.033 L3.34781639,18.7775231 C1.9113848,16.9530302 1.0780023,14.7086018 1.00520809,12.3305776 L1.00520809,12.3305776 L1,11.9899819 C1,5.92015801 5.925092,1 12,1 Z M12,3 C7.029213,3 3,7.0251765 3,11.9899819 C3,14.4118466 3.9618376,16.6790417 5.64469813,18.3554968 C6.03663024,18.7459371 6.037079,19.3804109 5.64569961,19.7714053 L5.64569961,19.7714053 L4.415,21 L11.807,21 L11.8708658,20.9882457 L11.9862897,20.9800578 L12.2524599,20.9765026 C17.1157565,20.8429794 21,16.8609457 21,11.9899819 C21,7.0251765 16.970787,3 12,3 Z M14.6923077,10.4615385 C15.3295593,10.4615385 15.8461538,10.978133 15.8461538,11.6153846 C15.8461538,12.2526362 15.3295593,12.7692308 14.6923077,12.7692308 C14.0550561,12.7692308 13.5384615,12.2526362 13.5384615,11.6153846 C13.5384615,10.978133 14.0550561,10.4615385 14.6923077,10.4615385 Z M11.6153846,10.4615385 C12.2526362,10.4615385 12.7692308,10.978133 12.7692308,11.6153846 C12.7692308,12.2526362 12.2526362,12.7692308 11.6153846,12.7692308 C10.978133,12.7692308 10.4615385,12.2526362 10.4615385,11.6153846 C10.4615385,10.978133 10.978133,10.4615385 11.6153846,10.4615385 Z M8.53846154,10.4615385 C9.17571317,10.4615385 9.69230769,10.978133 9.69230769,11.6153846 C9.69230769,12.2526362 9.17571317,12.7692308 8.53846154,12.7692308 C7.9012099,12.7692308 7.38461538,12.2526362 7.38461538,11.6153846 C7.38461538,10.978133 7.9012099,10.4615385 8.53846154,10.4615385 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </svg> */}
      </Badge>
    </IconButton>
  );
}

const useStyles = makeStyles({
  badgeContent: {
    backgroundColor: '#003C72',
    color: 'white',
  },
})
