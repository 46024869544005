export interface FedExLocation {
  name: string
  latitude: string
  longitude: string
  latitudeLongitudeDistance: number
  normalHours: FedExLocationHours[]
  imageUrl: string
  distance: FedExDistance
  currentDayofWeek: FedExDays
  isDropOffAvailable: boolean
  isOpenNow: boolean
  address1: string[]
  city: string
  state: string
  zip: string
  website: string
  phone: string
}

export interface FedExLocationHours {
  dayOfWeek: FedExDays
  operationalHours: 'OPEN_BY_HOURS' | 'CLOSED_ALL_DAY'
  hours: FedExHours[] | null
  operationalHoursSpecified: boolean
  dayOfWeekSpecified: boolean
}

interface FedExDistance {
  unit: 'MI' | 'KM' //?
  unitSpecified: boolean
  value: number
  valueSpecified: boolean
}

interface FedExHours {
  begins: string
  beginsSpecified: boolean
  ends: string
  endsSpecified: boolean
}

type FedExDays = 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN'

export enum FedExDaysOfWeekUIConversion {
  MON = 'Monday',
  TUE = 'Tuesday',
  WED = 'Wednesday',
  THU = 'Thursday',
  FRI = 'Friday',
  SAT = 'Saturday',
  SUN = 'Sunday',
}

export interface FedExAddress {
  line1?: string
  line2?: string
  city?: string
  state?: string
  zip: string
  country?: string
  latitude?: string
  longitude?: string
}
