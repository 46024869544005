// Note: This can be changed in the future if ESM-generated code is provided
//       by the SafeEcommerceSDK. The current setup uses the UMD version of the SDK.
const SafeEcommerceSDK = self.SafeEcommerceSDK

// Note: We can add more exports here as needed

// hey copilot, please rearrange these exports in alphabetical order
export const AddressModel = SafeEcommerceSDK.com.safeecommerce.models.others.AddressModel
export const UpdateShippingAddressParameters =
  SafeEcommerceSDK.com.safeecommerce.models.parameters
    .UpdateShippingAddressParameters
export const AddToCartParameters = SafeEcommerceSDK.com.safeecommerce.models.parameters.AddToCartParameters
export const AdjustCartLineQuantityParameters = SafeEcommerceSDK.com.safeecommerce.models.parameters.AdjustCartLineQuantityParameters
export const AdjustProductQuantityParameters = SafeEcommerceSDK.com.safeecommerce.models.parameters.AdjustProductQuantityParameters
export const AmountModel = SafeEcommerceSDK.com.safeecommerce.models.others.AmountModel
export const APIConfiguration = SafeEcommerceSDK.com.safeecommerce.models.configurations.APIConfiguration
export const EcommerceStringsHardcodedDefault = SafeEcommerceSDK.com.safeecommerce.outercore.EcommerceStringsHardcodedDefault
export const EcommerceUIConfiguration = SafeEcommerceSDK.com.safeecommerce.models.configurations.EcommerceUIConfiguration
export const IDTokenProcessorJSExposed = SafeEcommerceSDK.com.safeecommerce.IDTokenProcessorJSExposed
export const LoadProductDetailsParameters = SafeEcommerceSDK.com.safeecommerce.models.parameters.LoadProductDetailsParameters
export const PaymentMethod = SafeEcommerceSDK.com.safeecommerce.models.others.PaymentMethod
export const PollForCheckoutParameters = SafeEcommerceSDK.com.safeecommerce.models.parameters.PollForCheckoutParameters
export const ReachabilityCheckerJSExposed = SafeEcommerceSDK.com.safeecommerce.ReachabilityCheckerJSExposed
export const SafeEcommerceConfiguration = SafeEcommerceSDK.com.safeecommerce.models.configurations.SafeEcommerceConfiguration
export const SafeEcommerceSDKJS = SafeEcommerceSDK.com.safeecommerce.SafeEcommerceSDKJS
export const EcommerceCollectionType = SafeEcommerceSDK.com.safeecommerce.models.others.EcommerceCollectionType
export const ShippingDescription = SafeEcommerceSDK.com.safeecommerce.internal.api.models.ShippingDescription
export const TipOptionModel = SafeEcommerceSDK.com.safeecommerce.models.others.TipOptionModel
export const EcommerceStrings = SafeEcommerceSDK.com.safeecommerce.outercore.EcommerceStrings
export const EcommerceServiceType = SafeEcommerceSDK.com.safeecommerce.models.EcommerceServiceType
export const GetPurchaseHistoryParameters = SafeEcommerceSDK.com.safeecommerce.models.parameters.GetPurchaseHistoryParameters

export class SHEcommerceCollectionType {
  static testKits() {
      return EcommerceCollectionType.Companion.ofDynamic("test_kits")
  }
  static welcomeKits() {
      return EcommerceCollectionType.Companion.ofDynamic("welcome_kits")
  }
  static telehealthSync() {
      return EcommerceCollectionType.Companion.ofDynamic("telehealth_sync")
  }
  static telehealthAsync() {
      return EcommerceCollectionType.Companion.ofDynamic("telehealth_async")
  }
  static ofDynamic(key) {
      return EcommerceCollectionType.Companion.ofDynamic(key);
  }
}

export class UserServiceAbstract {
    getSelectedTenant() {
        // Returns a string
        throw new Error("getSelectedTenant() not implemented")
    }
    getUserData() {
        // Returns an object with the following properties: email
        throw new Error("getUserData() not implemented")
    }
    getSettings() {
        // Returns an object
        throw new Error("getSettings() not implemented")
    }
}

export class AuthServiceAbstract {
    getIDToken() {
        // Returns a string
        throw new Error("getIDToken() not implemented")
    }
}
