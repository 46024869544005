/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box } from '@mui/material'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { lsClient } from '../../ls-client'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { getNetworkSettings, selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import { selectCurrentStep, setActive } from '../register-a-test-module/model'
import { ChooseLocation, ReturnToLabStart } from './steps'
import { ReturnToLabProps, ReturnToLabStepType } from './types'

export const ReturnToLab = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const networkSettings = useSelector(selectNetworkSettings)
  const i18n = useSelector(selectLanguageContent)
  const currentStep = useSelector(selectCurrentStep)
  const tenantId = localStorage.getItem('tenantId') || ''
  const { type } = useParams<{ type: ReturnToLabStepType }>()

  useEffect(() => {
    if (tenantId && !networkSettings) {
      dispatch(getNetworkSettings(tenantId))
    }
  }, [networkSettings, tenantId])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const hasTestId = lsClient.getUserLSByKey('testId')
    // const hasScannedCode = lsClient.getUserLSByKey('kitId')
    // const isInTestFlow = lsClient.getUserLSByKey('step')

    // if (isInTestFlow) {
    //   navigate(paths.instructions())
    // }
    // if (hasTestId) {
    //   if (hasScannedCode) {
    //     dispatch(setActive(3))
    //   } else dispatch(setActive(2))
    // }
  }, [])

  const onExit = () => {
    navigate(paths.app.dashboard())
  }

  function handleNext() {
    dispatch(setActive(currentStep + 1))
  }

  function handleBack() {
    dispatch(setActive(currentStep - 1))
  }

  const stepProps = {
    handleNext,
    handleBack,
    i18n,
    networkSettings,
  } as ReturnToLabProps

  const steps = [
    {
      type: ReturnToLabStepType.START,
      content: <ReturnToLabStart {...stepProps} />,
    },
    {
      type: ReturnToLabStepType.CHOOSE_LOCATION,
      content: <ChooseLocation {...stepProps} />,
    },
  ]
  const renderStepContent = () => {
    const step = _.find(steps, { type })
    const index = _.findIndex(steps, { type })
    // if (currentStep !== index) {
    //   navigate(paths.registerTest(steps[currentStep].type))
    // }
    return <Box>{step?.content || steps[0].content}</Box>
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      rightContentTitle={i18n.exit_button}
      rightContentAction={onExit}
      color="#505358"
    >
      {renderStepContent()}
    </ModuleHeader>
  )
}
