import { Box, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormikProps, useFormik } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import EyeOff from '../../../../../assets/icons/eye-off.svg'
import EyeOn from '../../../../../assets/icons/eye-on.svg'
import { selectLanguageContent } from '../../../../../features/translation'
import {
  Button,
  CreatePasswordField,
  FilledTextField,
  PasswordValidation,
  PasswordVisibility,
  TextField,
  Typography,
} from '../../../../../ui'
import { PasswordFormValues } from '../../../type'
import validationSchema from '../validation-schema'

export const ChangePassword = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)

  const onSubmit = async (values: PasswordFormValues) => {}

  const formik = useFormik<PasswordFormValues>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      reNewPassword: '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  const handleChange = (name: string, value: string) => {
    formik.setFieldValue(name, value)
  }

  return (
    <form className={classes.contentWrapper} onSubmit={formik.handleSubmit}>
      <div className={classes.fields}>
        <PasswordField
          label={i18n.login_current_password}
          formik={formik}
          name="currentPassword"
        />
        <Box width="100%">
          <CreatePasswordField
            label={i18n.login_password}
            name={'newPassword'}
            value={formik.values.newPassword}
            handleChange={handleChange}
            onBlur={formik.handleBlur}
            i18n={i18n}
            error={
              (formik.touched?.newPassword && formik.errors?.newPassword) || ''
            }
          />
          <PasswordValidation i18n={i18n} value={formik.values.newPassword} />
        </Box>
        <PasswordField
          label={i18n.reenter_password}
          formik={formik}
          name="reNewPassword"
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          type="submit"
          className={classes.confirmButton}
          disabled={!formik.isValid}
          isLoading={formik.isSubmitting}
        >
          {i18n.save_button}
        </Button>
      </div>
    </form>
  )
}

const PasswordField = ({
  formik,
  name,
  label,
  noHelperText,
}: {
  label?: string
  formik: FormikProps<PasswordFormValues>
  name: keyof PasswordFormValues
  noHelperText?: boolean
}) => {
  const classes = useIconStyles()
  const [isShowPassword, setIsShowPassword] = useState(false)

  return (
    <FilledTextField
      label={label}
      value={formik.values[name]}
      onChange={formik.handleChange}
      type={isShowPassword ? 'text' : 'password'}
      name={name}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && formik.errors[name]}
      helperText={(formik.touched[name] && formik.errors[name]) || ''}
      endAdornment={
        <IconButton
          className={classes.iconBtn}
          aria-label="toggle password visibility"
          onClick={() => setIsShowPassword(!isShowPassword)}
          size="large">
          <PasswordVisibility showPassword={isShowPassword} />
        </IconButton>
      }
      required
    />
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '32px 0 24px',
    lineHeight: '36px',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    gap: 24,
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    maxWidth: 450,
    gap: 24,
  },
  confirmButton: {
    maxWidth: 'fit-content',
    alignSelf: 'flex-end',
    margin: '8px 0px 32px',
    [theme.breakpoints.down(600)]: {
      width: '90%',
      maxWidth: 'unset',
      margin: '12px auto',
    },
  },
  formContainer: {
    display: 'grid',
    gridColumnGap: 36,
    justifyContent: 'space-between',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridRowGap: 24,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    justifyContent: 'flex-end',
    maxWidth: 450,
    gap: 24,
    [theme.breakpoints.down(600)]: {
      zIndex: 101,
      backgroundColor: '#FFF',
      padding: '24px 0px',
      position: 'fixed',
      bottom: 0,
      left: 0,
      flexDirection: 'column',
      gap: 24,
    },
  },
}))

const useIconStyles = makeStyles({
  iconBtn: {
    'width': 12,
    'height': 12,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: 'rgb(0, 60, 114)',
    },
  },
})
