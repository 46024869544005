/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import MicIcon from '@mui/icons-material/Mic'
import MicOffIcon from '@mui/icons-material/MicOff'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from 'agora-rtc-react'
import {
  ClientConfig,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { EventId } from '../../../features/pusher-notifications/types'
import { lsClient } from '../../../ls-client'
import { paths } from '../../paths'
import {
  endTelehealthCall,
  getSessionStatus,
  NetworkInterruption,
  selectMemberInSession,
  selectSessionCanStart,
  selectSessionId,
  selectShowControls,
  selectTelehealthSessionData,
  selectTelehealthSessionLoading,
  selectTelehealthStatus,
  setMemberInSession,
  TelehealthPages,
  updateDirectCall,
} from '../model'
import { NetworkInterruptionPage } from './networkInterruption'

const appId = process.env.REACT_APP_AGORA_APP_ID || ''

const config: ClientConfig = {
  mode: 'rtc',
  codec: 'h264',
}

const useClient = createClient(config)
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks()

export const TelehealthVideoCall = (props: { type: TelehealthPages }) => {
  const { type } = props
  const navigate = useNavigate()
  const session = useSelector(selectTelehealthSessionData)
  const sessionStatus = useSelector(selectTelehealthStatus)
  const joinedClient = useSelector(selectMemberInSession)
  const sessionCanStart = useSelector(selectSessionCanStart)
  const sessionId = useSelector(selectSessionId)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([])
  const matches = true
  const [showControls, setShowControls] = useState(!matches)
  const client = useClient()
  const { ready, tracks } = useMicrophoneAndCameraTracks()
  const [interruption, setInterruption] = useState<
    NetworkInterruption | undefined
  >(undefined)

  useEffect(() => {
    if (ready && !joinedClient && sessionCanStart) {
      joinSession()
    }
  }, [ready, joinedClient, sessionCanStart])

  useEffect(() => {
    if (sessionStatus === 'Ended' || sessionStatus === 'Complete') {
      closeAgoraSession()
      dispatch(setMemberInSession(false))
      if (lsClient.getUserLSByKey('ratedProvider') === 'yes') {
        navigate(paths.telehealth(TelehealthPages.COMPLETE))
      } else
        navigate(paths.telehealth(TelehealthPages.RATE_YOUR_EXPERIENCE))
    }
  }, [sessionStatus])

  const endCall = () => {
    lsClient.setUserLS('memberCancelled', '1')
    const data = {
      _id: session?.channelId,
      history: [
        {
          eventId: EventId.DC_MD,
        },
      ],
    }
    dispatch(updateDirectCall(data))
    dispatch(endTelehealthCall(session?.channelId))
  }

  const closeAgoraSession = async () => {
    await client.leave()
    client.removeAllListeners()

    if (tracks) {
      tracks[0].close()
      tracks[1].close()
    }
  }

  useEffect(() => {
    client.on('user-published', async (user, mediaType) => {
      await client.subscribe(user, mediaType)
      setInterruption(undefined)
      if (mediaType === 'video') {
        setUsers((prevUsers) => {
          return [...prevUsers, user]
        })
      }
      if (mediaType === 'audio') {
        user.audioTrack?.play()
      }
    })

    client.on('user-unpublished', (user, type) => {
      setTimeout(() => {
        dispatch(getSessionStatus(sessionId))
      }, 500)
      if (type === 'audio') {
        user?.audioTrack?.stop()
      }
      if (type === 'video') {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid)
        })
      }
    })

    client.on('connection-state-change', (curState, revState, reason) => {
      if (curState === 'DISCONNECTED' && reason === 'LEAVE') {
        setTimeout(() => {
          dispatch(getSessionStatus(sessionId))
        }, 500)
      }
    })

    client.on('user-left', (user, reason) => {
      // if (reason === 'ServerTimeOut') {
      // }
      setTimeout(() => {
        dispatch(
          getSessionStatus(sessionId, () =>
            setInterruption(NetworkInterruption.PROVIDER)
          )
        )
      }, 500)
      setUsers((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid)
      })
    })
  }, [])
  const joinSession = async () => {
    if (client.connectionState === 'DISCONNECTED') {
      await client.join(
        session?.rtcAppId ? session?.rtcAppId : appId,
        session?.channelId,
        session?.sessionAccessToken,
        session?.memberAccessId
      )
      dispatch(setMemberInSession(true))
      if (tracks) {
        await client.publish([tracks[0], tracks[1]])
      }
    }
  }

  return (
    <>
      {!interruption ? (
        <Box>
          {tracks && (
            <Videos
              users={users}
              tracks={tracks}
              setShowControls={setShowControls}
              showControls={showControls}
              matches={matches}
              type={type}
            />
          )}
          <Box
            className={
              showControls
                ? classes.controlsContainer
                : classes.controlsContainerHidden
            }
          >
            <AgoraControls tracks={tracks} endCall={endCall} />
          </Box>
        </Box>
      ) : (
        <NetworkInterruptionPage
          type={interruption}
          endCall={closeAgoraSession}
        />
      )}
    </>
  )
}

const Videos = (props: {
  users: IAgoraRTCRemoteUser[]
  setShowControls: React.Dispatch<React.SetStateAction<boolean>>
  showControls: boolean
  tracks: [IMicrophoneAudioTrack, ICameraVideoTrack]
  matches: boolean
  type: TelehealthPages
}) => {
  const { users, tracks, setShowControls, showControls, matches, type } = props
  const classes = useStyles()
  const forceShowControls = useSelector(selectShowControls)
  const usersInSession = [] as string[]

  useEffect(() => {
    if (forceShowControls) {
      setShowControls(forceShowControls)
    }
  }, [forceShowControls])

  return (
    <div>
      {type === TelehealthPages.SESSION && (
        <Box className={classes.providerVideoCell}>
          {users.length > 0 &&
            users.map((user) => {
              if (usersInSession.includes(user.uid.toString())) return null
              if (user.videoTrack) {
                usersInSession.push(user.uid.toString())
                return (
                  <AgoraVideoPlayer
                    className={classes.providerVideoCell}
                    videoTrack={user.videoTrack}
                    key={user.uid}
                  />
                )
              }
              return null
            })}
        </Box>
      )}
      {matches ? (
        <>
          <Box
            className={
              type === TelehealthPages.SESSION
                ? classes.memberSessionCell
                : undefined
            }
          >
            <Box
              className={classes.videoMask}
              onClick={() => setShowControls(!showControls)}
            >
              <AgoraVideoPlayer
                className={classes.videoCell}
                videoTrack={tracks[1]}
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box className={classes.videoMask}>
            <AgoraVideoPlayer
              className={classes.videoCell}
              videoTrack={tracks[1]}
            />
          </Box>
        </>
      )}
    </div>
  )
}

const AgoraControls = (props: { tracks: any; endCall: () => void }) => {
  const classes = useStyles()
  const { tracks, endCall } = props
  const [trackState, setTrackState] = useState({ video: true, audio: true })

  const mute = async (type: 'audio' | 'video') => {
    if (type === 'audio') {
      await tracks[0].setEnabled(!trackState.audio)
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio }
      })
    } else {
      await tracks[1].setEnabled(!trackState.video)
      setTrackState((ps) => {
        return { ...ps, video: !ps.video }
      })
    }
  }

  return (
    <Box className={classes.controlContainer}>
      <Box className={classes.control}>
        {trackState.video ? (
          <VideocamIcon
            className={classes.iconsFilled}
            onClick={() => mute('video')}
          />
        ) : (
          <VideocamOffIcon
            className={classes.iconsFilled}
            onClick={() => mute('video')}
          />
        )}
        <Typography style={{ color: '#FFF' }}>Camera</Typography>
      </Box>
      <Box className={classes.control}>
        {trackState.audio ? (
          <MicIcon
            className={classes.iconsFilled}
            onClick={() => mute('audio')}
          />
        ) : (
          <MicOffIcon
            className={classes.iconsFilled}
            onClick={() => mute('audio')}
          />
        )}
        <Typography style={{ color: '#FFF' }}>Mic</Typography>
      </Box>
      <Box className={classes.control}>
        <CloseIcon
          style={{ backgroundColor: '#F53333', color: '#FFF' }}
          className={classes.iconsOutlined}
          onClick={() => endCall()}
        />
        <Typography style={{ color: '#FFF' }}>End</Typography>
      </Box>
    </Box>
  )
}

const EASE = 'all .5s ease-in-out'
const MASK_RADIAL = '-webkit-radial-gradient(circle, white 100%, black 100%)'

const useStyles = makeStyles((theme) => ({
  iconsFilled: {
    margin: 13,
    borderRadius: '100%',
    fontSize: '46px',
    padding: 11,
    backgroundColor: '#FFF',
    color: '#505358',
    cursor: 'pointer',
  },
  iconsOutlined: {
    margin: 13,
    borderRadius: '100%',
    fontSize: '46px',
    padding: 11,
    color: '#505358',
    cursor: 'pointer',
  },
  controlsContainer: {
    'transform': 'translate(0,-57px)',
    'transition': EASE,
    '-webkit-transition': EASE /** Chrome & Safari **/,
    '-moz-transition': EASE /** Firefox **/,
    '-o-transition': EASE /** Opera **/,
    [theme.breakpoints.up(576)]: {
      left: 38,
    },
    [theme.breakpoints.up(1000)]: {
      left: 16,
      top: 126,
      transform: 'translate(0,0px)',
      zIndex: 1,
    },
  },
  controlsContainerHidden: {
    'transform': 'translate(0, 3px)',
    'transition': EASE,
    '-webkit-transition': EASE /** Chrome & Safari **/,
    '-moz-transition': EASE /** Firefox **/,
    '-o-transition': EASE /** Opera **/,
    [theme.breakpoints.up(576)]: {
      left: 38,
    },
    [theme.breakpoints.up(1000)]: {
      left: 75,
    },
  },
  controlContainer: {
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    paddingBottom: '16px',
    backgroundColor: 'RGBA(0,0,0,.6)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  control: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  providerVideoCell: {
    width: '100vw',
    height: 'calc(90vh)',
    borderRadius: '8px',
    [theme.breakpoints.down(600)]: {
      height: 'calc(100vh - 48px)',
    },
  },
  memberSessionCell: {
    position: 'absolute',
    right: '124px',
    top: 130,
    [theme.breakpoints.down(960)]: {
      right: '52px',
    },
    [theme.breakpoints.down(600)]: {
      right: '5%',
      top: 'unset',
      bottom: '150px',
    },
  },
  icons: {
    margin: 2,
    borderRadius: '100%',
    fontSize: '24px',
    padding: 10,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  video: {
    width: '100%',
    minHeight: '200px',
  },
  videoMask: {
    'width': '80px',
    'height': '130px',
    'borderRadius': '8px',
    '-webkit-mask-image': MASK_RADIAL,
    [theme.breakpoints.up(576)]: {
      '-webkit-mask-image': MASK_RADIAL,
      'width': '200px',
      'height': '135px',
    },
  },
  videoCell: {
    width: '80px',
    height: '130px',
    zIndex: 100,
    [theme.breakpoints.up(576)]: {
      width: '200px',
      height: '135px',
    },
  },
}))
