import { styled } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import { hexToRGBA } from '../../../libs/helpers'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { getColor } from '../../../libs/utils'
import { ColorWithHex } from '../../../types/general'
import { Checkbox, RoundedCheckbox } from '../../../ui'

interface QuestionnaireCheckboxProps {
  type: 'checkbox' | 'radio'
  color?: ColorWithHex
  checked?: boolean
  onChange?: (e: React.ChangeEvent) => void
  label?: string
  id: string
}
export const QuestionnaireCheckbox: React.FC<QuestionnaireCheckboxProps> = ({
  onChange,
  color = 'primary',
  checked = false,
  label,
  type,
  id,
}) => {
  const { theme } = useMUITheme()
  return (
    <ControlWrapper
      id={id}
      control={
        type === 'checkbox' ? (
          <Checkbox color={color} checked={checked} onChange={onChange} />
        ) : (
          <RoundedCheckbox
            color={color}
            checked={checked}
            onChange={onChange}
          />
        )
      }
      label={label}
      sx={{
        alignItems: type === 'checkbox' ? 'flex-start' : 'center',

        transition: 'all .03s ease-in-out',
        backgroundColor: checked
          ? hexToRGBA(getColor(color, theme), 0.1)
          : '#FFF',
        [theme.breakpoints.up(960)]: {
          '&:hover': {
            transition: 'all .03s ease-in-out',
            backgroundColor: hexToRGBA(getColor(color, theme), 0.05),
          },
        },
      }}
      labelPlacement={'end'}
    />
  )
}
const ControlWrapper = styled(FormControlLabel)({
  'display': 'flex',
  'justifyContent': 'flex-start',
  'padding': '12px 12px 12px 24px',
  'borderRadius': '8px',
  'margin': '0px auto',
  'width': '90%',
  'transition': 'all .03s ease-in-out',
  '& span': {
    fontWeight: 500,
    color: '#505358 !important',
  },
})
