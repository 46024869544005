import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { testTypes } from '../../constants'
import { handleError } from '../../libs/helpers'
import { TestKitDetails } from '../../types/tests'
//todo - merge this code as needed into register-a-test module
interface SelectTestKitSlice {
  isLoading: boolean
  testKitsLoaded: boolean
  orderableTests: TestKitDetails[]
  orderablePanels: TestKitDetails[]
  testKitDetails: TestKitDetails
}

const initialStore: SelectTestKitSlice = {
  isLoading: false,
  testKitsLoaded: false,
  orderableTests: [],
  orderablePanels: [],
  testKitDetails: null,
}

export const selectTestKitSlice = createSlice({
  name: 'selectTestKitResult',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setTestKitsLoaded(store, { payload }: { payload: boolean }) {
      store.testKitsLoaded = payload
    },
    setTestKitsResult(store, { payload }: { payload: any }) {
      store.orderableTests = payload
    },
    setTestPanelsResult(store, { payload }: { payload: any }) {
      store.orderablePanels = payload
    },
    setTestKitDetails(store, { payload }: { payload: any }) {
      store.testKitDetails = payload
    },
  },
})

const {
  setLoading,
  setTestKitsLoaded,
  setTestKitsResult,
  setTestPanelsResult,
  setTestKitDetails,
} = selectTestKitSlice.actions

export const getTestKits =
  (type: string) => async (dispatch: any, getStore: any) => {
    dispatch(setLoading(true))

    const { languageContent } = getStore().translation

    const params = {
      returnOnlyFacilityTest: type === testTypes.APPOINTMENT,
      returnOnlyTestKits: type === testTypes.ORDER,
    }

    const { orderablePanels, orderableTests, error } =
      await api.getTestsForTenant(params)

    if (error) {
      dispatch(setLoading(false))
      // try to get translated error by key from localization file or render received error string
      return dispatch(handleError(null, languageContent[error] || error))
    }

    if (orderablePanels && orderableTests) {
      dispatch(setLoading(false))
      dispatch(setTestKitsLoaded(true))
      dispatch(setTestKitsResult(orderableTests))
      dispatch(setTestPanelsResult(orderablePanels))
    }
  }

export const getTestKitDetails =
  (id: string) => async (dispatch: any, getStore: any) => {
    dispatch(setLoading(true))

    const { selectedLanguage, languageContent } = getStore().translation

    const testDetails = await api.getLabTestOrderable(id, selectedLanguage)

    if (testDetails?.error) {
      // try to get translated error by key from localization file or render received error string
      dispatch(
        handleError(
          null,
          languageContent[testDetails.error] || testDetails.error
        )
      )
      return dispatch(setLoading(false))
    }

    if (testDetails) {
      dispatch(setLoading(false))
      dispatch(setTestKitDetails(testDetails))
    }
  }

export const selectTestKitReducer = selectTestKitSlice.reducer
export const selectTestKitReducerName = selectTestKitSlice.name

interface RootStore {
  [selectTestKitReducerName]: typeof initialStore
}

export const selectTestKits = ({ selectTestKitResult }: RootStore) =>
  selectTestKitResult.orderableTests

export const selectOrderablePanels = ({ selectTestKitResult }: RootStore) =>
  selectTestKitResult.orderablePanels

export const selectIsLoading = ({ selectTestKitResult }: RootStore) =>
  selectTestKitResult.isLoading

export const selectTestKitsLoaded = ({ selectTestKitResult }: RootStore) =>
  selectTestKitResult.testKitsLoaded

export const selectTestKitDetails = ({
  selectTestKitResult,
}: RootStore): TestKitDetails => selectTestKitResult.testKitDetails
