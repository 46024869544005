import styled from '@emotion/styled'
import PriorityHighRoundedIcon from '@mui/icons-material//PriorityHighRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CallIcon from '@mui/icons-material/Call'
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import PinDrop from '@mui/icons-material/PinDrop'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'
import RotateRightRoundedIcon from '@mui/icons-material/RotateRightRounded'
import { Box, Dialog, Link as MUILink, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReactComponent as AlertRed } from '../../../assets/icons/alert-red.svg'
import { ReactComponent as AlertYellow } from '../../../assets/icons/alert-yellow.svg'
import { ReactComponent as CheckGreen } from '../../../assets/icons/check-green.svg'
import { TestImageViewer } from '../../../features/details-helper/test-image-viewer/test-image-viewer'
import { I18n } from '../../../features/translation/types'
import { formatDateAndTime } from '../../../libs/helpers'
import { useLocationService } from '../../../libs/hooks/useLocation'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { capitalizeString } from '../../../libs/utils'
import { lsClient } from '../../../ls-client'
import { Button, Loading, LogoImage } from '../../../ui'
import { RenderFedExWorkingHours } from '../../facility/facilityHelper'
import {
  downloadLabReportPDF,
  selectImageLoading,
  selectOrderPDF,
} from '../model'
import { TestDetailFields } from './testDetailsMapper'

export const Divider = styled.div`
  margin: 24px 0;
  width: 100%;
  border-bottom: solid 1px #e0e0e0;
`

export const TextField = (props: {
  field: TestDetailFields
  i18n: any
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  return (
    <>
      <div style={{ paddingLeft: 16 }}>
        <Typography className={classes.testName}>
          {i18n[field.translationName]}
        </Typography>
        <Typography variant="subtitle2" className={classes.info}>
          {field.valueIsKey ? i18n[test[field.id]] : test[field.id]}
        </Typography>
      </div>
      {field.divider && <Divider />}
    </>
  )
}

export const DateField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  return (
    <>
      <div style={{ paddingLeft: 16 }}>
        <Typography className={classes.testName}>
          {i18n[field.translationName]}
        </Typography>
        <Typography variant="subtitle2" className={classes.info}>
          {formatDateAndTime(test[field.id])}
        </Typography>
      </div>
      {field.divider && <Divider />}
    </>
  )
}

export const AddressField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  const { openNativeMapFeature } = useLocationService().actions

  const openMap = () => {
    const address = {
      ...test[field.id],
    }
    openNativeMapFeature(address)
  }

  return (
    <div className={classes.addressWrapper}>
      <MUILink
        underline="none"
        role="button"
        color="primary"
        onClick={() => openMap()}
      >
        <Box className={classes.addressContainer}>
          <div>
            <div className={classes.locationNameWrapper}>
              <Typography style={{ fontWeight: 600 }} color="primary">
                {test[field.id].locationName}
              </Typography>
              <PinDrop />
            </div>
            <Typography variant="subtitle2" className={classes.info}>
              {test[field.id].line1} <br />
              {test[field.id].line2 && (
                <>
                  {test[field.id].line2} <br />
                </>
              )}
              {test[field.id].city}, {test[field.id].state} {test[field.id].zip}
            </Typography>
          </div>
        </Box>
      </MUILink>
      {test[field.id].locationPhone && (
        <div className={classes.locationNameWrapper}>
          <Typography style={{ fontWeight: 500, fontSize: 14 }} color="primary">
            {test[field.id].locationPhone}
          </Typography>
          <CallIcon color="primary" style={{ fontSize: 18 }} />
        </div>
      )}
      <RenderFedExWorkingHours
        officeData={test[field.id]}
        i18n={i18n}
        withDivider={true}
        textClass={classes.info}
      />
      {field.divider && <Divider />}
    </div>
  )
}

export const LinkField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  return (
    <div
      className={classes.linkWrapper}
      onClick={() =>
        lsClient.navigation.setBackPath(`/test-result-details/${test._id}`)
      }
    >
      <MUILink
        role="button"
        color="primary"
        component={Link}
        to={
          field.linkIsDetailView
            ? `${field.linkPath}/${test._id}`
            : field.linkPath
        }
        underline="none"
        className={classes.rescheduleLink}
      >
        {field.startIcon && (
          <div className={classes.rescheduleContainer}>{field.startIcon()}</div>
        )}
        <Typography align="center" className={classes.linkName}>
          {i18n[field.translationName]}
        </Typography>
      </MUILink>
      {field.divider && <Divider />}
    </div>
  )
}

export const PDFField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const pdf = useSelector(selectOrderPDF)
  const pdfLoading = useSelector(selectImageLoading)

  useEffect(() => {
    dispatch(downloadLabReportPDF(test._id))
  }, [test])

  if (!test.downloadLabReport) return null

  const getReport = () => {
    if (pdfLoading) return
    window.open(pdf, '_blank')
  }

  return (
    <div className={classes.linkWrapper}>
      <div className={classes.rescheduleLink} onClick={() => getReport()}>
        {field.startIcon && (
          <>
            {pdfLoading && (
              <div className={classes.pdfLoading}>
                <Loading size={35} />
              </div>
            )}
            <div
              className={
                pdfLoading
                  ? `${classes.rescheduleContainer} isLoading`
                  : classes.rescheduleContainer
              }
            >
              {field.startIcon()}
            </div>
          </>
        )}
        <Typography
          align="center"
          className={
            pdfLoading ? `${classes.linkName} isLoading` : classes.linkName
          }
        >
          {i18n[field.translationName]}
        </Typography>
        {field.divider && <Divider />}
      </div>
    </div>
  )
}

export const TestImageField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
  isMobile: boolean
}) => {
  const { field, i18n, test, isMobile } = props
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles()

  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <div className={classes.multiPathResultWrapper}>
        <div
          className={classes.contentWrapper}
          onClick={() => setOpenDialog(true)}
        >
          <div>
            <Typography className={classes.testName}>
              {i18n[field.translationName]}
            </Typography>
            <Typography className={classes.info}>
              {i18n.image_result}
            </Typography>
          </div>

          <ChevronRightSharpIcon
            style={{ marginRight: '-6px', color: '#757575' }}
          />
        </div>
        <Dialog open={openDialog} maxWidth={'xl'}>
          <TestImageViewer onClose={handleClose} id={test._id} />
        </Dialog>
      </div>
      {field.divider && <Divider />}
    </>
  )
}

export const ButtonField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  if (!test[field.enable]) return <></>

  return (
    <Button
      color="primary"
      variant="outlined"
      component={Link}
      to={
        field.linkIsDetailView
          ? `${field.linkPath}/${test._id}`
          : field.linkPath
      }
      className={classes.rescheduleLink}
    >
      {field.plainTextName || i18n[field.translationName]}
    </Button>
  )
}

export const CredentialHeaderField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field } = props
  const classes = useStyles()

  return (
    <Box className={classes.credentialBox}>
      <Typography className={classes.credential}>
        TEST <br /> CREDENTIAL
      </Typography>
      <LogoImage className={classes.credentialImage} />
      {field.divider && <Divider />}
    </Box>
  )
}

export const Result = (props: { result: string }) => {
  const classes = useStyles()
  const { result } = props
  switch (result) {
    case 'POSITIVE': {
      return (
        <Box className={classes.resultBox} style={{ borderColor: '#FF0000' }}>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ color: '#FF0000' }}
          >
            {capitalizeString(result)}
          </Typography>
          <AlertRed />
        </Box>
      )
    }
    case 'NEGATIVE': {
      return (
        <Box className={classes.resultBox} style={{ borderColor: '#009104' }}>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ color: '#009104' }}
          >
            {capitalizeString(result)}
          </Typography>
          <CheckGreen />
        </Box>
      )
    }
    case 'INVALID':
    case 'UNKNOWN':
    case 'ERROR':
    case 'INCONCLUSIVE': {
      return (
        <Box className={classes.resultBox} style={{ borderColor: '#FA9C13' }}>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ color: '#FA9C13' }}
          >
            {capitalizeString(result)}
          </Typography>
          <AlertYellow />
        </Box>
      )
    }
    default: {
      return (
        <Box className={classes.resultBox} style={{ borderColor: '#FA9C13' }}>
          <Typography
            variant="subtitle2"
            className={classes.info}
            style={{ color: '#FA9C13' }}
          >
            No result found
          </Typography>
          <AlertYellow />
        </Box>
      )
    }
  }
}

export const ResultIcon = (props: { result: string }) => {
  const classes = useStyles()
  const { result } = props
  switch (result) {
    //Completed Results
    case 'POSITIVE': {
      return (
        <AddRoundedIcon
          className={classes.iconFilled}
          style={{ backgroundColor: '#F20000' }}
        />
      )
    }
    case 'NEGATIVE': {
      return (
        <RemoveRoundedIcon
          className={classes.iconFilled}
          style={{ backgroundColor: '#00C04D' }}
        />
      )
    }
    case 'INVALID':
    case 'UNKNOWN':
    case 'ERROR':
    case 'INCONCLUSIVE': {
      return (
        <PriorityHighRoundedIcon
          className={classes.iconFilled}
          style={{ backgroundColor: '#E59701' }}
        />
      )
    }
    //In Progress Status results
    // case 'SPECIMEN_COLLECTED':
    default: {
      return (
        <RotateRightRoundedIcon
          className={classes.iconFilled}
          style={{ color: '#757575' }}
        />
      )
    }
  }
}

export const TitleAndDescField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  if (!test[field.id]?.title || !test[field.id]?.description) return null

  return (
    <>
      <div style={{ paddingLeft: 16 }}>
        {(field.plainTextName || i18n[field.translationName]) && (
          <Typography className={classes.testName} style={{ marginBottom: 8 }}>
            {capitalizeString(
              field.plainTextName || i18n[field.translationName]
            ) || ''}
          </Typography>
        )}
        <Typography
          className={
            (field.id as string) === 'invalidResultSummary'
              ? `${classes.testName} invalid`
              : classes.testName
          }
        >
          {test[field.id]?.title}
        </Typography>
        <Typography className={classes.info}>
          <HTMLTextComponent
            HTMLString={test[field.id]?.description}
            className={classes.summaryInfo}
          />
        </Typography>
      </div>
      {field.divider && <Divider />}
    </>
  )
}

export const NextStepsField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()

  const getInstruction = () => {
    switch (test.status) {
      case 'SPECIMEN_COLLECTED': {
        return i18n.next_step_dropoff_instruction
      }
      default:
        return null
    }
  }

  return (
    <div style={{ margin: '24px 0px' }}>
      <Typography className={classes.nextStepTitle}>
        {i18n[field.translationName]}
      </Typography>
      <Typography className={classes.multiPathTitle}>
        {getInstruction()}
      </Typography>
      {field.divider && <Divider />}
    </div>
  )
}

export const ResultField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  const testResult = test?.patientTestResult || test?.metadata?.outcome

  return (
    <>
      <Typography className={classes.testName}>
        {capitalizeString(i18n[field.translationName])}
      </Typography>
      {<Result result={testResult} />}
      {field.divider && <Divider />}
    </>
  )
}

export const SummaryField = (props: {
  field: TestDetailFields
  i18n: I18n
  test: any
}) => {
  const { field, i18n, test } = props
  const classes = useStyles()
  if (!test[field.id]) return <></>

  const getResult = () => {
    if (test.patientTestResult?.toUpperCase() === 'NEGATIVE') {
      return (
        <Typography className={classes.resultText} style={{ color: '#00C04D' }}>
          {test.resultDisplay}
        </Typography>
      )
    }
    if (test.patientTestResult?.toUpperCase() === 'POSITIVE') {
      return (
        <Typography className={classes.resultText} style={{ color: '#F20000' }}>
          {test.resultDisplay}
        </Typography>
      )
    }
  }
  return (
    <Box className={classes.summaryBox}>
      {(field.plainTextName || i18n[field.translationName]) && (
        <Typography className={classes.testName} style={{ marginBottom: 8 }}>
          {capitalizeString(
            field.plainTextName || i18n[field.translationName]
          ) || ''}{' '}
        </Typography>
      )}
      {getResult()}
      {test[field.id] && (
        <HTMLTextComponent
          HTMLString={test[field.id]}
          className={classes.summaryInfo}
        />
      )}
      {field.divider && <Divider />}
    </Box>
  )
}

export const TestField = (props: { field: TestDetailFields; test: any }) => {
  const { field, test } = props
  const classes = useStyles()
  return (
    <>
      <Box className={classes.multiPathResultWrapper}>
        <div className={classes.contentWrapper}>
          <Box>
            <Typography variant="subtitle2" className={classes.testName}>
              {test?.testName}
            </Typography>
            <Typography variant="subtitle2" className={classes.info}>
              {test?.labVendor}
            </Typography>
          </Box>
          <Box className={classes.testImageWrapper}>
            <img src={test.testImage} style={{ height: '33px' }} />
          </Box>
        </div>
      </Box>
      {field.divider && <Divider />}
    </>
  )
}

const SPACE_BETWEEN = 'space-between'
const useStyles = makeStyles((theme) => ({
  summaryBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
  },
  summaryHeader: {
    color: '#505358',
    fontWeight: 600,
    fontSize: 14,
  },
  summaryInfo: {
    color: '#757575',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '150%',
  },
  vendorBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  vendor: {
    color: '#757575',
  },
  credentialBox: {
    minHeight: '74px',
    width: '100%',
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    alignItems: 'center',
  },
  credential: {
    fontWeight: 600,
    fontSize: 16,
    color: '#54565B',
  },
  resultText: {
    fontWeight: 600,
    fontSize: 16,
  },
  titleAndDescWrapper: {},
  alert: {
    height: 14,
    width: 14,
  },
  resultBox: {
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    alignItems: 'center',
    borderRadius: '12px',
    border: 'solid 1px',
    padding: '2px 10px',
    width: '95%',
  },
  testName: {
    'color': '#505358',
    'fontSize': 20,
    'lineHeight': '24px',
    'fontWeight': 600,
    '&.invalid': {
      color: '#E59701',
    },
  },
  linkName: {
    'color': '#505358',
    'fontSize': 16,
    'lineHeight': '20px',
    'fontWeight': 500,
    '&.isLoading': {
      opacity: 0.5,
    },
  },
  rescheduleContainer: {
    'display': 'flex',
    'alignItems': 'center',
    'border': '1px solid #E6E7EA',
    'padding': '16px',
    'borderRadius': 12,
    '&.isLoading': {
      opacity: 0.5,
    },
  },
  pdfLoading: {
    position: 'absolute',
    top: 24,
  },
  rescheduleLink: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    gap: 16,
    [theme.breakpoints.up(600)]: {},
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  addressContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    minWidth: 500,
    justifyContent: SPACE_BETWEEN,
    [theme.breakpoints.down(600)]: {
      minWidth: 'unset',
    },
  },
  locationNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  nextStepTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: '#505358',
    marginBottom: 4,
  },
  info: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'left',
    color: '#757575',
  },
  credentialImage: {
    maxHeight: '48px',
    [theme.breakpoints.down(600)]: {
      maxHeight: '32px',
    },
  },
  iconFilled: {
    borderRadius: '100%',
    fontSize: '42px',
    padding: 4,
    fontWeight: 600,
    color: 'white',
  },
  multiPathTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#757575',
  },
  multiPathResultWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 32px)',
    gap: 16,
    padding: '0px 16px',
  },
  multiPathResult: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#505358',
  },
  outcomeWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 0,
    },
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      padding: '0px',
    },
  },
  linkWrapper: {
    minHeight: 150,
    width: 117,
    [theme.breakpoints.down(600)]: {
      width: 100,
    },
  },
  testImageWrapper: {
    padding: 8,
    borderRadius: 8,
    backgroundColor: '#F3F3F3',
  },
}))
