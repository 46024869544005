import { Dialog, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import alert from '../../../assets/img/alert.svg'
import { selectLanguageContent } from '../../../features/translation'
import { clearTestKitFlow } from '../../../libs/helpers'
import { Button } from '../../../ui'
import { paths } from '../../paths'
import { DialogProps } from '../types'

export const SessionExpiredDialog = (props: DialogProps) => {
  const { open, setDialogOpen } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)

  const endTest = () => {
    clearTestKitFlow()
    setDialogOpen(false)
    navigate(paths.app.dashboard())
  }

  return (
    <Dialog open={open}>
      <Box className={classes.schedulePageHolder}>
        <Box className={classes.scheduleWrapper}>
          <Box>
            <img src={alert} alt="test-history" className={classes.img} />
          </Box>
          <Box className={classes.headerWrapper}>
            <Typography
              variant="h4"
              color="primary"
              style={{ marginBottom: 40 }}
            >
              {i18n.expiration_title}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: '#7b7c7d', marginBottom: 20 }}
            >
              {i18n.expiration_message}
            </Typography>
          </Box>
          <Button onClick={endTest} className={classes.confirmButton}>
            {i18n.end_test_action}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  img: {
    height: 200,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    position: 'relative',
  },
  confirmButton: {
    minWidth: 250,
    marginTop: 10,
    marginBottom: 10,
  },
  schedulePageHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7F7F7',
    width: 300,
    height: 600,
    padding: 20,
    [theme.breakpoints.down(500)]: {
      padding: 0,
    },
  },
  scheduleWrapper: {
    margin: '0 auto',
    display: 'flex',
    padding: '0 15px',
    maxWidth: 350,
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
}))
