import styled from '@emotion/styled'
import Lottie from 'lottie-react'
import React from 'react'
import CircleCheck from '../../../assets/animations/checkmark-hc-blue-stopped.json'
import { useMUITheme } from '../../../libs/hooks/useTheme'

export const Success = (props: { size?: number, color?: string }) => {
  const { size = 200, color } = props
  const { primary } = useMUITheme().colors

  const StyledAnimation = styled(Lottie)({
    '.lottie-major-color path': {
      fill: `${color ? color : primary} !important`,
      stroke: `${color ? color : primary} !important`,
    },
  })
  return (
    <div style={{ width: size }}>
      <StyledAnimation animationData={CircleCheck} loop={false} />
    </div>
  )
}
