/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Dialog } from '@mui/material'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ALPHA_REGEX, StepType } from '../../../../constants'
import { addLinkWithinString } from '../../../../features/details-helper/fields'
import { selectLanguageContent } from '../../../../features/translation'
import { validateEntry } from '../../../../libs/utils'
import {
  Button,
  DateTextField,
  FilledTextField,
  MaskedInput,
} from '../../../../ui'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { InvitePages } from '../../../invite-web-registration/model/type'
import { selectNetworkSettings } from '../../../network/model'
import { NetworkSettings } from '../../../network/type'
import { paths } from '../../../paths'
import { TermsAndConditionsPage } from '../../../terms-and-conds/signupPage'
import { EligibilityError } from '../../components/eligibilityErrorDialog'
import { getName, scrollTop } from '../../helpers'
import {
  getEligibility,
  getEligibilityAttempt,
  getSignupType,
  resetRegisterForm,
  saveEligibilityInfo,
  selectEligibilityErrorDialog,
  setEligibiltyDialogOpen,
} from '../../model/registerSlice'
import { OnboardingType, RegisterStepProps } from '../../model/type'

export const Eligibility = (props: RegisterStepProps) => {
  const { handleNext, formik, accountId, tenantId, isInvite } = props
  const navigate = useNavigate()
  const classes = onboardingStyles()
  const type = StepType.ELIGIBILITY
  const i18n = useSelector(selectLanguageContent)
  const currentNetwork = useSelector(selectNetworkSettings)
  const eligibilityErrorDialog = useSelector(selectEligibilityErrorDialog)
  const eligibilityAttempt = useSelector(getEligibilityAttempt)
  const signupType = useSelector(getSignupType)
  const [termsOpen, setTermsOpen] = useState(false)
  const { accountVerificationScreenCriteria } =
    (currentNetwork as NetworkSettings) || {}
  const noFieldsConfigured = !(
    Boolean(accountVerificationScreenCriteria?.verificationField01) ||
    Boolean(accountVerificationScreenCriteria?.verificationField02) ||
    Boolean(accountVerificationScreenCriteria?.verificationField03)
  )
  const [dateFormatCorrect, setDateFormatCorrect] = useState(true)
  const [dateValid, setDateValid] = useState(true)

  const dispatch = useDispatch()

  useEffect(() => {
    document.addEventListener('focusout', scrollTop)
    return () => {
      document.removeEventListener('focusout', scrollTop)
    }
  }, [])

  const values = formik.values[type]
  const errors = formik.errors[type]
  const touched = formik.touched[type]

  const goNext = () => {
    const payload = {
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth
        ? format(new Date(values.dateOfBirth), 'MM/dd/y')
        : '',
      lastFourSSN: values.lastFourSSN,
      tenantId,
      accountId,
    }
    dispatch(saveEligibilityInfo(values))
    dispatch(getEligibility(payload, handleNext, i18n))
  }

  const cancelEligibility = () => {
    if (signupType === OnboardingType.SELF) {
      dispatch(resetRegisterForm())
      if (eligibilityAttempt === 6) {
        navigate(paths.login())
      } else navigate(paths.accessCode())
    } else {
      dispatch(resetRegisterForm())
      navigate(paths.inviteMessaging(InvitePages.SESSION_INVALID))
    }
  }

  const updateDate = (value: string) => {
    setDateFormatCorrect(
      Boolean(value.match(/(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/))
    )
    if (moment(value, 'MM/DD/YYYY', true).isValid()) {
      setDateValid(true)
      formik.setFieldValue(
        getName('dateOfBirth', type),
        moment(value).format('MM/DD/YYYY') || ''
      )
    } else {
      setDateValid(false)
      formik.setFieldValue(getName('dateOfBirth', type), '')
    }
  }

  const renderDOB = () =>
    Boolean(accountVerificationScreenCriteria?.verificationField02) && (
      <DateTextField
        label={i18n.date_of_birth}
        value={
          values.dateOfBirth
            ? moment(values.dateOfBirth.toString().split('Z')[0]).format(
                'MM/DD/YYYY'
              )
            : null
        }
        i18n={i18n}
        onBlur={formik.handleBlur}
        error={Boolean(touched?.dateOfBirth) && Boolean(errors?.dateOfBirth)}
        helperText={(touched?.dateOfBirth && errors?.dateOfBirth) || ''}
        name={getName('dateOfBirth', type)}
        handleChange={updateDate}
        inputProps={{
          inputMode: 'numeric',
        }}
        required={true}
      />
    )

  const renderLastName = () =>
    Boolean(accountVerificationScreenCriteria?.verificationField01) && (
      <FilledTextField
        label={i18n.last_name}
        autoComplete="family-name"
        name={getName('lastName', type)}
        type={'text'}
        onKeyDown={(event) => validateEntry(event, ALPHA_REGEX)}
        value={values?.lastName}
        error={touched?.lastName && Boolean(errors?.lastName)}
        helperText={(touched?.lastName && errors?.lastName) || ''}
        variant="filled"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        required
      />
    )

  const renderLastFour = () =>
    Boolean(accountVerificationScreenCriteria?.verificationField03) && (
      <>
        <MaskedInput
          variant="filled"
          mask="9999"
          maskChar=""
          label={i18n.last_four_digits_of_ssn}
          value={values?.lastFourSSN}
          onChange={formik.handleChange}
          name={getName('lastFourSSN', type)}
          onBlur={formik.handleBlur}
          error={touched?.lastFourSSN && Boolean(errors?.lastFourSSN)}
          helperText={(touched?.lastFourSSN && errors?.lastFourSSN) || ''}
          required
        />
        {addLinkWithinString(
          i18n.ssn_data_disclaimer,
          i18n.terms_and_conditions_link,
          () => setTermsOpen(true),
          classes.body,
          classes.termsLink
        )}
      </>
    )

  return (
    <form className={classes.content}>
      <Typography className={classes.title} color="primary" variant="h1">
        {i18n.verify_your_account}
      </Typography>
      <Typography className={classes.body} variant="body1" align="left">
        {i18n.eligibility_verification_instruction}
      </Typography>
      {renderLastName()}
      {renderDOB()}
      {renderLastFour()}
      <div
        className={
          isInvite ? classes.inviteMobileButtonWrapper : classes.buttonWrapper
        }
      >
        <Button
          className={classes.actionBtn}
          onClick={goNext}
          disabled={
            !formik.isValid ||
            !currentNetwork ||
            noFieldsConfigured ||
            (Boolean(accountVerificationScreenCriteria?.verificationField02) &&
              !dateFormatCorrect) ||
            !dateValid
          }
          isLoading={formik.isSubmitting}
        >
          {i18n.submit_button}
        </Button>
      </div>
      <Dialog open={termsOpen} fullScreen>
        <div className={classes.dialogContainer}>
          <TermsAndConditionsPage
            onClose={() => setTermsOpen(false)}
            parentTenantId={tenantId}
          />
        </div>
      </Dialog>
      <Dialog open={eligibilityErrorDialog} fullScreen>
        <EligibilityError
          onClose={() => dispatch(setEligibiltyDialogOpen(false))}
          onCancel={cancelEligibility}
        />
      </Dialog>
    </form>
  )
}
