import * as Yup from 'yup'
import { I18n } from '../../../../features/translation/types'

const passwordTabValidation = (i18n: I18n) =>
  Yup.object().shape({
    currentPassword: Yup.string().required(i18n.required),
    newPassword: Yup.string()
      .matches(
        new RegExp(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})'
        )
      )
      .required(i18n.required),
    reNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match!')
      .required(i18n.required),
  })

export default passwordTabValidation
