import { styled } from '@mui/styles'
import React, { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { ModuleHeader } from '../modules/moduleHeader'
import {
  ButtonWrapper,
  ContentWrapper,
  Divider,
  Message,
  PageTitle,
  PageWrapper,
  StyledLink,
  WrappedButton,
} from './helpers/styled-components'
import { ConsentScreenProps } from './types'

export const ConsentScreen = (props: ConsentScreenProps) => {
  const {
    title,
    contentIntro,
    content,
    signatureMessage,
    consentText,
    declineText,
    handleNext,
    handleDecline,
    i18n,
  } = props
  const sigCanvasRef = useRef<any>(null)
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const onClear = () => {
    sigCanvasRef.current?.clear()
    setSubmitDisabled(true)
  }

  const handleSignatureEnd = () => {
    if (sigCanvasRef.current && !sigCanvasRef.current.isEmpty()) {
      setSubmitDisabled(false)
    }
  }

  const onAccept = () => {
    // const signature = sigCanvasRef.current?.toDataURL('image/jpeg')
    const signature = ''

    handleNext({ out: 'consent accepted', consented: true, signature })
  }

  return (
    <ModuleHeader
      leftContentAction={handleDecline}
      leftContentTitle={i18n.back_button}
      color="#505358"
    >
      <PageWrapper>
        <ContentWrapper sx={{ marginTop: '0px' }}>
          <PageTitle
            align="left"
            style={{ width: '100%', margin: '24px 0px 0px' }}
          >
            {title}
          </PageTitle>
          <Divider sx={{ margin: '24px 0px 16px' }} />
          <Message sx={{ margin: '0px' }}>{contentIntro}</Message>
          <Divider sx={{ margin: '16px 0px 24px' }} />
          <Message sx={{ whiteSpace: 'pre-wrap' }}>{content}</Message>
          <Message sx={{ fontWeight: 700, color: '#282D37', marginTop: 16 }}>
            {signatureMessage}
          </Message>
          <CanvasWrapper>
            <SignatureCanvas
              clearOnResize={false}
              ref={sigCanvasRef}
              onEnd={handleSignatureEnd}
            />
            <Divider sx={{ width: '90%', margin: '0px auto' }} />
            <div
              onClick={() => onClear()}
              style={{
                cursor: 'pointer',
                maxWidth: 'fit-content',
                margin: '0px auto',
              }}
            >
              <Message sx={{ fontSize: 14, padding: 12, margin: 0 }}>
                {i18n.clear_button}
              </Message>
            </div>
          </CanvasWrapper>
          <ButtonWrapper nativePosition="relative">
            <WrappedButton onClick={onAccept} disabled={submitDisabled}>
              {consentText}
            </WrappedButton>
            <StyledLink onClick={handleDecline}>{declineText}</StyledLink>
          </ButtonWrapper>
        </ContentWrapper>
      </PageWrapper>
    </ModuleHeader>
  )
}

const CanvasWrapper = styled('div')({
  'width': '100%',
  'border': '1px solid #D0D0D0',
  'marginBottom': '48px',
  '& canvas': {
    width: '100%',
    height: '215px',
  },
})
