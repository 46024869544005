import { paths } from '../../pages/paths'
import { RegisterTestStepType } from '../../pages/register-a-test-module/types'
import { StoreStepType } from '../shopify/model/types'
import { FlowEngineModules } from './types'

export interface Feature {
  path: (any?: any) => any
}

export const FEATURES: { [key: string]: Feature } = {
  SymptomCheckerFeature: {
    path: (inputs) => paths.symptomCheckerIntroById(inputs['Questionnaire Id']),
  },
  GetTestedFeature: {
    path: (inputs) => paths.registerTest(RegisterTestStepType.CHOOSE_A_TEST),
  },
  RegisterTestFeature: {
    path: (inputs) => paths.registerTest(RegisterTestStepType.SCAN_CODE),
    // path: (inputs) => paths.flowEngineModule(FlowEngineModules.REGISTER_TEST),
  },
  OffRampFeature: {
    path: (inputs) => paths.flowOfframp(),
  },
  TelehealthFeature: {
    path: (inputs) => paths.telehealthIntake('confirm-location'),
  },
  ShoppingFeature: {
    path: (inputs) => paths.shopifyStore(StoreStepType.PRODUCTS),
  },

  IntroScreen: {
    path: (inputs) => paths.flowEngineModule(FlowEngineModules.INTRO),
  },
  Consent: {
    path: (inputs) => paths.flowEngineModule(FlowEngineModules.CONSENT),
  },
  GenericScreen: {
    path: (inputs) => paths.flowEngineModule(FlowEngineModules.NEXT),
  },
  TimeBasedMessage: {
    path: (inputs) => paths.flowEngineModule(FlowEngineModules.PROCESSING),
  },

  EndExecution: {
    path: (inputs) => paths.app.dashboard(),
  },
}
