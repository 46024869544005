import SafeEcommerceSDKService from './SafeEcommerceService'
import { AuthService } from './services/authService'
import { UserService } from './services/userService'

export const authService = new AuthService()
export const userService = new UserService()
export const ecommerceService = new SafeEcommerceSDKService(
  userService,
  authService
)
