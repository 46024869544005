import { Tab, Tabs } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { capitalizeString } from '../../libs/utils'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import { settingsTabs } from './tabsMapping'
import { SettingsTabs, SettingsTabType } from './type'
import { useMUITheme } from '../../libs/hooks/useTheme'

export const SettingsPage = () => {
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const { tab } = useParams<{ tab?: SettingsTabType }>()
  const [currentTab, setCurrentTab] = useState<SettingsTabType>(
    tab || SettingsTabType.ACCOUNT
  )
  const navigate = useNavigate()
  const { isMobile } = useMUITheme()

  useEffect(() => {
    if (!tab) return
    setCurrentTab(tab)
  }, [tab])

  const renderContent = () => {
    const Content = settingsTabs[currentTab].content

    if (!Content) return null

    return <Content />
  }

  const handleBack = () => {
    navigate(paths.app.dashboard())
  }

  const changeTabs = (tab: SettingsTabType) => {
    navigate(paths.settingsTab(tab, settingsTabs[tab].defaultSection))
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      color="#505358"
    >
      <div className={classes.contentWrapper}>
        {!isMobile && (
          <Tabs
            value={currentTab}
            onChange={(e, v) => changeTabs(v)}
            textColor="primary"
            className={classes.tabs}
          >
            {Object.keys(settingsTabs).map((tabType) => (
              <Tab
                className={classes.tab}
                key={`tab-${tabType}`}
                label={capitalizeString(i18n[settingsTabs[tabType as SettingsTabType].label_key])}
                value={tabType}
              />
            ))}
          </Tabs>
        )}
        {renderContent()}
      </div>
    </ModuleHeader>
  )
}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    margin: '12px 16px',
    [theme.breakpoints.down(600)]: {
      fontSize: 12,
    },
  },
  tabs: {
    width: '100%',
  },
  tab: {
    padding: '0px',
    width: '50%',
    [theme.breakpoints.down('xl')]: {
      fontSize: 12,
    },
  },
}))
