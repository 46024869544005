import { GoogleApiWrapper, IMapProps, Map, Marker } from 'google-maps-react'
import React, { PropsWithChildren, useEffect, useRef } from 'react'
import markerImage from '../../assets/icons/ic_marker.svg'

const mapStyles = {
  width: '100%',
  height: '95%',
  position: 'absolute',
}

const MapContainer = (props: any) => {
  const { facilities, selectedItem } = props
  const map = useRef<any>(null)

  const center = { lat: 34.14801788330078, lng: -118.76729583740234 }
  if (facilities?.length) {
    center.lat = facilities[0].latitude
    center.lng = facilities[0].longitude
  }

  useEffect(() => {
    if (facilities && facilities.length > 0 && map.current?.map) {
      const position = new props.google.maps.LatLng(
        facilities[0].latitude,
        facilities[0].longitude
      )
      map.current.map.setCenter(position)
      map.current.map.setZoom(14)
    }
  }, [facilities])

  useEffect(() => {
    if (!selectedItem) return

    const position = new props.google.maps.LatLng(
      selectedItem.latitude,
      selectedItem.longitude
    )
    if (map.current?.map) {
      map.current.map.setCenter(position)
      map.current.map.setZoom(14)
    }
  }, [selectedItem])

  if (!facilities?.length) return null

  return (
    <Map
      google={props.google}
      style={mapStyles}
      ref={(ref) => (map.current = ref)}
      initialCenter={center}
      mapTypeControl={false}
      fullscreenControl={false}
    />
    //   {facilities.map((item: any, index: number) => {
    //     return (
    //       <Marker
    //         key={`marker-${index}`}
    //         onClick={props.onMarkerClick}
    //         position={{
    //           lat: item.latitude,
    //           lng: item.longitude,
    //         }}
    //         icon={{
    //           url: markerImage,
    //         }}
    //       />
    //     )
    //   })}
    // </Map>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default GoogleApiWrapper({
  apiKey: 'AIzaSyA81t09urAbNO7LQOxAjM4qlHSkBRgywX4',
})(React.memo(MapContainer))
