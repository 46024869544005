import React from 'react'
import { useDispatch } from 'react-redux'
import { formatDate } from '../../../libs/helpers'
import { capitalizeString } from '../../../libs/utils'
import { Loading } from '../../../ui'
import { PageTitle } from '../../../ui/templates/generic-screens/helpers/styled-components'
import { setDetailId, updateSocialHistoryRecord } from '../model'
import {
  DetailListWrapper,
  NoContent,
} from '../shared/components/shared-components'
import {
  Allergy,
  Condition,
  FamilyHistory,
  HealthRecordProps,
  HealthRecordType,
  HistoryItem,
  Medication,
  Procedure,
  socialHistoryTitles,
  SocialHistoryType,
  Vaccination,
  VitalComponentCodes,
  VitalsItem,
  vitalTitles,
} from '../type'
import {
  AllergyDetail,
  ConditionDetail,
  FamilyMemberDetail,
  MedicationDetail,
  SocialHistoryDetail,
  VaccinationDetail,
} from './detail-mapping'

export const StandardRecordListPage = (props: HealthRecordProps) => {
  const { i18n, state, type, title } = props
  const dispatch = useDispatch()

  const handleUpdateSocial = (
    updatedValue: any,
    selectedParam: SocialHistoryType
  ) => {
    const updatedData = {
      [selectedParam]: {
        ...state.listState[HealthRecordType.SOCIAL_HISTORY]?.[selectedParam],
        codeDetail: {
          ...state.listState[HealthRecordType.SOCIAL_HISTORY]?.[selectedParam]
            .codeDetail,
          display: updatedValue,
        },
        dateRecorded: new Date().toISOString(),
        operationType: 'Edit',
      },
    }
    dispatch(updateSocialHistoryRecord(type, updatedData))
  }

  if (state.detailId) {
    switch (type) {
      case HealthRecordType.ALLERGY: {
        const detail = state.listState[type].find(
          (item: { id: string }) => item.id === state.detailId
        ) as Allergy
        return <AllergyDetail allergy={detail} i18n={i18n} />
      }
      case HealthRecordType.MEDICATIONS: {
        const detail = state.listState[type].find(
          (item: { id: string }) => item.id === state.detailId
        ) as Medication
        return <MedicationDetail medication={detail} i18n={i18n} />
      }
      case HealthRecordType.CONDITIONS: {
        const detail = state.listState[type].find(
          (item: { id: string }) => item.id === state.detailId
        ) as Condition
        return <ConditionDetail condition={detail} i18n={i18n} />
      }
      case HealthRecordType.FAMILY_MEMBER_HISTORY: {
        const detail = state.listState[type].find(
          (item: { id: string }) => item.id === state.detailId
        ) as FamilyHistory
        return <FamilyMemberDetail history={detail} i18n={i18n} />
      }
      case HealthRecordType.VACCINATIONS: {
        const detail = state.listState[type].find(
          (item: { id: string }) => item.id === state.detailId
        ) as Vaccination
        return <VaccinationDetail vaccination={detail} i18n={i18n} />
      }
      case HealthRecordType.SOCIAL_HISTORY: {
        const getDetail = () => {
          switch (state.detailId) {
            case i18n[
              socialHistoryTitles[SocialHistoryType.sexualOrientation]
            ]: {
              return SocialHistoryType.sexualOrientation
            }
            case i18n[socialHistoryTitles[SocialHistoryType.alcohol]]: {
              return SocialHistoryType.alcohol
            }
            case i18n[socialHistoryTitles[SocialHistoryType.drug]]: {
              return SocialHistoryType.drug
            }
            case i18n[socialHistoryTitles[SocialHistoryType.smoking]]: {
              return SocialHistoryType.smoking
            }
            default:
              return null
          }
        }

        const selectedParam = getDetail()
        if (!selectedParam) return null
        return (
          <SocialHistoryDetail
            detailTitle={state.detailId}
            selectedParam={selectedParam}
            currentValue={
              state.listState[type]?.[selectedParam].codeDetail?.display || ''
            }
            i18n={i18n}
            handleUpdate={handleUpdateSocial}
          />
        )
      }
      default:
        return null
    }
  }

  const listView = () => {
    if (
      type !== HealthRecordType.SOCIAL_HISTORY &&
      state.listState[type] &&
      state.listState[type].length === 0
    ) {
      return (
        <NoContent
          title={i18n.patient_records_no_record.replace('%@', title)}
        />
      )
    }
    switch (type) {
      case HealthRecordType.ALLERGY: {
        return (
          <>
            {state.listState[type]?.map((record: Allergy) => (
              <DetailListWrapper
                key={record.id}
                id={record.id}
                title={record.allergyCode.display}
                body={capitalizeString(record.clinicalStatus)}
                handleClick={(id: string) => dispatch(setDetailId(id))}
              />
            ))}
          </>
        )
      }
      case HealthRecordType.CONDITIONS: {
        return (
          <>
            {state.listState[type]?.map((record: Condition) => (
              <DetailListWrapper
                key={record.id}
                id={record.id}
                title={record.conditionCode.display}
                body={capitalizeString(record.clinicalStatus)}
                handleClick={(id: string) => dispatch(setDetailId(id))}
              />
            ))}
          </>
        )
      }
      case HealthRecordType.PROCEDURE: {
        return (
          <>
            {state.listState[type]?.map((record: Procedure) => (
              <DetailListWrapper
                key={record.id}
                id={record.id}
                title={record.operationType}
                body={formatDate(
                  record.performedStartDate,
                  false,
                  undefined,
                  'MMM. d, y'
                )}
                handleClick={(id: string) => dispatch(setDetailId(id))}
              />
            ))}
          </>
        )
      }
      case HealthRecordType.MEDICATIONS: {
        return (
          <>
            {state.listState[type]?.map((record: Medication) => (
              <DetailListWrapper
                key={record.id}
                id={record.id}
                title={record.medicationCode?.display || ''}
                body={capitalizeString(record.status)}
                handleClick={(id: string) => dispatch(setDetailId(id))}
              />
            ))}
          </>
        )
      }
      case HealthRecordType.FAMILY_MEMBER_HISTORY: {
        return (
          <>
            {state.listState[type]?.map((record: FamilyHistory) => (
              <DetailListWrapper
                key={record.id}
                id={record.id}
                title={record.name || ''}
                body={record.relationShip?.display || ''}
                handleClick={(id: string) => dispatch(setDetailId(id))}
              />
            ))}
          </>
        )
      }
      case HealthRecordType.VACCINATIONS: {
        return (
          <>
            {state.listState[type]?.map((record: Vaccination) => (
              <DetailListWrapper
                key={record.id}
                id={record.id}
                title={record.vgName || ''}
                body={formatDate(
                  record.administeredDate,
                  false,
                  undefined,
                  'MMM. d, y'
                )}
                handleClick={() => {}}
              />
            ))}
          </>
        )
      }
      case HealthRecordType.SOCIAL_HISTORY: {
        const historyKeys = Object.keys(state.listState[type] || {})
        const values = Object.values(
          state.listState[type] || {}
        ) as HistoryItem[]
        return (
          <>
            {values.map((record: HistoryItem, i) => (
              <DetailListWrapper
                key={record.id}
                id={i18n[socialHistoryTitles[historyKeys[i]]]}
                title={i18n[socialHistoryTitles[historyKeys[i]]]}
                body={record.codeDetail?.display || i18n.UNKNOWN}
                handleClick={(id: string) => dispatch(setDetailId(id))}
              />
            ))}
          </>
        )
      }
      case HealthRecordType.VITAL: {
        const vitalKeys = Object.keys(state.listState[type][0] || {})
        const values = Object.values(
          state.listState[type][0] || {}
        ) as VitalsItem[]
        return (
          <>
            {values.map((record: VitalsItem, i) => {
              // ignore the non-vitals keys
              if (!record.vitalCode) return null
              let vitalInputValue = `${record.vitalQuantity?.display} ${record.vitalQuantity?.codeValue}`
              // eslint-disable-next-line sonarjs/no-collapsible-if
              // Todo Add additional split value components - e.g. ft/in
              if (record.vitalComponent) {
                if (
                  record.vitalComponent[0].vitalQuantity?.codeValue ===
                  VitalComponentCodes.systolic_diastolic
                )
                  vitalInputValue = `${record.vitalComponent[0].vitalQuantity?.display}/${record.vitalComponent[1].vitalQuantity?.display} ${record.vitalComponent[0].vitalQuantity?.codeValue}`
              }

              return (
                <DetailListWrapper
                  key={record.id}
                  id={record.id}
                  title={i18n[vitalTitles[vitalKeys[i]]]}
                  body={`${vitalInputValue} | ${formatDate(
                    state.listState[type][0]?.effectiveDateTime,
                    false,
                    undefined,
                    'MMM. d, y'
                  )}`}
                  handleClick={() => {}}
                />
              )
            })}
          </>
        )
      }
      default:
        return null
    }
  }

  return (
    <>
      <PageTitle>{title}</PageTitle>
      {state.isLoading ? (
        <Loading size={100} style={{ marginTop: '20vh' }} />
      ) : (
        listView()
      )}
    </>
  )
}
