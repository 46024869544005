/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box } from '@mui/material'
import { ClassNameMap, makeStyles } from '@mui/styles'

import { useTheme } from '@mui/material/styles'
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import { format } from 'date-fns'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectLanguageContent } from '../../../features/translation'
import { I18n } from '../../../features/translation/types'
import { hexToRGBA } from '../../../libs/helpers'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { Observer } from '../../../libs/observer'
import { PageTitle } from '../../../libs/shared-components'
import { capitalizeString } from '../../../libs/utils'
import { PageLoading, Typography } from '../../../ui'
import { paths } from '../../paths'
import { getTestResultsMore } from '../model/model'
import {
  selectIsDone,
  selectIsLoading,
  selectTestResults,
} from '../model/selectors'
import { AllowedResults } from '../model/types'

export const TestResultsList = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const testResults = useSelector(selectTestResults)
  const isDone = useSelector(selectIsDone)
  const isLoading = useSelector(selectIsLoading)
  const i18n = useSelector(selectLanguageContent)

  const getMoreItems = () => {
    dispatch(getTestResultsMore())
  }
  if (isLoading && testResults?.length === 0) return <PageLoading size={100} />
  return (
    <div className={classes.listWrapper}>
      <div className={classes.content}>
        <PageTitle>{i18n.test_history_screen_title}</PageTitle>
        <NoTestResults i18n={i18n} classes={classes} />
        {testResults?.map((testResult, index) => (
          <React.Fragment key={testResult._id}>
            {testResults.length >= 20 &&
              !isDone &&
              testResults.length - 5 === index && (
                <Observer callback={getMoreItems} />
              )}
            <ResultItem
              testId={testResult._id}
              classes={classes}
              testResult={testResult}
              i18n={i18n}
              created={testResult.created}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

const NoTestResults = (props: { classes: ClassNameMap; i18n: I18n }) => {
  const { i18n, classes } = props
  const testResults = useSelector(selectTestResults)
  if (testResults?.length !== 0) return null
  return (
    <Typography className={classes.noItemsTitle}>
      {i18n.no_test_results}
    </Typography>
  )
}

const ResultItem = ({
  created,
  testId,
  classes,
  i18n,
  testResult,
}: {
  created: string
  testId: string
  classes: ClassNameMap
  i18n: I18n
  testResult: any
}) => {
  const navigate = useNavigate()
  const { isMobile } = useMUITheme()

  const testImage =
    testResult.details?.orderableTest?.productAssetDetail?.imageURL ||
    testResult.details?.orderablePanel?.productAssetDetail?.imageURL

  if (isMobile) {
    return (
      <Box
        className={classes.listItemBox}
        onClick={() => navigate(paths.testResultDetails(testId))}
      >
        <div className={classes.mobileContent}>
          <Typography variant="body1" className={classes.name}>
            {testResult.details.orderableTest?.customName ||
              testResult.details.orderablePanel?.shortName ||
              i18n.UNKNOWN}
          </Typography>
          <div className={classes.resultWrapperMobile}>
            <Result classes={classes} result={testResult.result} i18n={i18n} />
            <div
              style={{
                height: 14,
                borderRight: 'solid 2px #757575',
              }}
            />
            <Typography className={classes.date}>{`${
              created && format(new Date(created), 'MMM. d, y')
            }`}</Typography>
          </div>
        </div>
        {testImage ? (
          <div className={classes.imageWrapper}>
            <div
              className={classes.imageContainer}
              style={{
                backgroundImage: `url(${testImage})`,
              }}
            />
          </div>
        ) : (
          <div style={{ width: 64, height: 64 }} />
        )}
        <ChevronRightSharpIcon />
      </Box>
    )
  }
  return (
    <Box
      className={classes.listItemBox}
      onClick={() => navigate(paths.testResultDetails(testId))}
    >
      <Typography variant="body1" className={classes.name}>
        {testResult.details.orderableTest?.customName ||
          testResult.details.orderablePanel?.shortName ||
          i18n.UNKNOWN}
      </Typography>

      <Typography className={classes.date}>{`${
        created && format(new Date(created), 'MMM. d, y')
      }`}</Typography>

      <Result classes={classes} result={testResult.result} i18n={i18n} />
      {testImage ? (
        <div className={classes.imageWrapper}>
          <div
            className={classes.imageContainer}
            style={{
              backgroundImage: `url(${testImage})`,
            }}
          />
        </div>
      ) : (
        <div style={{ width: 64, height: 64 }} />
      )}
      <ChevronRightSharpIcon />
    </Box>
  )
}

const Result = ({
  result,
  classes,
  i18n,
}: {
  result: AllowedResults | undefined
  classes: ClassNameMap
  i18n: I18n
}) => {
  const theme = useTheme()
  const getColor = (result: AllowedResults | undefined) => {
    switch (result) {
      case 'POSITIVE': {
        return '#D45D39'
      }
      case 'NEGATIVE': {
        return '#009606'
      }
      case 'INVALID': {
        return theme.palette.warning.main
      }
      case 'ERROR': {
        return theme.palette.warning.main
      }
      default:
        return '#505358'
    }
  }

  return (
    <>
      {result ? (
        <>
          <div className={classes.pill}>
            <Typography
              align="center"
              className={classes.pillText}
              style={{ color: getColor(result) }}
            >
              {result ? capitalizeString(result) : i18n.in_progress}
            </Typography>
          </div>
        </>
      ) : (
        <div className={classes.pill}>
          <Typography
            align="center"
            className={classes.pillText}
            style={{ color: '#757575' }}
          >
            {i18n.in_progress}
          </Typography>
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  noItemsTitle: {
    padding: '1rem',
  },
  listWrapper: {
    marginTop: '78px',
    width: '100%',
    maxWidth: '1200px',
    margin: '0px auto',
    [theme.breakpoints.down(960)]: {
      width: '90vw',
      marginTop: '24px',
    },
  },
  content: {
    padding: '0px 78px',
    [theme.breakpoints.down(960)]: {
      padding: 0,
    },
  },
  listItemBox: {
    'padding': '16px 24px',
    'display': 'flex',
    // 'width': '100%',
    'alignItems': 'center',
    'marginTop': '-1px',
    'justifyContent': 'space-between',
    'borderTop': '1px solid #E0E0E0',
    'borderBottom': '1px solid #E0E0E0',
    'transition': 'all .03s ease-in-out',
    '&:hover': {
      transition: 'all .03s ease-in-out',
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
    },
    [theme.breakpoints.down(960)]: {
      padding: '16px 12px',
    },
    [theme.breakpoints.down(600)]: {
      padding: '16px 0px',
    },
  },
  name: {
    'margin': '4px 0px',
    'lineHeight': '1.1rem',
    'fontWeight': 600,
    'display': '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    'width': 300,
    [theme.breakpoints.down(600)]: {
      minWidth: 'unset',
      maxWidth: '65vw',
    },
  },
  result: {
    fontWeight: 600,
    fontSize: '16px',
    minWidth: 125,
    [theme.breakpoints.down(600)]: {
      minWidth: 'unset',
    },
  },
  mobileContent: {
    display: 'flex',
    flexDirection: 'column',
    alingItems: 'flex-start',
  },
  resultWrapperMobile: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  date: {
    fontSize: 16,
    fontWeight: 500,
    color: '#757575',
    minWidth: 125,
    [theme.breakpoints.down(600)]: {
      minWidth: 'unset',
      fontSize: 14,
    },
  },
  pill: {
    margin: '4px 0px',
    padding: '0px 8px',
    borderRadius: '16px',
    minWidth: 125,
    [theme.breakpoints.down(600)]: {
      minWidth: 'unset',
      padding: 0,
      margin: 0,
    },
  },
  pillText: {
    fontWeight: 600,
    fontSize: '16px',
    [theme.breakpoints.down(600)]: {
      fontSize: 14,
    },
  },
  imageWrapper: {
    height: 64,
    width: 64,
    backgroundColor: '#F3F3F3',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    height: 32,
    width: 48,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
  },
}))
