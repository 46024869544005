import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Slider, Typography, useTheme } from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getColor } from '../../../libs/utils'
import { getQuestionnaireColors } from '../outcomes/helpers/utils'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  selectQuestionnaireColor,
  updateAnswer,
} from '../questionnaireV2Slice'
import styles from './test.module.css'

export const SliderQuestion = () => {
  const questionnaireColor = useSelector(selectQuestionnaireColor)
  const theme = useTheme()
  const dispatch = useDispatch()
  const question = useSelector(selectCurrentQuestion)
  const existingAnswer = useSelector(selectExistingAnswer)
  const [sliderVal, setSliderVal] = useState(question.min || 0)

  useEffect(() => {
    if (existingAnswer) {
      setSliderVal(Number(existingAnswer.value))
      dispatch(updateAnswer(Number(existingAnswer.value)))
    } else {
      setSliderVal(question.min || 0)
    }
  }, [question, existingAnswer])

  const handleChange = (e: Event, value: number | number[]) => {
    let result: number
    if (typeof value === 'object') {
      result = value[0]
    } else result = value
    dispatch(updateAnswer(result))
    setSliderVal(result)
  }

  const sliderDecrement = () => {
    let newValue = sliderVal || question.min || 0
    newValue--
    if (newValue >= (question.min || 0)) {
      dispatch(updateAnswer(newValue))
      setSliderVal(newValue)
    } else {
      setSliderVal(question.min || 0)
    }
  }

  const sliderIncrement = () => {
    let newValue = sliderVal || question.min || 0
    newValue++
    if (newValue <= (question.max || 1)) {
      dispatch(updateAnswer(newValue))
      setSliderVal(newValue)
    }
  }

  return (
    <div
      className={`${styles.questionWrapper} text-center mx-auto d-flex flex-grow`}
    >
      <div className="row flex-grow">
        <div className="col-12 fb-auto mb-auto">
          <div className={styles.rangeSliderWrapper}>
            <span
              className={`${styles.rsArrow} ${styles.rsArrowLeft}`}
              onClick={sliderDecrement}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: getColor(
                    getQuestionnaireColors(questionnaireColor),
                    theme
                  ),
                }}
              >
                <ChevronLeftIcon color="inherit" />
              </Box>
            </span>
            <div className={styles.rangeSliderInner} >
              <IOSSlider
                valueLabelDisplay="on"
                min={question.min || undefined}
                max={question.max || undefined}
                value={sliderVal}
                step={question.step || 1}
                onChange={handleChange}
              />
            </div>
            <span className={`${styles.rsArrow}`} onClick={sliderIncrement}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: getColor(
                    getQuestionnaireColors(questionnaireColor),
                    theme
                  ),
                }}
              >
                <ChevronRightIcon color="inherit" />
              </Box>
            </span>
          </div>
          {/* {question.unit === 'temperature' && (
            <Box style={{ width: '100%' }}>
              <ScalePill showC={showC} setShowC={setShowC} />
            </Box>
          )} */}
        </div>
      </div>
    </div>
  )
}

const ScalePill = (props: {
  showC: boolean
  setShowC: (prop1: boolean) => void
}) => {
  const classes = useStyles()
  const { showC, setShowC } = props

  const handleClick = () => {
    setShowC(!showC)
  }
  return (
    <div className={classes.pillContainer} onClick={() => handleClick()}>
      <Typography
        align="center"
        className={!showC ? `${classes.degree} selected` : classes.degree}
      >
        °F
      </Typography>
      <Typography
        align="center"
        className={showC ? `${classes.degree} selected` : classes.degree}
      >
        °C
      </Typography>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  pillContainer: {
    margin: '0px auto',
    width: '100px',
    heigh: '32px',
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '16px',
    padding: '1px',
    cursor: 'pointer',
  },
  degree: {
    'width': '50px',
    'color': '#FFF',
    '&.selected': {
      width: '50px',
      borderRadius: '16px',
      color: theme.palette.secondary.main,
      backgroundColor: '#FFF',
    },
  },
}))

const iOSBoxShadow = '0px 2px 9px 0px #00000040'

const thumbWidth = 78

const IOSSlider = withStyles(() => ({
  root: {
    color: 'transparent',
    height: 2,
    padding: '30px 0',
    width: `calc(100% - ${thumbWidth}px - 10px)`,
  },
  thumb: {
    'height': 38,
    'width': thumbWidth,
    'backgroundColor': '#fff',
    'boxShadow': iOSBoxShadow,
    'marginTop': 4,
    'borderRadius': 30,
    'marginLeft': 44,
    '&.Mui-focusVisible': {
      boxShadow: iOSBoxShadow,
    },
    '&:focus, &:hover, &$active': {
      'boxShadow': iOSBoxShadow,
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    fontFamily: 'Avenir Next, Avenir Next Rounded Medium',
    fontWeight: 500,
    fontSize: 16,
    top: 35,
    background: 'transparent',
    color: '#757575',
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    marginTop: 4,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
    width: `calc(100% + ${thumbWidth}px + 10px)`,
  },
}))(Slider)
