import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATION_TYPES } from '../../constants'
import { handleMessage } from '../../libs/helpers'
import { Observer } from '../../libs/observer'
import { PageTitle } from '../../libs/shared-components'
import { shopifyStoreStyles } from '../../pages/_styles/shopifyStoreStyles'
import { paths } from '../../pages/paths'
import { Loading } from '../../ui'
import { selectLanguageContent } from '../translation'
import { selectCartItemCount, setCartCount } from './model/shopifyStoreSlice'
import { StoreStepType } from './model/types'
import { ProductListItem } from './shopify-ui-items'
import { ShopLoading } from './shopify-ui-items/storeLoading'

export const ShopifyListPage = (props: { sdk: any }) => {
  const classes = shopifyStoreStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const { sdk } = props
  const cartItems = useSelector(selectCartItemCount)
  const [isLoading, setIsloading] = useState(false)
  const [observerLoading, setObserverLoading] = useState(false)
  const [productsState, setProductsState] = useState(
    sdk.stateManager.currentProductsViewState()
  )

  let isObserverSetup = false

  useEffect(() => {
    setIsloading(true)
    setupObserver()
    setTimeout(() => {
      loadProducts(false)
    }, 250)
  }, [])

  useEffect(() => {
    if (productsState.cartItemsCount !== cartItems) {
      dispatch(setCartCount(productsState.cartItemsCount))
    }
    if (productsState.products && productsState.products.length === 1) {
      sessionStorage.setItem('singleChoice', 'y')
      selectProduct(productsState.products[0].identifier)
    }
  }, [productsState, isLoading])

  const setupObserver = () => {
    if (isObserverSetup) {
      return
    }
    sdk.stateManager.observeProductsViewState(
      (productsViewState: any) => {
        if (productsViewState.cartItemsCount !== cartItems) {
          dispatch(setCartCount(productsViewState.cartItemsCount))
        }
        setProductsState(productsViewState)
      },
      (error: any) => {
        console.log('Error observing products view state: ', error)
      }
    )
    isObserverSetup = true
  }
  const loadProducts = (loadsNextPage: any) => {
    if (isLoading) {
      return
    }

    if (!loadsNextPage) {
      setIsloading(true)
    } else {
      setObserverLoading(true)
    }

    sdk.productManager
      .loadProducts(loadsNextPage)
      .then((productsViewState: any) => {
        setIsloading(false)
        setObserverLoading(false)
      })
      .catch((error: any) => {
        console.log(error)
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        setIsloading(false)
        setObserverLoading(false)
      })
  }

  const selectProduct = (productId: string) => {
    navigate(
      paths.shopifyProductDetail(StoreStepType.PRODUCT_DETAIL, productId)
    )
  }

  if (isLoading) return <ShopLoading />

  return (
    <div className={classes.listContent}>
      <PageTitle style={{ paddingLeft: 16 }}>
        {i18n['ecommerce.add_to_cart_button']}
      </PageTitle>
      <div className={classes.testListWrapper}>
        {productsState &&
          productsState.products.length > 0 &&
          productsState.products.map((product: any) => (
            <ProductListItem
              key={product.identifier}
              product={product}
              color="#F5F5F5"
              handleNext={selectProduct}
            />
          ))}
        {productsState.hasNextPage && (
          <Observer callback={() => loadProducts(true)} />
        )}
        {observerLoading && <Loading />}
      </div>
    </div>
  )
}
