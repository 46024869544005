import { Loader } from '@googlemaps/js-api-loader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import { Box, Dialog, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormikProps, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATION_TYPES, USAStateList } from '../../../../constants'
import { updateEncounterRecord } from '../../../../features/encounter/model'
import {
  createExternalConsult,
  getHealthServiceList,
  selectHealthServiceList,
} from '../../../../features/health-service-lite/model'
import { showNotification } from '../../../../features/notifications/model'
import { I18n } from '../../../../features/translation/types'
import { cleanPhoneNumber } from '../../../../libs/utils'
import { lsClient } from '../../../../ls-client'
import { Button, FilledTextField, Loading, MaskedInput } from '../../../../ui'
import { FilledSelect } from '../../../../ui/atoms/select'
import { selectNetworkSettings } from '../../../network/model'
import { TelehealthOptions } from '../../../network/type'
import { paths } from '../../../paths'
import { selectProfileData, updateUserData } from '../../../profile/model'
import { createSession } from '../../../telehealth/model'
import { CurrentOptions, DispositionScreen } from '../../types'
import { CurrentLocation, CurrentPhoneNumber } from '../helpers/current-options'
import { FormValues } from '../helpers/formik/types'
import { validationSchema } from '../helpers/formik/validationSchema'

export const ConfirmLocation = (props: {
  i18n: I18n
  dispositionType: DispositionScreen
}) => {
  const { i18n, dispositionType } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const healthServiceList = useSelector(selectHealthServiceList)
  const networkSettings = useSelector(selectNetworkSettings)
  const profileData = useSelector(selectProfileData)
  const [editType, setEditType] = useState<CurrentOptions | ''>('')
  const [hasMissingData, setHasMissingData] = useState(false)
  const { line1, line2, city, state, zip } = profileData.shippingAddress || {}

  useEffect(() => {
    if (
      profileData.email &&
      profileData._id &&
      healthServiceList.length === 0
    ) {
      dispatch(
        getHealthServiceList(profileData._id, 'InProgress', profileData.email)
      )
    }
    if (!(line1 || city || state || zip)) {
      setHasMissingData(true)
    }
  }, [profileData])

  const enableContinue = !hasMissingData || !profileData.contact.mobileNumber

  const onSubmit = async (values: FormValues) => {
    const { line1, line2, city, state, zip, phone } = values

    const shippingAddress = {
      line1,
      line2,
      city,
      state,
      zip,
    }

    const payload = {
      ...profileData,
      contact: {
        mobileNumber: phone,
      },
      shippingAddress,
    }

    setEditType('')
    await dispatch(updateUserData(payload))
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      line1: line1 || '',
      line2: line2 || '',
      city: city || '',
      state: state || '',
      zip: zip || '',
      phone: profileData?.contact.mobileNumber || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema(i18n),
    onSubmit,
  })

  const updateInfo = () => {
    onSubmit(formik.values)
    setEditType('')
  }

  const onConfirm = () => {
    const healthServiceId = lsClient.getUserLSByKey('healthServiceId')
    const firstViewQuestionnaireExecutionIDs = [
      healthServiceList[0].virtualConsults[0].id,
      healthServiceList[0].virtualConsults[1].id,
    ]
    const questionnaireExecutionID = healthServiceList[0].virtualConsults[1].id
    const success = () => {
      navigate(paths.beInTouch())
    }
    if (
      networkSettings?.telehealthConfiguration !== TelehealthOptions.FIRST_VIEW_FINANCIAL
    ) {
      const callback = () => {
        const data = {
          healthServiceId,
        }
        dispatch(createSession(data, success))
      }
      const encounterParams = {
        _id: healthServiceId,
        contact: {
          countryCode: '+1',
          phoneNumber: formik.values.phone,
        },
        currentLocation: {
          line1: formik.values.line1,
          line2: formik.values.line2,
          city: formik.values.city,
          state: formik.values.state,
          zip: formik.values.zip,
        },
      }
      dispatch(updateEncounterRecord(encounterParams, callback))
    } else {
      const data = {
        type: 'TELEHEALTH_CONSULT',
        firstViewQuestionnaireExecutionIDs,
        questionnaireExecutionID,
      }
      dispatch(createExternalConsult(data, healthServiceId, success))
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.messageContainer}>
          <Typography
            variant="h4"
            align="center"
            style={{ marginBottom: '24px' }}
          >
            {dispositionType.title}
          </Typography>
          <Typography align="center" className={classes.body}>
            {dispositionType.body}
          </Typography>
          <CurrentPhoneNumber
            i18n={i18n}
            type="phone"
            profileData={profileData}
            setEditType={setEditType}
          />
          <CurrentLocation
            i18n={i18n}
            type="shipping-address"
            profileData={profileData}
            setEditType={setEditType}
            hasMissingData={hasMissingData}
          />
          <Button
            className={classes.button}
            onClick={onConfirm}
            disabled={!enableContinue}
          >
            {i18n.checkout_confirm_label}
          </Button>
        </Box>
      </div>
      <EditPhoneDialog
        open={editType === 'phone'}
        i18n={i18n}
        closeEditDialog={setEditType}
        formik={formik}
        updateInfo={updateInfo}
      />
      <EditShippingDialog
        open={editType === 'shipping-address'}
        i18n={i18n}
        closeEditDialog={setEditType}
        formik={formik}
        updateInfo={updateInfo}
      />
    </>
  )
}

const EditPhoneDialog = (props: {
  open: boolean
  i18n: I18n
  closeEditDialog: (state: CurrentOptions | '') => void
  formik: FormikProps<FormValues>
  updateInfo: () => void
}) => {
  const { open, closeEditDialog, i18n, formik, updateInfo } = props
  const classes = useStyles()

  const values = formik.values
  const errors = formik.errors
  const touched = formik.touched

  const onPhoneChange = (e: React.ChangeEvent<{ value: string }>) => {
    const cleanNumber = cleanPhoneNumber(e.target.value)
    formik.setFieldValue('phone', cleanNumber)
  }

  const close = () => {
    closeEditDialog('')
  }

  return (
    <Dialog open={open} fullScreen>
      <Box className={classes.dialogWrapper}>
        <Box className={classes.dialogContent}>
          <IconButton onClick={close} className={classes.backButton} size="large">
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Box className={classes.headerWrapper}>
            <Typography variant="h4" className={classes.title}>
              Edit phone number
            </Typography>
            <Typography variant="body1" className={classes.body}>
              This will be the phone number your licensed provider will use to
              call you.
            </Typography>
          </Box>
          <MaskedInput
            mask="+1 (999) 999-9999"
            maskChar=""
            value={values.phone}
            variant="filled"
            name="phone"
            onChange={(e) => onPhoneChange(e)}
            label={i18n.phone_number}
            onBlur={formik.handleBlur}
            error={touched?.phone && Boolean(errors?.phone)}
            helperText={touched?.phone ? errors?.phone : ''}
            required
          />
          <Box className={classes.lgButtonBox}>
            <Button onClick={updateInfo} className={classes.button}>
              {i18n.checkout_update_label}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

const EditShippingDialog = (props: {
  open: boolean
  i18n: I18n
  closeEditDialog: (state: CurrentOptions | '') => void
  formik: FormikProps<FormValues>
  updateInfo: () => void
}) => {
  const { open, closeEditDialog, i18n, formik, updateInfo } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [gettingLocation, setGettingLocation] = useState(false)

  const loadScript = async (): Promise<void> => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
      version: 'weekly',
    })

    await loader.load()
  }

  async function reverseGeo(lat: number, long: number) {
    await loadScript()
    const geocoder = new google.maps.Geocoder()

    const latlng = {
      lat,
      lng: long,
    }

    const apiResponse = (result: google.maps.GeocoderResult[] | null) => {
      if (!result) return
      if (result[0].address_components[6].short_name !== 'US') {
        dispatch(
          showNotification(
            'Your current location appears to be outside of the United States.',
            NOTIFICATION_TYPES.ERROR
          )
        )
        setGettingLocation(false)
        return
      }
      formik.setFieldValue(
        'line1',
        `${result[0].address_components[0].short_name} ${result[0].address_components[1].short_name}`
      )
      formik.setFieldValue('city', result[0].address_components[3].short_name)
      formik.setFieldValue('state', result[0].address_components[5].short_name)
      formik.setFieldValue('zip', result[0].address_components[7].short_name)
      setGettingLocation(false)
    }
    try {
      geocoder.geocode({ location: latlng }, apiResponse)
    } catch {
      setGettingLocation(false)
      dispatch(
        showNotification(
          'We are unable to get your current location. Please edit changes manually.',
          NOTIFICATION_TYPES.ERROR
        )
      )
    }
  }

  const getCurrentLocation = async () => {
    if (gettingLocation) return
    setGettingLocation(true)
    const { latitude, longitude } =
      (await getPosition()) as GeolocationCoordinates
    reverseGeo(latitude, longitude)
  }

  const getPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords)
        },
        (err) => {
          reject(err)
        }
      )
    })
  }

  const values = formik.values
  const errors = formik.errors
  const touched = formik.touched
  const close = () => {
    closeEditDialog('')
  }

  return (
    <Dialog open={open} fullScreen>
      <Box className={classes.dialogWrapper}>
        <Box className={classes.dialogContent}>
          <IconButton onClick={close} className={classes.backButton} size="large">
            <ArrowBackIcon color="primary" />
          </IconButton>
          <form className={classes.form}>
            <Box className={classes.headerWrapper}>
              <Typography variant="h4" className={classes.title}>
                Edit current location
              </Typography>
              <Typography variant="body1" className={classes.body}>
                Used to locate pharmacies near you and pairs you with a licensed
                provider in your state.
              </Typography>
              <div
                className={classes.useCurrentContainer}
                onClick={() => getCurrentLocation()}
              >
                {gettingLocation ? (
                  <div className={classes.loadingOverlay}>
                    <Loading />
                  </div>
                ) : (
                  <LocationOnOutlinedIcon
                    color="primary"
                    style={{ margin: '8px 8px' }}
                  />
                )}
                <Typography variant="body1" color="primary">
                  Use my current location
                </Typography>
              </div>
              <Typography variant="body1" className={classes.edit}>
                Or edit directly
              </Typography>
            </Box>
            <div className={classes.entries}>
              <FilledTextField
                label={i18n.street_address}
                autoComplete="address-line1"
                name={'line1'}
                type={'text'}
                value={values.line1}
                error={touched.line1 && Boolean(errors.line1)}
                helperText={touched.line1 ? errors.line1 : ''}
                variant="filled"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              />
              <FilledTextField
                label={i18n.patient_records_apt_or_suit}
                autoComplete="address-line2"
                name={'line1'}
                type={'text'}
                value={values.line2}
                error={touched.line2 && Boolean(errors.line2)}
                helperText={touched.line2 ? errors.line2 : ''}
                variant="filled"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FilledTextField
                label={i18n.city}
                autoComplete="address-level2"
                name={'city'}
                type={'text'}
                value={values.city}
                error={touched.city && Boolean(errors.city)}
                helperText={touched.city ? errors.city : ''}
                variant="filled"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FilledSelect
                className={classes.selectElm}
                label={i18n.state}
                options={USAStateList}
                autoComplete="address-level1"
                value={values.state}
                fullWidth
                variant="filled"
                name={'state'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state ? errors.state : ''}
                required
              />
              <MaskedInput
                mask="99999"
                maskChar=""
                label={i18n.zip_code}
                value={values.zip}
                variant="filled"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={touched.zip && Boolean(errors.zip)}
                helperText={touched.zip ? errors.zip : ''}
                name={'zip'}
                required
              />
            </div>
            <Box className={classes.lgButtonBox}>
              <Button onClick={updateInfo} className={classes.button}>
                {i18n.checkout_update_label}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '500px',
    [theme.breakpoints.down(600)]: {
      width: '90vw',
      maxWidth: 'unset',
    },
  },
  messageContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '48px',
    justifyContent: 'space-between',
    [theme.breakpoints.down(389)]: {
      marginTop: '24px',
    },
  },
  title: {
    fontWeight: 500,
    marginBottom: '24px',
    textAlign: 'inherit',
    [theme.breakpoints.down(600)]: {
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  body: {
    fontWeight: 500,
    marginBottom: '24px',
    textAlign: 'center',
    [theme.breakpoints.down(600)]: {
      marginBottom: '16px',
      width: '100%',
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      marginBottom: '10px',
      lineHeight: '20px',
    },
  },
  loadingOverlay: {
    transform: 'scale(.50)',
  },
  button: {
    width: '100%',
    maxWidth: '100%',
    maxHeight: 45,
    margin: '32px auto',
    [theme.breakpoints.down(600)]: {
      position: 'absolute',
      width: '90vw',
      bottom: 0,
    },
  },
  form: {
    'marginTop': 'auto',
    'marginBottom': 'auto',
    'width': '100%',
    'boxSizing': 'border-box',
    'padding': '0px',
    'display': 'flex',
    'flexDirection': 'column',
    'flexGrow': 1,
    '@media (max-width: 500px)': {},
  },
  entries: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  selectElm: {
    width: '100%',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'fit-content',
    padding: '10px 24px',
    width: '100%',
    height: '46px',
    [theme.breakpoints.down(576)]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  dialogWrapper: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F7F7F7',
    height: '100vh',
    // [theme.breakpoints.down(600)]: {
    //   // padding: '0px',
    // },
  },
  dialogContent: {
    width: '500px',
    margin: '40px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    gap: '32px',
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  useCurrentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginBottom: '24px',
  },
  backButton: {
    alignSelf: 'flex-start',
    height: 42,
    width: 42,
    [theme.breakpoints.down(300)]: {
      height: 24,
      width: 24,
    },
  },
  edit: {
    alignSelf: 'flex-start',
    marginBottom: '12px',
  },
}))
