import styled from '@emotion/styled'
import { Grid, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import React from 'react'
import { Link } from 'react-router-dom'
import { paths } from '../../../pages/paths'
import { Typography } from '../../../ui'

export const SubBackHeader = ({
  title,
  className = '',
  limitTitle = false,
  backPath = paths.app.dashboard(),
  backIcon = <KeyboardBackspaceIcon color="primary" />,
}: {
  title: string
  className?: any
  backPath?: string
  limitTitle?: boolean
  backIcon?: JSX.Element
}) => {
  const classes = useStyles()

  return (
    <SubBackHeaderWrapper className={className}>
      <Grid className={classes.headerTop}>
        <IconButton component={Link} to={backPath} color="inherit" size="large">
          {backIcon}
        </IconButton>
        <Typography
          color="primary"
          variant="h6"
          component="h2"
          className={limitTitle ? classes.title : undefined}
        >
          {title}
        </Typography>
      </Grid>
    </SubBackHeaderWrapper>
  );
}

const SubBackHeaderWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: 48px 1fr 48px;
`

const useStyles = makeStyles((theme) => ({
  title: {
    'display': '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
  },
  headerTop: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    gridTemplateColumns: '48px 1fr 48px',
  },
}))
