import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import { Typography } from '@mui/material'
import React from 'react'
import { shopifyStoreStyles } from '../../../pages/_styles/shopifyStoreStyles'
import { Loading } from '../../../ui'
import { UserDataMethods } from '../model/types'

export const CheckoutUserData = (props: {
  id: UserDataMethods
  title: string
  actionText: string | undefined
  hide: boolean
  icon: React.ReactNode
  handleOpen: (prop1: UserDataMethods) => void
  isLoading: boolean
}) => {
  const { id, title, actionText, hide, icon, handleOpen, isLoading } = props
  if (hide) return null
  const classes = shopifyStoreStyles()

  return (
    <div className={classes.dataCardWrapper} onClick={() => handleOpen(id)}>
      <div className={classes.dataContentWrapper}>
        <Typography className={classes.checkoutMethodTitle}>{title}</Typography>
        <div className={classes.infoWrapper}>
          <div className={classes.iconWrapper}>{icon}</div>
          <Typography className={classes.summaryLineText}>
            {actionText}
          </Typography>
        </div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <ChevronRightSharpIcon style={{ color: '#757575', marginRight: 16 }} />
      )}
    </div>
  )
}
