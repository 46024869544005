import { createSlice, Dispatch } from '@reduxjs/toolkit'
import api from '../../api'
import { HealthRecordState, HealthRecordType } from './type'

interface APIResponse {
  data: any
  type: HealthRecordType
}

const initialState: HealthRecordState = {
  isLoading: false,
  listState: {
    [HealthRecordType.ALLERGY]: [],
    [HealthRecordType.VITAL]: [],
    [HealthRecordType.CONDITIONS]: [],
    [HealthRecordType.MEDICATIONS]: [],
    [HealthRecordType.VACCINATIONS]: [],
    [HealthRecordType.SOCIAL_HISTORY]: undefined,
    [HealthRecordType.FAMILY_MEMBER_HISTORY]: [],
    [HealthRecordType.PROCEDURE]: [],
  },
  stateFetched: {
    [HealthRecordType.ALLERGY]: false,
    [HealthRecordType.VITAL]: false,
    [HealthRecordType.CONDITIONS]: false,
    [HealthRecordType.MEDICATIONS]: false,
    [HealthRecordType.VACCINATIONS]: false,
    [HealthRecordType.SOCIAL_HISTORY]: false,
    [HealthRecordType.FAMILY_MEMBER_HISTORY]: false,
    [HealthRecordType.PROCEDURE]: false,
  },
  detailId: '',
}

export const healthRecordSlice = createSlice({
  name: 'healthRecord',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setList: (state, { payload }: { payload: APIResponse }) => {
      state.isLoading = false
      state.listState[payload.type] = payload.data
      state.stateFetched[payload.type] = true
    },
    setDetailId: (state, { payload }: { payload: string }) => {
      state.detailId = payload
    },
  },
})

const { setList, setLoading } = healthRecordSlice.actions

export const { setDetailId } = healthRecordSlice.actions

export const getHealthRecordList =
  (npi: string, type: HealthRecordType) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    const { success, data, error } = await api.getHealthRecord(npi, type)
    dispatch(setLoading(false))

    if (error) {
      // handleError(error)
    }

    if (success && data) {
      dispatch(setList({ data, type }))
    }
  }

export const getVaccinationList =
  (npi: string, type: HealthRecordType) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    const { success, data, error } = await api.getVaccinationRecords(npi, type)
    dispatch(setLoading(false))

    if (error) {
      // handleError(error)
    }

    if (success && data) {
      dispatch(setList({ data, type }))
    }
  }

export const updateSocialHistoryRecord =
  (type: HealthRecordType, patientDetailDTO: any) => async (dispatch: any, getState: any) => {
    const safeEMRId = getState().profile.data.nationalHealthId
    const params = {
      resourceType: type,
      safeEMRId,
      patientDetailDTO,
    }

    dispatch(setLoading(true))
    const { success, data, error } = await api.updateHealthRecord(type, params)
    dispatch(setLoading(false))

    if (error) {
      // handleError(error)
    }

    if (success && data) {
      dispatch(getHealthRecordList(safeEMRId, type))
      dispatch(setDetailId(''))
    }
  }

export const healthRecordReducerName = healthRecordSlice.name
export const healthRecordReducer = healthRecordSlice.reducer

interface RootStore {
  [healthRecordReducerName]: typeof initialState
}

export const selectHealthRecord = ({ healthRecord }: RootStore) =>
  healthRecord.listState

export const selectStateLoading = ({ healthRecord }: RootStore) =>
  healthRecord.isLoading

export const selectDetialId = ({ healthRecord }: RootStore) =>
  healthRecord.detailId

export const selectFetched = ({ healthRecord }: RootStore) =>
  healthRecord.stateFetched