import { LinearProgress } from '@mui/material'
import React from 'react'
import { lightenDarkenHexColor } from '../../libs/helpers'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { getColor } from '../../libs/utils'
import { ColorWithHex } from '../../types/general'

export const ProgressBar = (props: {
  progress?: number
  progressColor?: ColorWithHex
}) => {
  const { progress, progressColor = 'primary' } = props
  const { theme } = useMUITheme()

  if (!progress) return null

  return (
    <LinearProgress
      sx={
        progressColor !== 'primary'
          ? {
              'backgroundColor': lightenDarkenHexColor(
                getColor(progressColor, theme),
                60
              ),
              '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: getColor(progressColor, theme),
              },
            }
          : undefined
      }
      variant="determinate"
      value={progress}
    />
  )
}
