import { getCurrentUser } from './libs/utils'
import { LSUserData, LSUserDataKeys } from './types/ls'

export const lsClient = {
  checkout: {
    setCheckoutDataLS(data: any) {
      localStorage.setItem('checkoutData', JSON.stringify(data))
    },
    setSelectedFacilityLS(data: any) {
      const checkoutData = this.getCheckoutDataLS() || {}
      checkoutData.selectedFacility = data
      localStorage.setItem('checkoutData', JSON.stringify(checkoutData))
    },
    setSelectedAppointmentDataLS(appointmentId: string, date: any, time: any) {
      const checkoutData = this.getCheckoutDataLS() || {}
      checkoutData.appointmentId = appointmentId
      checkoutData.selectedAppointmentDate = { date, time }

      localStorage.setItem('checkoutData', JSON.stringify(checkoutData))
    },
    getCheckoutDataLS() {
      const data = localStorage.getItem('checkoutData')
      if (data) {
        return JSON.parse(data)
      }
      return null
    },
  },
  VCB1: {
    setQuestionnaireId(id: string) {
      lsClient.setUserLS('VCquestionnaireId', id)
    },
    clearQuesionnaire() {
      lsClient.removeUserKeyLS('VCquestionnaireId')
    },
  },
  /**
   * Retrieves entire current user's data stored in local storage
   * @returns {LSUserData}
   */
  getUserLS() {
    const userData = localStorage.getItem(btoa(getCurrentUser()))
    if (userData) {
      return JSON.parse(atob(userData)) as LSUserData
    }
  },
  /**
   * Retrieves a single key's value from email's data stored in local storage
   * Use if needed outside of auth
   * @param key {LSUserDataKeys}
   * @returns {LSUserData}
   */
  getUserLSByKeyandEmail(key: LSUserDataKeys, email: string) {
    const userData = localStorage.getItem(btoa(email))
    if (userData) {
      return JSON.parse(atob(userData))[`${key}`]
    }
  },
  /**
   * Retrieves a single key's value from current user's data stored in local storage
   * @param key {LSUserDataKeys}
   * @returns {LSUserData}
   */
  getUserLSByKey(key: LSUserDataKeys) {
    const currentUser = getCurrentUser()
    if (!currentUser) return
    const userData = localStorage.getItem(btoa(currentUser))
    if (userData) {
      return JSON.parse(atob(userData))[`${key}`]
    }
  },
  /**
   * Sets a single key:value pair into current user's data stored in local storage
   * @param key
   * @param value
   */
  setUserLS(key: LSUserDataKeys, value: any) {
    const currentUser = getCurrentUser()
    if (!currentUser) return
    let userData = localStorage.getItem(btoa(currentUser))
    if (userData) {
      userData = atob(userData)
      const userJSON = JSON.parse(userData) as LSUserData
      const updatedData = {
        ...userJSON,
        [key]: value,
      }
      localStorage.setItem(btoa(currentUser), btoa(JSON.stringify(updatedData)))
    } else {
      const data = {
        [key]: value,
      }
      localStorage.setItem(btoa(currentUser), btoa(JSON.stringify(data)))
    }
  },

  /**
   * Sets a single key:value pair into passed email user's data stored in local storage
   * @param key
   * @param value
   */
  setUserLSbyEmail(key: LSUserDataKeys, value: any, email: string) {
    if (!email) return
    let userData = localStorage.getItem(btoa(email))
    if (userData) {
      userData = atob(userData)
      const userJSON = JSON.parse(userData) as LSUserData
      const updatedData = {
        ...userJSON,
        [key]: value,
      }
      localStorage.setItem(btoa(email), btoa(JSON.stringify(updatedData)))
    } else {
      const data = {
        [key]: value,
      }
      localStorage.setItem(btoa(email), btoa(JSON.stringify(data)))
    }
  },
  /**
   * Removes a single key from current user's data stored in local storage
   * @param key, value
   */
  removeUserKeyLS(key: LSUserDataKeys) {
    const currentUser = getCurrentUser()
    let userData = localStorage.getItem(btoa(currentUser))
    if (userData) {
      userData = atob(userData)
      const userJSON = JSON.parse(userData) as LSUserData
      delete userJSON[key]
      localStorage.setItem(btoa(currentUser), btoa(JSON.stringify(userJSON)))
    } else return null
  },
  /**
   * Clears all information from current user's data stored in local storage
   */
  clearCurrentUserLS() {
    localStorage.removeItem(btoa(getCurrentUser()))
  },
  navigation: {
    setBackPath(path: string) {
      sessionStorage.setItem('backpath', path)
    },
    getBackPath() {
      return sessionStorage.getItem('backpath')
    },
    removeBackPath() {
      sessionStorage.removeItem('backpath')
    },
  }
}
