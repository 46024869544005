import { makeStyles } from '@mui/styles'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { DataGrid, DataGridProps, GridSortItem } from '@mui/x-data-grid'
import React, { useCallback, useEffect, useState } from 'react'
import { CustomPagination } from './components/custom-pagination'
import { TableSkeletonLoader } from './table-skeleton-loader'

export const CustomTable = (
  props: DataGridProps & {
    onTableUpdate?: any
    initialRowsPerPage: number
    initialSort: GridSortItem[]
    total: number
    disableSelectionOnClick?: boolean
  }
) => {
  const classes = useStyles()
  const {
    columns,
    rows,
    initialRowsPerPage = 10,
    initialSort,
    // rowsPerPageOptions,
    total,
    loading,
    hideFooter,
    onTableUpdate,
    disableSelectionOnClick = true,
    autoHeight = true,
    disableColumnMenu = true,
  } = props

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)
  const [sort, setSort] = useState(initialSort)

  const onChangePage = (updatedPage: number) => {
    setPage(updatedPage)
    onTableUpdate(updatedPage, rowsPerPage, sort)
  }
  const onChangeRowsPerPage = (event: React.ChangeEvent<any>) => {
    const currentPage = 0
    setRowsPerPage(event.target.value)
    setPage(currentPage)
    onTableUpdate(currentPage, event.target.value, sort)
  }

  const onSortModelChange = useCallback(
    (newSortModel: GridSortItem[]) => {
      if (JSON.stringify(newSortModel) !== JSON.stringify(sort)) {
        const currentPage = 0
        setPage(currentPage)
        setSort(newSortModel)
        onTableUpdate(currentPage, rowsPerPage, newSortModel)
      }
    },
    [sort, rowsPerPage, page]
  )

  if (loading) return <TableSkeletonLoader />

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      className={classes.noSelect}
      autoHeight={autoHeight}
      disableColumnMenu={disableColumnMenu}
      // pageSize={rowsPerPage}
      components={{
        ColumnResizeIcon: () => null,
        ColumnSortedAscendingIcon: KeyboardArrowUpRoundedIcon,
        ColumnSortedDescendingIcon: KeyboardArrowDownRoundedIcon,
        LoadingOverlay: () => null,
        Pagination: CustomPagination,
      }}
      componentsProps={{
        pagination: {
          count: total,
          page,
          rowsPerPage,
          showFirstButton: true,
          showLastButton: true,
          // onChangeRowsPerPage,
          // onChangePage,
          // rowsPerPageOptions,
          // disableMultipleColumnsSorting: true,
        },
      }}
      sortingOrder={['asc', 'desc']}
      sortModel={sort}
      onSortModelChange={onSortModelChange}
      // disableSelectionOnClick={disableSelectionOnClick}
      hideFooter={hideFooter}
    />
  )
}

const useStyles = makeStyles(() => ({
  noSelect: {
    userSelect: 'none',
  },
}))
