import { styled } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as NoOrdersImg } from '../../assets/img/no-orders.svg'
import { NOTIFICATION_TYPES } from '../../constants'
import { handleMessage } from '../../libs/helpers'
import { Observer } from '../../libs/observer'
import {
  CenteredContentWrapper,
  ContentBody,
  ContentTitle,
  FlexColumnCenter,
  PageTitle,
} from '../../libs/shared-components'
import { shopifyStoreStyles } from '../../pages/_styles/shopifyStoreStyles'
import { paths } from '../../pages/paths'
import { Loading } from '../../ui'
import { selectLanguageContent } from '../translation'
import { I18n } from '../translation/types'
import { StoreStepType } from './model/types'
import { GetPurchaseHistoryParameters } from './SAFE-ecommerce-service/EcommerceSDK'
import { OrderHistoryItem } from './shopify-ui-items'
import { ShopLoading } from './shopify-ui-items/storeLoading'

export const OrderHistory = (props: { sdk: any }) => {
  const classes = shopifyStoreStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const { sdk } = props
  const [isLoading, setIsLoading] = useState(false)
  const [ordersState, setOrdersState] = useState<any>(undefined)
  const [nextPageLoading, setNextPageLoading] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      loadHistory(false)
    }, 250)
  }, [])

  const loadHistory = (loadsNextPage: any) => {
    if (isLoading) {
      return
    }
    const params =
      GetPurchaseHistoryParameters.Companion.exceptWelcomeKits(loadsNextPage)
    setNextPageLoading(true)
    sdk.customerManager
      .getPurchaseHistory(params)
      .then((response: any) => {
        setOrdersState(response)
        setPageLoaded(true)
        setIsLoading(false)
        setNextPageLoading(false)
      })
      .catch((error: any) => {
        console.log(error)
        setPageLoaded(true)
        setIsLoading(false)
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        setNextPageLoading(false)
      })
  }

  const selectProduct = (productId: string) => {
    navigate(
      paths.shopifyProductDetail(StoreStepType.ORDER_HISTORY_DETAIL, productId)
    )
  }

  if (isLoading || !pageLoaded) return <ShopLoading />

  if (
    !ordersState?.purchaseHistory ||
    (ordersState?.purchaseHistory && ordersState.purchaseHistory.length === 0)
  )
    return <NoOrders i18n={i18n} />

  return (
    <div className={classes.listContent}>
      <PageTitle style={{ paddingLeft: 16 }}>{i18n.purchase_history}</PageTitle>
      <div className={classes.orderListWrapper}>
        {ordersState.purchaseHistory.map((order: any) => (
          <OrderHistoryItem
            key={order.identifier}
            i18n={i18n}
            order={order}
            handleNext={selectProduct}
          />
        ))}
        {ordersState?.hasNextPage && (
          <Observer callback={() => loadHistory(true)} />
        )}
        {nextPageLoading && <Loading />}
      </div>
    </div>
  )
}

const NoOrders = (props: { i18n: I18n }) => {
  const { i18n } = props
  return (
    <CenteredContentWrapper>
      <ContentWrapper>
        <NoOrdersImg />
        <FlexColumnCenter>
          <ContentTitle>{i18n.purchase_history}</ContentTitle>
          <ContentBody align="center">{i18n.ecommerce_no_orders}</ContentBody>
        </FlexColumnCenter>
      </ContentWrapper>
    </CenteredContentWrapper>
  )
}

export const ContentWrapper = styled(FlexColumnCenter)(({ theme }) => ({
  padding: 48,
  marginTop: 78,
  gap: 48,
  [theme.breakpoints.down(600)]: {
    padding: 0,
    marginTop: 16,
    gap: 24,
  },
}))
