import styled from '@emotion/styled'
import { ListItem, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { stripHtml } from 'string-strip-html'
import { paths } from '../../../pages/paths'
import { selectLanguageContent } from '../../translation'
import { DateSend } from '../atoms'
import { SystemMessage } from '../types'

export const MessageItemInList = ({ message }: { message: SystemMessage }) => {
  const i18n = useSelector(selectLanguageContent)
  const stripedMessage = message.messages?.[0]?.body
    ? stripHtml(message.messages[0].body)
        .result.replace(/([\r|\n]+)/g, ' ')
        .slice(0, 100)
    : i18n.message_no_content
  return (
    <ChatListItem tabIndex={-1} title={message.subject}>
      <ChatLink to={paths.systemMessageItem(message.conversationId)}>
        <MessageAndDate>
          <Texts>
            <MessageAuthor>
              <span className="system">{message.conversationFrom}</span>
            </MessageAuthor>
            <DateSend dateSend={message.date} />
          </Texts>
          <Texts>
            <MessageContentPreview>
              <MessageSubject color="primary">{message.subject}</MessageSubject>
              <MessagePreview>{stripedMessage}</MessagePreview>
            </MessageContentPreview>
            <ChevronRightIcon />
          </Texts>
        </MessageAndDate>
      </ChatLink>
    </ChatListItem>
  )
}

const MessageContentPreview = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 85%;
`

const Texts = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const MessageAuthor = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Avenir Next Rounded Medium !important;
  font-size: 16px !important;
  color: #545454;
  & > .system {
    font-weight: 600 !important;
  }
`
const MessageSubject = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Avenir Next Rounded Medium !important;
  font-size: 16px !important;
  & > .system {
    font-weight: 600 !important;
  }
`

const MessagePreview = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3;
  font-family: Avenir Next Rounded Regular !important;
  font-size: 14px !important;
`
const ChatListItem = styled(ListItem)`
  padding: 0 10px 0 15px !important;
`
const ChatLink = styled(Link)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: 0.9rem 0;
  border-bottom: 1px solid #efefef;
  text-decoration: none;
  outline: none;
  color: #7b7c7d;
`
const MessageAndDate = styled.div`
  overflow: hidden;
`
