import React, {  useState } from 'react'
import { Box, Checkbox } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button, Typography } from '../../ui'
import { selectProfileData } from '../profile/model'
import { selectNetworkSettings } from '../network/model'
import { selectLanguageContent } from '../../features/translation'
import { SubHeaderGetTested } from '../../features/application'
import {
  useStyles,
  Wrapper,
  Content,
  Controls,
} from '../../ui/templates/app-layout'

const InputMask = require('react-input-mask')

//Currently not in the flow per iOS app
export const GuardianAgreementPage = () => {
  const childClasses = childStyles()
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  // const historyState = history.location.state as {prevSection?: string}
  const memberData = useSelector(selectProfileData)
  const i18n = useSelector(selectLanguageContent)
  const networkSettings = useSelector(selectNetworkSettings)
  const [agreeToTerms, setAgreeToTerms] = useState(false)

  const handleCheck = () => {
    setAgreeToTerms(!agreeToTerms)
  }

  const { _id: userId, email } = useSelector(selectProfileData)
  
  const handleBack = () => {
    history.back()
  }

  return (
    <Wrapper>
      <SubHeaderGetTested title={i18n.legal_guardian_required} onHandleBack={handleBack} />
        <Content className={childClasses.content}>
        <Box className={classes.infoText}>
              {i18n.legal_guardian_required}
          </Box>
            <Box className={classes.optionsWrapper}>
              <Box className={classes.optionWrapper}>
                <Typography component='div'>
                More words
                </Typography>
              </Box>
            </Box>
          <Controls >
            <Box className={childClasses.consent}>
                <Checkbox
                    color='primary'
                    checked={agreeToTerms}
                    onChange={handleCheck}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <Typography variant='subtitle2' className={childClasses.testLab}>
                    {i18n.agree_terms_label}
                </Typography>
            </Box> 
            <Button
              className={classes.button}
              type="submit"
              disabled={!agreeToTerms}
            >
              {i18n.continue_button}
            </Button>
          </Controls>

        </Content>
      </Wrapper>
  )
}



const childStyles = makeStyles((theme) => ({
  consent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    marginLeft: '30px',
  },
  testLab: {
    color: '#7B7C7D',
  },
  infoText: {
    margin: '24px 0',
    fontSize: 26,
    color: '#7B7C7D',
    alignSelf: 'center',
    textAlign: 'center',
  },
  content: {
    padding: '0px 0px !important',
  },
  field: {
    width: '100%',
    marginBottom: '25px',
    letterSpacing: '.1em',
    fontFamily: 'Avenir Next Rounded Medium',
    fontWeight: 600,
    color: 'red'
  },

  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding:"0px 15px",
  },
  formHeader: {
    display: 'flex',
    alignItems: 'center',
    // fontFamily: 'Avenir Next Rounded Medium',
    color: '#6d6d6d',
    fontWeight: 600,
    backgroundColor: 'rgb(237, 237, 237)',
    width: '100%',
    height: '45px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderTop: '1px solid rgba(0, 0, 0, 0.42)',
  },
}))

const NameWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`
