export interface Dependent {
  _id: string
  email: string | null
  subscriberId: string
  externalId: string
  firstName: string
  lastName: string
  phone: string
  address: string
  status: string
  inviteStatus: InviteStatus
}

export enum InviteStatus {
  NONE = 'none',
  INVITED = 'invited',
  ACCEPTED = 'accepted',
  DEACTIVATED = 'deactivated',
}
