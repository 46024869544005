import { makeStyles } from '@mui/styles'
import { hexToRGBA } from '../../libs/helpers'

export const facilityStyles = makeStyles((theme) => ({
  page: {
    width: '100vw',
    display: 'flex',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
    },
  },
  mapImage: {
    display: 'block',
    width: 'calc(100vw - 600px)',
    height: '100vh',
    position: 'relative',
    [theme.breakpoints.down(600)]: {
      width: '100vw',
      height: '30vh',
      marginBottom: '30vh',
      position: 'fixed',
      top: 64,
      zIndex: 1001,
    },
  },
  facilityInfoBox: {
    height: 'calc(100vh - 64px)',
    overflow: 'auto',
    maxWidth: 600,
    [theme.breakpoints.down(600)]: {
      height: 'unset',
    },
  },
  content: {
    padding: 24,
    [theme.breakpoints.down(600)]: {
      padding: '0px 3vw',
    },
  },
  titleWrapper: {
    padding: '0px 24px',
    [theme.breakpoints.down(600)]: {
      padding: 0,
      marginTop: '30vh',
    },
  },
  locationWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down(600)]: {
      justifyContent: 'space-between',
    },
  },
  closeWrapper: {
    textAlign: 'right',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 24,
    position: 'relative',
  },
  composeInput: {
    'flex': 1,
    'fontSize': '14px',
    'padding': 16,
    'width': '100%',
    'background': 'none',
    'border': '1px solid #757575',
    'borderRadius': '100px',
    'position': 'relative',
    '&::placeholder': {
      opacity: 0.3,
    },
  },
  moreLoadingBox: {
    margin: 20,
    paddingLeft: 15,
  },
  pageTitle: {
    fontSize: 32,
    fontWeight: 600,
    color: '#282D37',
    margin: '8px 0px 32px',
    [theme.breakpoints.down(600)]: {
      margin: '8px 0px 8px',
    },
  },
  image: {
    height: 224,
    width: 224,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    [theme.breakpoints.down(600)]: {
      height: '45vw',
      width: '45vw',
    },
  },
  addressWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  description: {
    color: '#757575',
    fontSize: 14,
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      '&.smallCard': {
        maxWidth: '45vw',
      },

    },
  },
  distance: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down(600)]: {
      alignSelf: 'flex-start',
    },
  },
  locationText: {
    'fontWeight': 600,
    'fontSize': 14,
    '&.light': {
      fontWeight: 500,
    },
  },
  open: {
    color: theme.palette.primary.main,
  },
  closed: {
    color: theme.palette.error.main,
  },
  openSoon: {
    color: theme.palette.success.main,
  },
  info: {
    color: '#757575',
    [theme.breakpoints.down(600)]: {
      whiteSpace: 'pre-wrap',
    },
  },
  dailyHours: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    '&.withDivider': {
      gap: 4,
      justifyContent: 'flex-start',
    },
  },
  moreInfoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  detailContent: {
    position: 'relative',
    minWidth: '600px',
    [theme.breakpoints.down(600)]: {
      minWidth: 'unset',
      marginTop: '30vh',
    },
  },
  contentWrapper: {
    padding: '0px 48px',
  },
  iconWrapper: {
    position: 'absolute',
    right: 16,
    top: 16,
  },
  closeIcon: {
    color: '#505358',
    cursor: 'pointer',
  },
  detailLocationText: {
    'color': '#505358',
    'letterSpacing': 0.25,
    'fontWeight': 500,
    'fontSize': 16,
    '& .noDecoration': {
      textDecoration: 'none',
    },
    '& .phone': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  locationInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  locationTitle: {
    'color': '#282D37',
    'fontWeight': 600,
    'fontSize': 40,
    'maxWidth': 500,
    'margin': '32px 0px 16px',
    'display': '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down(600)]: {
      fontSize: 32,
    },
  },
  clamp1: {
    'display': '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
  },
  optionDescription: {
    color: '#505358',
    fontSize: 16,
    fontWeight: 500,
  },
  btnWrapper: {
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      marginBottom: 24,
    },
  },
  confirmButton: {
    height: 48,
    fontWeight: 600,
    marginTop: 32,
    alignSelf: 'center',
  },
  divider: {
    width: '100%',
    borderBottom: '1px solid #E0E0E0',
    margin: '16px 0px',
  },
  img: {
    width: 600,
  },
  imageWrapper: {
    height: 338,
    width: 600,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    [theme.breakpoints.down(600)]: {
      height: '177px',
      width: '100vw',
    },
  },
  addressInfo: {
    maxWidth: 170,
  },
  timeStatus: {
    textAlign: 'right',
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  optionWrapper: {
    'padding': 24,
    'borderRadius': 9,
    'display': 'flex',
    'justifyContent': 'space-between',
    'cursor': 'pointer',
    '&:hover': {
      transition: 'all .03s ease-in-out',
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
    },
    [theme.breakpoints.down(600)]: {
      padding: 0,
      marginBottom: '32px',
    },
  },
  name: {
    'fontSize': 24,
    'fontWeight': 600,
    'color': '#282D37',
    '&.smallCard': {
      'maxWidth': 224,
      'display': '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      'overflow': 'hidden',
    },
    [theme.breakpoints.down(600)]: {
      'fontSize': 16,
      'fontWeight': 600,
      '&.smallCard': {
        marginTop: '8px',
        maxWidth: '45vw',
      },
    },
  },
}))
