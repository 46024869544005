export interface WorkflowItem {
  id: string
  name: string
  description: string
}

export enum FlowEngineModules {
  INTRO = 'intro',
  NEXT = 'next',
  PROCESSING = 'processing',
  CONSENT = 'consent',
  REGISTER_TEST = 'register-test',
}
