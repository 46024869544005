import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { NOTIFICATION_TYPES } from '../../constants'
import { showNotification } from '../notifications/model'

interface EncounterService {
  isLoading: boolean
  fetchedRecord: boolean
  _id: string
  healthserviceId: string
  data: any
  history: EncounterHistoryListItem[]
  page: number
  listComplete: boolean
}

interface EncounterHistoryListItem {
  _id: string
  workflowTitle: string
  created: string
}

const initialStore: EncounterService = {
  isLoading: false,
  fetchedRecord: false,
  _id: '',
  healthserviceId: '',
  data: undefined,
  history: [],
  page: 0,
  listComplete: false,
}

export enum EncounterStatus {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNED = 'ASSIGNED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  UNKNOWN = 'UNKNOWN',
}

export enum EncounterType {
  SYNC_CONSULT_VIDEO = 'SYNC_CONSULT_VIDEO',
  SYNC_CONSULT_PHONE = 'SYNC_CONSULT_PHONE',
  SYNC_CONSULT_CHAT = 'SYNC_CONSULT_CHAT',
  ASYNC_CONSULT = 'ASYNC_CONSULT',
  // DIAGNOSTIC_TEST = 'DIAGNOSTIC_TEST',
  // VIRTUAL_CONSULT = 'VIRTUAL_CONSULT',
  // DIAGNOSTIC_ASSESMENT = 'DIAGNOSTIC_ASSESMENT',
  // TELEHEALTH_CONSULT = 'TELEHEALTH_CONSULT',
  // VIRTUAL_CONSOLE_REVIEW = 'VIRTUAL_CONSOLE_REVIEW',
}

export const encounterServiceSlice = createSlice({
  name: 'encounterService',
  initialState: initialStore,
  reducers: {
    // setHealthServiceList(store, { payload }: { payload: HealthService[] }) {
    //   store.healthServiceList = payload
    // },
    setEncounterLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setEncounterFetched(store, { payload }: { payload: boolean }) {
      store.fetchedRecord = payload
    },
    setEncounterData(store, { payload }: { payload: any }) {
      store.data = payload
    },
    setHistory(
      store,
      {
        payload,
      }: {
        payload: {
          data: EncounterHistoryListItem[]
          page: number
          limit: number
          nextPage: boolean
        }
      }
    ) {
      store.page = payload.page
      store.history = payload.nextPage
        ? [...store.history, ...payload.data]
        : payload.data
      store.listComplete = payload.data.length < payload.limit
    },
    resetHSStore: () => initialStore,
  },
})

const {
  setEncounterFetched,
  resetHSStore,
  setEncounterLoading,
  setEncounterData,
  setHistory,
} = encounterServiceSlice.actions

export const createEncounterRecord = (params: any) => async (dispatch: any) => {
  dispatch(setEncounterLoading(true))
  const { data, error } = await api.createEncounterRecord(params)

  if (error) {
    dispatch(setEncounterLoading(false))
    dispatch(
      showNotification(
        error.message || 'Server error',
        NOTIFICATION_TYPES.V2_ERROR
      )
    )
  }

  if (data) {
    dispatch(setEncounterLoading(false))
  }
}

export const getEncounterRecord =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(setEncounterLoading(true))
    dispatch(setEncounterFetched(false))
    const { data, error } = await api.getEncounterRecord(id)
    dispatch(setEncounterFetched(true))
    if (error) {
      dispatch(setEncounterLoading(false))
    }

    if (data) {
      dispatch(setEncounterLoading(false))
      dispatch(setEncounterData(data))
    }
  }

export const getTreamentHistory =
  (
    id: string,
    pagination: { skip: number; limit: number },
    nextPage: boolean
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setEncounterLoading(true))
    const { data, error, total } = await api.userTreatmentPlanHistory(
      id,
      pagination
    )

    if (error) {
      dispatch(setEncounterLoading(false))
    }

    if (data) {
      dispatch(setEncounterLoading(false))
      dispatch(
        setHistory({
          page: pagination.skip,
          data,
          limit: pagination.limit,
          nextPage,
        })
      )
    }
  }

export const updateEncounterRecord =
  (params: any, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    dispatch(setEncounterLoading(true))
    const { data, error } = await api.updateEncounterRecord(params)

    if (error) {
      dispatch(setEncounterLoading(false))
    }

    if (data) {
      dispatch(setEncounterLoading(false))
      if (onSuccess) onSuccess()
    }
  }

export const selectEncounter = ({ encounterService }: RootStore) =>
  encounterService.data

export const selectEncounterFetched = ({ encounterService }: RootStore) =>
  encounterService.fetchedRecord

export const selectEncounterLoading = ({ encounterService }: RootStore) =>
  encounterService.isLoading

export const selectTreatmentPlan = ({ encounterService }: RootStore) =>
  encounterService.data?.treatmentPlan

export const selectEncounterDisposition = ({ encounterService }: RootStore) =>
  encounterService.data?.disposition

export const selectEncounterHistory = ({ encounterService }: RootStore) =>
  encounterService.history

export const selectEncounterHistoryPage = ({ encounterService }: RootStore) =>
  encounterService.page

export const selectEndOfList = ({ encounterService }: RootStore) =>
  encounterService.listComplete

export const encounterServiceReducer = encounterServiceSlice.reducer

export const encounterServiceReducerName = encounterServiceSlice.name

interface RootStore {
  [encounterServiceReducerName]: typeof initialStore
}
