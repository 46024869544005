import * as Yup from 'yup'
import { I18n } from '../../features/translation/types'
import { isValidPasswordDigit, isValidPasswordLength, isValidPasswordLower, isValidPasswordSpecial, isValidPasswordUpper } from '../../libs/helpers'

// eslint-disable-next-line import/no-anonymous-default-export
export default (i18n: I18n) =>
  Yup.object().shape({
    password: Yup.string()
      .test('valid-length', i18n.password_requirements_warning, (value) =>
        isValidPasswordLength(value || '')
      )
      .test('valid-upper', i18n.password_requirements_warning, (value) =>
        isValidPasswordUpper(value || '')
      )
      .test('valid-lower', i18n.password_requirements_warning, (value) =>
        isValidPasswordLower(value || '')
      )
      .test('valid-digit', i18n.password_requirements_warning, (value) =>
        isValidPasswordDigit(value || '')
      )
      .test('valid-special', i18n.password_requirements_warning, (value) =>
        isValidPasswordSpecial(value || '')
      ),
    verificationCode: Yup.string()
      .min(6, '6 digit code required')
      .max(6, '6 digit code required')
      .required(i18n.required),
  })
