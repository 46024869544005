import Box from '@mui/material/Box'
import React from 'react'
import { testTypes } from '../../../constants'
import { Typography } from '../../../ui'
import { facilityStyles } from '../../_styles/facilityStyles'
import { hoursOfOperation } from '../facilityHelper'

export const FacilityOption = (props: {
  item: any
  setShowDetails: any
  setSelectedItem: any
  type: testTypes
}) => {
  const classes = facilityStyles()
  const { item, setShowDetails, setSelectedItem, type } = props

  const handleShowDetails = () => {
    setSelectedItem(item)
    setShowDetails(true)
  }
  return (
    <Box className={classes.optionWrapper} onClick={handleShowDetails}>
      <Box>
        <div
          className={classes.image}
          style={{
            backgroundImage: `url(${item.imageUrl})`,
          }}
        />
        {item.displayAddress?.line1 === 'FedEx Drop Box' ? (
          <>
            <Typography className={`${classes.name} smallCard`}>
              {item.displayAddress?.line1}
            </Typography>
            <Typography
              className={`${classes.description} smallCard ${classes.clamp1}`}
            >
              {item.displayAddress?.line2}
            </Typography>
          </>
        ) : (
          <Typography className={`${classes.name} smallCard`}>
            {item.name}
          </Typography>
        )}
        <div className={classes.addressWrapper}>
          <div>
            <Typography className={classes.description}>
              {`${item.address1},`}
            </Typography>
            <Typography className={classes.description}>
              {`${item.city}, ${item.state} ${
                item.zip ? item.zip.slice(0, 5) : ''
              }`}
            </Typography>
          </div>
          {item.distance?.unitSpecified && item.distance?.valueSpecified && (
            <Typography
              className={`${classes.distance} ${classes.description}`}
            >
              {`${Math.round(item.distance.value * 100) / 100} ${
                item.distance.unit ? item.distance.unit.toLowerCase() : ''
              }`}
            </Typography>
          )}
        </div>
        <Box>{hoursOfOperation(item?.normalHours)}</Box>
      </Box>
    </Box>
  )
}
