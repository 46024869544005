import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { handleError } from '../../libs/helpers'
import { lsClient } from '../../ls-client'
import { ProctorStatus } from '../../types/ls'

export interface ProctorSession {
  channelId: string
  channelName: string
  status: 'W' | 'D' | 'C' | 'R' | 'V' | ''
  sessionAccessToken: string
  memberAccessId: number
  rtcAppId: string
}

interface ProctorSlice {
  data: ProctorSession
  sessionAccessToken: string
  audioOn: boolean
  showControls: boolean
  proctorStatus: ProctorStatus
  memberInSession: boolean
  loading: boolean
  sessionStartTime: string
}
const initialStore: ProctorSlice = {
  data: {
    channelId: '',
    channelName: '',
    status: '',
    sessionAccessToken: '',
    memberAccessId: 0,
    rtcAppId: '',
  },
  sessionStartTime: '',
  audioOn: true,
  showControls: false,
  sessionAccessToken: '',
  proctorStatus: null,
  memberInSession: false,
  loading: false,
}

export const proctorSlice = createSlice({
  name: 'proctor',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.loading = payload
    },
    setAudio(store, { payload }: { payload: boolean }) {
      store.audioOn = payload
    },
    //This allows forcing Agora controls visible if user is muted when trying to join session
    setForceShowControls(store, { payload }: { payload: boolean }) {
      store.showControls = payload
    },
    setSessionData(store, { payload }: { payload: any }) {
      store.data = {
        ...payload.session,
        sessionAccessToken: payload.sessionAccessToken,
        memberAccessId: Number.parseInt(payload.session.memberAccessId, 10),
      }
      store.sessionAccessToken = payload.sessionAccessToken
    },
    setProctorStatus(store, { payload }: { payload: ProctorStatus }) {
      store.proctorStatus = payload
    },
    setMemberInSession(store, { payload }: { payload: boolean }) {
      store.memberInSession = payload
    },
    setSessionStatus(store, { payload }: { payload: any }) {
      store.data.status = payload.session
        ? payload.session.status
        : payload.status
    },
    setSessionStartTime(store, { payload }: { payload: any }) {
      store.sessionStartTime = payload.session
        ? payload.session?.started
        : payload.started
    },
    setSessionReload(store, { payload }: { payload: any }) {
      store.data.sessionAccessToken = payload.sessionAccessToken
      store.data.memberAccessId = Number.parseInt(payload.sessionUserId, 10)
      store.data.channelName = payload.sessionId
      store.data.channelId = payload.sessionId
      store.sessionAccessToken = payload.sessionAccessToken
    },
    resetProctorStore: () => initialStore,
  },
})

const { setSessionData, setLoading, setSessionStatus, setSessionReload } =
  proctorSlice.actions
export const {
  setProctorStatus,
  resetProctorStore,
  setAudio,
  setForceShowControls,
  setSessionStartTime,
  setMemberInSession,
} = proctorSlice.actions

export const createProctorSession =
  (data: any, onSuccess?: () => void) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const res = await api.createProctorSession(data)
    if (res?.error) {
      dispatch(setLoading(false))
      dispatch(handleError(res.error))
    }
    if (res.data && res.isOK) {
      dispatch(setLoading(false))
      dispatch(setSessionData(res.data))
      lsClient.setUserLS('proctor', 'Waiting')
      dispatch(setProctorStatus('Waiting'))
      if (onSuccess) {
        onSuccess()
      }
    }
  }

export const getProctorSession = (id: string) => async (dispatch: any) => {
  dispatch(setLoading(true))
  const res = await api.getProctorSession(id)

  if (res?.error) {
    dispatch(setLoading(false))
    dispatch(handleError(res.error))
  }

  if (res.data && res.isOK) {
    dispatch(setLoading(false))
    dispatch(setSessionData(res.data))
    lsClient.setUserLS('proctor', 'Waiting')
    dispatch(setProctorStatus('Waiting'))
  }
}

export const getSessionStatus = (id: string) => async (dispatch: any) => {
  dispatch(setLoading(true))
  const res = await api.getSessionStatus(id)

  if (res?.error) {
    dispatch(setLoading(false))
    dispatch(handleError(res.error))
  }

  if (res.data && res.isOK) {
    dispatch(setLoading(false))
    dispatch(setSessionStartTime(res.data))
    dispatch(setSessionStatus(res.data))
  }
}

export const getLastSession = () => async (dispatch: any) => {
  const currentSessionStatus = lsClient.getUserLSByKey(
    'proctor'
  ) as ProctorStatus

  dispatch(setLoading(true))
  const res = await api.getLastSession()

  if (res?.error) {
    dispatch(setLoading(false))
    dispatch(handleError(res.error))
  }

  if (res.data && res.isOK) {
    dispatch(setProctorStatus(currentSessionStatus))
    dispatch(setLoading(false))
    dispatch(setSessionStatus(res.data))
    if (res.data.status !== 'C') {
      dispatch(getNewToken(res.data.sessionId))
    }
  }
}

export const getNewToken = (id: string) => async (dispatch: any) => {
  dispatch(setLoading(true))
  const res = await api.regenerateProctorToken(id)

  if (res?.error) {
    dispatch(setLoading(false))
    dispatch(handleError(res.error))
  }

  if (res.data && res.isOK) {
    dispatch(setLoading(false))
    dispatch(setSessionReload(res.data))
  }
}

export const trackProctorSession = (data: any) => async (dispatch: any) => {
  const res = await api.trackProctorSession(data)

  if (res?.error) {
    dispatch(handleError(res.error))
  }
}

export const cancelProctorSession =
  (id: string, isReturning: boolean) => async (dispatch: any) => {
    dispatch(setLoading(true))
    const data = {
      _id: id,
      isReturning,
    }

    const res = await api.cancelProctorSession(data)
    if (res?.error) {
      dispatch(setLoading(false))
      dispatch(handleError(res.error))
    }
    if (res.data && res.isOK) {
      dispatch(setLoading(false))
      if (!isReturning) {
        dispatch(resetProctorStore())
      } else {
        dispatch(getProctorSession(res.data))
      }
    }
  }

export const proctorReducer = proctorSlice.reducer
export const proctorReducerName = proctorSlice.name

interface RootStore {
  [proctorReducerName]: typeof initialStore
}

export const selectProctorSessionLoading = ({ proctor }: RootStore) =>
  proctor.loading

export const selectAudioState = ({ proctor }: RootStore) => proctor.audioOn

export const selectShowControls = ({ proctor }: RootStore) =>
  proctor.showControls

export const selectSessionStatus = ({ proctor }: RootStore) =>
  proctor.data.status

export const selectSessionStartTime = ({ proctor }: RootStore) =>
  proctor.sessionStartTime

export const selectProctorSessionData = ({ proctor }: RootStore) => proctor.data

export const selectProctorSessionToken = ({ proctor }: RootStore) =>
  proctor.sessionAccessToken

export const selectProctorStatus = ({ proctor }: RootStore) =>
  proctor.proctorStatus

export const selectSessionId = ({ proctor }: RootStore) =>
  proctor.data.channelId

export const selectMemberInSession = ({ proctor }: RootStore) =>
  proctor.memberInSession
