import styled from '@emotion/styled'
import { Theme, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import CloseIcon from '@mui/icons-material/Close'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { emailRegex, NOTIFICATION_TYPES } from '../../constants'
import { showNotification } from '../../features/notifications/model'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { hexToRGBA } from '../../libs/helpers'
import { Button, FilledTextField, Typography } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import {
  getDependents,
  inviteDependent,
  selectDependents,
  selectDependentsIsLoading,
} from './model/dependentsSlice'
import { Dependent, InviteStatus } from './model/types'

const initialEmailState = { value: '', touched: false, error: '' }

const isValidEmailAddress = (email: string) =>
  emailRegex.test(email) ? '' : 'Email address is invalid'

export const DependentListPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)
  const profileData = useSelector(selectProfileData)
  const dependents = useSelector(selectDependents)
  const [selected, setSelected] = useState<Dependent | undefined>(undefined)
  const [email, setEmail] = useState(initialEmailState)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))

  useEffect(() => {
    if (selected?.email) {
      setEmail({ value: selected.email, touched: false, error: '' })
    } else setEmail(initialEmailState)
  }, [selected])

  useEffect(() => {
    if (profileData._id) {
      dispatch(getDependents(profileData._id))
    }
  }, [profileData])

  const handleChangeEmail = (value: string) => {
    const update = { ...email }
    update.value = value
    update.error = isValidEmailAddress(value)
    setEmail(update)
  }

  const handleBlur = () => {
    const update = { ...email }
    update.touched = true
    setEmail(update)
  }
  const selectDependent = (_id: string) => {
    const selectedDependent = _.find(dependents, { _id })
    setSelected(selectedDependent)
    if (selectedDependent?.email) {
      setEmail({ ...email, value: selectedDependent.email })
    }
  }

  const onBack = () => {
    setSelected(undefined)
    setEmail(initialEmailState)
  }

  const sendInvite = () => {
    if (!selected?.externalId) return
    const fullName = `${selected.firstName} ${selected.lastName}`
    const callback = () => {
      dispatch(
        showNotification(
          selected.inviteStatus === InviteStatus.NONE
            ? i18n.invite_sent.replace('%s', fullName)
            : i18n.invite_resent.replace('%s', fullName),
          NOTIFICATION_TYPES.TRANSPARENCY
        )
      )
      navigate(paths.app.dashboard())
    }
    const data = {
      email: email.value,
      dependentId: selected._id,
    }
    dispatch(inviteDependent(data, callback))
  }

  const handleBack = () => {
    if (selected) {
      onBack()
    } else navigate(paths.app.dashboard())
  }

  return (
    <ModuleHeader
      leftContentTitle={i18n.back_button}
      leftContentAction={handleBack}
      color="#505358"
    >
      <>
        {(!isMobile || !selected) && (
          <Typography className={classes.pageTitle}>
            {i18n.dependents}
          </Typography>
        )}
        {isMobile ? (
          <div className={classes.contentWrapper}>
            {selected ? (
              <AddDependent
                dep={selected}
                onBack={onBack}
                i18n={i18n}
                email={email}
                handleBlur={handleBlur}
                handleChange={handleChangeEmail}
                sendInvite={sendInvite}
                isMobile={isMobile}
              />
            ) : (
              <Box className={classes.dependentList}>
                {dependents.length > 0 &&
                  dependents.map((d) => (
                    <DependentCard
                      key={d._id}
                      dep={d}
                      i18n={i18n}
                      selected={false}
                      selectDependent={selectDependent}
                    />
                  ))}
              </Box>
            )}
          </div>
        ) : (
          <div className={classes.contentWrapper}>
            <div className={classes.desktopContent}>
              <ColumnWrapper>
                <Box className={classes.dependentList}>
                  {dependents.length > 0 &&
                    dependents.map((d) => (
                      <DependentCard
                        key={d._id}
                        dep={d}
                        i18n={i18n}
                        selected={selected?._id === d._id}
                        selectDependent={selectDependent}
                      />
                    ))}
                </Box>
              </ColumnWrapper>
              <ColumnWrapper>
                {selected && (
                  <AddDependent
                    dep={selected}
                    onBack={onBack}
                    i18n={i18n}
                    email={email}
                    handleBlur={handleBlur}
                    handleChange={handleChangeEmail}
                    sendInvite={sendInvite}
                    isMobile={isMobile}
                  />
                )}
              </ColumnWrapper>
            </div>
          </div>
        )}
      </>
    </ModuleHeader>
  )
}
const DependentCard = (props: {
  dep: Dependent
  i18n: I18n
  selected: boolean
  selectDependent: (id: string) => void
}) => {
  const { dep, i18n, selected, selectDependent } = props
  const classes = useStyles()
  const getStatus = () => {
    switch (dep.inviteStatus) {
      case InviteStatus.NONE: {
        return (
          <DependentWrapper>
            <WarningRoundedIcon color="primary" style={{ fontSize: 18 }} />
            <DependentStatus>{i18n.pending_invite}</DependentStatus>
          </DependentWrapper>
        )
      }
      case InviteStatus.INVITED: {
        return (
          <DependentWrapper>
            <WarningRoundedIcon color="primary" style={{ fontSize: 18 }} />
            <DependentStatus>{i18n.pending_acceptance}</DependentStatus>
          </DependentWrapper>
        )
      }
      case InviteStatus.ACCEPTED: {
        return (
          <DependentWrapper>
            <DependentStatus>{i18n.dependent}</DependentStatus>
          </DependentWrapper>
        )
      }
      case InviteStatus.DEACTIVATED: {
        return (
          <DependentWrapper>
            <DependentStatus>{i18n.deactivated}</DependentStatus>
          </DependentWrapper>
        )
      }
      default:
        return <></>
    }
  }
  return (
    <CardWrapper
      className={selected ? classes.selected : undefined}
      onClick={() => selectDependent(dep._id)}
    >
      <div>
        <DependentName>{`${dep.firstName} ${dep.lastName}`}</DependentName>
        <div>{getStatus()}</div>
      </div>
      <ChevronRightSharpIcon />
    </CardWrapper>
  )
}

const AddDependent = (props: {
  dep: Dependent
  i18n: I18n
  email: {
    value: string
    touched: boolean
    error: string
  }
  handleChange: (value: string) => void
  handleBlur: () => void
  sendInvite: () => void
  onBack: () => void
  isMobile: boolean
}) => {
  const {
    dep,
    i18n,
    email,
    handleBlur,
    handleChange,
    sendInvite,
    onBack,
    isMobile,
  } = props
  const classes = useStyles()
  const isLoading = useSelector(selectDependentsIsLoading)
  return (
    <div className={classes.detailWrapper}>
      <div className={classes.detailContent}>
        <div className={classes.titleRow}>
          <Typography className={`${classes.pageTitle} noMargin`}>
            {dep.inviteStatus === InviteStatus.NONE ||
            dep.inviteStatus === InviteStatus.INVITED
              ? i18n.add_dependent
              : i18n.dependent}
          </Typography>
          {!isMobile && (
            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => onBack()} />
          )}
        </div>
        {(dep.inviteStatus === InviteStatus.NONE ||
          dep.inviteStatus === InviteStatus.INVITED) && (
          <Typography className={classes.body}>
            {i18n.add_dependent_desc}
          </Typography>
        )}
        <div className={classes.fields}>
          <FilledTextField
            label={i18n.dependent_email}
            autoComplete="email"
            name={'email'}
            type={'text'}
            value={email.value}
            error={email.touched && Boolean(email.error)}
            helperText={(email.touched && email.error) || ''}
            variant="filled"
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            disabled={
              dep.inviteStatus === InviteStatus.ACCEPTED ||
              dep.inviteStatus === InviteStatus.DEACTIVATED
            }
            required
          />
          <div>
            <Typography>{i18n.first_name}</Typography>
            <DependentName>{dep.firstName}</DependentName>
          </div>
          <div>
            <Typography>{i18n.last_name}</Typography>
            <DependentName>{dep.lastName}</DependentName>
          </div>
        </div>
        {!isMobile &&
          (dep.inviteStatus === InviteStatus.NONE ||
            dep.inviteStatus === InviteStatus.INVITED) && (
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.actionBtn}
                onClick={sendInvite}
                disabled={!email.value || Boolean(email.error) || isLoading}
              >
                {dep.inviteStatus === InviteStatus.NONE
                  ? i18n.send_invite_button
                  : i18n.resend_invite_button}
              </Button>
            </div>
          )}
      </div>
      {isMobile &&
        (dep.inviteStatus === InviteStatus.NONE ||
          dep.inviteStatus === InviteStatus.INVITED) && (
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.actionBtn}
              onClick={sendInvite}
              disabled={!email.value || Boolean(email.error) || isLoading}
            >
              {dep.inviteStatus === InviteStatus.NONE
                ? i18n.send_invite_button
                : i18n.resend_invite_button}
            </Button>
          </div>
        )}
    </div>
  )
}

const CardWrapper = styled.div`
  border-top: 1px solid #505358;
  border-bottom: 1px solid #505358;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  margin-top: -1px;
`

const ColumnWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DependentWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`
const DependentName = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
`

const DependentStatus = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
`

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    'color': '#282D37',
    'margin': '32px 48px',
    'fontSize': 36,
    'fontWeight': 600,
    '&.noMargin': {
      margin: 0,
    },
    [theme.breakpoints.down('lg')]: {
      'margin': '16px',
      '&.noMargin': {
        margin: 0,
      },
    },
  },
  detailWrapper: {
    border: '2px #505358 solid',
    width: '100%',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
      border: 'none',
    },
  },
  detailContent: {
    padding: '16px',
  },
  dependentList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: '0px 16px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('lg')]: {
      marginTop: '24px',
    },
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 48,
    alignItems: 'flex-start',
  },
  desktopContent: {
    padding: '0px 48px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 48,
    alignItems: 'flex-start',
  },
  header: {
    minHeight: '525px',
    [theme.breakpoints.down(600)]: {
      padding: '24px 0px',
    },
  },
  selected: {
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.05),
  },
  titleRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: '#54565B',
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 600,
  },
  body: {
    fontSize: 16,
    fontWeight: 500,
    color: '#757575',
    alignSelf: 'flex-start',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    width: '100%',
    margin: '24px 0px',
  },
  buttonWrapper: {
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      position: 'fixed',
      bottom: '24px',
      padding: 0,
      width: '100%',
      margin: '0px',
    },
  },

  actionBtn: {
    width: '100%',
    justifySelf: 'center',
    maxHeight: 48,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 20,
    marginBottom: 0,
    [theme.breakpoints.down('lg')]: {
      maxWidth: '90%',
      margin: '0px auto',
    },
  },
}))
