import { createSlice, Dispatch } from '@reduxjs/toolkit'
import api from '../../../api'
import { lsClient } from '../../../ls-client'
import { HasDisagreedConsents, Term } from '../types'

// const tenant = process.env.REACT_APP_TENANT_NAME
const getTenant = () => {
  return lsClient.getUserLSByKey('tenantId')
}

const docKey = 'IntroductionText'

export const initialStore = {
  hasDisagreedConsents: HasDisagreedConsents.UNKNOWN,
  terms: [] as Term[],
  docTerm: null as Term | null,
  docLoading: false,
  unfilteredTerms: [] as Term[],
}

const terms = createSlice({
  name: 'terms',
  initialState: initialStore,
  reducers: {
    saveTerms(store, { payload }: { payload: { terms: Term[] } }) {
      store.terms = payload.terms
    },
    saveDocTerm(store, { payload }: { payload: { docTerm: Term | null } }) {
      store.docTerm = payload.docTerm
    },
    clearDocTerm(store) {
      store.docTerm = null
    },
    setDocLoading(store, { payload }: { payload: boolean }) {
      store.docLoading = payload
    },
    saveHasDisagreedConsents(
      store,
      { payload }: { payload: { hasDisagreedConsents: HasDisagreedConsents } }
    ) {
      store.hasDisagreedConsents = payload.hasDisagreedConsents
    },
    setAllTerms(store, { payload }: { payload: Term[] }) {
      store.unfilteredTerms = payload
    },
  },
})

const { actions } = terms
export const { clearDocTerm } = terms.actions

export const fetchTerms =
  (userEmail: string) => async (dispatch: Dispatch, getStore: any) => {
    const {
      translation: { selectedLanguage },
    } = getStore()

    // get consent docs by an old JAVA API to find introductionText
    const docTerms = await api.getTerms<Term[]>()
    const pendingTerms = await api.getPendingTerms(selectedLanguage, userEmail)

    const docTerm = docTerms.find((term) => term.key === docKey)
    if (docTerm) dispatch(actions.saveDocTerm({ docTerm }))

    //Unfiltered list for Terms and Conditions page
    const legalNotices = docTerms.filter((term) => term.document)
    if (legalNotices) {
      dispatch(actions.setAllTerms(legalNotices))
    }

    if (pendingTerms?.isOK) {
      dispatch(
        actions.saveTerms({
          terms: pendingTerms.data,
        })
      )
    }
  }

export const fetchTermByKey =
  (verbiageKey: string, tenantId: string) =>
  async (dispatch: Dispatch, getStore: any) => {
    dispatch(actions.setDocLoading(true))
    const {
      translation: { selectedLanguage },
    } = getStore()

    const data = await api.getTenantTermByKey(
      selectedLanguage,
      tenantId,
      verbiageKey
    )
    dispatch(actions.setDocLoading(false))
    if (data?.data?.[0]) {
      const docTerm = data.data[0] as Term
      dispatch(actions.saveDocTerm({ docTerm }))
    }
  }

export const getConsents =
  (accountId: string, email: string) => async (dispatch: Dispatch) => {
    const terms = await api.getConsent<Term[]>(accountId, email)
    const hasDisagreedConsents =
      terms.length > 0 ? HasDisagreedConsents.TRUE : HasDisagreedConsents.FALSE
    dispatch(actions.saveHasDisagreedConsents({ hasDisagreedConsents }))
  }

export const termsAgreed = () => async (dispatch: Dispatch, getStore: any) => {
  const { terms, translation } = getStore()
  const verbiageKeys = terms.terms.map((item: any) => item.key)

  const data = {
    tenantId: getTenant(),
    language: translation.selectedLanguage,
    verbiageKeys,
  }

  const res = await api.agreeTerm(data)

  if (res?.isOK) {
    dispatch(
      actions.saveHasDisagreedConsents({
        hasDisagreedConsents: HasDisagreedConsents.FALSE,
      })
    )
  }
}

export const termsReducer = terms.reducer
export const termsReducerName = terms.name
