import { lsClient } from '../ls-client'


export function createResourcesLink() {
  // const tenant = process.env.REACT_APP_TENANT_NAME || ''
  const tenant = lsClient.getUserLSByKey('tenantId') || ''
  const env = process.env.REACT_APP_ENV || ''
  const envMode = process.env.REACT_APP_ENV_MODE || ''
  console.log(tenant)
  console.log(envMode)
  if (!tenant) return null

  return {
    basic: `https://safe-content-cache-us-west-2-${env}-${envMode}.s3.us-west-2.amazonaws.com/${env}/api-responses/videos-${tenant}.json`,
    primary: `https://safe-content-cache-us-west-2-${env}-${envMode}.s3.us-west-2.amazonaws.com/${env}/api-responses/resource-primary-video-${tenant}.json`,
  }
}
