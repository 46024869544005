import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../translation'
import { DateSend } from '../atoms'

export const SystemMessageItem = (props: any) => {
  const { messageData } = props
  const i18n = useSelector(selectLanguageContent)

  if (!messageData) {
    return (
      <TypographyStyled variant="h6" align={'center'}>
        No data
      </TypographyStyled>
    )
  }

  return (
    <>
      <MessageWrapper>
        <MessageAuthor>
          <span className="system">
            {i18n.from}: {messageData.conversationFrom}
          </span>
        </MessageAuthor>
        <Divider />
        <DateSend dateSend={messageData.date} />
        <MessageAuthor>
          <span className="system">{messageData?.subject}</span>
        </MessageAuthor>
        <Divider />

        <MessageBody>
          {messageData?.messages && messageData.messages[0]?.body ? (
            <span
              dangerouslySetInnerHTML={{
                __html: messageData.messages[0]?.body,
              }}
            />
          ) : (
            <TypographyStyled variant="body1" style={{ marginBottom: 20 }}>
              {i18n.message_no_content}
            </TypographyStyled>
          )}
        </MessageBody>
      </MessageWrapper>
    </>
  )
}

const MessageAuthor = styled(Typography)`
  font-family: Avenir Next Rounded Medium !important;
  font-size: 16px !important;
  padding: 15px 0;
  color: #545454;
  & > .system {
    font-weight: 600 !important;
  }
`

const MessageWrapper = styled.div`
  font-size: 14px !important;
  padding: 0 20px;
`
const TypographyStyled = styled(Typography)`
  color: #7b7c7d;
`
const Divider = styled.div`
  margin-bottom: 10px;
  border-bottom: solid 1px #efefef;
`
const MessageBody = styled.div`
  width: 100%;
`
