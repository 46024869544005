import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { Prescription } from '../../types/treatment-plan'

interface OrderStatusSnackbarSlice {
  orderStatusMessage: any
  treatmentPlan: Prescription | null
}

const initialStore: OrderStatusSnackbarSlice = {
  orderStatusMessage: null,
  treatmentPlan: null,
}

export const orderStatusSnackbarSlice = createSlice({
  name: 'orderStatusSnackbar',
  initialState: initialStore,
  reducers: {
    setOrderStatusMessage(store, { payload }: { payload: any }) {
      store.orderStatusMessage = payload
    },
    setTreatmentPlan(store, { payload }: { payload: Prescription }) {
      store.treatmentPlan = payload
    },
  },
})

const { setOrderStatusMessage } = orderStatusSnackbarSlice.actions

export const getOrderStatus = () => async (dispatch: Dispatch) => {
  const { data } = await api.getOrderStatus()

  if (data) {
    dispatch(setOrderStatusMessage(data))
  }
}

// export const getTreatmentPlan =
//   (npi: string, healthServiceId: string, prescriptionId: string) =>
//   async (dispatch: Dispatch) => {
//     const { data } = await api.getPrescriptionById(
//       npi,
//       healthServiceId,
//       prescriptionId
//     )

//     if (data) {
//       dispatch(setOrderStatusMessage(data))
//     }
//   }

export const orderStatusSnackbarReducer = orderStatusSnackbarSlice.reducer

export const orderStatusSnackbarReducerName = orderStatusSnackbarSlice.name

interface RootStore {
  [orderStatusSnackbarReducerName]: typeof initialStore
}

export const selectOrderStatusMessage = ({ orderStatusSnackbar }: RootStore) =>
  orderStatusSnackbar.orderStatusMessage

export const selectTreatmentPlan = ({ orderStatusSnackbar }: RootStore) =>
  orderStatusSnackbar.treatmentPlan
