import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import RegisterTestDefault from '../../../assets/img/Register-Test.png'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { lsClient } from '../../../ls-client'
import { Loading } from '../../../ui'
import { ModuleIntroWrapper } from '../../../ui/templates/modules/moduleIntroWrapper'
import { ModuleActionButton } from '../../../ui/templates/modules/types'
import { selectNetworkSettings } from '../../network/model'
import { selectOrderablePanels, selectTestKits } from '../../order-test/model'
import { paths } from '../../paths'
import {
  getLabTestDetails,
  getTestPanelDetails,
  resetTestStore,
  selectRegisterDetails,
  selectTestLoading,
  setActive,
} from '../model'
import { RegisterTestProps } from '../types'

export const ScanIntroduction = (props: RegisterTestProps) => {
  const { i18n, handleNext, handleBack } = props
  const navigate = useNavigate()
  const loadingTest = useSelector(selectTestLoading)
  const dispatch = useDispatch()
  const registerDetails = useSelector(selectRegisterDetails)
  const networkSettings = useSelector(selectNetworkSettings)
  const testId = lsClient.getUserLSByKey('testId')
  const testType = lsClient.getUserLSByKey('testType')
  const testKits = useSelector(selectTestKits)
  const orderablePanels = useSelector(selectOrderablePanels)
  const numberOfTests = (testKits?.length || 0) + (orderablePanels?.length || 0)

  useEffect(() => {
    // Advance member to Collection Flow if resuming HS and kit scan id exists
    const dmCode = lsClient.getUserLSByKey('code')
    const step = lsClient.getUserLSByKey('step')
    if (dmCode && !step) {
      dispatch(setActive(3))
    } else if (dmCode && step) {
      navigate(paths.instructions())
    } else if (testId && !registerDetails && testType === 'orderableTest') {
      dispatch(getLabTestDetails(testId))
    } else if (testId && !registerDetails && testType === 'orderablePanel') {
      dispatch(getTestPanelDetails(testId))
    }
  }, [])

  const clearTest = () => {
    lsClient.removeUserKeyLS('testId')
    lsClient.removeUserKeyLS('testType')
    dispatch(resetTestStore())
  }

  const back = () => {
    clearTest()
    handleBack()
  }

  const handleClose = () => {
    clearTest()
    navigate(paths.app.dashboard())
  }

  const actions = [
    {
      label: registerDetails?.buttonTitle || i18n.scan_code_button,
      action: handleNext,
      variant: 'contained',
    },
  ] as ModuleActionButton[]

  if (loadingTest) return <Loading size={100} />

  return (
    <ModuleIntroWrapper
      title={registerDetails?.title || i18n.scan_code_instruction_title}
      body={
        registerDetails?.description ? (
          <HTMLTextComponent HTMLString={registerDetails.description} />
        ) : (
          i18n.scan_code_instruction_text
        )
      }
      contentImage={registerDetails?.imageURL || RegisterTestDefault}
      actions={actions}
      onBack={
        numberOfTests > 1 && networkSettings?.enableChooseATestScreen
          ? back
          : undefined
      }
      onClose={handleClose}
    />
  )
}
