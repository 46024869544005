import React, { useEffect } from 'react'
import { ContentWrapper, PageWrapper, Title } from './helpers/styled-components'
import { TimeBasedScreenProps } from './types'

export const TimeBasedMessageScreen = (props: TimeBasedScreenProps) => {
  const { title, handleNext, backgroundColor, icon, delay } = props

  useEffect(() => {
    setTimeout(() => {
      handleNext()
    }, delay)
  }, [delay])

  return (
    <PageWrapper backgroundcolor={backgroundColor}>
      <ContentWrapper>
        <>
          {icon}
          <Title backgroundcolor={backgroundColor}>{title}</Title>
        </>
      </ContentWrapper>
    </PageWrapper>
  )
}
