/* eslint-disable unicorn/no-nested-ternary */
import { useEffect, useState } from 'react'

declare const window: Window &
  typeof globalThis & {
    opr: any,
    chrome: any
  }

export enum UserDevice {
  iOS = 'iOS',
  Android = 'Android',
  Desktop_Laptop = 'Desktop_Laptop',
  Windows_Phone = 'Windows_Phone',
  BlackBerry = 'BlackBerry',
  Macintosh = 'Macintosh',
}

export enum UserBrowser {
  edge = 'edge',
  'chromium based edge' = 'chromium based edge',
  opera = 'opera',
  chrome = 'chrome',
  ie = 'ie',
  firefox = 'firefox',
  safari = 'safari',
  other = 'other',
}
export const useAgent = () => {
  const [device, setDevice] = useState<UserDevice | undefined>(undefined)
  const [browser, setBrowser] = useState<UserBrowser | undefined>(undefined)

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase()
    if (
      userAgent.match(/iphone/i) ||
      userAgent.match(/ipad/i) ||
      userAgent.match(/ipod/i)
    ) {
      setDevice(UserDevice.iOS)
    } else if (userAgent.match(/android/i)) {
      setDevice(UserDevice.Android)
    } else if (userAgent.match(/webos/i)) {
      setDevice(UserDevice.Desktop_Laptop)
    } else if (userAgent.match(/windows phone/i)) {
      setDevice(UserDevice.Desktop_Laptop)
    } else if (userAgent.match(/blackberry/i)) {
      setDevice(UserDevice.BlackBerry)
    } else if (userAgent.match(/macintosh/i)) {
      setDevice(UserDevice.Macintosh)
    }
    const browser = userAgent.includes('edge')
      ? 'edge'
      : userAgent.includes('edg')
      ? 'chromium based edge'
      : userAgent.includes('opr') && Boolean(window.opr)
      ? 'opera'
      : userAgent.includes('chrome') && Boolean(window.chrome) || userAgent.includes('crios')
      ? 'chrome'
      : userAgent.includes('trident')
      ? 'ie'
      : userAgent.includes('firefox')
      ? 'firefox'
      : userAgent.includes('safari')
      ? 'safari'
      : 'other'
    setBrowser(UserBrowser[browser])
  }, [])

  return { device, browser }
}
