import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFlowControl } from '../../features/flowcontrol/use-flow-control'
import { selectDisposition } from '../../features/health-service-lite/model'
import { selectLanguageContent } from '../../features/translation'
import { lsClient } from '../../ls-client'
import { selectNetworkSettings } from '../network/model'
import { TelehealthOptions } from '../network/type'
import { paths } from '../paths'
import { Disposition, GetTested, Instructions } from './outcomes'
import {
  resetQuestionnaireV2Store,
  selectQuestionnaireOutcome,
} from './questionnaireV2Slice'

export const ResultQuestionnaire = () => {
  const navigate = useNavigate()
  const outcome = useSelector(selectQuestionnaireOutcome)
  const disposition = useSelector(selectDisposition)
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const networkSettings = useSelector(selectNetworkSettings)

  const { state, actions } = useFlowControl()

  useEffect(() => {
    if (state.isFlowExecuting) {
      actions.next({ out: outcome.shortName || '' })
    }
  }, [outcome])

  const clearQuestionnaire = () => {
    lsClient.removeUserKeyLS('VCquestionnaireId')
    dispatch(resetQuestionnaireV2Store())
  }

  const outcomeView = () => {
    if (state.isFlowExecuting) return null
    if (disposition) {
      if (
        networkSettings?.telehealthConfiguration ===
        TelehealthOptions.FIRST_VIEW_FINANCIAL
      ) {
        return <Disposition disposition={disposition} i18n={i18n} />
      }
      navigate(paths.telehealthIntake('confirm-location'))
    }
    if (outcome.type === 'test') {
      clearQuestionnaire()
      navigate(paths.registerTest('intro'))
    }
    if (
      (outcome.type === 'v_consult' ||
        outcome.type === 'v_consultation' ||
        //'instructions' added for temp bypass to get to Safe Telehealth flow
        outcome.type === 'instructions') &&
      networkSettings?.telehealthConfiguration ===
        TelehealthOptions.SAFE_TELEHEALTH
    ) {
      clearQuestionnaire()
      navigate(paths.telehealthIntake('confirm-location'))
    }

    return <Instructions outcome={outcome} i18n={i18n} />
  }
  return <>{outcomeView()}</>
}
