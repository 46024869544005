import RandExp from 'randexp'

// Generate random device password with Cognito rules for MFA Remember Device
const randexp = new RandExp(
  /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{12}$/
)

const getDeviceCookiesForUser = (userEmail: string) => {
  return document.cookie
    .split(';')
    .filter((e) => e.includes('_safe_device'))
    .find((c) => c.includes(btoa(userEmail)))
}

export const userHasDeviceCookie = (userEmail: string) => {
  const hasCookies = getDeviceCookiesForUser(userEmail)
  return Boolean(hasCookies)
}

export const getSavedDeviceValues = (userEmail: string) => {
  const hasCookies = getDeviceCookiesForUser(userEmail)
  if (!hasCookies) return
  const userCookie = hasCookies.split('_safe_device=')
  const cookieParams = userCookie[1]
    ? atob(userCookie[1].replaceAll('%3D', ''))
    : ''
  return cookieParams ? JSON.parse(cookieParams) : ''
}

export const createWebDeviceId = () => `web-${self.crypto.randomUUID()}`

export const createDevicePassword = () => randexp.gen()
