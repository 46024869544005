import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { LogoImage } from '../../ui'
import { SupportDialog } from '../contact-support/supportDialog'

export const Error404Page = () => {
  const classes = useStyles()
  const [supportDialogOpen, setSupportDialogOpen] = useState(false)

  return (
    <Box className={classes.welcomeWrapper}>
      <Box className={classes.welcomeContentWrapper} style={{ flex: 1 }}>
        <Box className={classes.welcomeContent}>
          <LogoImage />
          <Box className={classes.contentContainer}>
            <Box className={classes.infoText}>404 Not Found</Box>
            <Box className={classes.optionsWrapper}>
              <Box className={classes.optionWrapper}>
                <Typography className={classes.subText}>
                  Sorry, the page you have requested does not exist.
                </Typography>
              </Box>
            </Box>
          </Box>
          <div className={classes.buttonWrapper}>
            {/* <MUILink
              role="button"
              color="primary"
              className={classes.pageLink}
              underline="always"
              onClick={() => setSupportDialogOpen(true)}
            >
              {i18n.contact_support}
            </MUILink> */}
            {/* <Typography className={classes.contactCompany}>
              Safe Health Systems, Inc.
            </Typography>
            <Typography className={classes.contactAddress}>
              1475 N. Scottsdale Rd. Suite 200
            </Typography>
            <Typography className={classes.contactAddress}>
              Scottsdale, Arizona 85257
            </Typography> */}
          </div>
        </Box>
      </Box>
      <SupportDialog
        open={supportDialogOpen}
        setSupportDialogOpen={setSupportDialogOpen}
      />
    </Box>
  )
}

const SPACE_BETWEEN = 'space-between'
const useStyles = makeStyles((_theme) => ({
  welcomeWrapper: {
    maxWidth: 540,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    width: '90%',
    margin: '0 auto',
    textAlign: 'center',
  },
  welcomeContentWrapper: {
    maxWidth: 540,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  infoText: {
    'fontWeight': 600,
    'maxWidth': '90vw',
    'width': '100%',
    'color': '#505358',
    'margin': '24px 0',
    'fontSize': 24,
    'alignSelf': 'center',
    'textAlign': 'center',
    'whiteSpace': 'pre-wrap',

    '& span': {
      fontFamily: 'Avenir Next Regular, Avenir Next Rounded Medium',
      fontWeight: 800,
    },
  },
  subText: {
    fontWeight: 400,
    width: '100%',
    color: '#505358',
    margin: '24px 0',
    fontSize: 20,
    alignSelf: 'center',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    margin: '0 auto',
    minHeight: 200,
  },
  welcomeContent: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 50px)',
  },
  optionsWrapper: {
    width: '100%',
  },
  optionWrapper: {
    padding: '0px 20px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    margin: '0 auto',
    maxWidth: 400,
    color: '#7B7C7D',
  },
  pageLink: {
    marginTop: '15px',
    marginBottom: '30px',
    cursor: 'pointer',
  },
  contactCompany: {
    fontWeight: 600,
    width: '100%',
    color: '#505358',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: 0.5,
    alignSelf: 'center',
    textAlign: 'center',
    marginTop: '16px',
  },
  contactAddress: {
    fontWeight: 400,
    width: '100%',
    color: '#505358',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: 0.5,
    alignSelf: 'center',
    textAlign: 'center',
  },
  buttonWrapper: {
    marginTop: '30px',
    width: '100%',
  },
}))
