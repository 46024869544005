import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { styled } from '@mui/material'
import React from 'react'
import { useMUITheme } from '../../../../libs/hooks/useTheme'
import { ListItemTitle, ListWrapper } from '../../../../libs/shared-components'
import { capitalizeString } from '../../../../libs/utils'
import { Typography } from '../../../../ui'
import { PageTitle } from '../../../../ui/templates/generic-screens/helpers/styled-components'
import { ClinicalStatus, Code } from '../../type'

export const DetailListWrapper = (props: {
  id: string
  title: string
  body: string
  handleClick: (id: string) => void
}) => {
  const { id, title, body, handleClick } = props
  const { success } = useMUITheme().colors
  return (
    <ListWrapper key={id} onClick={() => handleClick(id)}>
      <div>
        <ListItemTitle>{title}</ListItemTitle>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 500,
            color:
              body === capitalizeString(ClinicalStatus.active)
                ? success
                : '#505358',
          }}
        >
          {body}
        </Typography>
      </div>
      <div style={{ color: '#898989' }}>
        <ChevronRightRoundedIcon color="inherit" />
      </div>
    </ListWrapper>
  )
}

export interface ListDetail {
  title: string
  value: string
}

export const StandardDetailView = (props: {
  detailTitle: string
  secondaryText: string
  listDetails: ListDetail[]
}) => {
  const { detailTitle, secondaryText, listDetails } = props
  const { success } = useMUITheme().colors

  return (
    <ContentWrapper style={{ maxWidth: 600 }}>
      <PageTitle>{detailTitle}</PageTitle>
      <Typography
        style={{
          fontSize: 24,
          fontWeight: 500,
          color:
            secondaryText === capitalizeString(ClinicalStatus.active)
              ? success
              : '#505358',
        }}
      >
        {secondaryText}
      </Typography>
      <DetailFieldWrapper>
        {listDetails.map((item) => (
          <DetailField title={item.title} value={item.value} />
        ))}
      </DetailFieldWrapper>
    </ContentWrapper>
  )
}

export const NoContent = (props: { title: string }) => {
  const { title } = props
  return (
    <ListWrapper>
      <ListItemTitle>{title}</ListItemTitle>
    </ListWrapper>
  )
}

export const ListedDetailView = (props: {
  detailTitle: string
  secondaryText: string
  listDetails: Code[]
  handleClick: (id: string) => void
}) => {
  const { detailTitle, secondaryText, listDetails, handleClick } = props
  const { success } = useMUITheme().colors

  return (
    <ContentWrapper style={{ maxWidth: 600 }}>
      <PageTitle>{detailTitle}</PageTitle>
      <Typography
        style={{
          fontSize: 24,
          fontWeight: 500,
          color:
            secondaryText === capitalizeString(ClinicalStatus.active)
              ? success
              : '#505358',
        }}
      >
        {secondaryText}
      </Typography>
      <DetailFieldWrapper>
        {listDetails.map((item) => (
          <DetailListWrapper
            key={item.display}
            id={item.display}
            title={item.display || ''}
            body={''}
            handleClick={handleClick}
          />
        ))}
      </DetailFieldWrapper>
    </ContentWrapper>
  )
}

const DetailField = (props: { title: string; value: string }) => {
  return (
    <div>
      <Typography style={{ fontSize: 12, color: '#6E6E6E', fontWeight: 500 }}>
        {props.title}
      </Typography>
      <Typography style={{ fontSize: 16, color: '#282D37', fontWeight: 500 }}>
        {props.value}
      </Typography>
    </div>
  )
}

export const AddEdit = (props: { title: string; handleClick: () => void }) => {
  return (
    <div
      onClick={() => props.handleClick()}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        cursor: 'pointer',
      }}
    >
      <AddEditText>{props.title}</AddEditText>
      <AddRoundedIcon color="primary" />
    </div>
  )
}

export const DetailFieldWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  margin: '32px 0px',
  padding: '0px 16px',
})

export const TitleAndEditWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
})

export const AddEditText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: theme.palette.primary.main,
}))

export const ContentWrapper = styled('div')(({ theme }) => ({
  margin: '72px auto',
  width: '100%',
  maxWidth: '800px',
  [theme.breakpoints.down(960)]: {
    width: '90%',
    marginTop: 24,
  },
  [theme.breakpoints.down(600)]: {
    width: 'calc(100% - 24px)',
    marginTop: 24,
    marginLeft: 24,
  },
}))

export const SelectWrapper = styled('div')(({ theme }) => ({
  'display': 'flex',
  'alignItems': 'center',
  'marginBottom': 30,
  '& .MuiInput-formControl': {
    'margin': 0,
    '&::before': {
      display: 'none',
    },
    'transform': 'translate(12px,12px)',
    '& .MuiSelect-select:focus': {
      backgroundColor: '#FFF',
    },
  },
  '& .MuiInputBase-input': {
    color: '#282D37',
    fontSize: 36,
    fontWeight: 600,
  },
  '& .MuiSelect-icon': {
    top: 2,
  },
  '& .Mui-focused': {
    'backgroundColor': '#FFF',
    '&::after': {
      display: 'none',
    },
  },
}))
