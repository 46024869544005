import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { isValidEmailAddress } from '../../libs/helpers'

interface ResetPasswordSlice {
  isLoading: boolean
  email: string
  emailError: boolean
  emailTouched: boolean
  verificationCodeError: boolean
  error: string
  requestSuccessful: boolean
}
const initialStore: ResetPasswordSlice = {
  isLoading: false,
  email: '',
  emailError: false,
  emailTouched: false,
  verificationCodeError: false,
  error: '',
  requestSuccessful: false,
}

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setEmail(store, { payload }: { payload: string }) {
      store.email = payload
      store.emailTouched = true
      store.emailError = !isValidEmailAddress(payload)
    },
    setEmailTouched(store) {
      store.emailTouched = true
      store.emailError = !isValidEmailAddress(store.email)
    },
    setError(store, { payload }: { payload: string }) {
      store.error = payload
    },
    setRequestSuccessful(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
  },
})
export const { setLoading, setEmail, setEmailTouched, setError } =
  resetPasswordSlice.actions

export const requestPasswordReset =
  (email: string, success: () => void) => async (dispatch: Dispatch) => {
    dispatch(setError(''))
    dispatch(setLoading(true))
    const params = {
      email,
    }

    const { data, error } = await api.requestResetPassword(params)
    if (data?.isEmailSent) {
      dispatch(setLoading(false))
      success()
    } else if (data && !data.isEmailSent) {
      dispatch(setLoading(false))
      dispatch(setError(data.errorMessage))
    } else {
      dispatch(
        setError(error.message || 'Unable to reset password at this time')
      )
      dispatch(setLoading(false))
    }
  }

export const submitPasswordChange =
  (
    email: string,
    confirmationCode: string,
    password: string,
    success: () => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setError(''))
    dispatch(setLoading(true))
    const params = {
      username: email,
      password,
      confirmationCode,
    }

    const { data, error, errorMessage } = await api.confirmResetPassword(params)

    if (data?.isReset) {
      dispatch(setLoading(false))
      success()
    } else if (data && !data.isReset) {
      dispatch(
        setError(
          error || errorMessage || data.errorMessage || 'Password reset failed'
        )
      )
      dispatch(setLoading(false))
    } else if (error) {
      dispatch(
        setError(
          data.errorMessage ||
            'A server error occurred. Unable to reset password at this time'
        )
      )
      dispatch(setLoading(false))
    }
  }

export const resetPasswordReducer = resetPasswordSlice.reducer
export const resetPasswordReducerName = resetPasswordSlice.name
interface RootStore {
  [resetPasswordReducerName]: typeof initialStore
}

export const selectEmail = ({ resetPassword }: RootStore) => resetPassword.email

export const selectEmailTouched = ({ resetPassword }: RootStore) =>
  resetPassword.emailTouched

export const selectEmailError = ({ resetPassword }: RootStore) =>
  resetPassword.emailError

export const selectPasswordResetLoading = ({ resetPassword }: RootStore) =>
  resetPassword.isLoading

export const selectVerificationCodeError = ({ resetPassword }: RootStore) =>
  resetPassword.verificationCodeError

export const selectPasswordResetError = ({ resetPassword }: RootStore) =>
  resetPassword.error
