import styled from '@emotion/styled'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QuestionnaireCheckbox } from '../../../features/questionnaire/atoms'
import { isAppRunningInstalled } from '../../../libs/helpers'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { getColor } from '../../../libs/utils'
import { Typography } from '../../../ui'
import { getQuestionnaireColors } from '../outcomes/helpers/utils'
import {
  selectCurrentQuestion,
  selectExistingAnswer,
  selectQuestionnaireColor,
  setAutoSubmit,
  setCanContiune,
  updateAnswer,
} from '../questionnaireV2Slice'
import { MultipleChoiceAnswer, Option, QuestionType } from '../types'

export const MultipleQuestion = () => {
  const questionnaireColor = useSelector(selectQuestionnaireColor)
  const color = getQuestionnaireColors(questionnaireColor)
  const dispatch = useDispatch()
  const existingAnswer = useSelector(selectExistingAnswer)
  const question = useSelector(selectCurrentQuestion)
  const classes = useStyles({ color })
  const [answer, setAnswer] = useState<string[]>([])
  const [noneOption, setNoneOption] = useState<string | undefined>(undefined)
  const appRunningInstalled = isAppRunningInstalled()
  const aToZ = [] as string[]
  const { theme } = useMUITheme()
  const questionnaireThemeColor = getColor(color, theme)

  useEffect(() => {
    const answers = existingAnswer?.valueList
    if (answers) {
      setAnswer(answers)
      dispatch(updateAnswer(createAnswer(answers)))
    }
  }, [existingAnswer])

  useEffect(() => {
    const options = question.options?.map((o) => o.value.translations.eng)
    if (options) {
      setNoneOption(options.find((o) => o.includes('None')))
    }
  }, [question])

  const createAnswer = (answerUpdate: string[]) => {
    const updatedAnswer: MultipleChoiceAnswer[] = []
    answerUpdate.forEach((answerValue) => {
      const answerOption = {
        answer: answerValue,
        nodeId: null,
        comment: null,
        type: QuestionType.SmallText,
      }
      updatedAnswer.push(answerOption)
    })
    return updatedAnswer
  }
  const multipleAnswerChanged = (value: string) => {
    if (question.multiSelect) {
      const answerUpdate = [...answer]
      if (answerUpdate.includes(value)) {
        answerUpdate.splice(answerUpdate.indexOf(value), 1)
      } else {
        if (noneOption && answerUpdate.includes(noneOption)) {
          answerUpdate.splice(answerUpdate.indexOf(noneOption, 1))
        }
        answerUpdate.push(value)
      }
      if (noneOption && answerUpdate.includes(noneOption)) {
        setAnswer([noneOption])
        createAnswer([noneOption])
        dispatch(updateAnswer(createAnswer([noneOption])))
      } else {
        setAnswer(answerUpdate)
        createAnswer(answerUpdate)
        dispatch(updateAnswer(createAnswer(answerUpdate)))
        if (answerUpdate.length === 0) {
          dispatch(setCanContiune(false))
        }
      }
    } else {
      setAnswer([value])
      dispatch(updateAnswer(createAnswer([value])))
      if (!existingAnswer) {
        dispatch(setAutoSubmit(true))
      }
    }
  }
  const scrollToOption = (id: string) => {
    // eslint-disable-next-line unicorn/prefer-query-selector
    document.getElementById(id)?.scrollIntoView()
  }

  const optionList = () => {
    return question.options?.map((option: Option, i: number) => {
      let isFirstOfAlpha = false
      const alphaIndex =
        option.value.translations.eng
          .match(/[a-zA-Z]/)
          ?.pop()
          ?.toUpperCase() || ''
      if (question.enableAlpha && !aToZ.includes(alphaIndex)) {
        aToZ.push(alphaIndex)
        isFirstOfAlpha = true
      }
      return (
        <>
          <QuestionnaireCheckbox
            id={isFirstOfAlpha ? alphaIndex : ''}
            key={option.value.translations.eng}
            checked={answer.includes(option.value.translations.eng)}
            onChange={() =>
              multipleAnswerChanged(option.value.translations.eng)
            }
            label={option.value.translations.eng}
            type={question.multiSelect ? 'checkbox' : 'radio'}
            color={getQuestionnaireColors(questionnaireColor)}
          />
          {question.options?.length && i !== question.options.length - 1 && (
            <Divider />
          )}
        </>
      )
    })
  }

  return (
    <div
      className={
        appRunningInstalled ? `${classes.root} installed` : classes.root
      }
      id="content"
    >
      {optionList()}
      {question.enableAlpha && (
        <div className={classes.quickNav}>
          {aToZ.map((alphaIndex) => (
            <div key={alphaIndex} onClick={() => scrollToOption(alphaIndex)}>
              <Typography
                sx={{ color: questionnaireThemeColor }}
                className={classes.navText}
              >
                {alphaIndex}
              </Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gap: '8px',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.down(600)]: {
      'paddingBottom': '120px',
      '&.installed': {
        paddingBottom: '60px',
      },
    },
  },
  quickNav: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 64px)',
    gap: 6,
    position: 'fixed',
    bottom: 10,
    right: 20,
    [theme.breakpoints.down(960)]: {
      right: 5,
      gap: 'calc(.1vh)',
    },
  },
  navText: {
    userSelect: 'none',
    fontSize: 12,
    fontWeight: 500,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}))

const Divider = styled.div`
  width: 100%;
  border-bottom: solid 1px #eaeaea;
`
