import React from 'react'
import {
  FindingProvider,
  ProviderReady,
  ProviderReview,
} from './waiting-rooms/index'

export const WaitingRoomWrapper = {
  FindingProvider: () => <FindingProvider />,
  ProviderReview: () => <ProviderReview />,
  ProviderReady: () => <ProviderReady />,
}
