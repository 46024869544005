import React from 'react'
import { I18n } from '../../features/translation/types'
import { StandardRecordListPage } from './detail-pages'
import { VitalsListPage } from './detail-pages/vitals-list'
import { HealthRecordProps, HealthRecordType } from './type'

export const healthRecordTypeMap = (
  i18n: I18n,
  healthRecordProps?: HealthRecordProps
) => ({
  [HealthRecordType.ALLERGY]: {
    label: i18n.patient_records_title_allergies,
    addEditLabel: i18n.patient_records_title_add_allergy,
    content: healthRecordProps ? (
      <StandardRecordListPage
        {...healthRecordProps}
        title={i18n.patient_records_title_allergies}
      />
    ) : null,
    type: HealthRecordType.ALLERGY,
  },
  [HealthRecordType.CONDITIONS]: {
    label: i18n.patient_records_title_conditions,
    addEditLabel: i18n.patient_records_title_add_conditions,
    content: healthRecordProps ? (
      <StandardRecordListPage
        {...healthRecordProps}
        title={i18n.patient_records_conditions}
      />
    ) : null,
    type: HealthRecordType.CONDITIONS,
  },
  [HealthRecordType.VACCINATIONS]: {
    label: i18n.patient_records_title_vaccinations,
    addEditLabel: i18n.patient_records_title_add_immunization,
    content: healthRecordProps ? (
      <StandardRecordListPage
        {...healthRecordProps}
        title={i18n.patient_records_title_vaccinations}
      />
    ) : null,
    type: HealthRecordType.VACCINATIONS,
  },
  [HealthRecordType.VITAL]: {
    label: i18n.patient_records_title_vitals,
    addEditLabel: i18n.patient_records_title_add_vitals,
    content: healthRecordProps ? (
      <VitalsListPage
        {...healthRecordProps}
        title={i18n.patient_records_title_vitals}
      />
    ) : null,
    type: HealthRecordType.VITAL,
  },
  [HealthRecordType.MEDICATIONS]: {
    label: i18n.patient_records_title_medications,
    addEditLabel: i18n.patient_records_title_add_medication,
    content: healthRecordProps ? (
      <StandardRecordListPage
        {...healthRecordProps}
        title={i18n.patient_records_medication}
      />
    ) : null,
    type: HealthRecordType.MEDICATIONS,
  },
  [HealthRecordType.FAMILY_MEMBER_HISTORY]: {
    label: i18n.patient_records_title_family_details,
    addEditLabel: i18n.patient_records_title_add_family_details,
    content: healthRecordProps ? (
      <StandardRecordListPage
        {...healthRecordProps}
        title={i18n.patient_records_title_family_details}
      />
    ) : null,
    type: HealthRecordType.FAMILY_MEMBER_HISTORY,
  },
  [HealthRecordType.PROCEDURE]: {
    label: i18n.patient_records_title_pocedure,
    addEditLabel: i18n.patient_records_title_add_procedure,
    content: healthRecordProps ? (
      <StandardRecordListPage
        {...healthRecordProps}
        title={i18n.patient_records_title_pocedure}
      />
    ) : null,
    type: HealthRecordType.PROCEDURE,
  },
  [HealthRecordType.SOCIAL_HISTORY]: {
    label: i18n.patient_records_title_social_history,
    addEditLabel: i18n.patient_records_title_add_social_history,
    content: healthRecordProps ? (
      <StandardRecordListPage
        {...healthRecordProps}
        title={i18n.patient_records_title_social_history}
      />
    ) : null,
    type: HealthRecordType.SOCIAL_HISTORY,
  },
})
