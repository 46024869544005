import { Theme } from '@mui/material'
import { tracer } from '../api/tracer'
import { ColorWithHex } from '../types/general'
import { useMUITheme } from './hooks/useTheme'

export function dataURLtoBlob(url: any) {
  const array = url.split(',')
  const mime = array[0].match(/:(.*?);/)[1]
  const bstr = atob(array[1])
  let n = bstr.length
  const u8array = new Uint8Array(n)
  while (n--) {
    u8array[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8array], { type: mime })
}

export function merge(target: any, source: any) {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], merge(target[key], source[key]))
  }

  Object.assign(target || {}, source)
  return target
}

export const objectToArray = (obj: any) => {
  const array = []
  for (const key in obj) {
    array.push(obj[key])
  }
  return array
}

export const objectToSortedArray = (obj: any, sortOrder: string[]) => {
  const array: any[] = []
  sortOrder.forEach((key) => {
    array.push(obj[key])
  })
  return array
}

export const stripHyphen = (value: string): string => {
  return value.replace(/-/g, '');
}

export const cleanPhoneNumber = (value: string): string => {
  const removeplus = value.replace('+1', '')
  return removeplus.replace(/\D/g, '');
}

export const formatPhoneForDisplayUS = (value: string): string => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export function currency(value: number, symbol = '') {
  const round = Math.round(Number(value) * 100) / 100

  const match: any = round.toFixed(2).match(/(-?)(.+)/)
  return match[1] + String(symbol) + match[2]
}

export function isFloat(n: any) {
  return Number(n) === n && n % 1 !== 0
}

export function debounce(f: any, ms: any) {
  let isCooldown = false
  return (...args: any) => {
    if (isCooldown) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    f.apply(this, args)
    isCooldown = true
    setTimeout(() => {
      isCooldown = false
    }, ms)
  }
}

export const capitalizeString = (string: string) => {
  if (!string) return ''

  const newString = string.toLowerCase().split(' ')
  for (let i = 0; i < newString.length; i++) {
    newString[i] =
      newString[i]?.charAt(0).toUpperCase() + newString[i]?.slice(1)
  }
  return newString.join(' ')
}

export const cleanStringForAria = (string: string, type: 'id' | 'aria') => {
  let s = string
    .split('_')
    .join(' ')
    .split('-')
    .join(' ')
    .toLowerCase()
    .split(' ')
    .filter(Boolean)
  for (let i = 0; i < s.length; i++) {
    s[i] = s[i][0].toUpperCase() + s[i].slice(1)
  }
  return s.join(type === 'aria' ? ' ' : '')
}

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const getCurrentUser = () => {
  const userToken = sessionStorage.getItem('id_token')
  if (!userToken) return
  return parseJwt(userToken)['email']
}

export const sendAuditLog = (
  name: string,
  tags: { [k: string]: string | Date }
) => {
  const span = tracer.startSpan(name)

  for (const tagName in tags) {
    span.setTag(tagName, tags[tagName])
  }

  span.finish()
}

export const appVersion = () => {
  const APP_VERSION = process.env.REACT_APP_VERSION
  let version = APP_VERSION ? APP_VERSION.replace('-migration', '') : '1.0.0'
  if (!version.startsWith('v')) {
    version = `v${version}`
  }
  return version
}

export const addSpaceEvery4 = (code: string) => {
  return code.match(/.{1,4}/g)?.join(' ');
}

export const validateEntry = (
  event: React.KeyboardEvent<Element>,
  regex: RegExp
) => {
  if (!new RegExp(regex).test(event.key)) {
    event.preventDefault()
  }
}

export const getColor = (color: ColorWithHex, theme: Theme) => {
  if (color === 'primary') return theme.palette.primary.main
  if (color === 'secondary') return theme.palette.secondary.main
  return color
}

export const getColorV2 = (color: ColorWithHex) => {
  // const { primary, secondary } = useMUITheme().colors

  if (color === 'primary') return 'red'
  if (color === 'secondary') return 'green'
  return color
}
