import { Loader } from '@googlemaps/js-api-loader'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguageContent } from '../../features/translation'

interface LocationAddress {
  line1: string
  city: string
  state: string
}

interface FullAddress extends LocationAddress {
  zip: string
}

interface LatLng {
  lat: number
  lng: number
}

type LocationType = 'address' | 'latlng'

export const useLocationService = () => {
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const [gettingLocation, setGettingLocation] = useState(false)
  const [error, setError] = useState('')
  const [foundAddress, setFoundAddress] = useState<FullAddress | undefined>(
    undefined
  )
  const [latlng, setLatlng] = useState<LatLng | undefined>(undefined)

  const clearError = () => {
    setError('')
  }

  const loadScript = async (): Promise<void> => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
      version: 'weekly',
    })

    await loader.load()
  }

  async function reverseGeo(lat: number, long: number) {
    await loadScript()
    const geocoder = new google.maps.Geocoder()

    const latlng = {
      lat,
      lng: long,
    }

    const apiResponse = (result: google.maps.GeocoderResult[] | null) => {
      if (!result) return
      if (result[0].address_components[6].short_name !== 'US') {
        setError(
          'Your current location appears to be outside of the United States.'
        )
        setGettingLocation(false)
        return
      }
      const address = {
        line1: `${result[0].address_components[0].short_name} ${result[0].address_components[1].short_name}`,
        city: result[0].address_components[3].short_name,
        state: result[0].address_components[5].short_name,
        zip: result[0].address_components[7].short_name,
        latitude: lat,
        longitude: long,
      }
      setFoundAddress(address)
      setGettingLocation(false)
    }

    try {
      await geocoder.geocode({ location: latlng }, apiResponse)
    } catch {
      setGettingLocation(false)
      setError(
        'We are unable to get your current location. Please edit changes manually.'
      )
    }
  }

  const getLatLangFromAddress = async (address: any) => {
    if (gettingLocation) return
    setGettingLocation(true)
    await loadScript()
    const geocoder = new google.maps.Geocoder()

    const apiResponse = (result: google.maps.GeocoderResult[] | null) => {
      if (!result) return

      setGettingLocation(false)
      setLatlng({
        lat: result[0].geometry.location.lat(),
        lng: result[0].geometry.location.lng(),
      })
      const address = {
        line1: '',
        city: result[0].address_components[1].short_name,
        state: result[0].address_components[2].short_name,
        zip: result[0].address_components[0].short_name,
        latitude: result[0].geometry.location.lat(),
        longitude: result[0].geometry.location.lng(),
      }
      setFoundAddress(address)
      return {
        lat: result[0].geometry.location.lat(),
        lng: result[0].geometry.location.lng(),
      }
    }

    try {
      return await geocoder.geocode({ address: address.zip }, apiResponse)
    } catch {
      setGettingLocation(false)
      setError(
        'We are unable to get your current location. Please edit changes manually.'
      )
    }
  }

  const getCurrentLocation = async (type: LocationType) => {
    if (gettingLocation) return
    setError('')
    setGettingLocation(true)
    try {
      const { latitude, longitude } =
        (await getPosition()) as GeolocationCoordinates
      setLatlng({ lat: latitude, lng: longitude })
      if (type === 'address') {
        reverseGeo(latitude, longitude)
      } else {
        setGettingLocation(false)
      }
    } catch (error: any) {
      // setError(error.message)
      setError(i18n.location_not_enabled)
      console.log(error)
    }
  }

  const getPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords)
        },
        (err) => {
          setGettingLocation(false)
          reject(err)
        }
      )
    })
  }

  const openNativeMapFeature = (addressObject: LocationAddress) => {
    const address =
      `${addressObject.line1}, ${addressObject.city}  ${addressObject.state}`.replaceAll(
        ' ',
        '+'
      )
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      window.open(`maps://maps.google.com/maps?daddr=<${address}>&amp;ll=`)
    } else
      window.open(`https://maps.google.com/maps?daddr=<${address}>&amp;ll=`)
  }

  const actions = {
    clearError,
    getCurrentLocation,
    getLatLangFromAddress,
    openNativeMapFeature,
  }

  const state = {
    gettingLocation,
    foundAddress,
    latlng,
    error,
  }

  return { state, actions }
}
