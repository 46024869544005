import { Theme, useMediaQuery, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { ClassNameMap,makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import GetTestedImage from '../../../assets/img/Take-a-Test.svg'
import { StoreStepType } from '../../../features/shopify/model/types'
import { lsClient } from '../../../ls-client'
import { TestKitDetails } from '../../../types/tests'
import { Button, Typography } from '../../../ui'
import { ModuleIntroWrapper } from '../../../ui/templates/modules/moduleIntroWrapper'
import { ModuleActionButton } from '../../../ui/templates/modules/types'
import {
  getTestKits,
  selectOrderablePanels,
  selectTestKits,
} from '../../order-test/model'
import { paths } from '../../paths'
import { resetIntroStore } from '../../symptom-checker-introduction/symptomCheckerIntroSlice'
import { getLabTestDetails, getTestPanelDetails } from '../model'
import { RegisterTestProps } from '../types'

export const GetTested = (props: RegisterTestProps) => {
  const { i18n, handleNext } = props
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(960))
  const { step } = useParams<{ step: 'choose-a-test' }>()

  const dispatch = useDispatch()
  const testKits = useSelector(selectTestKits)
  const classes = useStyles()

  const orderablePanels = useSelector(selectOrderablePanels)
  const numberOfTests = (testKits?.length || 0) + (orderablePanels?.length || 0)

  const [showTests, setShowTests] = useState(Boolean(step))
  const handleClose = () => {
    navigate(paths.app.dashboard())
  }

  const getTestDetails = (testData: any) => {
    if (testData.labTestOrderableId) {
      lsClient.setUserLS('testId', testData.labTestOrderableId)
      const success = () => {
        handleNext()
      }
      dispatch(getLabTestDetails(testData.labTestOrderableId, success))
    } else if (testData.labTestPanelId) {
      lsClient.setUserLS('testId', testData.labTestOrderableId)
      const success = () => {
        handleNext()
      }
      dispatch(getTestPanelDetails(testData.labTestPanelId, success))
    }
  }

  useEffect(() => {
    dispatch(getTestKits('scan'))

    return () => {
      setShowTests(false)
    }
  }, [])

  const next = () => {
    if (numberOfTests === 1) {
      if (testKits?.length === 1) {
        getTestDetails(testKits[0])
      } else {
        getTestDetails(orderablePanels[0])
      }
    } else if (numberOfTests > 1) {
      setShowTests(true)
    }
  }
  const getOrder = () => {
    dispatch(resetIntroStore())
    navigate(paths.shopifyStore(StoreStepType.PRODUCTS))
  }

  const clostTestList = () => {
    setShowTests(false)
  }

  const actions = [
    {
      label: i18n.i_have_test_action,
      action: next,
      variant: 'contained',
      disabled: numberOfTests === 0,
    },
    {
      label: i18n.order_test_kit_option,
      action: getOrder,
      variant: 'outlined',
    },
  ] as ModuleActionButton[]

  return (
    <ModuleIntroWrapper
      contentImage={isMobile && showTests ? undefined : GetTestedImage}
      backgroundColor="#003D71"
      childPosition="center"
      contentPosition="center"
      onClose={handleClose}
      onBack={showTests ? clostTestList : undefined}
      mobileContentPosition="unset"
    >
      <Box style={{ width: '100%' }}>
        <div
          className={showTests ? `${classes.pageOne} hidden` : classes.pageOne}
        >
          <Typography variant="h4" className={classes.title}>
            {i18n.get_tested}
          </Typography>
          <Typography className={classes.body}>
            {i18n.take_test_outcome_text}
          </Typography>
          <Box className={classes.buttonContainer}>
            {actions?.map((b) => {
              return (
                <Button
                  key={b.label}
                  className={`${classes.button} ${b.variant}`}
                  variant={isMobile ? 'outlined' : b.variant}
                  onClick={b.action}
                >
                  {b.label}
                </Button>
              )
            })}
          </Box>
        </div>
        {/* <div
          className={showTests ? `${classes.pageTwo} show` : classes.pageTwo}
        >
          <Typography
            variant="h4"
            className={
              isMobile && showTests ? `${classes.title} whiteBG` : classes.title
            }
          >
            {i18n.choose_a_test_title}
          </Typography>
          <Typography
            className={
              isMobile && showTests ? `${classes.body} whiteBG` : classes.body
            }
          >
            {i18n.choose_a_test_message}
          </Typography>
          <div className={classes.listWrapper}>
            {testKits.length > 0 &&
              testKits.map((test) => (
                <TestItem
                  key={test?.labTestOrderableId}
                  test={test}
                  showLargeCards={numberOfTests === 2}
                  classes={classes}
                  color={getColor(test)}
                  handleNext={getTestDetails}
                />
              ))}
            {orderablePanels.length > 0 &&
              orderablePanels.map((test) => (
                <TestItem
                  key={test?.labTestPanelId}
                  test={test}
                  showLargeCards={numberOfTests === 2}
                  classes={classes}
                  color={getColor(test)}
                  handleNext={getTestDetails}
                />
              ))}
          </div>
        </div> */}
      </Box>
    </ModuleIntroWrapper>
  )
}

const TestItem = (props: {
  test: TestKitDetails
  showLargeCards: boolean
  color: string
  classes: ClassNameMap
  handleNext: (test: TestKitDetails) => void
}) => {
  const { test, showLargeCards, color, classes, handleNext } = props
  if (!test) return null

  return (
    <Box
      key={test.labTestOrderableId || test.labTestPanelId}
      className={classes.testWrapper}
      onClick={() => {
        handleNext(test)
      }}
    >
      <div className={classes.imageBox} style={{ backgroundColor: color }}>
        {test.chooseATestDetails?.testKitImageURL && (
          <img
            src={test.chooseATestDetails.testKitImageURL}
            className={
              showLargeCards ? `${classes.testImg} large` : classes.testImg
            }
          />
        )}
      </div>
      <Box className={classes.testDetails}>
        <Typography
          className={
            showLargeCards ? `${classes.testName} large` : classes.testName
          }
        >
          {test.longName}
        </Typography>
        <Typography
          className={
            showLargeCards ? `${classes.testDesc} large` : classes.testDesc
          }
        >
          {test.vendorName}
        </Typography>
      </Box>
    </Box>
  )
}

const IMAGE_SHADOW = '0px 8px 16px rgba(0, 0, 0, 0.25)'

const useStyles = makeStyles((theme) => ({
  'pageOne': {
    'position': 'relative',
    'zIndex': 2,
    'maxWidth': 500,
    '&.hidden': {
      zIndex: 1,
      animation: '$fadeOut 1s',
      opacity: 0,
    },
  },
  'pageTwo': {
    'position': 'absolute',
    'top': 96,
    'zIndex': 1,
    'width': '60vw',
    'opacity': 0,
    'display': 'none',
    '&.show': {
      animation: '$fadeIn 1s',
      opacity: 1,
      zIndex: 2,
      display: 'inline-block',
    },
    [theme.breakpoints.down(960)]: {
      width: '90vw',
      top: 64,
    },
  },
  '@keyframes fadeOut': {
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  'listWrapper': {
    overflowX: 'auto',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    [theme.breakpoints.down(600)]: {
      gap: 16,
    },
  },
  'title': {
    fontWeight: 600,
    color: '#505358',
    lineHeight: '55px',
    fontSize: 36,

    textAlign: 'inherit',
    [theme.breakpoints.down(960)]: {
      'width': '90%',
      'color': '#FFF',
      '&.whiteBG': {
        color: '#505358',
      },
    },
    [theme.breakpoints.down(389)]: {
      width: '90%',
      fontSize: 32,
      lineHeight: '36px',
    },
  },
  'body': {
    fontWeight: 500,
    color: '#505358',
    lineHeight: '22px',
    fontSize: 16,
    margin: '24px 0px 37px',
    maxWidth: 665,
    [theme.breakpoints.down(960)]: {
      'margin': '0px 0px 24px',
      'width': '90%',
      'color': '#FFF',
      '&.whiteBG': {
        color: '#505358',
      },
    },
    [theme.breakpoints.down(389)]: {
      fontSize: 14,
      marginBottom: '10px',
      lineHeight: '20px',
    },
  },
  'buttonContainer': {
    display: 'flex',
    flexDirection: 'column',
  },
  'button': {
    margin: '8px 0px',
    padding: '10px 48px',
    [theme.breakpoints.down(960)]: {
      maxWidth: 'unset',
    },
  },
  'page': {
    padding: 32,
    [theme.breakpoints.down(960)]: {
      padding: '32px 0px',
    },
  },
  'testWrapper': {
    width: 'calc(33.333333% - 32px)',
    cursor: 'pointer',
    marginBottom: 48,
    padding: '16px',
    maxHeight: 'fit-content',
    [theme.breakpoints.down(600)]: {
      width: 'calc(50% - 8px)',
      padding: '0px',
    },
  },
  'imageBox': {
    height: 0,
    padding: '50% 0px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  'testImg': {
    'height': '88px',
    '&.large': {
      height: '102px',
      [theme.breakpoints.down(960)]: {
        height: '64px',
      },
    },
    '-webkit-box-shadow': IMAGE_SHADOW,
    '-moz-box-shadow': IMAGE_SHADOW,
    'box-shadow': IMAGE_SHADOW,
    [theme.breakpoints.down(960)]: {
      height: '64px',
    },
  },
  'testDetails': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: 12,
    gap: 2,
  },
  'testName': {
    'fontSize': '14px',
    'lineHeight': '16px',
    'color': '#282D37',
    'fontWeight': 600,
    'width': '100%',
    '&.large': {
      fontSize: '22px',
      lineHeight: '33px',
      [theme.breakpoints.down(960)]: {
        fontSize: '14px',
        lineHeight: '18px',
      },
    },
    [theme.breakpoints.down(960)]: {
      fontSize: '14px',
    },
  },
  'testDesc': {
    'fontSize': '12px',
    'lineHeight': '16px',
    'color': '#757575',
    'fontWeight': 500,
    '&.large': {
      fontSize: '19px',
      lineHeight: '28px',
      [theme.breakpoints.down(960)]: {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    [theme.breakpoints.down(960)]: {
      fontSize: '12px',
    },
  },
  'testPrice': {
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: 500,
    width: '100%',
  },
}))
