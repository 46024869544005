import { Box, SxProps } from '@mui/material'
import MUIButton from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import React, { CSSProperties, PropsWithChildren } from 'react'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import {
  capitalizeString,
  cleanStringForAria,
  getColor,
} from '../../../libs/utils'
import { ButtonStyle, ButtonType } from '../../../pages/network/type'
import { ColorWithHex } from '../../../types/general'
import { Typography } from '../typography'

export type Variant = 'contained' | 'text' | 'outlined'

export const getButtonVariant = (variant: ButtonStyle): ButtonType => {
  switch (variant) {
    default:
    case 0:
      return ButtonType.contained
    case 1:
      return ButtonType.outlined
    case 2:
      return ButtonType.text
  }
}

interface ButtonProps extends PropsWithChildren {
  color?: ColorWithHex
  fullWidth?: boolean
  type?: 'submit' | 'reset' | 'button'
  className?: string
  disabled?: boolean
  onClick?: (e: any) => void
  component?: any
  to?: string
  isLoading?: boolean
  variant?: Variant
  id?: string
  style?: CSSProperties
  sx?: SxProps
}
export const Button: React.FC<ButtonProps> = ({
  children,
  color = 'primary',
  fullWidth = false,
  type = 'button',
  className,
  disabled,
  onClick,
  component,
  to,
  isLoading,
  variant = 'contained',
  id,
  style,
  sx,
}) => {
  const classes = useStyles({ color, variant })
  const { theme } = useMUITheme()
  const buttonColor = getColor(color, theme)

  if (variant === 'text') {
    return (
      <Box onClick={onClick}>
        <Typography className={classes.textButton} align="center">
          {children}
        </Typography>
      </Box>
    )
  }
  return (
    <MUIButton
      id={id ? id : cleanStringForAria(children?.toString() || '', 'id')}
      classes={{ root: classes.root }}
      className={className}
      fullWidth={fullWidth}
      type={type}
      disableElevation={true}
      disabled={disabled || isLoading}
      onClick={onClick}
      component={component}
      to={to}
      variant={variant}
      style={style}
      sx={{
        'color': variant === 'contained' ? '#fff' : buttonColor,
        'backgroundColor': variant === 'contained' ? buttonColor : '#fff',
        'borderColor': buttonColor,
        '&:hover': {
          color: variant === 'contained' ? buttonColor : '#fff',
          borderColor: variant === 'contained' ? buttonColor : '#fff',
          backgroundColor: variant === 'contained' ? '#fff' : buttonColor,
          border: '2px solid',
        },
        [theme.breakpoints.down(600)]: {
          '&:hover': {
            color: variant === 'contained' ? '#fff' : buttonColor,
            borderColor: variant === 'contained' ? buttonColor : '#fff',
            backgroundColor: variant === 'contained' ? buttonColor : '#fff',
          },
        },
        '&.Mui-disabled': {
          color: variant === 'contained' ? '#fff' : buttonColor,
          backgroundColor: variant === 'contained' ? buttonColor : '#fff',
          borderColor: variant === 'contained' ? buttonColor : '#fff',
          opacity: 0.4,
        },
        ...sx,
      }}
      aria-label={
        typeof children === 'string'
          ? cleanStringForAria(children.toString() || '', 'aria')
          : undefined
      }
    >
      {isLoading ? (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ) : (
        <span className={classes.label}>
          {typeof children === 'string'
            ? capitalizeString(children.toString() || '')
            : children}
        </span>
      )}
    </MUIButton>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 48,
    fontSize: 16,
    fontWeight: 700,
    width: '100%',
    opacity: 1,
    textTransform: 'none',
    transition: 'opacity .2s',
    border: '2px solid',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
  },
  buttonProgress: {
    top: 'calc(50% - 12px)',
    color: 'white',
    left: 'calc(50% - 12px)',
  },
  label: {
    letterSpacing: '.25px',
    fontWeight: 600,
  },
  textButton: {
    'cursor': 'pointer',
    'margin': '0px auto',
    'width': 'fit-content',
    'maxHeight': 41,
    'padding': '10px 24px',
    'fontSize': '16px',
    'fontWeight': 600,
    'letterSpacing': '.25px',
    'color': theme.palette.primary.main,
    '& span': {
      whiteSpace: 'noWrap',
    },
  },
}))
