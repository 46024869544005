import Lottie from 'lottie-react'
import React from 'react'
import WhiteCheckMark from '../../../../assets/animations/WhiteCheckMark.json'
import { ReactComponent as AlertLight } from '../../../../assets/img/alertLight.svg'

export const AlertCell = () => (
  <AlertLight style={{ width: '200px', color: '#FA9C14' }} />
)
export const AnimatedCheckMark = () => (
  <Lottie animationData={WhiteCheckMark} loop={false} />
)
export const Empty = () => <></>
