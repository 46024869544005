import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'
import { SimpleDialog } from '../application/templates/simple-dialog'
import { DialogContent } from './types'

const DialogContext = createContext({})

interface DialogInterface {
  openDialog: (content: DialogContent) => void
  closeDialog: () => void
}

export const DialogProvider = (props: PropsWithChildren) => {
  const [dialogContent, setDialogContent] = useState<DialogContent | undefined>(
    undefined
  )
  const openDialog = (content: DialogContent) => {
    setDialogContent(content)
  }

  const closeDialog = () => {
    setDialogContent(undefined)
  }

  const value = useMemo(
    () => ({
      openDialog,
      closeDialog,
    }),
    [openDialog, closeDialog]
  )

  return (
    <DialogContext.Provider value={value}>
      {props.children}
      {dialogContent && (
        <SimpleDialog open={Boolean(dialogContent)} {...dialogContent} />
      )}
    </DialogContext.Provider>
  )
}

export const useDialog = (): DialogInterface => {
  return useContext(DialogContext) as DialogInterface
}
