import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'

interface WelcomeSlice {
  isLoading: boolean
  welcomeVerbiage: string
  error: string
}
const initialStore: WelcomeSlice = {
  isLoading: false,
  welcomeVerbiage: '',
  error: '',
}

export const welcomeSlice = createSlice({
  name: 'welcome',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setWelcomeMessage(store, { payload }: { payload: any }) {
      store.welcomeVerbiage = payload
    },
    setError(store, { payload }: { payload: any }) {
      store.error = payload
    },
  },
})
export const { setLoading, setWelcomeMessage, setError } = welcomeSlice.actions

export const getVerbiage = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true))
  const WelcomeKey = 'WelcomeMessage'

  const data = await api.getVerbiage()

  if (data) {
    const WelcomeInfo = data.filter((d: any) => d.key === WelcomeKey)
    if (WelcomeInfo.length > 0) {
      dispatch(setWelcomeMessage(WelcomeInfo[0]?.value))
    } else {
      //Use this if no VirtualConsultCitation set in Admin Verbiage
      dispatch(setWelcomeMessage(fallbackWelcomeMessage))
    }
  }

  dispatch(setLoading(false))
  //   else if (error) {
  //     dispatch(setError("Sorry, we are unable locate a tenant for this user"))
  //     dispatch(setLoading(false))
  //   }
}

export const welcomeReducer = welcomeSlice.reducer
export const welcomeReducerName = welcomeSlice.name
interface RootStore {
  [welcomeReducerName]: typeof initialStore
}

export const selectInfoLoading = ({ welcome }: RootStore) => welcome.isLoading

export const selectWelcomeVerbiage = ({ welcome }: RootStore) =>
  welcome.welcomeVerbiage

const fallbackWelcomeMessage = `<p>You can now use connected tests and electronically verify your vaccination status without compromising your privacy</p>`
