import { createSlice, Dispatch } from '@reduxjs/toolkit'
import api from '../../../api'
import { fetchTerms } from '../../../features/terms'
import { Term } from './type'

interface TermsSlice {
  isLoading: boolean
  pendingConsents: Term[]
  consentsFetched: boolean
  consentsAgreed: boolean
  consentError: string
}

const initialStore: TermsSlice = {
  isLoading: false,
  pendingConsents: [],
  consentsFetched: false,
  consentsAgreed: false,
  consentError: '',
}

const termsAndCondsPage = createSlice({
  name: 'termsAndCondsPage',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setConsentsFetched(store, { payload }: { payload: boolean }) {
      store.consentsFetched = payload
    },
    setConsents(store, { payload }: { payload: Term[] }) {
      store.pendingConsents = payload
    },
    setConsentError(store, { payload }: { payload: string }) {
      store.consentError = payload
    },
    setConsentsAgreed(store, { payload }: { payload: boolean }) {
      store.consentsAgreed = payload
    },
  },
})

export const { setConsentError, setConsentsAgreed } = termsAndCondsPage.actions

const { setLoading, setConsentsFetched, setConsents } =
  termsAndCondsPage.actions

export const pageMounted =
  (userEmail: string) => async (dispatch: Dispatch<any>) => {
    dispatch(setLoading(true))
    await dispatch(fetchTerms(userEmail))
    dispatch(setConsentsFetched(true))
    dispatch(setLoading(false))
  }

export const getConsents = (tenantId: string) => async (dispatch: Dispatch) => {
  dispatch(setConsentError(''))
  dispatch(setLoading(true))

  const lang = 'en'

  const { data, error } = await api.getTenantTerms(lang, tenantId)

  if (data) {
    dispatch(setLoading(false))
    dispatch(setConsentsFetched(true))
    dispatch(setConsents(data))
    return true
  }
  if (error) {
    dispatch(
      setConsentError(
        error?.errorMessage ||
          'A server error occurred. Unable to get Terms And Conditions at this time'
      )
    )
    dispatch(setLoading(false))
    dispatch(setConsentsFetched(true))
    return false
  }
  dispatch(setLoading(false))
}

export const termsAndCondsPageReducer = termsAndCondsPage.reducer
export const termsAndCondsPageReducerName = termsAndCondsPage.name

interface RootStore {
  [termsAndCondsPageReducerName]: typeof initialStore
}
export const selectLoading = ({ termsAndCondsPage }: RootStore) =>
  termsAndCondsPage.isLoading

export const selectConsentsFetched = ({ termsAndCondsPage }: RootStore) =>
  termsAndCondsPage.consentsFetched

export const selectConsents = ({ termsAndCondsPage }: RootStore) =>
  termsAndCondsPage.pendingConsents

export const selectConsentError = ({ termsAndCondsPage }: RootStore) =>
  termsAndCondsPage.consentError

export const selectConsentsAgreed = ({ termsAndCondsPage }: RootStore) =>
  termsAndCondsPage.consentsAgreed
