import { FormikProps } from 'formik'
import { RouteProps } from 'react-router'
import { StepType } from '../../../constants'
import { Address } from '../../../types/user'

export interface FormValues {
  [StepType.ELIGIBILITY]: EligibilityType
  [StepType.SIGN_UP]: SignUpType
  [StepType.CREATE_PASSWORD]: CreatePasswordType
  [StepType.ENTER_PHONE_NUMBER]: Contact
  [StepType.SHIPPING_ADDRESS]: ShippingInformation
  [StepType.ACC_INFO]: AccountInformation
  [StepType.INSURANCE]: InsuranceInformation
  [StepType.POLICY_INFO]: PolicyInformation
}

export interface SignUpType {
  firstName: string
  hasPrefillFN?: boolean
  lastName: string
  hasPrefillLN?: boolean
  email: string
  dependentId?: string
}

export interface EligibilityType {
  lastName: string
  dateOfBirth: ((string | number | object | Date | undefined) & Date) | null
  lastFourSSN: string
  try: EligibilityTry
}

export type EligibilityTry = 0 | 1 | 2 | 3 | 4 | 5 | 6

export interface EligibilityPrefillData {
  address: Address
  dateOfBirth: ((string | number | object | Date | undefined) & Date) | null
  externalId: string
  firstName: string
  gender: GenderResponse
  lastName: string
  phone: string
  status: number
  tenantId: string
}
export type GenderResponse = 'M' | 'F' | 'MALE' | 'FEMALE' | null

export enum EligibilityGender {
  M = 'MALE',
  F = 'FEMALE',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum OnboardingType {
  INVITE = 'INVITE',
  DEPENDENT = 'DEPENDENT',
  SELF = 'SELF',
  UNSET = 'UNSET',
}

export interface CreatePasswordType {
  password: string
  confirmPassword: string
}

export interface Contact {
  mobileNumber: string
  countryCode: string
}

export interface AccountInformation {
  hasPrefillDOB?: boolean
  dob: ((string | number | object | Date | undefined) & Date) | null
  sexAtBirth: string
  race: string
  ethnicity: string
}

export interface ShippingInformation {
  address: Address
}

export interface InsuranceInformation {
  insuranceProviderCompany: InsuranceProviderCompanyData | null
  insuranceId: string
  groupNumber: string
  planName: string
  coverageDate: Date | null
  notPolicyHolder: boolean
}

export interface PolicyInformation {
  firstName: string
  lastName: string
  phone: string
  dob: Date | null
  relationship: string
  address: Address
}

export interface USPSAddressModel {
  address1: string | undefined
  address2: string
  city: string
  deliveryPoint: null
  isAddressExactMatch: boolean
  isAddressFound: boolean
  isBusinessAddress: boolean
  isPo: boolean
  state: string
  zip: string
}

export type RegisterStepProps = RouteProps & {
  handleNext: () => void
  handleBack?: () => void
  mode: string
  formik: FormikProps<FormValues>
  type: StepType
  accountId: string
  tenantId: string
  isInvite: boolean
}

export interface InsuranceProviderCompanyData {
  insuranceProvider: string
  payorId: string
  name: string
  status: boolean
  address: {
    address1: string | null
    address2: string | null
    city: string | null
    state: string | null
    zip: string | null
    country: string | null
  }
  phone: string | null
  states: string | null
  tradingPartnerId: string | null
  _id: string
  created: string
  creator: string
  modified: string
  modifier: string
}

export interface InsuranceProviderData {
  providerType: number
  providerUserType: number
  isSafeProvider: boolean
  doesProviderWantsToBeSAFEPlatformProvider: boolean
  payTierType: number
  passwordType: number
  safeApplicationStatus: number
  providerPresence: number
  incompleteEncounters: number
  firstName: string
  lastName: string
  title: string
  sex: string
  ssn: number
  shortSSN: number
  contactInformationOfficePhone: string
  contactInformationFax: string
  homeAddressAddressOne: string
  homeAddressAddressTwo: string
  homeAddressAddressCity: string
  homeAddressState: string
  homeAddressZip: string
  homeAddressCountry: string
  npi: string
  professionalIdentificationsCAQHApplicationType: number
  professionalIdentificationsCAQHOrganizationID: number
  professionalIdentificationsCAQHRegionID: number
  isActive: boolean
  latitude: number
  longitude: number
  schedulingOnline: boolean
  organizationSubType: number
  created: string
  modified: string
}

export interface UserCreate {
  email: string
  isEmailVerified: boolean
  password: string
  firstName: string
  lastName: string
  tenantId: string
  accountId: string
  permission: number
  is3rdParty: boolean
  isDependent: boolean
  externalId?: string
  dateOfBirth?: ((string | number | object | Date | undefined) & Date) | null
  lastFourSSN?: string
}
