import { Box, Link, Theme, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useSelector } from 'react-redux'
import { HTMLTextComponent, PageTitle } from '../../../libs/shared-components'
import { Timer } from '../../../ui/molecules/timer/timer'
import { testInstructionStyles } from '../../_styles/testInstructionStyle'
import { selectNetworkSettings } from '../../network/model'
import { TimerProps } from '../types'

interface TimerOnlyProps extends TimerProps {
  remainingTime: number
}

export const TimerOnly = (props: TimerOnlyProps) => {
  const {
    current,
    timerActive,
    timerValue,
    remainingTime,
    timerContinue,
    openHelpDialog,
  } = props
  const classes = testInstructionStyles()

  const settings = useSelector(selectNetworkSettings)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))
  const timerStartColor = settings?.primaryColor || '#004777'
  const children = ({ remainingTime }: any) => {
    const minutes = Math.floor(remainingTime / 60)
    const seconds = remainingTime % 60
    let spacer = ''
    if (seconds < 10) {
      spacer = '0'
    }
    return (
      <>
        <Typography
          className={classes.circularTime}
          style={{ color: timerStartColor }}
        >
          {`${minutes}:${spacer}${seconds}`}
        </Typography>
      </>
    )
  }

  if (!(current && timerActive && timerValue && remainingTime)) return null

  return (
    <Box>
      <Box
        className={`${classes.instructionContainer} reduced`}
        id="instructionContainer"
      >
        <Box className={classes.assetBox} id="instructions">
          <PageTitle style={{ marginBottom: 8 }}>{current?.title}</PageTitle>
          <Typography className={classes.body}>
            <HTMLTextComponent HTMLString={current?.body} />
          </Typography>
        </Box>
        {current?.imageURL && (
          <img
            className={classes.img}
            src={current?.imageURL}
            alt="test-instructions"
          />
        )}
        {current?.videoURL && (
          <Box className={classes.videoContainer}>
            <video src={current.videoURL} controls className={classes.video} />
          </Box>
        )}
        <Box className={classes.timerBox}>
          {/* <Timer
            isPlaying={true}
            color={settings?.primaryColor || '#004777'}
            // timeLimit={timerValue}
           /> */}
          <CountdownCircleTimer
            isPlaying={timerActive}
            strokeWidth={14}
            trailStrokeWidth={14}
            size={225}
            strokeLinecap="round"
            rotation="counterclockwise"
            onComplete={() => {
              timerContinue(current?.autoStartTimer)
              return [false, 0]
            }}
            duration={timerValue}
            initialRemainingTime={remainingTime}
            colors={[[`${timerStartColor}`, 1]]}
          >
            {children}
          </CountdownCircleTimer>
        </Box>
        {current.additionalInformationBody && (
          <div className={classes.helpLinkWrapper}>
            <Link
              underline="always"
              role="button"
              color="primary"
              onClick={() => openHelpDialog()}
              style={{ cursor: 'pointer' }}
            >
              {current.additionalInformationLabel}
            </Link>
          </div>
        )}
      </Box>
    </Box>
  )
}
