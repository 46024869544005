import { createSlice } from '@reduxjs/toolkit'
import api from '../../api'
import { handleError } from '../../libs/helpers'
import { InsuranceProviderCompanyData } from '../register/model/type'

interface InsuranceForOrderSlice {
  isLoading: boolean
  insuranceData: any
  insuranceProvidersCompaniesList: InsuranceProviderCompanyData[]
  insuranceProvidersCompaniesLoading: boolean
  insuranceProvidersList: []
  insuranceProvidersLoading: boolean
  selectedProvider: any
  notPolicyHolderFlag: boolean
  policyholderInfo: any
  verifyingLoader: boolean
  isInsuranceVirified: any
}

const initialStore: InsuranceForOrderSlice = {
  isLoading: false,
  insuranceData: null,
  insuranceProvidersCompaniesList: [],
  insuranceProvidersCompaniesLoading: false,
  insuranceProvidersList: [],
  insuranceProvidersLoading: false,
  selectedProvider: null,
  notPolicyHolderFlag: false,
  policyholderInfo: null,
  verifyingLoader: false,
  isInsuranceVirified: null,
}

export const insuranceSlice = createSlice({
  name: 'insurance',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setInsuranceData(store, { payload }: { payload: any }) {
      store.insuranceData = payload
    },
    setInsuranceProviderCompaniesList(store, { payload }: { payload: any }) {
      store.insuranceProvidersCompaniesList = payload
    },
    setInsuranceProviderCompaniesLoading(
      store,
      { payload }: { payload: boolean }
    ) {
      store.insuranceProvidersCompaniesLoading = payload
    },
    setInsuranceProviderList(store, { payload }: { payload: any }) {
      store.insuranceProvidersList = payload
    },
    resetInsuranceProviderList(store) {
      store.insuranceProvidersList = []
    },
    setInsuranceProvidersLoading(store, { payload }: { payload: boolean }) {
      store.insuranceProvidersLoading = payload
    },
    setSelectedProvider(store, { payload }: { payload: any }) {
      store.selectedProvider = payload
    },
    setPolicyHolderFlag(store, { payload }: { payload: boolean }) {
      store.notPolicyHolderFlag = payload
    },
    setPolicyholderInfo(store, { payload }: { payload: any }) {
      store.policyholderInfo = payload
      store.notPolicyHolderFlag = true
    },
    setVerifyingLoader(store, { payload }: { payload: any }) {
      store.verifyingLoader = payload
    },
    setIsInsuranceVerified(store, { payload }: { payload: any }) {
      store.isInsuranceVirified = payload
    },
  },
})

const {
  setInsuranceProvidersLoading,
  setInsuranceProviderList,
  setInsuranceProviderCompaniesLoading,
} = insuranceSlice.actions

export const {
  setLoading,
  setInsuranceData,
  setInsuranceProviderCompaniesList,
  setSelectedProvider,
  setPolicyHolderFlag,
  setPolicyholderInfo,
  setVerifyingLoader,
  setIsInsuranceVerified,
  resetInsuranceProviderList,
} = insuranceSlice.actions

export const getInsuranceInfoForOrder = () => async (dispatch: any) => {
  dispatch(setLoading(true))

  const res = await api.getInsuranceInfoForTenant()

  if (res?.success) {
    dispatch(setInsuranceData(res.data))
  }
  // dispatch(setInsuranceData({
  //   insuranceId: 'TGX910060463',
  //   coverageDate: '2020-06-08T11:55:30.628Z',
  //   notPolicyHolder: false,
  //   groupNumber: 'W0051486',
  //   planType: 'PPO',
  // }))
  dispatch(setLoading(false))
}

export const getInsuranceProviderCompanies = () => async (dispatch: any) => {
  dispatch(setInsuranceProviderCompaniesLoading(true))
  const { data, success } = await api.getInsuranceProviderRequest()
  dispatch(setInsuranceProviderCompaniesLoading(false))

  if (success) {
    dispatch(setInsuranceProviderCompaniesList(data))
  }
}

export const getInsuranceProviders =
  (q = '') =>
  async (dispatch: any) => {
    dispatch(setInsuranceProvidersLoading(true))
    const { data, isOK } = await api.getInsuranceProvidersRequest(q)
    dispatch(setInsuranceProvidersLoading(false))
    if (isOK) {
      dispatch(setInsuranceProviderList(data))
    }
  }

export const updateInsuranceData =
  (insuranceData: any) => async (dispatch: any) => {
    dispatch(setVerifyingLoader(true))
    dispatch(setIsInsuranceVerified(null))

    // // druft data
    // const c = {
    //   planType: 'PPO',
    //   payorId: '11173',
    //   notPolicyHolder: false,
    //   nationalHealthId: '0096000372988',
    //   insuranceId: 'TGX910060463',
    //   group: 'W0051486',
    // }
    // const b = {
    //   nationalHealthId: '0096000372988',
    //   planType: 'PPO',
    //   notPolicyHolder: true,
    //   dependantPolicyHolderDetail: {
    //     phone: '5862295898',
    //     city: 'City',
    //     firstName: 'Firstname',
    //     address1: '123',
    //     lastName: 'Lastname',
    //     birthDate: '2009-09-27T00:00:00',
    //     relationship: 'es_Family Member',
    //     zipCode: '12345',
    //     state: 'AL',
    //   },
    //   payorId: '10896',
    //   insuranceId: 'Skjhskjhadskj',
    //   group: '7326873426',
    // }

    const { success, data, error } = await api.updateInsuranceDataRequest(
      insuranceData
    )

    if (error) {
      dispatch(handleError({ message: error }))
    }

    if (success) {
      dispatch(setInsuranceData(data))
      dispatch(setIsInsuranceVerified(true))
      return true
    }

    dispatch(setIsInsuranceVerified(false))
    dispatch(setVerifyingLoader(false))
    return false
  }

export const insuranceReducer = insuranceSlice.reducer

export const insuranceReducerName = insuranceSlice.name

interface RootStore {
  [insuranceReducerName]: typeof initialStore
}

export const selectIsLoading = ({ insurance }: RootStore) => insurance.isLoading

export const selectInsurance = ({ insurance }: RootStore) =>
  insurance.insuranceData

export const selectInsuranceProviders = ({ insurance }: RootStore) =>
  insurance.insuranceProvidersList

export const selectInsuranceProvidersCompanies = ({ insurance }: RootStore) =>
  insurance.insuranceProvidersCompaniesList

export const selectInsuranceProvidersCompaniesLoading = ({
  insurance,
}: RootStore) => insurance.insuranceProvidersCompaniesLoading

export const selectInsuranceProvidersLoading = ({ insurance }: RootStore) =>
  insurance.insuranceProvidersLoading

export const selectSelectedProvider = ({ insurance }: RootStore) =>
  insurance.selectedProvider

export const selectPayorId = ({ insurance }: RootStore) =>
  insurance.selectedProvider?.payorId

export const selectNotPolicyholder = ({ insurance }: RootStore) =>
  insurance.notPolicyHolderFlag

export const selectPolicyHolderInfo = ({ insurance }: RootStore) =>
  insurance.policyholderInfo

export const selectVerifyingLoader = ({ insurance }: RootStore) =>
  insurance.verifyingLoader

export const selectIsInsuranceVerified = ({ insurance }: RootStore) =>
  insurance.isInsuranceVirified
