import React, { useState } from 'react'
import { shopifyStoreStyles } from '../../../../pages/_styles/shopifyStoreStyles'
import { TextField, Typography } from '../../../../ui'
import { I18n } from '../../../translation/types'

export type TipMethodOptions = 'tip' | 'instructions'

export const TipAndInstructions = (props: {
  i18n: I18n
  currentSelectedMethod: any
  tipOptions: any[]
  onChangeTipOptions: (method: any, type: TipMethodOptions) => void
  instructions: string | null
  isLoading: boolean
}) => {
  const {
    i18n,
    currentSelectedMethod,
    tipOptions,
    onChangeTipOptions,
    instructions,
    isLoading,
  } = props
  const classes = shopifyStoreStyles()

  const [deliveryInst, setDeliveryInst] = useState<string | null>(instructions)

  const handleChangeInstructions = (
    e: React.ChangeEvent<{ value: string }>
  ) => {
    setDeliveryInst(e.target.value)
  }

  const submitInstructions = () => {
    if (!deliveryInst) return
    onChangeTipOptions(deliveryInst, 'instructions')
  }

  return (
    <div className={classes.dataCardWrapper}>
      <div className={classes.dataContentWrapper}>
        <div className={classes.tipContentWrapper}>
          <Typography className={classes.checkoutMethodTitle}>
            {i18n['ecommerce.tip_header']}
          </Typography>
          <div className={classes.tipWrapper}>
            {tipOptions.length > 0 &&
              tipOptions.map((option) => (
                <div
                  onClick={() => onChangeTipOptions(option, 'tip')}
                  className={
                    option.title === currentSelectedMethod.title
                      ? `${classes.tipOptionWrapper} selected`
                      : classes.tipOptionWrapper
                  }
                >
                  <Typography
                    align="center"
                    className={
                      option.title === currentSelectedMethod.title
                        ? `${classes.tipOption} selected`
                        : classes.tipOption
                    }
                  >
                    {option.title}
                  </Typography>
                </div>
              ))}
          </div>
          <Typography className={classes.cartTestDesc}>
            {i18n['ecommerce.tip.message']}
          </Typography>
        </div>
        <TextField
          variant="outlined"
          type="textarea"
          value={deliveryInst}
          onChange={handleChangeInstructions}
          placeholder={
            i18n['ecommerce.checkout_view.delivery_instructions_placeholder']
          }
          onBlur={submitInstructions}
          isMultiline={true}
          rows={4}
        />
      </div>
    </div>
  )
}
