import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormikValues, useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import {
  Button,
  ContentLayout,
  CreatePasswordField,
  FilledTextField,
  MaskedInput,
  PasswordValidation,
  Typography,
} from '../../ui'
import { Wrapper } from '../../ui/templates/app-layout'
import { paths } from '../paths'
import {
  selectPasswordResetError,
  selectPasswordResetLoading,
  setError,
  submitPasswordChange,
} from './passwordResetSlice'
import validationSchema from './validationSchema'

export const ResetConfirmationPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const error = useSelector(selectPasswordResetError)
  const navigate = useNavigate()
  const isLoading = useSelector(selectPasswordResetLoading)
  const { email } = useParams<{ email: string }>()

  useEffect(() => {
    //Reset any errors and error messages on page load
    dispatch(setError(''))
  }, [])

  const onSubmit = async (values: FormikValues) => {
    const { verificationCode, password } = values
    const success = () => {
      navigate(paths.resetSuccessful())
    }

    dispatch(
      submitPasswordChange(email || '', verificationCode, password, success)
    )

    formik.setSubmitting(false)
  }
  const formik = useFormik({
    initialValues: {
      password: '',
      verificationCode: '',
    },
    validationSchema: validationSchema(i18n),
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit,
  })

  const handleChange = (name: string, value: string) => {
    formik.setFieldValue(name, value)
  }

  const submitDisabled = !formik.isValid || isLoading

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <ContentLayout className={classes.form}>
          <Typography className={classes.title} color="primary" variant="h1">
            {i18n.forgot_password_screen_title}
          </Typography>
          <Typography className={classes.instructions} align="center">
            {i18n.forgot_password_email_message}
          </Typography>
          <Email i18n={i18n} email={email || ''} />
          <VerificationCode i18n={i18n} formik={formik} />
          <CreatePasswordField
            label={i18n.login_password}
            name={'password'}
            value={formik.values.password}
            handleChange={handleChange}
            onBlur={formik.handleBlur}
            i18n={i18n}
            error={(formik.touched?.password && formik.errors?.password) || ''}
          />
          <PasswordValidation i18n={i18n} value={formik.values.password} />
          <div className={classes.buttonWrapper}>
            <Button
              color="primary"
              className={classes.actionBtn}
              type="submit"
              disabled={submitDisabled}
              // onClick={() => handleSubmit()}
            >
              {i18n.change_password_title}
            </Button>
          </div>
          {error && (
            <Box className={classes.errorMessage}>
              <Typography align="center" className={classes.errorMessage}>
                {error}
              </Typography>
            </Box>
          )}
        </ContentLayout>
      </form>
    </Wrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: '#940000',
  },
  instructions: {
    margin: '25px 10px',
    color: '#54565B',
    fontSize: 16,
    fontFamily: 'Avenir Next Rounded regular',
    lineHeight: '22px',
  },
  fieldContainer: {
    border: '1px solid #79747E',
    borderRadius: '4px',
    padding: '1px',
  },
  buttonWrapper: {
    width: '100%',
  },
  title: {
    fontSize: 36,
    color: '#505358',
    textAlign: 'center',
    marginTop: '12px',
    marginBottom: 8,
    [theme.breakpoints.down('xl')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 24,
    },
  },
  form: {
    'width': '100%',
    'boxSizing': 'border-box',
    'padding': '0 45px',
    'display': 'flex',
    'flexDirection': 'column',
    'flexGrow': 1,
    '@media (max-width: 500px)': {
      padding: '0 20px',
    },
  },
  actionBtn: {
    '&:hover': {
      borderColor: '#003C72',
      color: '#003C72',
    },
    'width': '100%',
    'justifySelf': 'center',
    'maxHeight': 48,
    'paddingTop': 10,
    'paddingBottom': 10,
    'marginTop': 10,
    'marginBottom': 0,
  },
}))

const VerificationCode = ({ i18n, formik }: { i18n: I18n; formik: any }) => {
  return (
    <MaskedInput
      mask="999999"
      maskChar=""
      value={formik.values.verificationCode}
      onChange={formik.handleChange}
      label={i18n.verification_code}
      name={'verificationCode'}
      variant="filled"
      onBlur={formik.handleBlur}
      error={
        formik.touched.verificationCode &&
        Boolean(formik.errors.verificationCode)
      }
      helperText={
        formik.touched.verificationCode && formik.errors.verificationCode
          ? formik.errors.verificationCode
          : ''
      }
      required
    />
  )
}

const Email = ({ i18n, email }: { i18n: I18n; email: string }) => {
  return (
    <FilledTextField
      label={i18n.email}
      value={email}
      required
      autoComplete="off"
      type="text"
      name="email"
      readOnly
    />
  )
}
