import { Box } from '@mui/material'
import React from 'react'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Typography } from '../../../../ui'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { CarouselItem } from '../../../network/type'
import { CardProps } from './types'

export const CarouselCard = (props: CardProps) => {
  const { button, buttonAction, startWorkflows, radius } = props
  const classes = dashboardCardStyles()

  const handleClick = (item: CarouselItem | undefined) => {
    if (!item) return

    if (item.itemButtonAction.value === 'Workflow' && item.workflowIds) {
      startWorkflows(item.workflowIds)
    } else if (item.itemButtonAction.value === 'RedirectToUrl') {
      if (!item.itemRedirectUrl) return
      buttonAction(item.itemButtonAction.value, item.itemRedirectUrl)
    } else if (item.itemButtonAction.value === 'Resources') {
      if (!item.link) {
        buttonAction(item.itemButtonAction.value)
      } else {
        buttonAction(
          item.itemButtonAction.value,
          item.link.itemResourceId,
          item.link.type
        )
      }
    } else if (item.itemButtonAction.value) {
      buttonAction(item.itemButtonAction.value)
    }
  }

  return (
    <Box
      className={classes.largeCardWrapper}
      sx={{
        borderRadius: radius,
      }}
    >
      <div className={classes.largeCard}>
        <Typography className={classes.topTitle}>
          <HTMLTextComponent HTMLString={button.cardTitle} />
        </Typography>
        {button.carouselItems?.[0] && button.style === 6 && (
          <>
            <Box
              className={classes.carouselImageContainer}
              style={{
                cursor: 'pointer',
                backgroundImage: `url(${button.carouselItems[0].itemImageUrl})`,
              }}
              onClick={
                button.carouselItems && button.carouselItems.length > 0
                  ? () => handleClick(button.carouselItems?.[0])
                  : undefined
              }
            />
            <Typography
              className={classes.dashboardButtonBody}
              style={{ marginTop: 16, fontWeight: 600 }}
            >
              <HTMLTextComponent
                HTMLString={button.carouselItems[0].itemTitle}
              />
            </Typography>
          </>
        )}
        <Box className={classes.secondaryItems}>
          {button.carouselItems &&
            button.carouselItems.length > 0 &&
            button.carouselItems.map((item, i) => (
              <React.Fragment key={`${item.itemButtonAction?._id}-${i}`}>
                {i === 0 && button.style === 6 ? null : (
                  <Box className={classes.itemContainer}>
                    <Box
                      className={classes.smallImageContainer}
                      style={{
                        backgroundImage: `url(${item.itemImageUrl})`,
                      }}
                      onClick={() => handleClick(item)}
                    />
                    <Typography className={classes.secondaryText}>
                      <HTMLTextComponent HTMLString={item.itemTitle} />
                    </Typography>
                  </Box>
                )}
              </React.Fragment>
            ))}
        </Box>
      </div>
    </Box>
  )
}
