import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '../../libs/useAuth0'
import api from '../../api'
import { resetVerifError } from '../register/model/registerSlice'
import { selectIsUserVerified, selectProfileData } from '../profile/model'
import { paths } from '../paths'
import { selectNetworkSettings } from '../network/model'
import { getCurrentUser } from '../../libs/utils'
import { VerificationCode } from './components/VerificationCode/VerifiicationCode'

export const ConfirmEmailPage = () => {
  const classes = useStyles()
  const email = getCurrentUser()
  const isUserVerified = useSelector(selectIsUserVerified)
  const userDetails = useSelector(selectProfileData)
  const networkSettings = useSelector(selectNetworkSettings)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useAuth0({ closedForLoggedIn: false, closedForAnonymous: true })

  useEffect(() => {
    if (!userDetails.tenantId || !networkSettings) return

    if (!networkSettings.verifyEmail || isUserVerified)
      return navigate(paths.app.dashboard())

  }, [isUserVerified, userDetails.tenantId, networkSettings])

  const sendVerifCode = async () => {
    await api.sendEmailVerificationCode(email)
    dispatch(resetVerifError())
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Typography className={classes.title}>
          Check Your Email For A Code
        </Typography>
        <Typography className={classes.description} align="center">
          We’ve sent a code to <b>{email}</b>. The code expires shortly, so
          please enter it as soon as possible.
        </Typography>
        <Typography
          className={classes.description}
          style={{ fontStyle: 'italic', marginTop: 20 }}
          align="center"
        >
          NOTE: If you can’t find your code, check your spam folder.
        </Typography>
        <VerificationCode />
        <Typography className={classes.reSendCode} onClick={sendVerifCode}>
          Re-send Code
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '0 40px 30px',
    alignItems: 'center',
    boxSizing: 'border-box',

    maxWidth: 620,
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100vw',
    },
  },
  title: {
    fontSize: 36,
    fontWeight: 600,
    textAlign: 'center',
    [theme.breakpoints.down('xl')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 24,
    },
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
    align: 'center',
    lineHeight: '22px',
    marginTop: 8,
  },
  reSendCode: {
    'marginTop': 32,
    'color': '#7E7E7E',
    'fontWeight': 600,
    'cursor': 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))
