import React from 'react'
import { useParams } from 'react-router-dom'
import { AccountSelectionOptions } from '../../../../../../features/user/types/mfa'
import { AccountSettingsType } from '../../../../type'
import { MobileNumber } from './enter-number'
import { PhoneVerify } from './verify'

export const MobileNumberView = () => {
  const {selection } = useParams<{
    type?: AccountSettingsType
    selection: AccountSelectionOptions
  }>()

  return <>{selection ? <PhoneVerify /> : <MobileNumber />}</>
}
