import * as Yup from 'yup'
import { I18n } from '../../../../features/translation/types'

export const passwordTabValidation = (i18n: I18n) =>
  Yup.object().shape({
    currentPassword: Yup.string().required(i18n.required),
    newPassword: Yup.string()
      .matches(
        new RegExp(
          '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})'
        )
      )
      .required(i18n.required),
    reNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match!')
      .required(i18n.required),
  })

export const phoneValidation = (i18n: I18n) => {
  const phone = Yup.string()
    .min(10, i18n.invalid_phone_error_message)
    .max(10, i18n.invalid_phone_error_message)
    .matches(/^(?!0{10})/, i18n.value_not_allowed)
    .matches(/^(?!0+[1-9])/, i18n.value_not_allowed)
    .required(i18n.required)

  return Yup.object().shape({
    phone,
  })
}
