import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATION_TYPES } from '../../constants'
import { useFlowControl } from '../../features/flowcontrol/use-flow-control'
import { setCartCount } from '../../features/shopify/model/shopifyStoreSlice'
import { StoreStepType } from '../../features/shopify/model/types'
import { PollForCheckoutParameters } from '../../features/shopify/SAFE-ecommerce-service/EcommerceSDK'
import { selectLanguageContent } from '../../features/translation'
import { handleMessage } from '../../libs/helpers'
import { Loading } from '../../ui'
import { paths } from '../paths'

export const OrderProcessingPage = (props: { sdk: any }) => {
  const { sdk } = props
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const [isComplete, setIsComplete] = useState(false)
  const { state, actions } = useFlowControl()

  useEffect(() => {
    const parameters = new PollForCheckoutParameters(5, 1000, true)
    sdk.checkoutManager
      .pollForCheckout(parameters)
      .then((response: any) => {
        console.log(response)
        if (response.isComplete) {
          setIsComplete(true)
        }
      })
      .catch((error: any) => {
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        navigate(paths.shopifyStore(StoreStepType.CHECKOUT))
        console.log('ERROR', error)
      })
  }, [])

  useEffect(() => {
    if (isComplete) {
      dispatch(setCartCount(0))
      if (state.isFlowExecuting) {
        actions.next({ out: 'store complete' || '' })
      } else {
        navigate(paths.orderComplete())
      }
    }
  }, [isComplete])

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.spinner}>
          <Loading size={100} />
        </Box>
        <Typography variant="h6" className={classes.processText}>
          {i18n['ecommerce.processing_title']}
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          {i18n['ecommerce.processing_subtitle']}
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '500px',
  },
  btnWrapper: {
    display: 'flex',
    backgroundColor: '#FFF',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '32px 0px',
    position: 'relative',
    width: '100%',
    zIndex: 10,
    [theme.breakpoints.down(600)]: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      padding: '12px 0px 24px',
      width: '100%',
    },
  },
  btnStyle: {
    margin: '10px auto',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 124,
    justifyContent: 'center',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    textAlign: 'center',
    width: 200,
    marginBottom: '-50px',
  },
  processText: {
    fontSize: 32,
    fontWeight: 500,
    color: '#505358',
    marginTop: 32,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
    color: '#757575',
  },
}))
