import styled from '@emotion/styled'
import React from 'react'

export const HeaderTransparencyMask = (props: {
  position: 'top' | 'bottom'
}) => {
  const { position } = props
  return (
    <HeaderMask
      style={{
        top: position === 'top' ? 0 : undefined,
        bottom: position === 'bottom' ? 0 : undefined,
      }}
    />
  )
}

const HeaderMask = styled.div`
  height: 64px;
  // top: 0;
  left: 0;
  background-color: #fff;
  z-index: 0;
  width: 100vw;
  position: fixed;
`
