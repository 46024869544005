import { Box, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StepType, USAStateList } from '../../../../constants'
import { selectLanguageContent } from '../../../../features/translation'
import { Address } from '../../../../types/user'
import {
  Button,
  FilledSelect,
  FilledTextField,
  MaskedInput,
} from '../../../../ui'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { selectNetworkSettings } from '../../../network/model'
import { NetworkSettings } from '../../../network/type'
import { getName, scrollTop } from '../../helpers'
import { saveAddress, verifyAddress } from '../../model/registerSlice'
import { RegisterStepProps } from '../../model/type'

export const ShippingAddress = (props: RegisterStepProps) => {
  const { handleNext, formik, isInvite } = props
  const classes = onboardingStyles()
  const type = StepType.SHIPPING_ADDRESS
  const i18n = useSelector(selectLanguageContent)
  const currentNetwork = useSelector(selectNetworkSettings)
  const dispatch = useDispatch()

  const values = formik.values[type]
  const errors = formik.errors[type]
  const touched = formik.touched[type]

  useEffect(() => {
    document.addEventListener('focusout', scrollTop)
    return () => {
      document.removeEventListener('focusout', scrollTop)
    }
  }, [])

  const onVerify = (address: Address) => {
    if (_.isEqual(address, values.address)) {
      formik.submitForm()
    } else handleNext()
  }

  const goNext = () => {
    dispatch(saveAddress(values))
    const data = {
      address2: values.address.line1,
      address1: values.address.line2 || '',
      city: values.address.city,
      state: values.address.state,
      zip: values.address.zip,
    }
    dispatch(verifyAddress(data, onVerify))
  }
  const {
    collectAddress,
    collectStreetAddress,
    collectStreetAddressRequired,
    collectCity,
    collectCityRequired,
    collectZip,
    collectZipRequired,
    collectState,
    collectStateRequired,
  } = (currentNetwork as NetworkSettings) || {}

  const renderStreetAddress = () => (
    <>
      {collectAddress && collectStreetAddress && (
        <>
          <FilledTextField
            label={i18n.street_address}
            autoComplete="address-line1"
            name={getName('address.line1', type)}
            type={'text'}
            value={values.address.line1}
            error={touched?.address?.line1 && Boolean(errors?.address?.line1)}
            helperText={touched?.address?.line1 ? errors?.address?.line1 : ''}
            variant="filled"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required={collectStreetAddressRequired}
          />
          <Box className={classes.twoFieldsAlways}>
            <FilledTextField
              label={i18n.patient_records_apt_or_suit}
              autoComplete="address-line2"
              name={getName('address.line2', type)}
              type={'text'}
              value={values.address.line2}
              variant="filled"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {collectCity && (
              <FilledTextField
                label={i18n.city}
                autoComplete="address-level2"
                name={getName('address.city', type)}
                type={'text'}
                value={values.address.city}
                error={touched?.address?.city && Boolean(errors?.address?.city)}
                helperText={touched?.address?.city ? errors?.address?.city : ''}
                variant="filled"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required={collectCityRequired}
              />
            )}
          </Box>
        </>
      )}
    </>
  )

  const renderZip = () =>
    collectZip && (
      <MaskedInput
        mask="99999"
        maskChar=""
        autoComplete="postal-code"
        variant="filled"
        label={i18n.zip_code}
        value={values.address.zip}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={touched?.address?.zip && Boolean(errors?.address?.zip)}
        helperText={touched?.address?.zip ? errors?.address?.zip : ''}
        name={getName('address.zip', type)}
        inputProps={{
          inputMode: 'numeric',
        }}
        required={collectZipRequired}
      />
    )

  const renderState = () =>
    collectState && (
      <FilledSelect
        label={i18n.state}
        options={USAStateList}
        autoComplete="address-level1"
        value={values.address.state}
        fullWidth
        native={true}
        variant="filled"
        name={getName('address.state', type)}
        onChange={formik.handleChange}
        onFocus={scrollTop}
        onBlur={formik.handleBlur}
        error={touched?.address?.state && Boolean(errors?.address?.state)}
        helperText={touched?.address?.state ? errors?.address?.state : ''}
        required={collectStateRequired}
      />
    )

  return (
    <form className={classes.content}>
      <Typography className={classes.title} color="primary" variant="h1">
        {i18n.shipping_address_header}
      </Typography>
      <Typography className={classes.body} variant="body1" align="left">
        {i18n.shipping_address_instruction}
      </Typography>
      {renderStreetAddress()}
      <Box className={classes.twoFieldsAlways}>
        {renderState()}
        {renderZip()}
      </Box>
      <div
        className={
          isInvite ? classes.inviteMobileButtonWrapper : classes.buttonWrapper
        }
      >
        <Button
          className={classes.actionBtn}
          onClick={goNext}
          disabled={!formik.isValid}
          isLoading={formik.isSubmitting}
        >
          {i18n.continue_button}
        </Button>
      </div>
    </form>
  )
}
