import React, { useEffect, useState } from 'react'
import { MaskedInput, TextField } from '../../index'

export const CreditCardEntry = (props: {
  handleChange: (e: React.ChangeEvent<any>) => void
  name: string
  label: string
  value: any
  handleBlur: (e: React.FocusEvent<any, Element>) => void
  error: boolean
  helperText: string | undefined
  autoComplete?: string
}) => {
  const {
    handleChange,
    handleBlur,
    name,
    label,
    value,
    error,
    helperText,
    autoComplete = 'cc-number',
  } = props

  const [cardMask, setCardMask] = useState('9999 9999 9999 9999')

  useEffect(() => {
    setCardMask(getMask())
  }, [value])

  const getMask = () => {
    const defaultMask = '9999 9999 9999 9999'
    switch (value.charAt(0)) {
      case '3': {
        if (
          //Amex
          value.charAt(1) === '4' ||
          value.charAt(1) === '7'
        ) {
          return '9999 999999 99999'
        }
        if (
          value.charAt(1) === '0' ||
          value.charAt(1) === '6' ||
          value.charAt(1) === '8'
        ) {
          //Diners Club
          return '9999 999999 9999'
        }
        return defaultMask
      }
      case '6':
      case '5':
      case '4':
      default:
        //Visa / MC / Discover (modern)
        return defaultMask
    }
  }

  return (
    <MaskedInput
      mask={cardMask}
      maskChar=""
      autoComplete={autoComplete}
      skipTest //allows spaces to be added
      type="text"
      label={label}
      value={value}
      variant="filled"
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      helperText={helperText}
      name={name}
    />
  )
}
