import React from 'react'

export const Observer = ({ callback }: { callback: () => void }) => {
  const observerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!observerRef.current) return
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio) callback()
    })
    observer.observe(observerRef.current)
  }, [])

  return <div ref={observerRef} />
}
