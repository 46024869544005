import React from 'react'
import { shopifyStoreStyles } from '../../../../pages/_styles/shopifyStoreStyles'
import { Address } from '../../../../types/user'
import { Typography } from '../../../../ui'
import { VerifyAddress } from '../../../../ui/organisms/verify-address/verify-address'
import { I18n } from '../../../translation/types'
import { UserDataMethods } from '../../model/types'
import { AddressModel } from '../../SAFE-ecommerce-service/EcommerceSDK'

export const ConfirmAddressWrapper = (props: {
  onChange: (
    method: any,
    type: UserDataMethods.BILLING_ADDRESS | UserDataMethods.SHIPPING_ADDRESS,
    skipsUSPSValidation: boolean
  ) => void
  enteredAddress: Address | undefined
  foundAddress: Address | undefined
  i18n: I18n
}) => {
  const { i18n, onChange, enteredAddress, foundAddress } = props
  if (!foundAddress || !enteredAddress) return null
  const classes = shopifyStoreStyles()

  const handleContinue = (selectedAddress: Address) => {
    const parameters = new AddressModel(
      selectedAddress.line1,
      selectedAddress.line2,
      selectedAddress.city,
      selectedAddress.state,
      selectedAddress.country,
      selectedAddress.zip
    )

    onChange(parameters, UserDataMethods.SHIPPING_ADDRESS, true)
  }

  return (
    <div className={classes.checkoutMethodContent}>
      <Typography className={`${classes.pageTitle} noPadding noMargin`}>
        {i18n.verify_address_header}
      </Typography>
      <div className={classes.entries}>
        <Typography className={classes.summaryLineText}>
          {i18n.verify_address_instruction}
        </Typography>
        <VerifyAddress
          enteredAddressLabel={i18n.address_entered}
          foundAddressLabel={i18n.address_found}
          buttonLabel={i18n.continue_button}
          handleSubmit={handleContinue}
          foundAddress={foundAddress}
          enteredAddress={enteredAddress}
        />
      </div>
    </div>
  )
}
