import { Typography } from '@mui/material'
import { shopifyStoreStyles } from '../../../pages/_styles/shopifyStoreStyles'

export const LineItem = (props: {
  title: string
  value: number
  isTotal?: boolean
}) => {
  const { title, value, isTotal } = props
  const classes = shopifyStoreStyles()

  return (
    <div className={classes.summaryLineItem}>
      <Typography
        variant="h2"
        className={
          isTotal ? `${classes.summaryLineText} total` : classes.summaryLineText
        }
      >
        {title}
      </Typography>
      <Typography
        variant="h2"
        className={
          isTotal
            ? `${classes.summaryLineValue} total`
            : classes.summaryLineValue
        }
      >
        {value}
      </Typography>
    </div>
  )
}
