/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useFlowControl } from '../../features/flowcontrol/use-flow-control'
import { createHealthService } from '../../features/health-service-lite/model'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { HTMLTextComponent } from '../../libs/shared-components'
import { lsClient } from '../../ls-client'
import { PageLoading } from '../../ui'
import { ModuleIntroWrapper } from '../../ui/templates/modules/moduleIntroWrapper'
import { ModuleActionButton } from '../../ui/templates/modules/types'
import { getNetworkSettings, selectNetworkSettings } from '../network/model'
import { paths } from '../paths'
import { selectProfileData } from '../profile/model'
import { resetQuestionnaireV2Store } from '../questionnaireV2/questionnaireV2Slice'
import {
  getIntroScreen,
  resetIntroStore,
  selectIntroLoading,
  selectVcIntroScreen,
} from './symptomCheckerIntroSlice'

export const SymptomCheckerIntroductionPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(selectProfileData)
  const introScreen = useSelector(selectVcIntroScreen)
  const { id } = useParams<{ id: string }>()
  const networkSettings = useSelector(selectNetworkSettings)
  const tenantId = localStorage.getItem('tenantId')
  const isLoading = useSelector(selectIntroLoading)
  const { state, actions } = useFlowControl()
  const { isMobile } = useMUITheme()
  useEffect(() => {
    if (lsClient.getUserLSByKey('VCquestionnaireId')) {
      navigate(paths.symptomCheckerV2())
    }
    if (!networkSettings && tenantId) {
      dispatch(getNetworkSettings(tenantId))
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    dispatch(resetQuestionnaireV2Store())
    dispatch(getIntroScreen(id ? id : ''))
  }, [id])

  const handleBack = () => {
    dispatch(resetIntroStore())
    navigate(-1)
  }

  const handleCancel = () => {
    navigate(paths.app.dashboard())
  }

  const goNext = () => {
    const isHealthService = lsClient.getUserLSByKey('isHealthService') //this can be removed, everything is a HS now
    const healthService = lsClient.getUserLSByKey('healthServiceId')
    const tenantId = lsClient.getUserLSByKey('tenantId')
    lsClient.removeUserKeyLS('VCquestionnaireId')
    if (!healthService) {
      const params = {
        tenantId,
        accountId: user.accountId,
        UserId: user._id,
        Description: 'Starting Health Service',
      }
      const onCompletion = (healthServiceId: string) => {
        lsClient.setUserLS('healthServiceId', healthServiceId)
        if (id) navigate(paths.symptomCheckerById(id))
        else navigate(paths.symptomCheckerV2())
      }
      dispatch(createHealthService(params, onCompletion))
    } else if (id) navigate(paths.symptomCheckerById(id))
    else navigate(paths.symptomCheckerV2())
  }

  const buttonActions = [
    {
      label: introScreen?.buttonText?.translations?.eng,
      action: goNext,
      variant: 'contained',
      style: {
        backgroundColor: introScreen?.buttonColor
          ? introScreen.buttonColor
          : 'unset',
        borderColor: introScreen?.buttonColor
          ? introScreen.buttonColor
          : 'unset',
        color: introScreen?.buttonTextColor
          ? introScreen.buttonTextColor
          : 'unset',
      },
    },
  ] as ModuleActionButton[]

  if (isLoading) return <PageLoading size={100} />

  return (
    <ModuleIntroWrapper
      title={introScreen?.title?.translations?.eng}
      body={
        introScreen?.description?.translations?.eng ? (
          <HTMLTextComponent
            HTMLString={introScreen.description.translations.eng}
          />
        ) : (
          ''
        )
      }
      contentPosition={isMobile ? 'end' : 'center'}
      contentImage={introScreen?.image || ''}
      actions={buttonActions}
      onBack={state.isFlowExecuting ? undefined : handleBack}
      onClose={handleCancel}
    />
  )
}
