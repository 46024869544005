import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { handleError } from '../../libs/helpers'
import {
  AvailablePickUp,
  CheckAvailabilityPayload,
  SchedulePickUpRequest,
  ShortAddress,
} from './types'

interface ShippingTestSlice {
  dropOffPickupLocation: any
  isLoadingConfirmation: boolean
  availablePickUps: AvailablePickUp[] | null
}

const initialState: ShippingTestSlice = {
  dropOffPickupLocation: null,
  isLoadingConfirmation: false,
  availablePickUps: null,
}

const shippingTestSlice = createSlice({
  name: 'shippingTest',
  initialState,
  reducers: {
    setDropOffPickupLocation: (state, { payload }: { payload: any }) => {
      state.dropOffPickupLocation = payload
    },
    setLoadingConfirmation(store, { payload }: { payload: boolean }) {
      store.isLoadingConfirmation = payload
    },
    setAvailablePickUps: (
      state,
      { payload }: { payload: AvailablePickUp[] }
    ) => {
      state.availablePickUps = payload
    },
  },
})

export const {
  setDropOffPickupLocation,
  setAvailablePickUps,
  setLoadingConfirmation,
} = shippingTestSlice.actions

export const validatePickUpAddress =
  (payload: ShortAddress) => async (dispatch: Dispatch<any>, getStore: any) => {
    const { languageContent } = getStore().translation

    const { data, error, success } = await api.validatePickUpAddressRequest(
      payload
    )

    if (error) {
      dispatch(handleError(null, languageContent[error] || error))
      return false
    }

    if (success && data) {
      return true
    }
  }

export const checkPickUpAvailability =
  (payload: CheckAvailabilityPayload) =>
  async (dispatch: Dispatch<any>, getStore: any) => {
    const { languageContent } = getStore().translation
    const { data, error, success } = await api.checkPickUpAvailabilityRequest(
      payload
    )

    if (error) {
      dispatch(handleError(null, languageContent[error] || error))
      return false
    }

    if (success && data) {
      dispatch(setAvailablePickUps(data))
      return true
    }
  }

export const schedulePickUp =
  (payload: SchedulePickUpRequest) =>
  async (dispatch: Dispatch<any>, getStore: any) => {
    const { languageContent } = getStore().translation

    const res = await api.schedulePickUpRequest(payload)
    const { data, error, success } = res

    if (error) {
      dispatch(handleError(null, languageContent[error] || error))
      return false
    }

    if (success && data) {
      dispatch(setAvailablePickUps(data))
      return true
    }
  }

interface IConfirmParams {
  logisticsPartner: string
  orderId: string
  contact?: Record<string, string> | null
  address?: Record<string, string> | null
  normalHours?: Record<string, string> | null
}

export const confirmDropOff =
  (orderId: string) => async (dispatch: any, getStore: any) => {
    const { languageContent } = getStore().translation
    const { dropOffPickupLocation } = getStore().shippingTest
    const params: IConfirmParams = {
      logisticsPartner: 'FedEx',
      orderId,
      contact: dropOffPickupLocation?.contact,
      address: dropOffPickupLocation?.address,
      normalHours: dropOffPickupLocation?.normalHours,
    }

    dispatch(setLoadingConfirmation(true))

    const { success, data, error } = await api.confirmDropOffRequest(params)

    if (error || !success) {
      // try to get translated error by key from localization file or render received error string
      dispatch(handleError(null, languageContent[error] || error))
      dispatch(setLoadingConfirmation(false))
      return false
    }

    if (success) {
      dispatch(setLoadingConfirmation(false))
      return true
    }

    dispatch(handleError())
    return false
  }

export const shippingLocationReducer = shippingTestSlice.reducer
export const shippingLocationReducerName = shippingTestSlice.name

interface RootStore {
  [shippingLocationReducerName]: ShippingTestSlice
}

export const selectDropOffPickupLocation = ({ shippingTest }: RootStore) =>
  shippingTest.dropOffPickupLocation

export const selectIsLoadingConfirmation = ({ shippingTest }: RootStore) =>
  shippingTest.isLoadingConfirmation

export const selectAvailablePickUps = ({ shippingTest }: RootStore) =>
  shippingTest.availablePickUps
