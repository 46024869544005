import { Box, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { hexToRGBA } from '../../../libs/helpers'
import { Typography } from '../../../ui'
import { ArticleResource, ResourceType, VideoResource } from '../types'

export const ListVideoResource = (props: {
  r: VideoResource
  onResourceClick: (type: ResourceType, id: string) => void
  grid: boolean
  isMobile: boolean
}) => {
  const { r, grid, onResourceClick, isMobile } = props
  const classes = useStyles()

  const Resource = () => (
    <Box
      className={classes.listResourceFrame}
      onClick={() => onResourceClick('video', r._id)}
    >
      <div
        style={{
          backgroundImage: `url(${r.thumbnailURL || r.largeImageURL})`,
        }}
        className={classes.listResourceImage}
      >
        <PlayArrowIcon className={classes.iconFilled} />
      </div>
      <Box className={classes.listResourceBody}>
        <Typography className={classes.listResourceTitle}>{r.title}</Typography>
        {isMobile && <ChevronRightSharpIcon style={{ color: '#505358' }} />}
      </Box>
    </Box>
  )

  return grid ? (
    <Grid item md={4} sm={6} xs={12} key={r._id}>
      <Resource />
    </Grid>
  ) : (
    <Box className={classes.noGridWrapper}>
      <Resource />
    </Box>
  )
}

export const ListArticleResource = (props: {
  r: ArticleResource
  onResourceClick: (type: ResourceType, id: string) => void
  grid: boolean
  isMobile: boolean
}) => {
  const { r, grid, onResourceClick, isMobile } = props
  const classes = useStyles()

  const Resource = () => (
    <Box
      className={classes.listResourceFrame}
      onClick={() => onResourceClick('article', r._id)}
    >
      <div
        style={{ backgroundImage: `url(${r.imageURL})` }}
        className={classes.listResourceImage}
      />
      <Box className={classes.listResourceBody}>
        <Typography className={classes.listResourceTitle}>{r.title}</Typography>
        {isMobile && <ChevronRightSharpIcon style={{ color: '#505358' }} />}
      </Box>
    </Box>
  )
  return grid ? (
    <Grid item md={4} sm={6} xs={12} key={r._id}>
      <Resource />
    </Grid>
  ) : (
    <Box className={classes.noGridWrapper}>
      <Resource />
    </Box>
  )
}

const WEBKIT_BOX = '-webkit-box'
const shadow = '0px 8px 23px 0px rgba(0, 0, 0, 0.08);'

const useStyles = makeStyles((theme) => ({
  noGridWrapper: {
    width: 392,
    minWidth: 392,
    [theme.breakpoints.down(600)]: {
      width: 250,
      minWidth: 250,
    },
  },
  listResourceFrame: {
    'height': '275px',
    'backgroundColor': '#FFF',
    'borderRadius': '16px',
    'padding': '16px',
    'transition': 'all .1s ease-in-out',
    'cursor': 'pointer',
    '&:hover': {
      backgroundColor: hexToRGBA(theme.palette.primary.main, 0.1),
      transition: 'all .1s ease-in-out',
    },
    [theme.breakpoints.down(600)]: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px',
      gap: 4,
      height: 'unset',
    },
  },
  listResourceImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '185px',
    [theme.breakpoints.up(600)]: {
      borderRadius: '8px',
    },
    [theme.breakpoints.down(600)]: {
      borderTopLeftRadius: '7px',
      borderBottomLeftRadius: '7px',
      height: '75px',
      width: '113px',
    },
  },
  listResourceBody: {
    padding: '16px',
    [theme.breakpoints.down(600)]: {
      width: '100%',
      padding: '32px 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '1px solid #E0E0E0',
    },
  },
  listResourceTitle: {
    'fontSize': '16px',
    'fontWeight': 500,
    'lineHeight': '21px',
    'display': WEBKIT_BOX,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    [theme.breakpoints.down(600)]: {
      color: '#757575',
    },
  },
  listResourceDesc: {
    'display': WEBKIT_BOX,
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    'overflow': 'hidden',
    'fontSize': '12px',
    'lineHeight': '18px',
    'marginTop': '16px',
    [theme.breakpoints.down(600)]: {
      fontSize: '10px',
      lineHeight: '15px',
    },
  },
  iconFilled: {
    borderRadius: '100%',
    fontSize: '32px',
    padding: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      fontSize: '24px',
      padding: '1px',
    },
  },
}))
