import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectLanguageContent } from '../../../features/translation'
import { Typography } from '../../../ui'
import { paths } from '../../paths'
import { telehealthStyles } from '../components/styles'
import { WaitingRoomWrapper } from '../components/waitingRoomWrapper'
import { getLastSession, selectTelehealthStatus } from '../model'

type WaitingRoomPage = 'FindingProvider' | 'ProviderReview' | 'ProviderReady'

export const WaitingRoomPage = (props: any) => {
  const classes = telehealthStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const sessionStatus = useSelector(selectTelehealthStatus)
  const [view, setview] = useState<WaitingRoomPage>('FindingProvider')
  const [page, setPage] = useState(0)
  const steps = ['0', '1', '2']

  useEffect(() => {
    if (!sessionStatus) {
      dispatch(getLastSession())
    }
  }, [])

  useEffect(() => {
    if (sessionStatus === 'Waiting') {
      setPage(0)
      setview('FindingProvider')
    } else if (sessionStatus === 'ProviderReviewing') {
      setPage(1)
      setview('ProviderReview')
    } else if (
      sessionStatus === 'ProviderJoined' ||
      sessionStatus === 'Restarting'
    ) {
      setPage(2)
      setview('ProviderReady')
    }
  }, [sessionStatus])

  return (
    <>
      <div className={classes.headerWrapper}>
        <Typography
          color="primary"
          className={classes.headerTitle}
          variant="h1"
        >
          {i18n.telehealth_waiting_room_title}
        </Typography>
        {(page === 0 || page === 1) && (
          <Box
            onClick={() => navigate(paths.app.dashboard())}
            className={classes.closeIconWrapper}
          >
            <CloseIcon color="primary" className={classes.backIcon} />
          </Box>
        )}
      </div>
      <div className={classes.headerWrapper}>
        {steps.map((step, i) => (
          <StepBar active={page >= i} />
        ))}
      </div>
      <Box className={classes.pageWrapper} style={{paddingBottom: 250}}>{WaitingRoomWrapper[view]()}</Box>
    </>
  )
}

const StepBar = (props: { active: boolean }) => {
  const { active } = props
  const classes = stepStyles()
  return (
    <Box
      className={classes.step}
      style={{ backgroundColor: active ? '#009104' : '#E0E0E0' }}
    ></Box>
  )
}

const stepStyles = makeStyles({
  title: {
    fontSize: '26px',
    fontWeight: 500,
    width: '100%',
  },
  closeIconWrapper: {
    position: 'absolute',
    left: '16px',
    top: '16px',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    gap: '24px',
  },
  step: {
    width: '30%',
    height: '4px',
    margin: '0px 4px',
  },
})
