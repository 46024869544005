/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useSelector } from 'react-redux'
import { HTMLTextComponent } from '../../../libs/shared-components'
import { lsClient } from '../../../ls-client'
import { selectProctorStatus } from '../../proctor/model'
import { TimerProps } from '../types'

type BorderColor = '#009104' | '#FAC133' | '#EE441F'

export const ProctorWaitingRoom = (props: TimerProps) => {
  const { current, timerActive, timerValue, timerContinue } = props
  const classes = useStyles()
  const proctorStatus = useSelector(selectProctorStatus)
  const [focused, setFocused] = useState(!document.hidden)

  useEffect(() => {
    //Broswers can prevent timers from operating while backgrounded / not active tab
    const focus = () => {
      if (document.hidden) {
        setFocused(false)
      } else {
        setFocused(true)
      }
    }
    document.addEventListener('visibilitychange', focus)
    return () => {
      document.removeEventListener('visibilitychange', focus)
    }
  }, [])

  return (
    <Box>
      <Box
        className={`${classes.instructionContainer} 'reduced'}`}
        id="instructionContainer"
      >
        <Box className={classes.assetBox} id="instructions">
          {current?.imageURL && (
            <img
              className={classes.img}
              src={current?.imageURL}
              alt="test-instructions"
            />
          )}
          {current?.videoURL && (
            <Box className={classes.videoContainer}>
              <video
                src={current.videoURL}
                controls
                className={classes.video}
              />
            </Box>
          )}
          {current?.body && <HTMLTextComponent HTMLString={current.body} />}
          {proctorStatus === 'ProctorJoined' && (
            <Timer
              timerContinue={timerContinue}
              timerActive={timerActive}
              current={current}
              timerValue={timerValue}
              focused={focused}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

const Timer = (props: any) => {
  const { timerContinue, timerActive, current, timerValue, focused } = props
  const classes = useStyles()
  const [proctorTime, setProctorTime] = useState(-1)
  const [startTimer, setStartTimer] = useState(false)
  const [borderColor, setBorderColor] = useState<BorderColor>('#009104')

  useEffect(() => {
    //Monitor visibilty and re-calculate the timer sync if window was not active when switched to 'Proctor Joined'
    if (timerActive && focused) {
      const startTime = lsClient.getUserLSByKey('session') || 0
      const elapsedTime =
        Math.round(
          moment.duration(moment(new Date()).diff(startTime)).asSeconds()
        ) || 0

      setProctorTime(current.timeInSeconds - elapsedTime)
      setStartTimer(true)
    }
  }, [timerActive, focused])

  const children = ({ remainingTime }: any) => {
    if (remainingTime <= 60 && remainingTime >= 30) {
      setBorderColor('#FAC133')
    } else if (remainingTime < 30) {
      setBorderColor('#EE441F')
    } else {
      setBorderColor('#009104')
    }
    const minutes = Math.floor(remainingTime / 60)
    const seconds = remainingTime % 60
    let spacer = ''
    if (seconds < 10) {
      spacer = '0'
    }
    return (
      <Typography className={classes.timer} style={{ color: `${borderColor}` }}>
        {`${minutes}:${spacer}${seconds}`}
      </Typography>
    )
  }

  if (!startTimer) return null

  return (
    <Box className={classes.joinTimerContainer}>
      <Box className={classes.linearBox} style={{ borderColor: borderColor }}>
        <CountdownCircleTimer
          isPlaying={startTimer}
          strokeWidth={0}
          trailStrokeWidth={0}
          size={50}
          strokeLinecap="round"
          rotation="counterclockwise"
          onComplete={() => {
            timerContinue()
            setStartTimer(false)
            return [false, 0]
          }}
          duration={timerValue}
          initialRemainingTime={proctorTime}
          colors={`${borderColor}`}
        >
          {children}
        </CountdownCircleTimer>
        <Typography className={classes.timerText}>
          before being placed back into the queue.
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    minHeight: '200px',
  },
  videoContainer: {
    marginTop: '20px',
    width: '100%',
  },
  timer: {
    fontSize: 48,
    [theme.breakpoints.up(576)]: {
      fontSize: 36,
      // marginLeft: '24px',
    },
  },
  img: {
    maxWidth: '325px',
    [theme.breakpoints.down(320)]: {
      width: '90%',
    },
  },
  joinTimerContainer: {
    margin: '0 auto',
    maxHeight: '99px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up(576)]: {
      flexDirection: 'row',
      maxHeight: '79px',
    },
  },
  linearBox: {
    padding: 10,
    margin: '0 auto',
    maxHeight: '99px',
    display: 'flex',
    flexDirection: 'column',
    width: '146px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid',
    borderRadius: '8px',
    [theme.breakpoints.up(576)]: {
      flexDirection: 'row',
      width: '238px',
      justifyContent: 'space-evenly',
    },
  },
  timerText: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#505358',
    [theme.breakpoints.up(576)]: {
      flexDirection: 'row',
      width: '114px',
      marginLeft: 9,
    },
  },
  timerBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  assetBox: {
    'margin': '16px',
    'width': '100%',
    '& span': {
      textAlign: 'left',
    },
    [theme.breakpoints.down(576)]: {
      'width': '90%',
      'margin': 0,
      '& span': {
        fontSize: '20px !important',
      }
    },
  },
  instructionContainer: {
    'overflowX': 'hidden',
    'margin': '0px auto',
    'width': '504px',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'space-evenly',
    [theme.breakpoints.down(576)]: {
      width: '90%',
      justifyContent: 'flex-start',
      height: 'calc(100vh - 275px)',
      margin: '10px auto',
    },
    '&.reduced': {
      justifyContent: 'flex-start',
    },
  },
}))
