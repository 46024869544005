import React from 'react'
import { Button } from '../../../../ui'
import { getButtonVariant } from '../../../../ui/atoms/button/button'
import { CardProps } from './types'

export const SimpleButton = (props: CardProps) => {
  const { button, handleClick } = props

  if (!button.enabled) return null
  return (
    <Button
      style={{
        backgroundColor: button.buttonColor ? button.buttonColor : 'unset',
        borderColor: button.buttonColor ? button.buttonColor : 'unset',
        color: button.buttonLabelColor ? button.buttonLabelColor : 'unset',
      }}
      onClick={handleClick}
      disabled={!button.buttonEnabled}
      variant={getButtonVariant(button.buttonStyle)}
    >
      {button.buttonLabel}
    </Button>
  )
}
