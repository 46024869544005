import { Box, Divider, Link } from '@mui/material'
import { ClassNameMap } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { NOTIFICATION_TYPES } from '../../constants'
import { formatDate, handleMessage } from '../../libs/helpers'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { PageTitle } from '../../libs/shared-components'
import { shopifyStoreStyles } from '../../pages/_styles/shopifyStoreStyles'
import { paths } from '../../pages/paths'
import { Button, Typography } from '../../ui'
import { useDialog } from '../dialog/application-dialog'
import { selectLanguageContent } from '../translation'
import { CheckoutItem, LineItem } from './shopify-ui-items'
import { ShopLoading } from './shopify-ui-items/storeLoading'

export const OrderDetail = (props: { sdk: any }) => {
  const { sdk } = props
  const classes = shopifyStoreStyles()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const navigate = useNavigate()
  const [orderDetailsState, setOrderDetailsState] = useState<any>(undefined)
  const [isLoading, setIsloading] = useState(false)
  const isMobile = useMUITheme().isMobile
  const orderId = new URLSearchParams(window.location.search).get('id') || ''
  const { openDialog, closeDialog } = useDialog()

  useEffect(() => {
    setIsloading(true)
    setTimeout(() => {
      loadOrderDetails(orderId)
    }, 250)
  }, [])

  const loadOrderDetails = (orderId: string) => {
    if (isLoading) {
      return
    }

    setIsloading(true)
    sdk.customerManager
      .getPurchaseRecord(orderId)
      .then((response: any) => {
        setOrderDetailsState(response)
        setIsloading(false)
      })
      .catch((error: any) => {
        console.log(error)
        dispatch(handleMessage(error.message, NOTIFICATION_TYPES.V2_ERROR))
        setIsloading(false)
      })
  }

  const orderDetailItems = [
    {
      title: i18n['ecommerce.purchase_record.order_date_header'],
      body: formatDate(orderDetailsState?.orderDateText, true, '\n'),
      display: true,
    },
    {
      title: i18n['ecommerce.purchase_record.shipping_address_header'],
      body: orderDetailsState?.shippingAddressText || '',
      display: orderDetailsState?.displaysShippingAddress,
    },
    {
      title: i18n['ecommerce.purchase_record.billing_address_header'],
      body: orderDetailsState?.billingAddressText || '',
      display: orderDetailsState?.displaysBillingAddress,
    },
    {
      title: i18n['ecommerce.purchase_record.payment_method_header'],
      body: orderDetailsState?.paymentMethodText || '',
      display: orderDetailsState?.displaysPaymentMethod,
    },
    {
      title: i18n['ecommerce.purchase_record.shipping_method_header'],
      body: orderDetailsState?.shippingMethodText || '',
      display: orderDetailsState?.displaysShippingMethod,
    },
  ]

  const openURL = (url: string) => {
    closeDialog()
    window.open(url, '_blank')
  }

  const handleClickURL = (url: string) => {
    const content = {
      title: i18n.leaving_the_app,
      body: i18n.leaving_the_app_instruction,
      onCancel: () => closeDialog(),
      cancelLabel: i18n.not_right_now,
      onConfirm: () => openURL(url),
      confirmationLabel: i18n.continue_to_site,
    }
    openDialog(content)
  }

  const desktopSectionBuilder = () => {
    let index = 0

    const sectionColumns = [] as React.ReactNode[][]

    for (let i = 0; i < 2; i++) {
      sectionColumns.push([] as React.ReactNode[])
    }
    orderDetailItems?.forEach((o, i: number) => {
      if (i % 2) {
        index = 1
      } else {
        index = 0
      }
      if (!o.display || o.body === 'null') return null
      sectionColumns[index]?.push(
        <div className={classes.detailColumn} key={o.title}>
          <RecordItem
            title={o.title}
            body={o.body}
            display={o.display}
            classes={classes}
          />
        </div>
      )
    })

    return (
      <div className={classes.cardContainerWrapper}>
        <div className={classes.cardWrapper}>
          {sectionColumns.map((col, i) => (
            <div className={classes.columns} key={`column-${i}`}>
              {col}
            </div>
          ))}
        </div>
      </div>
    )
  }

  if (isLoading) return <ShopLoading />

  return (
    <Box className={classes.dataContentWrapper}>
      <div className={classes.historyDetailWrapper}>
        <PageTitle style={{ marginBottom: 8}}>
          {i18n['ecommerce.purchase_record.navigation_title']}
        </PageTitle>
        {orderDetailsState?.displaysTrackingInformation &&
          orderDetailsState?.shippingMethodText && (
            <div className={classes.methodSelectionWrapper}>
              <div className={classes.detailSectionWrapper}>
                <Typography variant="h2" className={classes.orderSummaryTitle}>
                  {
                    i18n[
                      'ecommerce.purchase_record.tracking_information_header'
                    ]
                  }
                </Typography>
                <Divider
                  style={{
                    margin: '16px 0px',
                    backgroundColor: '#E0E0E0',
                  }}
                />
                <RecordItem
                  title={orderDetailsState.trackingTitle}
                  body={orderDetailsState.trackingMessage}
                  display={true}
                  classes={classes}
                />
                {orderDetailsState?.trackingDetails?.trackingUrl && (
                  <div style={{ margin: '16px 0px' }}>
                    <Link
                      role="button"
                      color="primary"
                      underline="always"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        handleClickURL(
                          orderDetailsState.trackingDetails?.trackingUrl
                        )
                      }
                    >
                      {i18n.ecommerce_track_your_package}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        <div className={classes.methodSelectionWrapper}>
          <div className={classes.detailSectionWrapper}>
            <Typography variant="h2" className={classes.orderSummaryTitle}>
              {i18n['ecommerce.purchase_record.order_details_header']}
            </Typography>
            <Divider
              style={{
                margin: '16px 0px',
                backgroundColor: '#E0E0E0',
              }}
            />
            <div className={classes.orderDetailContent}>
              {isMobile ? (
                <>
                  {orderDetailItems.map((o) => {
                    if (!o.display || o.body === 'null') return null
                    return (
                      <div className={classes.detailColumn} key={o.title}>
                        <RecordItem
                          title={o.title}
                          body={o.body}
                          display={o.display}
                          classes={classes}
                        />
                      </div>
                    )
                  })}
                </>
              ) : (
                desktopSectionBuilder()
              )}
            </div>
          </div>
        </div>
        <div className={classes.methodSelectionWrapper}>
          <div className={classes.detailSectionWrapper}>
            <Typography variant="h2" className={classes.orderSummaryTitle}>
              {i18n['ecommerce_summary_of_purchases']}
            </Typography>
            <Divider
              style={{
                margin: '16px 0px',
                backgroundColor: '#E0E0E0',
              }}
            />
            {orderDetailsState?.orderLines.map((product: any) => (
              <CheckoutItem
                key={product.productTitle}
                i18n={i18n}
                product={product}
                color="#F5F5F5"
                forHistory={true}
              />
            ))}
          </div>
        </div>
        <div className={classes.methodSelectionWrapper}>
          <div className={classes.detailSectionWrapper}>
            <Typography variant="h2" className={classes.orderSummaryTitle}>
              {i18n['ecommerce.purchase_record.payment_details_header']}
            </Typography>
            <Divider
              style={{
                margin: '16px 0px',
                backgroundColor: '#E0E0E0',
              }}
            />
            <LineItem
              title={i18n['ecommerce.order.charges.subtotal']}
              value={orderDetailsState?.subtotalText}
              isTotal={false}
            />
            {orderDetailsState?.chargesDisplayed.map((item: any) => (
              <React.Fragment key={item.label}>
                <LineItem
                  title={item.label}
                  value={item.amount.printed()}
                  isTotal={false}
                />
              </React.Fragment>
            ))}
            <Divider
              style={{
                marginBottom: 16,
                backgroundColor: '#E0E0E0',
              }}
            />
            <LineItem
              title={i18n['ecommerce.order.charges.total']}
              value={orderDetailsState?.totalText}
              isTotal={true}
            />
          </div>
        </div>
        <div
          className={classes.lgButtonBox}
          style={{ justifyContent: 'flex-end' }}
        >
          <Button
            onClick={() => navigate(paths.app.dashboard())}
            className={classes.confirmButton}
          >
            {i18n.return_home}
          </Button>
        </div>
      </div>
    </Box>
  )
}

const RecordItem = (props: {
  title: string
  body: string
  classes: ClassNameMap
  display: boolean
}) => {
  const { title, body, classes, display } = props

  if (!display || body === 'null') return null

  return (
    <>
      <Typography className={classes.detailItemTitle}>{title}</Typography>
      <Typography className={classes.detailItemBody}>{body}</Typography>
    </>
  )
}
