import styled from '@emotion/styled'
import { Box, CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import secureMess from '../../../assets/img/secure-mess.svg'
import { Observer } from '../../../libs/observer'
import { selectLanguageContent } from '../../translation'
import {
  fetchMoreSystemMessages,
  selectIsDone,
  selectIsLoadingPage,
  selectList,
} from '../model'
import { MessageItemInList } from '../molecules'

export const SystemMessagesList = () => {
  const dispatch = useDispatch()
  const childClasses = childStyles()
  const messages = useSelector(selectList)
  const isDone = useSelector(selectIsDone)
  const isLoadingPage = useSelector(selectIsLoadingPage)
  const getMoreMessages = () => {
    dispatch(fetchMoreSystemMessages())
  }

  return (
    <Box className={childClasses.listContainer}>
      <ListStyled>
        {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          !messages || messages?.length === 0 ? (
            <NoMessages />
          ) : (
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            messages?.map((message, index) => (
              <li key={message.conversationId}>
                {messages.length >= 20 &&
                  !isDone &&
                  messages.length - 5 === index && (
                    <Observer callback={getMoreMessages} />
                  )}
                <MessageItemInList message={message} />
              </li>
            ))
          )
        }
        {isLoadingPage && (
          <SpinnerLi>
            <CircularProgress />
          </SpinnerLi>
        )}
      </ListStyled>
    </Box>
  )
}
const NoMessages = () => {
  const childClasses = childStyles()
  const i18n = useSelector(selectLanguageContent)
  return (
    <>
      <Box className={childClasses.box}>
        <Box className={childClasses.boxIcon}>
          <img
            src={secureMess}
            alt="secure-messenger"
            className={childClasses.testResultsImage}
          />
        </Box>
        <TypographyStyled
          variant="h4"
          align="center"
          className={childClasses.noMessageTest}
        >
          {i18n.no_messages}
        </TypographyStyled>
      </Box>
    </>
  )
}

const ListStyled = styled.ul`
  width: 100%;
  padding: 0;
  margin: 5px auto 16px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
`
const SpinnerLi = styled.li`
  display: grid;
  justify-content: center;
  margin-top: 20px;
`
const TypographyStyled = styled(Typography)`
  color: #003c71;
`
const childStyles = makeStyles((theme) => ({
  listContainer: {
    maxHeight: 'calc(100vh - 64px - 48px - 20px)',
    overflow: 'auto',
  },
  boxIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  box: {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
  },
  testResultsImage: {
    paddingTop: 20,
    width: 150,
    [theme.breakpoints.down(450)]: {
      width: 100,
    },
  },
  noMessageTest: {
    marginTop: 50,
  },
}))
