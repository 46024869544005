import { useTheme } from '@mui/material/styles'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import React from 'react'

interface PasswordVisibilityProps {
  showPassword: boolean
}

export const PasswordVisibility = (props: PasswordVisibilityProps) => {
  const { showPassword } = props
  const theme = useTheme()

  return (
    <>
      {showPassword ? (
        <>
          <VisibilityIcon
            style={{
              color: theme.palette.primary.main,
              fontSize: '22px',
            }}
          />
        </>
      ) : (
        <>
          <VisibilityOffIcon
            style={{
              color: theme.palette.primary.main,
              fontSize: '22px',
            }}
          />
        </>
      )}
    </>
  )
}
