import { createSlice } from '@reduxjs/toolkit'


interface InitialState {
  loading: boolean
  // formData: FormValues
  cartItemCount: number
}

const initialState: InitialState = {
  loading: false,
  cartItemCount: 0,
}

const shopifySlice = createSlice({
  name: 'shopify',
  initialState,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.loading = payload
    },
    setCartCount(store, { payload }: { payload: number }) {
      store.cartItemCount = payload
    },
    resetShopifyForm: () => initialState,
  },
})

const { setLoading } = shopifySlice.actions

export const {
  resetShopifyForm,
  setCartCount
} = shopifySlice.actions

export const shopifyReducer = shopifySlice.reducer
export const shopifyReducerName = shopifySlice.name
interface RootStore {
  [shopifyReducerName]: typeof initialState
}

export const selectShopifyLoading = ({ shopify }: RootStore) => shopify.loading


export const selectCartItemCount = ({ shopify }: RootStore) =>
  shopify.cartItemCount
