import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearTestKitFlow } from '../../libs/helpers'
import { lsClient } from '../../ls-client'
import { SHADOW } from '../../pages/_styles/dashboardCardStyles'
import { selectNetworkSettings } from '../../pages/network/model'
import { TelehealthOptions } from '../../pages/network/type'
import { paths } from '../../pages/paths'
import { resetQuestionnaireV2Store } from '../../pages/questionnaireV2/questionnaireV2Slice'
import {
  getLabTestDetails,
  getTestPanelDetails,
} from '../../pages/register-a-test-module/model'
import {
  cancelTelehealthSession,
  createSession,
  resetTelehealthStore,
  selectTelehealthStatus,
  TelehealthPages,
} from '../../pages/telehealth/model'
import { useDialog } from '../dialog/application-dialog'
import {
  getEncounterRecord,
  selectEncounter,
  selectEncounterDisposition,
  selectEncounterFetched,
} from '../encounter/model'
import { useFlowControl } from '../flowcontrol/use-flow-control'
import {
  completeHealthService,
  selectFVFConsult,
} from '../health-service-lite/model'
import {
  ConsultStatus,
  EncounterDisposition,
  HealthService,
  HealthServiceDisposition,
  HealthServiceType,
} from '../health-service-lite/types'
import { clearDeviceInterests } from '../pusher-notifications/beamsMessaging'
import { selectLanguageContent } from '../translation'
import { statusCards } from './helpers/statusCards'
import { ProgressView, SimpleView } from './views'

export const TreatmentPlanSnackbar = (props: {
  healthServiceList: HealthService[]
}) => {
  const { healthServiceList } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const sessionStatus = useSelector(selectTelehealthStatus)
  const firstViewFinancialConsult = useSelector(selectFVFConsult)
  const disposition = useSelector(selectEncounterDisposition)
  const encounterFetched = useSelector(selectEncounterFetched)
  const encounter = useSelector(selectEncounter)
  const networkSettings = useSelector(selectNetworkSettings)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = useStyles()
  const [hsType, setHsType] = useState(HealthServiceType.Unset)
  const [hsTypeId, setHsTypeId] = useState('')
  const [view, setView] = useState<ConsultStatus | undefined>(undefined)
  const { openDialog, closeDialog } = useDialog()

  const { state, actions } = useFlowControl()

  useEffect(() => {
    return () => {
      if (
        view === ConsultStatus.cancelled ||
        view === ConsultStatus.safe_cancelled ||
        view === ConsultStatus.CANCELLED ||
        view === ConsultStatus.healthservice_expired ||
        view === ConsultStatus.safe_treatment_ready
      ) {
        endHealthService()
      }
    }
  }, [view])

  useEffect(() => {
    if (healthServiceList[0] && healthServiceList[0].status === 'Cancelled') {
      if (
        healthServiceList[0].disposition ===
        HealthServiceDisposition.Expired24Hours
      ) {
        setView(ConsultStatus.healthservice_expired)
      } else {
        setView(ConsultStatus.CANCELLED)
      }
    }
    if (healthServiceList[0] && healthServiceList[0].status !== 'Completed') {
      if (healthServiceList[0].lastUpdatedStep) {
        const typeKey = healthServiceList[0].lastUpdatedStep.discriminator
        const typeId = healthServiceList[0].lastUpdatedStep.id
        setHsType(HealthServiceType[typeKey])
        setHsTypeId(typeId)
      }
      dispatch(getEncounterRecord(healthServiceList[0].id))
    }
  }, [healthServiceList, networkSettings])

  useEffect(() => {
    if (
      healthServiceList[0] &&
      healthServiceList[0].status !== 'Completed' &&
      encounterFetched
    ) {
      setView(getStatusForTenantAndDisposition())
      if (
        disposition === EncounterDisposition.COMPLETED ||
        disposition === EncounterDisposition.TREATMENT_READY
      ) {
        clearDeviceInterests()
      }
    }
  }, [disposition, healthServiceList, encounter, encounterFetched])

  const refreshEncounter = () => {
    dispatch(getEncounterRecord(encounter._id))
  }

  const onCancel = () => openDialog(dialogs.cancel)
  const onFinish = () => openDialog(dialogs.finish)
  const gotoTreatmentPlan = () =>
    navigate(paths.treatmentPlan(healthServiceList[0].id))
  const reviewCarePlan = () =>
    navigate(paths.treatmentPlan(firstViewFinancialConsult?._id))

  const cancelSession = () => {
    dispatch(
      cancelTelehealthSession(hsTypeId, healthServiceList[0].id, false, () => {
        window.location.reload()
      })
    )
  }

  const getStatusForTenantAndDisposition = () => {
    const SAFE =
      networkSettings?.telehealthConfiguration ===
      TelehealthOptions.SAFE_TELEHEALTH
    const SteadyMD =
      networkSettings?.telehealthConfiguration === TelehealthOptions.STEADY_MD

    switch (disposition) {
      case EncounterDisposition.UNASSIGNED: {
        if (SAFE) return ConsultStatus.safe_created
        if (SteadyMD) return ConsultStatus.NOT_ASSIGNED
        return undefined
      }
      case EncounterDisposition.IN_REVIEW: {
        if (SAFE) return ConsultStatus.safe_dequeued
        if (SteadyMD) return ConsultStatus.steadyMD_in_review
        return undefined
      }
      case EncounterDisposition.IN_CALL: {
        return ConsultStatus.IN_CALL
      }
      case EncounterDisposition.TREATMENT_READY: {
        return ConsultStatus.safe_treatment_ready
      }
      default:
        return disposition || ConsultStatus.IN_PROGRESS
    }
  }

  const endHealthService = () => {
    const hasSessionThatCanCancel =
      sessionStatus === 'Waiting' ||
      disposition === EncounterDisposition.UNASSIGNED

    const params = {
      status: 'Completed',
    }
    closeDialog()
    lsClient.removeUserKeyLS('ratedProvider')
    lsClient.removeUserKeyLS('VCquestionnaireId')
    lsClient.removeUserKeyLS('healthServiceId')
    lsClient.removeUserKeyLS('patientTestId')
    lsClient.removeUserKeyLS('telehealthSessionId')
    lsClient.removeUserKeyLS('telehealth')
    lsClient.removeUserKeyLS('flowExecution')
    sessionStorage.removeItem('workflowListIds')
    clearTestKitFlow()
    dispatch(resetTelehealthStore())
    dispatch(resetQuestionnaireV2Store())
    dispatch(
      completeHealthService(
        healthServiceList[0].id,
        params,
        hasSessionThatCanCancel
          ? cancelSession
          : () => {
              window.location.reload()
            }
      )
    )
  }

  const returnToWaitingRoom = () => {
    navigate(paths.telehealth('waiting-room'))
  }

  const requestNewVisit = () => {
    const patientTestId = lsClient.getUserLSByKey('patientTestId')
    const healthServiceId = lsClient.getUserLSByKey('healthServiceId')

    const onSuccess = () => {
      navigate(paths.telehealth(TelehealthPages.BE_IN_TOUCH))
    }
    const data = {
      orderableTestId: null,
      patientTestId,
      allowSMS: true,
      healthServiceId,
    }
    dispatch(createSession(data, onSuccess))
  }
  const continueService = () => {
    const flowEx = lsClient.getUserLSByKey('flowExecution')
    if (flowEx) {
      actions.resumeFlow()
    } else {
      switch (hsType) {
        case HealthServiceType.VirtualConsult: {
          lsClient.setUserLS('VCquestionnaireId', hsTypeId)
          navigate(paths.symptomCheckerV2())
          break
        }
        case HealthServiceType.ConnectedTestV1:
        case HealthServiceType.ConnectedTest: {
          const testType = lsClient.getUserLSByKey('testType')
          lsClient.setUserLS('testId', hsTypeId)
          if (healthServiceList[0].connectedTests.length === 1) {
            const success = () => {
              navigate(paths.registerTest())
            }
            if (testType === 'orderableTest') {
              dispatch(getLabTestDetails(hsTypeId, success))
            } else {
              dispatch(getTestPanelDetails(hsTypeId, success))
            }
          } else if (healthServiceList[0].connectedTests.length > 1) {
            lsClient.setUserLS('patientTestId', hsTypeId)
            navigate(paths.testResultDetails(hsTypeId))
          }
          break
        }
        case HealthServiceType.TelehealthConsult:
        case HealthServiceType.TelehealthConsultV1: {
          lsClient.setUserLS('telehealthSessionId', hsTypeId)
          returnToWaitingRoom()
          break
        }
        case HealthServiceType.Prescription: {
          lsClient.setUserLS('PEquestionnaireId', hsTypeId)
          navigate(paths.symptomCheckerV2())
          break
        }
        default:
          break
      }
    }
  }
  const openMap = () => {
    let url = `http://maps.google.com/?q=Walgreens%205504%20Balboa%20Ave%20San%20Diego%2CCA`
    window.open(url, '_blank')
  }

  const dialogs = {
    cancel: {
      title: i18n.are_you_sure,
      body: i18n.cancel_health_service_message,
      onCancel: () => closeDialog(),
      cancelLabel: i18n.exit_button,
      onConfirm: endHealthService,
      confirmationLabel: i18n.cancel_health_service_button,
    },
    finish: {
      title: 'Complete this Health Service?',
      body: 'Your treatment plan will be available in Care Plan history.',
      onCancel: () => closeDialog(),
      cancelLabel: i18n.exit,
      onConfirm: endHealthService,
      confirmationLabel: 'Complete Service',
    },
  }

  const buttonActions = {
    continueService,
    returnToWaitingRoom,
    requestNewVisit,
    endHealthService,
    onFinish,
    cancelSession,
    gotoTreatmentPlan,
    reviewCarePlan,
    onCancel,
    openMap,
  }

  const renderStatusCard = () => {
    const step = _.find(statusCards(i18n, buttonActions), {
      view,
    })

    if (!step) return

    if (step.type === 'PROGRESS') {
      return (
        <ProgressView
          i18n={i18n}
          networkSettings={networkSettings}
          endHealthService={endHealthService}
          step={step}
          healthService={healthServiceList[0]}
          deliveryStep={encounter?.deliveryDetails?.delivery_status}
        />
      )
    }
    return (
      <SimpleView
        i18n={i18n}
        networkSettings={networkSettings}
        step={step}
        healthService={healthServiceList[0]}
        endHealthService={onCancel} // Remove this endHealthService once dev complete
      />
    )
  }

  return (
    <>
      {view && (
        <StatusCardWrapper>
          <Box
            className={classes.btnbase}
            onClick={() => refreshEncounter()}
            style={{
              borderRadius: radius,
            }}
          >
            <Box>{renderStatusCard()}</Box>
          </Box>
        </StatusCardWrapper>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  btnbase: {
    'padding': '48px',
    'marginBottom': '48px',
    'width': '100%',
    '-webkit-box-shadow': SHADOW,
    '-moz-box-shadow': SHADOW,
    'box-shadow': SHADOW,
    'color': theme.palette.primary.main,
    'boxSizing': 'border-box',
    'backgroundColor': '#fff',
    [theme.breakpoints.down(960)]: {
      padding: 24,
      marginBottom: 24,
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '24px',
      padding: '16px',
    },
  },
  image: {
    height: 148,
    [theme.breakpoints.down(960)]: {
      height: 112,
    },
    [theme.breakpoints.down(600)]: {
      height: 64,
    },
  },
  smallImage: {
    height: 84,
    [theme.breakpoints.down(600)]: {
      height: 64,
    },
  },
}))

const StatusCardWrapper = styled.div`
  margin: 8px 0px;
  width: 100%;
  @media (max-width: 600px) {
    margin-bottom: 24px;
    }
  }
`
