import { Dialog, Link as MUILink, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../features/translation'
import { Button } from '../../../ui'

export const MicrophoneRequiredDialog = (props: {
  open: boolean
  close: (state: boolean) => void
}) => {
  const { open, close } = props
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)

  return (
    <Dialog open={open}>
      <Box className={classes.schedulePageHolder}>
        <Box className={classes.scheduleWrapper}>
          <Box className={classes.headerWrapper}>
            <Typography
              variant="h4"
              color="primary"
              className={classes.title}
              align="left"
            >
              You must enable microphone access
            </Typography>
            <Typography variant="body1" className={classes.body}>
              In order to begin your proctoring session, you must enable
              microphone access. View these pages to find out how to enable
              microphone access in your browser.
            </Typography>
            <Box className={classes.linkBox}>
              <MUILink
                underline="always"
                color="primary"
                className={classes.pageLink}
                href="https://support.google.com/chrome/answer/2693767"
                target="_blank"
              >
                Google Chrome
              </MUILink>
              <Typography variant="body1" className={classes.pageLink}>
                •
              </Typography>
              <MUILink
                underline="always"
                color="primary"
                className={classes.pageLink}
                href="https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac"
                target="_blank"
              >
                Safari
              </MUILink>
              <Typography
                variant="body1"
                className={classes.pageLink}
                align="center"
              >
                •
              </Typography>
              <MUILink
                color="primary"
                underline="always"
                className={classes.pageLink}
                href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions"
                target="_blank"
              >
                Firefox
              </MUILink>
            </Box>
          </Box>
          <Box className={classes.lgButtonBox}>
            <Button
              onClick={() => close(false)}
              variant="outlined"
              className={classes.confirmButton}
            >
              {i18n.cancel_button}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  linkBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '10px',
  },
  title: {
    fontSize: '30px',
    marginBottom: '28px',
    width: '100%',
  },
  body: {
    color: '#7b7c7d',
    fontSize: '18px',
    width: '100%',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  lgButtonBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  confirmButton: {
    minWidth: 'unset',
    maxWidth: 'fit-content',
    padding: '10px 24px',
    width: '100%',
    height: '46px',
    [theme.breakpoints.down(576)]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  pageLink: {
    cursor: 'pointer',
    margin: '10px 5px',
    // width: '100%',
  },
  schedulePageHolder: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F7F7F7',
    width: 596,
    height: 357,
    [theme.breakpoints.down(500)]: {
      padding: '0px',
    },
  },
  scheduleWrapper: {
    width: '100%',
    margin: '40px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
}))
