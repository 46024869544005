import * as Yup from 'yup'
import { I18n } from '../../../../../../features/translation/types'

const contactFormValidation = (i18n: I18n, currentNetwork: any) => {
  if (!currentNetwork) return
  const {
    collectAddress,
    collectStreetAddress,
    collectStreetAddressRequired,
    collectState,
    collectStateRequired,
    collectZip,
    collectZipRequired,
  } = currentNetwork

  const line1 =
    collectAddress && collectStreetAddress && collectStreetAddressRequired
      ? Yup.string().required(i18n.required)
      : Yup.string()

  const state =
    collectAddress && collectState && collectStateRequired
      ? Yup.string().required(i18n.required)
      : Yup.string()

  const city =
    collectAddress && collectStreetAddress && collectStreetAddressRequired
      ? Yup.string().required(i18n.required)
      : Yup.string()

  const zip =
    collectAddress && collectZip && collectZipRequired
      ? Yup.string()
          .min(5, i18n.invalid_zip_code_error_message)
          .max(5, i18n.invalid_zip_code_error_message)
          .matches(/^(?!0{5})/, i18n.value_not_allowed)
          .required(i18n.required)
      : Yup.string()
          .min(5, i18n.invalid_zip_code_error_message)
          .max(5, i18n.invalid_zip_code_error_message)
          .matches(/^(?!0{5})/, i18n.value_not_allowed)

  return Yup.object().shape({
    line1,
    state,
    city,
    zip,
  })
}
export default contactFormValidation
