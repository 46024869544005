import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import Lottie from 'lottie-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CheckMark from '../../../assets/animations/checkmark-hc-blue-stopped.json'
import { selectLanguageContent } from '../../../features/translation'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { lsClient } from '../../../ls-client'
import { Button } from '../../../ui'
import { resetOutcomeStore } from '../../outcomes/model'
import { paths } from '../../paths'
import { resetUploadStore } from '../model'

export const UploadSuccessfulPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const i18n = useSelector(selectLanguageContent)
  const themeColor = useMUITheme().colors.primary

  const goNext = () => {
    const testId = lsClient.getUserLSByKey('patientTestId')
    dispatch(resetUploadStore())
    dispatch(resetOutcomeStore())
    navigate(paths.testResultDetails(testId))
  }

  const StyledAnimation = styled(Lottie)({
    '.lottie-major-color path': {
      fill: `${themeColor} !important`,
      stroke: `${themeColor} !important`,
    },
  })

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.loadingWrapper}>
        <Box className={classes.loadingContent}>
          <Box className={classes.animationContent}>
            <StyledAnimation animationData={CheckMark} loop={false} />
          </Box>
        </Box>
        <Typography className={classes.processText}>
          {i18n.scan_cassete_processed}
        </Typography>
        <Button onClick={goNext} className={classes.loadingButton}>
          {i18n.continue_button}
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  placedText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingContainer: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '500px',
  },
  animationContent: {
    textAlign: 'center',
    width: 200,
    marginTop: 50,
    [theme.breakpoints.down(600)]: {
      marginTop: 0,
    },
  },
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 48,
    height: '100%',
    maxWidth: 540,
    margin: '0 auto',
    textAlign: 'center',
  },
  loadingContent: {
    textAlign: 'center',
    marginTop: '150px',
  },
  processText: {
    fontSize: 26,
    color: '#282D37',
    margin: 10,
    fontWeight: 500,
  },
  loadingButton: {
    width: '100%',
    maxHeight: 48,
    marginBottom: 48,
    marginLeft: 15,
    marginRight: 15,
    [theme.breakpoints.down(600)]: {
      width: '90vw',
    },
  },
}))
