import { Box, Dialog } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as MoreInfo } from '../../../assets/icons/moreinfo.svg'
import { selectLanguageContent } from '../../../features/translation'
import { I18n } from '../../../features/translation/types'
import { hexToRGBA } from '../../../libs/helpers'
import {
  ContentBody,
  HTMLTextComponent,
  PageTitle,
} from '../../../libs/shared-components'
import { SelfAssessmentInstruction, TestResult } from '../../../types/tests'
import { Button, Checkbox, Typography } from '../../../ui'
import { ModuleHeader } from '../../../ui/templates/modules/moduleHeader'
import { selectSelfAssessmentInstructions } from '../../register-a-test-module/model'
import { selectLoading } from '../model'
import { CancelTestDialog } from './cancelTestDialog'

type SelfAssessmentInstructionDialog = SelfAssessmentInstruction | undefined

interface SelfReportingDialogProps {
  open: boolean
  setSelfAssessment: any
  selfAssessment: TestResult
  submitScan: () => void
  closeSelfAssessment: () => void
}

export const SelfReportingDialog = (props: SelfReportingDialogProps) => {
  const {
    open,
    setSelfAssessment,
    selfAssessment,
    submitScan,
    closeSelfAssessment,
  } = props
  const classes = useStyles()
  const i18n = useSelector(selectLanguageContent)
  const [infoDialogType, setInfoDialogType] =
    useState<SelfAssessmentInstructionDialog>(undefined)
  const [iAgree, setIAgree] = useState(false)
  const [cancelTestDialogOpen, setCancelTestDialogOpen] = useState(false)
  const isloading = useSelector(selectLoading)
  const selfAssessmentInstructions = useSelector(
    selectSelfAssessmentInstructions
  )

  return (
    <Dialog open={open} fullScreen>
      <ModuleHeader
        leftContentTitle={i18n.back_button}
        leftContentAction={closeSelfAssessment}
        rightContentTitle={i18n.exit_button}
        rightContentAction={() => setCancelTestDialogOpen(true)}
        color="#505358"
        progress={100}
      >
        <Box className={classes.content}>
          <Box className={classes.resultsContainer}>
            {selfAssessmentInstructions.map((inst, i) => (
              <Box className={classes.resultsColumn} key={`${i}-${inst.title}`}>
                <Box
                  className={
                    selfAssessment === inst.title
                      ? `${classes.results} selected`
                      : `${classes.results}`
                  }
                  onClick={() => setSelfAssessment(inst.title)}
                >
                  <Box>
                    <img
                      className={classes.img}
                      src={inst.imageURL}
                      alt={i18n.negative_text_info_title}
                    />
                  </Box>
                  <Typography className={classes.resultText}>
                    {inst.title}
                  </Typography>
                </Box>
                <MoreInfo
                  className={classes.infoButton}
                  onClick={() => setInfoDialogType(inst)}
                />
              </Box>
            ))}
          </Box>
          <Box className={classes.assetBox}>
            <PageTitle style={{ marginBottom: 8 }}>
              {i18n.self_reporting}
            </PageTitle>
            <ContentBody>{i18n.select_yout_test_cassette}</ContentBody>
          </Box>
          <Box className={classes.buttonWrapper}>
            <Box className={classes.checkbox}>
              <Checkbox
                label={i18n.i_agree_self_assessment_select_result}
                name="attestCorrect"
                onChange={() => setIAgree(!iAgree)}
                checked={iAgree}
              />
            </Box>
            <Button
              onClick={submitScan}
              className={classes.confirmButton}
              disabled={!iAgree || selfAssessment === 'unset' || isloading}
            >
              {i18n.preview_confirm_result}
            </Button>
          </Box>
        </Box>
      </ModuleHeader>
      <InfoDialog
        type={infoDialogType}
        setInfoDialogType={setInfoDialogType}
        i18n={i18n}
      />
      <CancelTestDialog
        open={cancelTestDialogOpen}
        setDialogOpen={setCancelTestDialogOpen}
      />
    </Dialog>
  )
}

const InfoDialog = (props: {
  type: SelfAssessmentInstructionDialog
  setInfoDialogType: any
  i18n: I18n
}) => {
  const { type, setInfoDialogType, i18n } = props
  const classes = useStyles()

  return (
    <Dialog open={type !== undefined}>
      <div className={classes.dialogWrapper}>
        <div className={classes.dialogContent}>
          <Typography className={classes.dialogTitle} align="left">
            {type?.title}
          </Typography>
          <Typography className={classes.body} align="left">
            {type && <HTMLTextComponent HTMLString={type.body} />}
          </Typography>
        </div>
        <div>
          <div onClick={() => setInfoDialogType(undefined)}>
            <CloseRoundedIcon style={{ color: '#757575' }} />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    display: 'flex',
    maxWidth: '500px',
    margin: '0px auto',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 78,
    [theme.breakpoints.down(600)]: {
      width: '90vw',
      marginTop: 48,
    },
  },
  resultsContainer: {
    marginBottom: '32px',
    display: 'flex',
    gap: 14,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(600)]: {
      gap: 10,
    },
  },
  resultsColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  results: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'justifyContent': 'center',
    'width': '155px',
    'height': '261px',
    'border': '1px solid #C4C4C4',
    'borderRadius': '11px',
    [theme.breakpoints.up(960)]: {
      '&:hover': {
        border: '1px solid #003C71',
        backgroundColor: hexToRGBA('#DEF5FF', 0.3),
        boxShadow: '0 4px 4px 0 rgba(0,0,0,.25)',
      },
    },
    '&.selected': {
      border: '1px solid #003C71',
      backgroundColor: '#DEF5FF',
    },
    [theme.breakpoints.down(600)]: {
      width: '112px',
      height: '187px',
    },
  },
  resultText: {
    marginTop: '10px',
    fontSize: '14px',
    color: '#505358',
    textAlign: 'center',
  },
  infoButton: {
    marginTop: '22px',
    height: '24px',
    width: '24px',
  },
  checkbox: {
    margin: '32px 0px 40px',
    alignSelf: 'flex-start',
  },
  checkboxLabel: {
    fontSize: '16px',
    color: '#505358',
  },
  checkboxActive: {},
  checkmark: {
    'padding': 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$checkboxActive': {
      color: theme.palette.primary.main,
    },
    '&$checkboxActive:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: '#505358',
    },
    '&$checkboxActive .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  confirmButton: {
    marginTop: 15,
    marginBottom: 35,
  },
  assetBox: {
    width: '100%',
  },
  img: {
    margin: '4px',
    height: '128px',
    [theme.breakpoints.down(600)]: {
      margin: '2px',
      height: '92px',
    },
  },
  title: {
    fontSize: 36,
    fontWeight: 600,
    color: '#282D37',
  },
  body: {
    fontSize: 16,
    color: '#757575',
    textAlign: 'left',
    fontWeight: 500,
  },
  infoDialog: {
    minHeight: '450px',
    minWidth: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(600)]: {
      minHeight: '450px',
      minWidth: '80vw',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 32,
    width: '100%',
    maxHeight: '250px',
    zIndex: 10,
    [theme.breakpoints.down(600)]: {
      margin: '0px auto',
      width: '90vw',
      padding: '20px 0px',
      position: 'fixed',
      bottom: 0,
      left: '5%',
      backgroundColor: '#FFF',
    },
    [theme.breakpoints.down(320)]: {
      padding: '0px 0px 16px',
    },
  },
  dialogWrapper: {
    padding: 16,
    display: 'flex',
  },
  dialogContent: {
    padding: '16px 8px 16px 32px',
    [theme.breakpoints.down(380)]: {
      padding: '4px 2px 4px 8px',
    },
  },
  dialogTitle: {
    fontSize: 24,
    fontWeight: 600,
    color: '#282D37',
  },
}))
