import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import alert from '../../assets/img/alert.svg'
import { paths } from '../paths'
import { selectLanguageContent } from '../../features/translation'
import { Button } from '../../ui'
import { selectActiveTenant } from '../tenant-selection/tenantSlice'
import { TenantSelectionDialog } from '../tenant-selection/tenant-selection-page'
import { lsClient } from '../../ls-client'
import { selectProfileData } from '../profile/model'

export const GuardianNotificationPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const i18n = useSelector(selectLanguageContent)
  const { fullName: userName } = useSelector(selectProfileData)
  const [tenantSelectionOpen, setTenantSelectionOpen] = React.useState(false)
  const reduxTenant = useSelector(selectActiveTenant)
  const tenant = lsClient.getUserLSByKey('tenantId') || reduxTenant

  const goNext = () => {
    navigate(paths.guardianInfo())
  }

  React.useEffect(() => {
    if (!tenant) {
      setTenantSelectionOpen(true)
    }
  }, [tenant])

  return (
    <Box className={classes.testWrapper}>
      <Box className={classes.testWrapper} style={{ flex: 1 }}>
        <Box className={classes.testingContent}>
          <img src={alert} alt='' style={{ width: '200px', color: '#FA9C14' }} />
          <Box className={classes.contentContainer}>
          <Box className={classes.infoText}>
              {i18n.legal_guardian_required}
          </Box>
            <Box className={classes.optionsWrapper}>
              <Box className={classes.optionWrapper}>
                <Typography component='div'>
                This account for <strong>{`${userName}`} </strong>
                requires a legal guardian who must provide basic information and consent to the 
                Caregiver & Legal Guardian Agreement
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>



      <Button onClick={goNext} variant='contained' className={classes.continueButton}>
        {i18n['continue_button']}
      </Button>    
      <TenantSelectionDialog open={tenantSelectionOpen} dialogOpen={setTenantSelectionOpen} />
    </Box>
  )
}

const SPACE_BETWEEN = 'space-between'
const useStyles = makeStyles((theme) => ({
  infoText: {
    margin: '48px 0',
    fontSize: 26,
    color: '#7B7C7D',
    alignSelf: 'center',
    textAlign: 'center',
    },
  unknownResult: {
    fontSize: 20,
    fontWeight: 600,
    color: '#000000',
  },
  positiveResult: {
    fontSize: 20,
    fontWeight: 600,
    color: '#900000',
  },
  negativeResult: {
    fontSize: 20,
    fontWeight: 600,
    color: '#006633',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    margin: '0 auto',
    minHeight: 200,
  },
  headerTitle: {
    fontSize: 20,
    color: '#003C71',
    fontWeight: 600,
  },
  testWrapper: {
    maxWidth: 540,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: SPACE_BETWEEN,
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
  },
  testingContent: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 50px)',
    [theme.breakpoints.down(767)]: {
      padding: '0 15px',
    },
  },
  optionsWrapper: {
    width: '100%',
  },
  optionWrapper: {
    padding: '20px 15px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: SPACE_BETWEEN,
    margin: '0 auto 20px',
    maxWidth: 400,
    color: '#7B7C7D',
    '&:first-child': {
      cursor: 'pointer',
    },
  },
  continueButton: {
    margin: '0 auto',
    maxHeight: 45,
    marginBottom: 10,
    maxWidth: 300,
  },
}))
