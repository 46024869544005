import { createSlice } from '@reduxjs/toolkit'
import { Dispatch, Store } from 'redux'
import api from '../../api'
import { ArticleResource,VideoResource } from './types'

interface resourcesSlice {
  videosLoading: boolean
  articlesLoading: boolean
  resourcesLoaded: boolean
  resourcesSearch: string
  videosList: VideoResource[]
  articlesList: ArticleResource[]
}

const initialStore: resourcesSlice = {
  videosLoading: false,
  articlesLoading: false,
  resourcesLoaded: false,
  resourcesSearch: '',
  videosList: [],
  articlesList: [],
}

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState: initialStore,
  reducers: {
    setVideosLoading(store, { payload }: { payload: boolean }) {
      store.videosLoading = payload
    },
    setArticlesLoading(store, { payload }: { payload: boolean }) {
      store.articlesLoading = payload
    },
    setVideos(store, { payload }: { payload: VideoResource[] }) {
      store.videosList = payload
      store.videosLoading = false
    },
    setArticles(store, { payload }: { payload: ArticleResource[] }) {
      store.articlesList = payload
      store.articlesLoading = false
    },
    setSearch(store, { payload }: { payload: string }) {
      store.resourcesSearch = payload
    },
    setResourcesLoaded(store, { payload }: { payload: boolean }) {
      store.resourcesLoaded = payload
    },
  },
})

const {
  setVideosLoading,
  setArticlesLoading,
  setVideos,
  setArticles,
  setResourcesLoaded,
} = resourcesSlice.actions

export const { setSearch } = resourcesSlice.actions

export const getVideoResources =
  (search: string) => async (dispatch: Dispatch, getStore: any) => {
    dispatch(setResourcesLoaded(false))
    const language = getStore().language
    dispatch(setVideosLoading(true))
    const { data } = await api.getVideoResourcesList(language, search)

    if (data) {
      const removeUnpublished = data.filter((r: VideoResource) => r.publish)
      dispatch(setResourcesLoaded(true))
      dispatch(setVideos(removeUnpublished))
    } else dispatch(setVideosLoading(false))
  }

export const getArticleResources =
  (search: string) => async (dispatch: Dispatch, getStore: any) => {
    dispatch(setResourcesLoaded(false))
    const language = getStore().language
    dispatch(setArticlesLoading(true))
    const { data } = await api.getArticleResourcesList(language, search)

    if (data) {
      const removeUnpublished = data.filter((r: ArticleResource) => r.publish)

      dispatch(setResourcesLoaded(true))
      dispatch(setArticles(removeUnpublished))
    } else dispatch(setArticlesLoading(false))
  }

export const selectVideosLoading = ({ resources }: RootStore) =>
  resources.videosLoading

export const selectArticlesLoading = ({ resources }: RootStore) =>
  resources.articlesLoading

export const selectVideosList = ({ resources }: RootStore) =>
  resources.videosList

export const selectArticlesList = ({ resources }: RootStore) =>
  resources.articlesList

export const selectResourcesLoaded = ({ resources }: RootStore) =>
  resources.resourcesLoaded

export const selectResourcesSearch = ({ resources }: RootStore) =>
  resources.resourcesSearch

export const resourcesReducer = resourcesSlice.reducer

export const resourcesReducerName = resourcesSlice.name

interface RootStore {
  [resourcesReducerName]: typeof initialStore
}
