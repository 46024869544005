import { CircularProgress, CircularProgressProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Lottie from 'lottie-react'
import React from 'react'
import SafeAnimation from '../../../assets/animations/SAFE_Logo_Animation.json'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { ColorWithHex } from '../../../types/general'

interface PageLoadingProps extends CircularProgressProps {
  appColor?: ColorWithHex
}

export const Launch = (props: { size?: number }) => {
  const { size = 200 } = props

  return (
    <div style={{ width: '100vw' }}>
      <div style={{ width: size, margin: '20vh auto' }}>
        <Lottie animationData={SafeAnimation} loop={false}  />
      </div>
    </div>
  )
}

export const Loading = (props: CircularProgressProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress {...props} />
    </div>
  )
}

export const PageLoading = (props: PageLoadingProps) => {
  const { isMobile } = useMUITheme()
  const { size = isMobile ? 75 : 100, appColor, color } = props
  const classes = useStyles()
  return (
    <div
      className={classes.loadingWrapper}
      style={{
        color: appColor ? appColor : color,
      }}
    >
      <Loading {...props} size={size} color="inherit" />
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flex: 1,
  },
  loadingWrapper: {
    height: 'calc(100vh - 64px)',
    width: '100%',
  },
})
