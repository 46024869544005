import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectLanguageContent } from '../../../features/translation'
import { lsClient } from '../../../ls-client'
import { Button } from '../../../ui'
import { onboardingStyles } from '../../_styles/registrationStyles'
import { getNetworkSettings, selectNetworkSettings } from '../../network/model'
import { paths } from '../../paths'
import { resetRegisterForm } from '../../register/model/registerSlice'
import {
  requestNewEmailLink,
  resetInviteStore,
  selectIsLoading,
  selectUser,
  setUserData,
} from '../model/inviteRegistrationSlice'
import { InvitedUser, InvitePages, ReinviteReason } from '../model/type'
import {
  ContactSupportFooter,
  InvitationPageWrapper,
  StoreLinks,
} from './shared'
import { EasableSupport } from './shared/support-link'

const easableAccounts = process.env.REACT_APP_PBP_ACCOUNT_NAMES

export const InviteMessagingPage = () => {
  const classes = onboardingStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [supportDialogOpen, setSupportDialogOpen] = useState(false)
  const currentNetwork = useSelector(selectNetworkSettings)
  const appName = sessionStorage.getItem('appName') || 'HealthCheck'
  const i18n = useSelector(selectLanguageContent)
  const isLoading = useSelector(selectIsLoading)

  const { type } = useParams<{ type: string }>()
  const showStores =
    type === InvitePages.ACCOUNT_EXISTS || type === InvitePages.THANK_YOU
  const showFooter =
    type === InvitePages.ACCOUNT_EXISTS || type === InvitePages.ERROR
  const userParams = useSelector(selectUser)
  const appStoreLink = sessionStorage.getItem('appleStoreId')
  const isEasableAccount = easableAccounts?.includes(userParams?.accountId)

  const sendNewEmailLink = (reinviteReason: ReinviteReason) => {
    const data = {
      ...userParams,
      reinviteReason,
    }
    dispatch(
      requestNewEmailLink(data, () =>
        navigate(paths.inviteMessaging(InvitePages.NEW_LINK_SENT))
      )
    )
  }

  useEffect(() => {
    const member = sessionStorage.getItem('member')
    const registerCompleteMarker = sessionStorage.getItem('registerComplete')
    if (!userParams.email && member) {
      const memberData = JSON.parse(atob(member)) as InvitedUser
      dispatch(setUserData(memberData))
      if (!currentNetwork) {
        dispatch(getNetworkSettings(memberData.tenantId))
      }
    } else if (
      (type === InvitePages.ACCOUNT_EXISTS && !member) ||
      (type === InvitePages.NEW_LINK_SENT && !member) ||
      (type === InvitePages.THANK_YOU && !registerCompleteMarker)
    ) {
      navigate(paths.inviteMessaging(InvitePages.SESSION_INVALID))
    }
  }, [])

  const messaging = () => {
    switch (type) {
      case InvitePages.ACCOUNT_EXISTS: {
        return (
          <Box className={classes.contentContainer}>
            {isEasableAccount ? (
              <Typography
                color="primary"
                variant="h1"
                align="left"
                className={classes.verifyHeader}
              >
                You already have an{' '}
                <span className={classes.supportNameEmphasis}>easable</span>{' '}
                account
              </Typography>
            ) : (
              <Typography
                color="primary"
                variant="h1"
                align="left"
                className={classes.verifyHeader}
              >
                You have already created an account
              </Typography>
            )}
            <Box className={classes.optionsWrapper}>
              {(currentNetwork?.appleAppDownloadUrl ||
                currentNetwork?.androidAppDownloadUrl) && (
                <Typography variant="h3" className={classes.subText}>
                  Download the mobile app to access your account and complete
                  registration
                </Typography>
              )}
            </Box>
          </Box>
        )
      }
      case InvitePages.THANK_YOU: {
        localStorage.clear()
        dispatch(resetInviteStore())
        dispatch(resetRegisterForm())
        return (
          <Box className={classes.contentContainer}>
            <Typography
              color="primary"
              variant="h1"
              align="left"
              style={{ fontWeight: 600, fontSize: '24px' }}
              className={classes.verifyHeader}
            >
              Thank you for registering!
            </Typography>
            <Box className={classes.optionsWrapper}>
              {(currentNetwork?.appleAppDownloadUrl ||
                currentNetwork?.androidAppDownloadUrl) && (
                <Typography className={classes.subText}>
                  Download the mobile app to access your account.
                </Typography>
              )}
            </Box>
          </Box>
        )
      }
      case InvitePages.SESSION_INVALID: {
        return (
          <Box className={classes.contentContainer}>
            <Typography
              color="primary"
              variant="h1"
              align="left"
              className={classes.verifyHeader}
            >
              This session is no longer valid
            </Typography>
            <Box className={classes.optionsWrapper}>
              <Typography className={classes.subText}>
                If you have already completed the registration process, please
                download the mobile app to access your account. Otherwise,
                please contact your administrator.
              </Typography>
            </Box>
          </Box>
        )
      }
      case InvitePages.TRY_AGAIN_LATER: {
        return (
          <Box className={classes.contentContainer}>
            <Typography
              color="primary"
              variant="h1"
              align="left"
              className={classes.verifyHeader}
            >
              Please try again later
            </Typography>
            <Box className={classes.optionsWrapper}>
              <Typography className={classes.subText}>
                We will send a new link to your email address.
              </Typography>
            </Box>
            <div className={classes.buttonWrapper}>
              <Button
                color="primary"
                className={classes.actionBtn}
                type="button"
                style={{ margin: '32px 0px' }}
                disabled={isLoading}
                onClick={() => sendNewEmailLink(ReinviteReason.INCOMPLETE)}
              >
                {i18n.invite_send_new_link}
              </Button>
            </div>
          </Box>
        )
      }
      case InvitePages.NEW_LINK_SENT: {
        return (
          <Box className={classes.contentContainer}>
            <Typography
              color="primary"
              variant="h1"
              align="left"
              className={classes.verifyHeader}
            >
              New link sent
            </Typography>
            <Box
              className={classes.optionsWrapper}
              style={{ marginBottom: 32 }}
            >
              <Typography className={classes.subText}>
                A new link has been sent to your email address.
              </Typography>
            </Box>
          </Box>
        )
      }
      case InvitePages.ERROR: {
        return (
          <Box className={classes.contentContainer}>
            <Typography
              color="primary"
              variant="h1"
              align="left"
              className={classes.verifyHeader}
            >
              An error occurred while processing your request
            </Typography>
            <Box
              className={classes.optionsWrapper}
              style={{ marginBottom: 32 }}
            >
              <Typography className={classes.subText}>
                Please contact your administrator.
              </Typography>
            </Box>
          </Box>
        )
      }
      default: {
        return <></>
      }
    }
  }

  return (
    <InvitationPageWrapper
      supportDialogOpen={supportDialogOpen}
      setSupportDialogOpen={setSupportDialogOpen}
    >
      <Box>
        {showStores && (
          <Helmet>
            <title>Download {appName} now</title>
            <meta name="apple-itunes-app" content={`app-id=${appStoreLink}`} />
          </Helmet>
        )}
        {messaging()}
        {showStores && <StoreLinks currentNetwork={currentNetwork} />}
        {userParams.email && showFooter && (
          <>
            {isEasableAccount ? (
              <EasableSupport />
            ) : (
              <>
                <Typography className={classes.subText}>
                  {i18n.invite_generic_error_message}
                </Typography>
                <ContactSupportFooter
                  setSupportDialogOpen={setSupportDialogOpen}
                  currentNetwork={currentNetwork}
                />
              </>
            )}
          </>
        )}
      </Box>
    </InvitationPageWrapper>
  )
}
