import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'
import { addHours, differenceInMinutes } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { lsClient } from '../../../ls-client'
import { Button, Typography } from '../../../ui'
import { DeliveryStatus } from '../../encounter/types'
import { progressSteps } from '../helpers/statusHelper'
import { StatusSnackbarProps } from '../types'

export const ProgressView = (props: StatusSnackbarProps) => {
  const { networkSettings, step, deliveryStep, endHealthService } = props
  const [progress, setProgress] = useState(20)
  const radius = networkSettings?.buttonStyle === 0 ? 8 : 24
  const classes = useStyles({ radius, progress })
  const [currentProgress, setCurrentProgress] = useState(0)
  const [title, setTitle] = useState(step.header)
  const [body, setBody] = useState(step.body)

  useEffect(() => {
    if (
      deliveryStep === 'delivered' &&
      !lsClient.getUserLSByKey('delivery_time')
    ) {
      const date = new Date().toISOString
      lsClient.setUserLS('delivery_time', date)
    }
  }, [deliveryStep])

  const getProgress = () => {
    switch (deliveryStep) {
      case DeliveryStatus.inTransit:
      case DeliveryStatus.confirmed: {
        setCurrentProgress(0)
        setProgress(20)
        break
      }
      case DeliveryStatus.outForDelivery: {
        setCurrentProgress(0)
        setProgress(50)
        setTitle('On its Way!')
        setBody('Your prescription is en route to your drop off location.')
        setTimeout(() => {
          setCurrentProgress(1)
        }, 1000)
        break
      }
      case DeliveryStatus.readyForPickup: {
        setCurrentProgress(1)
        setProgress(80)
        setTitle('Arrived!')
        setBody('Your prescription has arrived at the drop off location.')
        setTimeout(() => {
          setCurrentProgress(2)
        }, 1000)
        break
      }
      case DeliveryStatus.delivered: {
        setCurrentProgress(2)
        setProgress(100)
        setTitle('Delivered!')
        setBody(
          'Your delivery has been completed. We hope you feel better soon!'
        )
        setTimeout(() => {
          setCurrentProgress(3)
        }, 1000)
        break
      }
      default: {
        break
      }
    }
  }

  useEffect(() => {
    getProgress()
    const deliveryTime = lsClient.getUserLSByKey('delivery_time')
    // end the health service 3 hours after delivery notification is received
    if (deliveryStep === 'delivered' && deliveryTime) {
      const timeExpired =
        differenceInMinutes(addHours(new Date(deliveryTime), 3), new Date()) <=
        0
      if (timeExpired) {
        endHealthService()
      }
    }
  }, [deliveryStep])

  const getStepClass = (i: number) => {
    if (i > currentProgress) return classes.incomplete
    if (i < currentProgress) return classes.complete
    switch (i) {
      case 0:
        return classes.step0
      case 1:
        return classes.step1
      case 2:
        return classes.step2
      case 3:
        return classes.step3
      default:
        return classes.incomplete
    }
  }

  return (
    <>
      <Box className={classes.content}>
        {step.image}
        <Box className={classes.textContainer}>
          <Box className={classes.messageHeaderContainer}>
            <Typography variant="h2" className={classes.messageHeader}>
              {title}
            </Typography>
          </Box>
          <Typography variant="h2" className={classes.messageBody}>
            {body}
          </Typography>
          <Box className={classes.progress}>
            <Box className={classes.progressBarMask}>
              <div className={classes.deliveryIconContainer}>
                {progressSteps.map((s, i) => (
                  <div
                    key={s.key}
                    className={`${classes.deliveryIcon} ${getStepClass(i)}`}
                  >
                    {s.image}
                  </div>
                ))}
              </div>
              <Box className={classes.progressBar} />
            </Box>
          </Box>
        </Box>
      </Box>
      {step.actions.length > 0 && (
        <Box className={classes.buttonContainer}>
          {step.actions.map((action) => (
            <Button
              key={action.title}
              onClick={(event) => {
                event.stopPropagation()
                action.function()
              }}
              variant={action.variant}
              disabled={action.disabled}
              className={classes.button}
            >
              {action.title}
            </Button>
          ))}
        </Box>
      )}
    </>
  )
}

const EASE = 'all 2s ease-in-out'

const useStyles = makeStyles((theme) => ({
  step0: {
    fill: '#FFF',
    background: 'linear-gradient(to right, #02Ca47, #02C98E)',
  },
  step1: {
    fill: '#FFF',
    background: 'linear-gradient(to right, #00C86C, #00C785)',
  },
  step2: {
    fill: '#FFF',
    background: 'linear-gradient(to right, #01C89D, #01C8AC)',
  },
  step3: {
    fill: '#FFF',
    background: 'linear-gradient(to right, #01C7CC, #01C7BD)',
  },
  complete: {
    fill: '#FFF',
    background: '#00C93C',
  },
  incomplete: {
    fill: '#898989',
    background: '#E7E7E7',
  },
  deliveryIconContainer: {
    maxWidth: '1156px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  deliveryIcon: {
    'marginTop': '-13px',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'borderRadius': '50%',
    'height': '32px',
    'width': '32px',
    '&.colorFill': {
      fill: '#000',
    },
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    [theme.breakpoints.up(600)]: {
      height: 75,
      width: 75,
    },
  },
  hours: {
    color: '#FFF',
    fontWeight: 600,
    fontSize: 32,
    lineHeight: '32px',
    [theme.breakpoints.down(600)]: {
      fontSize: 20,
      lineHeight: '20px',
    },
  },
  remaining: {
    color: '#FFF',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '14px',
    [theme.breakpoints.down(600)]: {
      fontSize: 10,
      lineHeight: '10px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '8px',
  },
  messageHeaderContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '4px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: 48,
    [theme.breakpoints.down(960)]: {
      marginLeft: 16,
    },
    [theme.breakpoints.down(600)]: {
      marginLeft: 0,
      width: '75%',
    },
  },
  imageContainer: {
    'padding': '12px',
    '-webkit-mask-image':
      '-webkit-radial-gradient(circle, white 100%, black 100%)',
    'borderRadius': ({ radius }: { radius: number; progress: number }) =>
      radius,
    [theme.breakpoints.down(600)]: {
      height: 40,
      width: 40,
    },
  },
  icon: {
    fill: '#FFF',
    fontSize: '24px',
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
    },
  },
  headerIcon: {
    height: '26px',
    [theme.breakpoints.down(600)]: {
      height: '14px',
    },
  },
  progress: {
    padding: '32px 0px 32px',
    width: '100%',
    borderRadius: '3px',
  },
  progressBar: {
    'borderRadius': '3px',
    'marginTop': '-19px',
    'width': ({ progress }: { radius: number; progress: number }) =>
      `${progress}%`,
    'height': '6px',
    'background': 'linear-gradient(to right, #00C93C 50%, #00C6DD 100%)',
    'transition': EASE,
    '-webkit-transition': EASE /** Chrome & Safari **/,
    '-moz-transition': EASE /** Firefox **/,
    '-o-transition': EASE /** Opera **/,
  },
  progressBarMask: {
    borderRadius: '3px',
    width: '100%',
    height: '6px',
    background: '#E0E0E0',
  },
  button: {
    'margin': '0px auto',
    'maxWidth': 'fit-content',
    'maxHeight': 46,
    'padding': '8px 20px',
    'fontSize': '16px',
    'fontWeight': 600,
    '& span': {
      padding: '2px 4px',
      whiteSpace: 'noWrap',
    },
    '&.MuiButton-outlined': {
      'color': '#505358',
      'borderColor': '#505358',
      '&:hover': {
        backgroundColor: '#505358',
        borderColor: '#505358',
        color: '#FFF !important',
      },
    },
    '&.MuiButton-contained': {
      'backgroundColor': '#505358',
      'borderColor': '#505358',
      '&:hover': {
        borderColor: '#505358',
        backgroundColor: '#FFF',
        color: '#505358 !important',
      },
    },
    [theme.breakpoints.down(600)]: {
      marginTop: '8px',
      fontSize: '14px',
      fontWeight: 600,
      maxWidth: 'unset',
      maxHeight: '33px',
    },
  },
  smallButton: {
    'maxHeight': '42px',
    'maxWidth': '45%',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      fontWeight: 500,
      maxHeight: '33px',
      lineHeight: '14px',
      marginTop: 8,
    },
    '&.MuiButton-outlined': {
      color: '#505358',
      borderColor: '#505358',
    },
    '&.MuiButton-contained': {
      backgroundColor: '#505358',
      borderColor: '#505358',
    },
  },
  messageHeader: {
    fontWeight: 600,
    color: '#505358',
    fontSize: '32px',
    lineHeight: '40px',
    [theme.breakpoints.down(960)]: {
      fontSize: '28px',
      lineHeight: '32px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  messageBody: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#757575',
    marginTop: 12,
    [theme.breakpoints.down(960)]: {
      fontSize: '18px',
      lineHeight: '24px',
      marginTop: 6,
    },
    [theme.breakpoints.down(600)]: {
      lineHeight: '16px',
      fontSize: '14px',
    },
  },
}))
