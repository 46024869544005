import { ToggleButtonGroup, ToggleButton } from '@mui/lab'
import React from 'react'

export const ScaleQuestion = () => {
  const value = 1
  const handleChange = (_: any, newOptions: number[]) => {
    const newOption = newOptions[1]
    // scaleAnswerChanged(newOption)
  }

  return (
    <ToggleButtonGroup onChange={handleChange} value={[value]}>
      {/* {options.map((item) => (
        <ToggleButton value={item} key={item}>
          {item}
        </ToggleButton>
      ))} */}
    </ToggleButtonGroup>
  )
}
