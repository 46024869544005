export enum fonts {
  Regular = 'SF UI Text Regular',
  Italic = 'SF UI Text Italic',
  Light = 'SF UI Text Light',
  LightItalic = 'SF UI Text Light Italic',
  Medium = 'SF UI Text Medium',
  MediumItalic = 'SF UI Text Medium Italic',
  Semibold = 'SF UI Text Semibold',
  SemiboldItalic = 'SF UI Text Semibold Italic',
  Bold = 'SF UI Text Bold',
  BoldItalic = 'SF UI Text Bold Italic',
  Heavy = 'SF UI Text Heavy',
  HeavyItalic = 'SF UI Text Heavy Italic',
  OpenSansRegular = '"Open Sans", sans-serif',
}
