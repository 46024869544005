import Box from '@mui/material/Box'
import React from 'react'
import { shopifyStoreStyles } from '../../../pages/_styles/shopifyStoreStyles'
import { Typography } from '../../../ui'
import { I18n } from '../../translation/types'
import { Quantity } from './quantity'
import { TELEHEALTH_SHOPIFY_ITEM } from '../../../libs/string'

export const CartItem = (props: {
  product: any
  color: string
  i18n: I18n
  handleAdjustQuantity: (type: 'decrease' | 'increase', id: string) => void
}) => {
  const { product, color, i18n, handleAdjustQuantity } = props
  const classes = shopifyStoreStyles()

  return (
    <Box className={classes.cartItemWrapper}>
      <div className={classes.cartItemContentWrapper}>
        <div
          className={classes.cartImageWrapper}
          style={{ backgroundColor: color }}
        >
          {product.productImageUrl && (
            <img
              src={product.productImageUrl}
              className={classes.testImg}
              alt={product.productTitle}
            />
          )}
        </div>
        <div style={{ flex: 1 }}>
          <Box className={classes.testDetails}>
            <Typography className={classes.cartTextBold}>
              {product.productTitle}
            </Typography>
            <Typography className={classes.cartTestDesc}>
              {product.productSubtitle}
            </Typography>
          </Box>
          <div className={classes.priceAndQuantity}>
            <Quantity
              showTitle={false}
              i18n={i18n}
              productId={product.identifier}
              quantity={product.quantity}
              size="sm"
              canIncrement={product.productTitle !== TELEHEALTH_SHOPIFY_ITEM}
              canDecriment={true}
              handleChangeQuantity={handleAdjustQuantity}
            />
            <Typography className={classes.cartTextBold}>
              {product.productPrice.printed()}
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  )
}
