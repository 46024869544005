/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Box, Link } from '@mui/material'
import React from 'react'
import { HTMLTextComponent, PageTitle } from '../../../libs/shared-components'
import { Typography } from '../../../ui'
import { testInstructionStyles } from '../../_styles/testInstructionStyle'
import { CollectionProps } from '../types'

export const CollectionInstructionsOnly = (props: CollectionProps) => {
  const { current, openHelpDialog } = props
  const classes = testInstructionStyles()

  return (
    <Box className={classes.instructionContainer} id="instructionContainer">
      {current?.imageURL && (
        <div className={classes.imageWrapper}>
          <img
            className={classes.img}
            src={current.imageURL}
            alt={`Test Instructions ${current.title}`}
          />
        </div>
      )}
      {current?.videoURL && (
        <Box className={classes.videoContainer}>
          <video src={current.videoURL} controls className={classes.video} />
        </Box>
      )}
      <Box className={classes.assetBox} id="instructions">
        <PageTitle style={{ marginBottom: 8 }}>{current?.title}</PageTitle>
        <Typography className={classes.body}>
          {current?.body && <HTMLTextComponent HTMLString={current.body} />}
        </Typography>
        {current.additionalInformationBody && (
          <div className={classes.helpLinkWrapper}>
            <Link
              underline="always"
              role="button"
              color="primary"
              onClick={() => openHelpDialog()}
              style={{ cursor: 'pointer' }}
            >
              {current.additionalInformationLabel}
            </Link>
          </div>
        )}
      </Box>
    </Box>
  )
}
