import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Dialog, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguageContent } from '../../features/translation'
import { I18n } from '../../features/translation/types'
import { HTMLTextComponent } from '../../libs/shared-components'
import { lsClient } from '../../ls-client'
import { Loading, LogoImage, Typography } from '../../ui'
import { Wrapper } from '../../ui/templates/app-layout'
import { selectUser } from '../invite-web-registration/model/inviteRegistrationSlice'
import {
  getConsents,
  selectConsentError,
  selectConsents,
  selectConsentsFetched,
  selectLoading,
} from './model/model'
import { Term } from './model/type'

export const TermsAndConditionsPage = (props: {
  onClose?: () => void
  parentTenantId?: string
}) => {
  const { onClose, parentTenantId = '' } = props
  const dispatch = useDispatch()
  const loading = useSelector(selectLoading)
  const user = useSelector(selectUser)
  const consents = useSelector(selectConsents)
  const consentsFetched = useSelector(selectConsentsFetched)
  const consentError = useSelector(selectConsentError)
  const [showMore, setShowMore] = useState(false)
  const [firstNotice, setFirstNotice] = useState('')
  const [isFirstNoticeLong, setIsFirstNoticeLong] = useState(false)
  const classes = useStyles({ isFirstNoticeLong })
  const [selectedId, setSelectedId] = useState('')
  const [termsDetails, setTermsDetails] = useState<Term>()
  const i18n = useSelector(selectLanguageContent)

  const getTermsDetail = () => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return consents?.find((t) => t.title === selectedId)
  }

  useEffect(() => {
    const tenantId = lsClient.getUserLSByKey('tenantId')
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (user?.email && !consentsFetched) {
      //for web invite flow
      dispatch(getConsents(user.tenantId))
    } else if (parentTenantId && !consentsFetched) {
      dispatch(getConsents(parentTenantId))
    } else if (tenantId && !consentsFetched) {
      dispatch(getConsents(tenantId))
    }
  }, [user])

  useEffect(() => {
    if (consents.length > 0) {
      const introductionText = consents.filter((term) => term.header)
      setFirstNotice(introductionText[0]?.value)
    }
  }, [consents])

  useEffect(() => {
    if (firstNotice) {
      setIsFirstNoticeLong(firstNotice.split('</p>').length > 4)
    }
  }, [firstNotice])

  useEffect(() => {
    if (selectedId) {
      setTermsDetails(getTermsDetail())
    }
  }, [selectedId])

  const introductionToTermsDisplay = () => {
    if (!firstNotice && !consentError) return

    if (consentError) {
      return (
        <Typography
          variant="body1"
          align="center"
          style={{ margin: '32px auto', maxWidth: '320px' }}
        >
          {consentError}
        </Typography>
      )
    }

    const toggleMore = (show: boolean) => {
      if (!isFirstNoticeLong) return

      setShowMore(show)
    }
    return (
      <>
        {showMore ? (
          <Box onClick={() => toggleMore(false)}>
            <div
              dangerouslySetInnerHTML={{ __html: firstNotice }}
              className={classes.noSelect}
            />
            {isFirstNoticeLong && (
              <Typography className={classes.less}>less</Typography>
            )}
          </Box>
        ) : (
          <Box
            className={classes.lessContainer}
            onClick={() => toggleMore(true)}
          >
            <div
              dangerouslySetInnerHTML={{ __html: firstNotice }}
              className={classes.termsLess}
            />
            {isFirstNoticeLong && (
              <Typography className={classes.more}>more</Typography>
            )}
          </Box>
        )}
      </>
    )
  }
  return (
    <Wrapper>
      <Box className={classes.pageWrapper}>
        <Box className={classes.form}>
          <div className={classes.header}>
            <LogoImage />
            <IconButton
              onClick={onClose ? onClose : () => history.back()}
              className={classes.backButton}
              size="large"
            >
              <ArrowBackIcon style={{ color: '#757575' }} />
            </IconButton>
          </div>
          <Typography variant="h2" className={classes.title}>
            {i18n.terms_and_conditions.replace('\\n', ' ')}
          </Typography>
          {loading ? (
            <Box className={classes.loadingWrapper}>
              <Loading />
            </Box>
          ) : (
            <>
              {introductionToTermsDisplay()}
              {consents
                .filter(
                  (term) =>
                    !term.header && term.key !== 'ProctorTermsandConditions'
                )
                .map((consent) => (
                  <div
                    key={consent.title}
                    className={classes.listWrapper}
                    onClick={() => setSelectedId(consent.title)}
                  >
                    <Typography variant="h6" className={classes.termsTitle}>
                      {consent.title}
                    </Typography>
                    <ExpandMoreIcon className={classes.expandIcon} />
                  </div>
                ))}
            </>
          )}
        </Box>
      </Box>
      <Dialog open={Boolean(selectedId)} fullScreen>
        <FAQDetails
          i18n={i18n}
          termsDetails={termsDetails}
          close={() => setSelectedId('')}
        />
      </Dialog>
    </Wrapper>
  )
}

const FAQDetails = (props: {
  i18n: I18n
  termsDetails: Term | undefined
  close: () => void
}) => {
  const { i18n, termsDetails, close } = props
  const classes = useStyles({ isFirstNoticeLong: false })
  const titleIsLong =
    termsDetails?.title.length && termsDetails.title.length > 32

  return (
    <>
      <div className={classes.detailHeader}>
        <IconButton
          onClick={() => close()}
          className={classes.detailBackButton}
          size="large"
        >
          <ArrowBackIcon color="primary" />
        </IconButton>
        <Typography
          variant="h2"
          className={
            titleIsLong ? classes.longDetailTitle : classes.detailTitle
          }
        >
          {termsDetails?.title}
        </Typography>
      </div>
      <Box className={classes.detailContainer}>
        {termsDetails?.value ? (
          <HTMLTextComponent HTMLString={termsDetails.value} />
        ) : (
          <Typography>{i18n.no_data_loaded_error_message}</Typography>
        )}
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '120px auto',
    textAlign: 'center',
  },
  pageWrapper: {
    padding: '0px 16px',
    width: '90vw',
    maxWidth: '500px',
    [theme.breakpoints.down(600)]: {
      padding: 0,
    },
  },
  backButton: {
    position: 'absolute',
    marginLeft: '-10px',
    height: 42,
    width: 42,
    [theme.breakpoints.down(300)]: {
      height: 24,
      width: 24,
    },
  },
  detailBackButton: {
    position: 'absolute',
    marginTop: '-5px',
    marginLeft: '-10px',
    height: 42,
    width: 42,
    [theme.breakpoints.down(600)]: {
      marginLeft: '9px',
    },
    [theme.breakpoints.down(300)]: {
      height: 24,
      width: 24,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    paddingBottom: '32px',
  },
  detailHeader: {
    margin: '24px auto',
    width: '100%',
    maxWidth: '500px',
    marginTop: 42,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    paddingBottom: '9px',
    borderBottom: '1px solid #C4C4C4',
  },
  title: {
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '1rem',
    },
  },
  detailTitle: {
    fontWeight: 500,
    margin: '0px auto',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(600)]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down(300)]: {
      fontSize: '1rem',
    },
  },
  longDetailTitle: {
    fontWeight: 500,
    margin: '0px auto',
    color: theme.palette.primary.main,
    [theme.breakpoints.down(600)]: {
      paddingTop: '4px',
      fontSize: '1rem',
    },
  },
  termsLess: {
    '& span': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    'userSelect': 'none',
    '& p:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3))': {
      display: 'none',
    },
    '& p:nth-child(3)': {
      'display': '-webkit-box',
      'maxWidth': '12ch',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      'overflow': 'hidden',
    },
  },
  more: {
    position: 'relative',
    bottom: 38,
    marginLeft: '16ch',
    marginBottom: '-24px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
  },
  less: {
    marginBottom: '24px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
  },
  noSelect: {
    'userSelect': 'none',
    '& span': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
  },
  lessContainer: {
    userSelect: 'none',
    borderBottom: '1px solid #C4C4C4',
    marginBottom: ({ isFirstNoticeLong }: { isFirstNoticeLong: boolean }) =>
      isFirstNoticeLong ? '10px' : '0px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
    color: '#505358',
  },
  termsTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  form: {
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '100%',
    boxSizing: 'border-box',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  detailContainer: {
    maxWidth: '500px',
    overflow: 'auto',
    margin: '24px auto',
    [theme.breakpoints.down('xl')]: {
      padding: '32px 48px',
    },
    [theme.breakpoints.down(600)]: {
      padding: '0px',
      maxHeight: 'unset',
      margin: '0px 24px',
    },
  },
  listWrapper: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    borderBottom: '1px solid #C4C4C4',
  },
}))
