import { Box, useMediaQuery, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Lottie from 'lottie-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InterruptedMobile from '../../../assets/animations/telehealth-interruption-connection-error-mobile.json'
import Interrupted from '../../../assets/animations/telehealth-interruption-connection-error-web.json'
import { selectLanguageContent } from '../../../features/translation'
import { Typography } from '../../../ui'
import { PageWrapper } from '../../../ui/templates/page-wrapper'
import { paths } from '../../paths'
import { NetworkInterruption, setMemberInSession } from '../model'
import { telehealthStyles } from './styles'

interface NetworkInterruptionProps {
  type: NetworkInterruption
  endCall: () => void
}

export const NetworkInterruptionPage = (props: NetworkInterruptionProps) => {
  const { type, endCall } = props
  const classes = telehealthStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down(600))

  const i18n = useSelector(selectLanguageContent)

  const closeCall = () => {
    endCall()
    dispatch(setMemberInSession(false))
    navigate(paths.app.dashboard())
  }

  return (
    <PageWrapper showBack={false}>
      <Box onClick={closeCall} style={{ alignSelf: 'flex-end'}}>
        <CloseIcon color="primary" className={classes.closeIcon} />
      </Box>
      <Box className={classes.interruption}>
        <Box className={classes.animationContainer} style={{ margin: 0 }}>
          <Lottie
            animationData={matches ? InterruptedMobile : Interrupted}
            loop={true}
          />
        </Box>
        <Box className={classes.textContentContainer}>
          <Typography className={classes.title}>
            {type === NetworkInterruption.PROVIDER
              ? i18n.telehealth_clinician_interruption_title
              : i18n.telehealth_member_interruption_title}
          </Typography>
          <Typography className={classes.body}>
            {type === NetworkInterruption.PROVIDER
              ? i18n.telehealth_clinician_interruption_text
              : i18n.telehealth_member_interruption_text}
          </Typography>
        </Box>
      </Box>
    </PageWrapper>
  )
}
