export interface EncounterRecord {
  _id: string
  status: EncounterStatus
  deliveryDetails: DeliveryDetails
}

enum EncounterStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum EncounterDisposition {
  UNKNOWN = 'UNKNOWN',
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
  CANCELLED = 'CANCELLED',
  TREATMENT_READY = 'TREATMENT_READY',
  MISSED_CALL = 'MISSED_CALL',
  MISSED_CALL_NO_ANSWER = 'MISSED_CALL_NO_ANSWER',
  IN_REVIEW = 'IN_REVIEW',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_CALL = 'IN_CALL',
  COMPLETED = 'COMPLETED',
}

export interface DeliveryDetails {
  deliveryType: string
  deliveryStatus: DeliveryStatus
  deliveryMethod: DeliveryMethod
}

export enum DeliveryType {
  pharmaceuticals = 'pharmaceuticals',
}

export enum DeliveryMethod {
  delivery = 'delivery',
  pickupAtPharmacy = 'pickupAtPharmacy',
}

export enum DeliveryStatus {
  confirmed = 'confirmed',
  readyForPickup = 'readyForPickup',
  inTransit = 'inTransit',
  outForDelivery = 'outForDelivery',
  delivered = 'delivered',
  failure = 'failure',
  attemptedDelivery = 'attemptedDelivery',
}

export enum PrescriptionStatus {
  order_created = 'order_created',
  created = 'created',
}
