import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { lsClient } from '../../ls-client'
import { SelectableTenant } from './types'
import { getNetworkSettings } from '../network/model'

interface TenantSlice {
  isLoading: boolean
  tenantsList: any
  activeTenant: string
  error: string
}
const initialStore: TenantSlice = {
  isLoading: false,
  tenantsList: undefined,
  activeTenant: '',
  error: '',
}

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState: initialStore,
  reducers: {
    setLoading(store, { payload }: { payload: boolean }) {
      store.isLoading = payload
    },
    setTenantsList(store, { payload }: { payload: any }) {
      store.tenantsList = payload
    },
    setError(store, { payload }: { payload: any }) {
      store.error = payload
    },
    setActiveTenant(store, { payload }: { payload: any }) {
      store.activeTenant = payload
    },
  },
})
export const { setLoading, setActiveTenant, setError, setTenantsList } =
  tenantSlice.actions

export const getUsersAvailableTenants = () => async (dispatch: any) => {
  dispatch(setLoading(true))

  const { data, error } = await api.getUsersAvailableTenants()

  if (data) {
    //Remove the Template Tenant from the list
    const filteredList = data.filter(
      (t: SelectableTenant) => !t._id.includes('template')
    )
    if (filteredList.length === 1) {
      lsClient.setUserLS('tenantId', filteredList[0]._id)
      dispatch(setActiveTenant(filteredList[0]._id))
      dispatch(getNetworkSettings(filteredList[0]._id))
    }
    dispatch(setTenantsList(filteredList))
  } else if (error) {
    dispatch(setError('Sorry, we are unable locate a tenant for this user'))
    dispatch(setLoading(false))
  }
}

export const tenantSliceReducer = tenantSlice.reducer
export const tenantReducerName = tenantSlice.name
interface RootStore {
  [tenantReducerName]: typeof initialStore
}

export const selectTenantList = ({ tenant }: RootStore) => tenant.tenantsList

export const selectActiveTenant = ({ tenant }: RootStore) => tenant.activeTenant
