import { I18n } from '../../features/translation/types'
import { NetworkSettings } from '../network/type'

export enum RegisterTestStepType {
  INTRO = 'intro',
  CHOOSE_A_TEST = 'choose-a-test',
  TEST_INTRODUCTION = 'test-introduction',
  SCAN_CODE = 'scan-code',
  PRODUCT_ASSET_DETAILS = 'product-details',
  DISCLAIMER_DETAILS = 'disclaimer-details',
}

export interface RegisterTestProps {
  i18n: I18n
  networkSettings: NetworkSettings | null
  handleNext: () => void
  handleBack: () => void
}

export interface ScanError {
  title: string
  body: string
}
