import { translationReducerName, initialStore } from './main'

interface RootStore {
  [translationReducerName]: typeof initialStore
}

export const selectLanguage = ({ translation }: RootStore) =>
  translation.selectedLanguage

export const selectLanguageContent = ({ translation }: RootStore) =>
  translation.languageContent
