import '../../../../fonts.css'
import { Theme, useMediaQuery } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLanguageContent } from '../../../../features/translation'
import { selectNetworkSettings } from '../../../network/model'
import { selectOrderList } from '../../../test-details/model'
import { TestHistory } from '../testHistory'

export const TestHistoryCard = () => {
  const testlist = useSelector(selectOrderList)
  const i18n = useSelector(selectLanguageContent)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(600))
  const networkSettings = useSelector(selectNetworkSettings)

  if (testlist.length === 0) return null
  return (
    <TestHistory
      i18n={i18n}
      isMobile={isMobile}
      test={testlist[0]}
      networkSettings={networkSettings}
      isLatest={true}
    />
  )
}
