import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectTerms } from '../model'
import { Term as TTerm } from '../types'

const alphs = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

export const Terms = () => {
  const terms = useSelector(selectTerms)
  return (
    <>
      {terms.map((term, i) => (
        <Term key={term.key} term={term} letter={alphs[i]} />
      ))}
    </>
  )
}

const Term = ({ term, letter }: { term: TTerm; letter: string }) => {
  const classes = useStyles()
  return (
    <Accordion TransitionProps={{ timeout: 250 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.accSum}>
          {letter}. {term.title}
        </div>
      </AccordionSummary>
      <AccordionDetails className="pt-3 pb-3">
        <div dangerouslySetInnerHTML={{ __html: term.value }} />
      </AccordionDetails>
    </Accordion>
  )
}

const useStyles = makeStyles((theme) => ({
  accSum: {
    color: theme.palette.primary.main,
  },
}))
