import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-icon.svg'
import { StepType } from '../../../../constants'
import { selectLanguageContent } from '../../../../features/translation'
import { Button } from '../../../../ui'
import { onboardingStyles } from '../../../_styles/registrationStyles'
import { selectProfileIsLoading } from '../../../profile/model'
import { AddressCard } from '../../components/addressCard'
import {
  saveAddress,
  selectFoundAddress,
  setActive,
} from '../../model/registerSlice'
import { RegisterStepProps } from '../../model/type'

export const VerifyAddress = (props: RegisterStepProps) => {
  const { formik, isInvite } = props
  const classes = onboardingStyles()
  const type = StepType.SHIPPING_ADDRESS
  const isLoading = useSelector(selectProfileIsLoading)
  const values = formik.values[type]
  const foundAddress = useSelector(selectFoundAddress)
  const i18n = useSelector(selectLanguageContent)
  const [userAddress, setUserAddress] = useState(values.address)
  const [selected, setSelected] = useState(values.address)
  const dispatch = useDispatch()

  const goNext = async () => {
    await dispatch(saveAddress({ address: selected }))
    formik.submitForm()
  }

  return (
    <>
      {foundAddress.isAddressFound ? (
        <form className={classes.content}>
          <Typography className={classes.title} color="primary" variant="h1">
            {i18n.verify_address_header}
          </Typography>
          <Typography className={classes.body} variant="body1" align="left">
            {i18n.verify_address_instruction}
          </Typography>
          <AddressCard
            label={i18n.address_entered}
            selected={userAddress === selected}
            setSelected={() => setSelected(userAddress)}
            address={userAddress}
          />
          <AddressCard
            label={i18n.address_found}
            selected={foundAddress.address === selected}
            setSelected={() => setSelected(foundAddress.address)}
            address={foundAddress.address}
          />
          <div
            className={
              isInvite
                ? classes.inviteMobileButtonWrapper
                : classes.buttonWrapper
            }
          >
            <Button
              className={classes.actionBtn}
              onClick={goNext}
              disabled={!formik.isValid || isLoading}
              isLoading={formik.isSubmitting}
            >
              {i18n.confirm_address}
            </Button>
          </div>
        </form>
      ) : (
        <div className={classes.content}>
          <div className={classes.warningWrapper} style={{ marginBottom: 16 }}>
            <WarningIcon className={classes.warningIcon} />
          </div>
          <div>
            <Typography
              className={classes.faqHeader}
              color="primary"
              variant="h1"
              align="center"
            >
              {i18n.address_not_found}
            </Typography>
            <Typography className={classes.body} variant="body1" align="center">
              {i18n.address_not_found_instruction}
            </Typography>
          </div>
          <div
            className={
              isInvite
                ? classes.inviteMobileButtonWrapper
                : classes.buttonWrapper
            }
          >
            <Button
              className={classes.actionBtn}
              onClick={() => dispatch(setActive(7))}
              disabled={!formik.isValid}
              isLoading={formik.isSubmitting}
            >
              {i18n.try_again}
            </Button>
            <Button
              className={classes.actionBtn}
              onClick={() => formik.submitForm()}
              variant="outlined"
              isLoading={formik.isSubmitting}
            >
              {i18n.proceed_anyway}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}
