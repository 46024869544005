interface LastAssessmentOutcome {
  lastQuestionnaire: null
  latestTestStatus: number
  nbOfDaysFromLatestTest: number
  outcome: null
  overriddenBy: null
  overriddenOn: null
  overriddenOutcome: null
  overriddenReason: null
  questionnaireExpired: boolean
  symptomatic: boolean
  user: unknown
}
export interface LastAssessmentData{
  enumStatusID: number
  lastAssessment: string
  message: string
  status: string
  questionnaireExpired: string
}
export interface LastAssessment {
  //This for VCB1 and Admin clearance-status api
  lastAssessment: string
  status: string
  //
  data: LastAssessmentData | null,
  checked: string
  checkerId: string
  id: string
  latitude: null | number
  longitude: null | number
  outcome: LastAssessmentOutcome | null
  tenantId: string
  userId: string
}

export enum AssessmentState {
  //legacy dashboard card
  UNKNOWN = 'unknown',
  SYMPTOMATIC = 'symptomatic',
  ASYMPTOMATIC = 'asymptomatic',
  //SAFE Passport dashboard card
  TEST_NOT_TAKEN = 'TEST_NOT_TAKEN',
  OUT_OF_COMPLIANCE = 'OUT_OF_COMPLIANCE',
  IN_COMPLIANCE = 'IN_COMPLIANCE',
}
