import TimeExpired from '../../../assets/img/treatment-plan/time_expired.svg'
import { ConsultStatus } from '../../health-service-lite/types'
import { I18n } from '../../translation/types'
import { Graphic, OrderStatusStep, Variant } from '../types'
import { Images } from './statusHelper'

export const statusCards = (
  i18n: I18n,
  actions: { [key: string]: () => void },
) =>
  [
    {
      view: ConsultStatus.safe_created,
      type: 'SIMPLE',
      header: i18n.unassigned_video_status_card_title,
      body: i18n.unassigned_video_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images().UpcomingImage,
      mobileButtonFlex: 'column',
      actions: [
        {
          title: i18n.cancel_consultation,
          function: () => actions.onCancel(),
          variant: 'text' as Variant,
          smallButton: false,
          disabled: false,
        },
        {
          title: i18n.telehealth_return_to_waiting_room_button,
          function: () => actions.returnToWaitingRoom(),
          variant: 'contained' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.safe_dequeued,
      type: 'SIMPLE',
      header: i18n.in_review_status_card_title,
      body: '',
      graphic: 'IMAGE' as Graphic,
      image: Images().UpcomingImage,
      actions: [
        {
          title: i18n.telehealth_return_to_waiting_room_button,
          function: () => actions.returnToWaitingRoom(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.steadyMD_in_review,
      type: 'SIMPLE',
      header: i18n.in_review_status_card_title,
      body: i18n.in_review_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images().InReviewImage,
      actions: [],
    },
    {
      view: ConsultStatus.IN_CALL,
      type: 'SIMPLE',
      header: i18n.in_call_status_card_title,
      body: i18n.in_call_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images().PreparingImage,
      actions: [],
    },
    {
      view: ConsultStatus.CANCELLED,
      type: 'SIMPLE',
      header: i18n.telehealth_session_cancelled_card_title,
      body: i18n.telehealth_session_cancelled_card_message,
      graphic: 'IMAGE' as Graphic,
      image: Images().CancelledImage,
      actions: [],
    },
    {
      view: ConsultStatus.MISSED_CALL,
      type: 'SIMPLE',
      header: i18n.missed_call_status_card_title,
      body: i18n.missed_call_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images().CallMissedImage,
      actions: [
        {
          title: i18n.request_new_telehealth_visit,
          function: () => actions.requestNewVisit(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.safe_treatment_ready,
      type: 'SIMPLE',
      header: i18n.treatment_ready_status_card_title,
      body: i18n.treatment_ready_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images().PlanReadyImage,
      sanityImage: 'ReviewSummaryIcon',
      actions: [
        {
          title: i18n.treatment_ready_status_card_action,
          function: () => actions.gotoTreatmentPlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
        // {
        //   title: 'Start New Health Service',
        //   function: () => actions.onFinish(),
        //   variant: 'contained' as Variant,
        //   smallButton: true,
        //   disabled: false,
        // },
      ],
    },
    {
      view: ConsultStatus.NOT_ASSIGNED,
      type: 'SIMPLE',
      header: i18n.unassigned_phone_status_card_title,
      body: i18n.unassigned_phone_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images().UpcomingImage,
      actions: [
        {
          title: i18n.cancel_consultation,
          function: () => actions.onCancel(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.IN_PROGRESS,
      type: 'SIMPLE',
      header: i18n.default_status_card_title,
      body: i18n.default_status_card_text,
      graphic: 'TIMER' as Graphic,
      bgColor: '#22A2FF',
      image: () => null,
      mobileButtonFlex: 'row',
      actions: [
        {
          title: i18n.cancel_button,
          function: () => actions.onCancel(),
          variant: 'outlined' as Variant,
          smallButton: true,
          disabled: false,
        },
        {
          title: i18n.continue_button,
          function: () => actions.continueService(),
          variant: 'contained' as Variant,
          smallButton: true,
          disabled: false,
        },
      ],
    },

    {
      view: ConsultStatus.no_treatment_required,
      type: 'SIMPLE',
      header: 'Your provider has determined that you don’t require treatment',
      body: 'If your symptoms worsen, let us know',
      graphic: 'IMAGE' as Graphic,
      image: Images().NoTreatmentImage,
      actions: [],
    },
    {
      view: ConsultStatus.cancelled,
      type: 'SIMPLE',
      header: 'Your prescription was cancelled',
      body: 'This medication is unavailable in your area. Please contact your Primary Care Provider.',
      graphic: 'IMAGE' as Graphic,
      image: Images().CancelledImage,
      actions: [],
    },
    {
      view: ConsultStatus.alt_pharm_loc,
      type: 'SIMPLE',
      header: 'Medication was sent to a different pharmacy',
      body: 'View new pharmacy information in your care plan.',
      graphic: 'IMAGE' as Graphic,
      image: Images().AltPharmLocImage,
      actions: [
        {
          title: 'Review Care Plan',
          function: () => actions.reviewCarePlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.for_delivery,
      type: 'PROGRESS',
      header: 'A delivery request has been created!',
      body: 'You will be notified when your delivery is on its way.',
      graphic: 'IMAGE' as Graphic,
      image: Images().DeliveryImage,
      actions: [
        {
          title: i18n.review_care_plan,
          function: () => actions.gotoTreatmentPlan(),
          variant: 'outlined' as Variant,
          smallButton: false,
          disabled: false,
        },
        //Web does not have timed card logic in place, so adding this allow dismissing the service
        {
          title: 'Start New Health Service',
          function: () => actions.onFinish(),
          variant: 'contained' as Variant,
          smallButton: true,
          disabled: false,
        },
      ],
    },
    {
      view: ConsultStatus.delivery_failed,
      type: 'SIMPLE',
      header: 'Your prescription cannot currently be filled',
      body: 'We experienced a problem when trying to fill or deliver your prescription. Please contact your pharmacy for further information.',
      graphic: 'IMAGE' as Graphic,
      image: Images().DeliveryImage,
      actions: [],
      sanityImage: 'care_status_canceled',
    },
    {
      view: ConsultStatus.healthservice_expired,
      type: 'SIMPLE',
      header: i18n.expired_status_card_title,
      headerIcon: TimeExpired,
      body: i18n.expired_status_card_text,
      graphic: 'IMAGE' as Graphic,
      image: Images().ExpiredImage,
      actions: [],
    },
  ] as OrderStatusStep[]
