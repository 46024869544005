import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  clearHold,
  selectToken,
} from '../../../features/user/model/userTenantSlice'
import { MFAOptions } from '../../../features/user/types/mfa'
import { Wrapper } from '../../../ui/templates/app-layout'
import { paths } from '../../paths'
import { SetupTOTP } from '../../settings/tabs/security/mfa-setup/totp-option'

export const SetupMFAOptionPage = () => {
  const { type } = useParams<{ type: MFAOptions }>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const tokens = useSelector(selectToken)

  const handleNext = () => {
    sessionStorage.setItem('id_token', tokens.idToken as string)
    sessionStorage.setItem('access_token', tokens.accessToken as string)
    sessionStorage.setItem('refresh_token', tokens.refreshToken as string)
    dispatch(clearHold())
    navigate(paths.app.dashboard())
  }

  const handleBack = () => {
    navigate(paths.setupMFA())
  }

  return (
    <Wrapper>
      {type === MFAOptions.TOTP && (
        <SetupTOTP handleBack={handleBack} handleFirstSetup={handleNext} />
      )}
    </Wrapper>
  )
}
