import { styled } from '@mui/material'
import MUICheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React, { CSSProperties } from 'react'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { getColor } from '../../../libs/utils'
import { ColorWithHex } from '../../../types/general'

interface CheckboxProps {
  checked?: boolean
  onChange?: (e: React.ChangeEvent) => void
  label?: React.ReactNode
  color?: ColorWithHex
  name?: string
  disabled?: boolean
  style?: CSSProperties
}
export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  color = 'primary',
  disabled,
  name,
  style,
}) => {
  const { theme } = useMUITheme()

  return (
    <ControlWrapper
      control={
        <MUICheckbox
          id={`${name}-checkbox`}
          disabled={disabled}
          name={name}
          checked={checked}
          disableRipple
          onChange={onChange}
          inputProps={{ 'aria-label': 'checkbox' }}
          sx={{
            '& .MuiSvgIcon-root': {
              color: checked ? getColor(color, theme) : '#505358',
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& .Mui-checked:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiFormControlLabel-label': {
              color: '#757575',
              fontWeight: 500,
            },
          }}
        />
      }
      style={style}
      label={label}
    />
  )
}

const ControlWrapper = styled(FormControlLabel)({
  'display': 'flex',
  'justifyContent': 'flex-start',
  'alignItems': 'flex-start',
  'marginLeft': '-2px',
  '& span:nth-child(1)': {
    padding: '0px 4px 0px 0px',
  },
  '& span:nth-child(2)': {
    fontWeight: 500,
  },
})
