import { createSlice, Dispatch } from '@reduxjs/toolkit'
import api from '../../../api'
import { GetStore } from '../../../libs/redux-types'
import {
  TestResultsContract,
  TestResultsItemContract,
  TestResultsV2State,
} from './types'

const initialState: TestResultsV2State = {
  isLoading: false,
  list: [],
  activeItem: null,
  error: '',
  page: 1,
  isDone: false,
}
export const testResultsV2Slice = createSlice({
  name: 'testResultsV2',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setList: (state, { payload }) => {
      state.isLoading = false
      state.list = payload
      state.page = 1
      state.isDone = false
    },
    setListMore: (state, { payload: { list, page } }) => {
      state.page = page
      state.list = [...state.list, ...list]
      if (list.length === 0) state.isDone = true
    },
    setActiveItem: (state, { payload }) => {
      state.isLoading = false
      state.activeItem = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

const { setList, setLoading, setActiveItem, setListMore } =
  testResultsV2Slice.actions

export const getTestResults = () => async (dispatch: Dispatch) => {
  dispatch(setLoading(true))
  const res = (await api.getTestOrderResults({
    page: 0,
  })) as TestResultsContract
  dispatch(setList(res.data))
}

export const getTestResultsMore =
  () =>
  async (
    dispatch: Dispatch,
    getState: GetStore<typeof testResultsV2ReducerName, TestResultsV2State>
  ) => {
    const page = getState().testResultsV2.page + 1
    const res = (await api.getTestOrderResults({ page })) as TestResultsContract
    dispatch(setListMore({ list: res.data, page }))
  }

export const getTestResultsItem =
  (itemId: string) => async (dispatch: Dispatch) => {
    dispatch(setLoading(true))
    const res: TestResultsItemContract = (await api.getTestResultItem(
      itemId
    )) as TestResultsItemContract
    // if (error.response) dispatch(setError(error.response.data.Message))
    dispatch(setActiveItem(res.data))
  }

export const testResultsV2ReducerName = testResultsV2Slice.name
export const testResultsV2Reducer = testResultsV2Slice.reducer
