export enum SanityKeys {
  HealthService_VisitSummary = 'HealthService_VisitSummary',
  StatusCard_PrepairingVisitSummary = 'StatusCard_PrepairingVisitSummary',
  StatusCard_InReview = 'StatusCard_InReview',
  StatusCard_SyncAudioScheduled = 'StatusCard_SyncAudioScheduled',
  StatusCard_PrescriptionCannotBeFilled = 'StatusCard_PrescriptionCannotBeFilled',
  StatusCard_ErrorMissedVisit = 'StatusCard_ErrorMissedVisit',
  StatusCard_NoTreatment = 'StatusCard_NoTreatment',
  StatusCard_SummaryReady = 'StatusCard_SummaryReady',
  StatusCard_PickUpAtPharmacy = 'StatusCard_PickUpAtPharmacy',
  StatusCard_DeliveryOnTheWay = 'StatusCard_DeliveryOnTheWay',
  StatusCard_Expired = 'StatusCard_Expired',
  StatusCard_NotPreferredPharmacy = 'StatusCard_NotPreferredPharmacy',
  StatusCard_CancelPopUp = 'StatusCard_CancelPopUp',
  LeavingApp_InterstitialMessage = 'LeavingApp_InterstitialMessage',
}
