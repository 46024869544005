import styled from '@emotion/styled'
import { makeStyles } from '@mui/styles'

const font = 'Avenir Next Regular'

export const useStyles = makeStyles((theme) => ({
  infoText: {
    margin: '48px 0',
    fontSize: 26,
    color: '#7B7C7D',
    alignSelf: 'center',
    textAlign: 'center',
  },
  optionsWrapper: {
    width: '100%',
  },

  optionWrapper: {
    fontFamily: font,
    fontWeight: 'bold',
    color: '#525458',
    padding: '20px 15px',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #E6E7EA',
    margin: '0 auto 20px',
    maxWidth: 400,
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  testName: {
    color: '#54565B',
    fontSize: 16,
    fontFamily: font,
    lineHeight: '22px',
    fontWeight: 900,
  },
  testLab: {
    fontSize: 18,
    marginTop: 5,
    color: '#7B7C7D',
  },
  testDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  testPrice: {
    display: 'flex',
    fontSize: 16,
    fontFamily: font,
    lineHeight: '22px',
    fontWeight: 900,
  },
  testInfoBox: {
    border: '1px solid #E6E7EA',
    borderRadius: 10,
    margin: '0 auto',
    width: '100%',
    maxWidth: 400,
    [theme.breakpoints.down(450)]: {
      maxWidth: 'none',
    },
  },
  testTextType: {
    textTransform: 'uppercase',
    fontSize: 16,
    textAlign: 'left',
    maxWidth: 400,
    margin: '0 auto',
  },
  productText: {
    maxWidth: 400,
    textTransform: 'uppercase',
    textAlign: 'left',
    margin: '10px auto',
  },
  textWrapper: {
    width: 450,
    maxWidth: 'calc(100% - 40px)',
    margin: '0 auto',
    [theme.breakpoints.down(500)]: {
      width: 'auto',
    },
  },
  textFullName: {
    marginTop: 40,
    maxWidth: 400,
    textAlign: 'left',
    margin: '0 auto',
    fontSize: 18,
  },
  stepImage: {
    maxWidth: '100%',
    display: 'block',
  },
  testNameText: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'left',
    margin: '0 auto',
    maxWidth: 400,
  },
  button: {
    margin: 15,
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  scheduleWrapper: {
    margin: '0 auto',
    display: 'flex',
    padding: '0 15px',
    maxWidth: 400,
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
  },
  appointmentOptionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: '14px 14px 14px 20px',
    backgroundColor: '#fff',
    width: '100%',
    cursor: 'pointer',
    marginBottom: 20,
    boxSizing: 'border-box',
  },
  backIconWrapper: {
    transform: 'rotate(45deg)',
    position: 'absolute',
    left: 15,
    cursor: 'pointer',
  },
  backIcon: {
    color: '#003C71',
    cursor: 'pointer',
  },
  verifyingProcessText: {
    fontSize: 26,
    color: 'white',
    lineHeight: 1.334,
    textAlign: 'center',
  },
  contentStyles: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 540,
    width: '100%',
    backgroundColor: '#003C72',
    textAlign: 'center',
    fontFamily: font,
  },
  contentWrapper: {
    backgroundColor: '#F7F7F7',
  },
}))

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Content = styled.form`
  width: 100%;
  max-width: 540px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 32px 15px 15px 15px;
  box-sizing: border-box;
`
export const ContentInner = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 32px;
`

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 540px;
  align-self: center;
  align-items: center;
  width: 90%;
`

export const FormWrapper = styled.div`
  flex: 1;
  display: grid;
  grid-gap: 16px;
  max-width: 540px;
  grid-template-rows: repeat(10, min-content);
  width: 100%;
  box-sizing: border-box;
`
