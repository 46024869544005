import { makeStyles } from '@mui/styles'
import React from 'react'
import { useParams } from 'react-router-dom'
import { MFAOptions } from '../../../../../features/user/types/mfa'
import { SetupMFASMSPage } from './sms-option'
import { SetupTOTP } from './totp-option'

export const SetupMFA = () => {
  const classes = useStyles()
  const { selection } = useParams<{
    tab: string
    type: string
    selection: MFAOptions
  }>()

  return (
    <div className={classes.wrapper}>
      {selection === MFAOptions.TOTP && <SetupTOTP />}
      {selection === MFAOptions.SMS && <SetupMFASMSPage />}
    </div>
  )
}

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    minWidth: 'unset',
  },
})
