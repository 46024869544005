import { Box } from '@mui/material'
import Lottie from 'lottie-react'
import React from 'react'
import { useSelector } from 'react-redux'
import providerReview from '../../../../assets/animations/provider_review.json'
import { selectLanguageContent } from '../../../../features/translation'
import { Typography } from '../../../../ui'
import { telehealthStyles } from '../styles'

export const ProviderReview = () => {
  const classes = telehealthStyles()
  const i18n = useSelector(selectLanguageContent)

  return (
    <>
      <Box className={classes.animationContainer}>
        <Lottie animationData={providerReview} loop={true} />
      </Box>
      <Box className={classes.textContentContainer}>
        <Typography className={classes.title}>
          {i18n.provider_reviewing_title}
        </Typography>
        <Typography className={classes.body}>
          {i18n.provider_reviewing_message}
        </Typography>
      </Box>
    </>
  )
}
