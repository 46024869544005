import { Box } from '@mui/material'
import React from 'react'
import { HTMLTextComponent } from '../../../../libs/shared-components'
import { Button, Typography } from '../../../../ui'
import { dashboardCardStyles } from '../../../_styles/dashboardCardStyles'
import { CardProps } from './types'

export const TransparentCard = (props: CardProps) => {
  const { button, handleClick } = props
  const classes = dashboardCardStyles()

  return (
    <Box className={classes.transparentCard}>
      {button.cardImageUrl && (
        <Box className={classes.transparentImageContainer}>
          <img src={button.cardImageUrl} className={classes.image} />
        </Box>
      )}
      <Box className={classes.transparentButtonCard}>
        <Typography className={classes.dashboardButtonTitle}>
          <HTMLTextComponent HTMLString={button.cardTitle} />
        </Typography>
        <Typography className={classes.dashboardButtonBody}>
          <HTMLTextComponent HTMLString={button.cardBody} />
        </Typography>
        {button.buttonAction && (
          <div className={classes.buttonWrapper}>
            <Button
              style={{
                backgroundColor: button.buttonColor
                  ? button.buttonColor
                  : 'unset',
                borderColor: button.buttonColor ? button.buttonColor : 'unset',
                color: button.buttonLabelColor
                  ? button.buttonLabelColor
                  : 'unset',
              }}
              onClick={handleClick}
              className={classes.confirmButton}
              disabled={!button.buttonEnabled}
              variant="contained"
            >
              {button.buttonLabel}
            </Button>
          </div>
        )}
      </Box>
    </Box>
  )
}
