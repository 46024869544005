import React from 'react'
import { useSelector } from 'react-redux'
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp'
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp'
import { makeStyles } from '@mui/styles'
import { Typography } from '../../../ui'
import { selectLatestResult } from '../model/userTenantSlice'


export const ResultAssessmentMessage = () => {
  const classes = useStyles()
  const latestResult = useSelector(selectLatestResult)

  const assessmentMessage = (status: string) => {
    switch (status) {
      case "NOT_CLEARED": {
          return (
            <>
            <ErrorSharpIcon className={classes.errorIcon} />
            <Typography variant="subtitle2">
              You are not cleared for work
            </Typography>
          </>
          )
        }
      case "CLEARED": {
        return (
          <>
          <CheckCircleSharpIcon className={classes.checkIcon} />
          <Typography variant="subtitle2">You are cleared for work</Typography>
        </>
        )
      }
      default : {
        return (
          <>
          </>
        )
      }
    }
  }
  return (
    <div className={classes.symptomaticMessage}>
      {assessmentMessage(latestResult.status)}
    </div>
  )
}

const useStyles = makeStyles({
  checkIcon: {
    color: '#129b4b',
    width: '1.25em',
    height: '1.25em',
  },
  errorIcon: {
    width: '1.25em',
    height: '1.25em',
  },
  symptomaticMessage: {
    alignItems: 'center',
    color: '#FFF !important',
    width: '100%',
    display: 'grid',
    padding: '8px',
    gridGap: '8px',
    gridTemplateColumns: 'min-content 1fr min-content',
    '@media (max-width: 500px)': {
      paddingBottom: '16px',
    },
  },
})
