import { TouchEvent, useState } from 'react'

interface SwipeInput {
  onSwipedLeft: () => void
  onSwipedRight: () => void
}

interface SwipeOutput {
  onTouchStart: (e: TouchEvent) => void
  onTouchMove: (e: TouchEvent) => void
  onTouchEnd: () => void
}

export const useSwipe = (input: SwipeInput): SwipeOutput => {
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const minSwipeDistance = 50

  const onTouchStart = (e: TouchEvent) => {
    setTouchEnd(0)
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX)
  } 

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      input.onSwipedLeft()
    }
    if (isRightSwipe) {
      input.onSwipedRight()
    }
  }

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  }
}

export const usePullDown = (): SwipeOutput => {

  let pStart = { x: 0, y: 0 }
  let pStop = { x: 0, y: 0 }


  function swipeCheck() {
    let changeY = pStart.y - pStop.y
    let changeX = pStart.x - pStop.x
    if (isPullDown(changeY, changeX)) {
      window.location.reload()
    }
  }

  function isPullDown(dY: number, dX: number) {
    // methods of checking slope, length, direction of line created by swipe action
    return (
      dY < 0 &&
      ((Math.abs(dX) <= 100 && Math.abs(dY) >= 300) ||
        (Math.abs(dX) / Math.abs(dY) <= 0.3 && dY >= 60))
    )
  }

  const onTouchStart = (e: TouchEvent) => {
    const touch = e.targetTouches[0]
    pStart.x = touch.screenX
    pStart.y = touch.screenY
  }

  const onTouchMove = (e: TouchEvent) => {
    const touch = e.changedTouches[0]
    pStop.x = touch.screenX
    pStop.y = touch.screenY
  }

  const onTouchEnd = () => {
    swipeCheck()

  }

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  }
}
