import React, { useEffect, useState } from 'react'
import { formatDate } from '../../../libs/helpers'
import { Loading, Select } from '../../../ui'
import { PageTitle } from '../../../ui/templates/generic-screens/helpers/styled-components'
import {
  DetailListWrapper,
  NoContent,
  SelectWrapper,
} from '../shared/components/shared-components'
import {
  HealthRecordProps,
  HealthRecordType,
  VitalComponentCodes,
  Vitals,
  VitalsItem,
  vitalTitles,
} from '../type'

export const VitalsListPage = (props: HealthRecordProps) => {
  const { i18n, state, type, title } = props

  if (type !== HealthRecordType.VITAL) return null

  const [listedVitals, setListedVitals] = useState<Vitals | undefined>(
    undefined
  )

  useEffect(() => {
    setListedVitals(state.listState[type][0])
  }, [state])

  const handleChangeSelected = (e: any) => {
    const date = e.target.value
    const newState = state.listState[type].find(
      (o: Vitals) => o.effectiveDateTime === date
    )
    if (newState) {
      setListedVitals(newState)
    }
  }

  const displayVitals = () => {
    if (!listedVitals) return null

    if (!listedVitals.effectiveDateTime) {
      return (
        <NoContent
          title={i18n.patient_records_no_record.replace('%@', title)}
        />
      )
    }
    const vitalKeys = Object.keys(listedVitals)
    const values = Object.values(listedVitals) as VitalsItem[]
    return (
      <>
        {values.map((record: VitalsItem, i) => {
          // ignore the non-vitals keys
          if (!record.vitalCode) return null
          let vitalInputValue = `${record.vitalQuantity?.display} ${record.vitalQuantity?.codeValue}`
          // Todo Add additional split value components once known - e.g. ft/in
          // eslint-disable-next-line sonarjs/no-collapsible-if
          if (record.vitalComponent) {
            if (
              record.vitalComponent[0].vitalQuantity?.codeValue ===
              VitalComponentCodes.systolic_diastolic
            )
              vitalInputValue = `${record.vitalComponent[0].vitalQuantity?.display}/${record.vitalComponent[1].vitalQuantity?.display} ${record.vitalComponent[0].vitalQuantity?.codeValue}`
          }

          return (
            <DetailListWrapper
              key={record.id}
              id={record.id}
              title={i18n[vitalTitles[vitalKeys[i]]]}
              body={`${vitalInputValue} | ${formatDate(
                listedVitals.effectiveDateTime,
                false,
                undefined,
                'MMM. d, y'
              )}`}
              handleClick={() => {}}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      <SelectWrapper>
        <PageTitle style={{ margin: 0 }}>
          {listedVitals?.effectiveDateTime ? `${title} | ` : title}
        </PageTitle>
        {listedVitals?.effectiveDateTime && state.listState[type] && (
          <Select
            options={state.listState[type]?.map((o: Vitals) => {
              const option = {
                value: o.effectiveDateTime,
                label: formatDate(
                  formatDate(o.effectiveDateTime, false, undefined, 'MMM. d, y')
                ),
              }
              return option
            })}
            value={listedVitals.effectiveDateTime}
            onChange={handleChangeSelected}
          />
        )}
      </SelectWrapper>
      {state.isLoading ? (
        <Loading size={100} style={{ marginTop: '20vh' }} />
      ) : (
        displayVitals()
      )}
    </>
  )
}
