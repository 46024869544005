import { useEffect, useState } from 'react'
import NotFound from '../../assets/img/sanity_not_found.jpg'
import { Loading } from '../../ui'
import SanityApiStore from './sanity-api-store'

interface SanityImageProps {
  imageKey: string
  width?: number
  height?: number
  alt?: string
}

const sanity = SanityApiStore()

export const SanityImage = ({ imageKey, ...props }: SanityImageProps) => {
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    const loadImages = async () => {
      await sanity.getImages()
      await sanity.getImageCallback(imageKey, setImageUrl)
    }
    loadImages()
  }, [])

  if (sanity.isLoading()) return <Loading />

  if (!imageUrl)
    return (
      <img src={NotFound} className="sanity-image" {...props} alt="Not found" />
    )

  return <img src={imageUrl} className="sanity-image" {...props} alt="" />
}

export default SanityImage
