import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined'
import React from 'react'
import { capitalizeString } from '../../../libs/utils'
import { Address } from '../../../types/user'
import { RoundedCheckbox, Typography } from '../..'
import { onboardingStyles } from '../../../pages/_styles/registrationStyles'

interface CardProps {
  address: Address
  label: string
  selected: boolean
  setSelected: (address: Address) => void
}

export const AddressCard = (props: CardProps) => {
  const classes = onboardingStyles()

  const { address, label, selected, setSelected } = props

  return (
    <div
      className={`${selected ? classes.cardSelected : classes.card} ${
        classes.cardBase
      }`}
      onClick={() => setSelected(address)}
    >
      <div className={classes.innerWrapper}>
        <div className={classes.cardLeft}>
          <Typography
            className={classes.cardTitle}
            color="primary"
            variant="h1"
          >
            {label}
          </Typography>
          <div className={classes.cardBody}>
            <HouseOutlinedIcon
              style={{ alignSelf: 'flex-start', color: '#505358' }}
            />
            <div className={classes.addressContainer}>
              <Typography
                variant="subtitle2"
                color="primary"
                className={classes.cardAddress}
              >
                {capitalizeString(address.line1)} <br />
                {address.line2 && (
                  <>
                    {capitalizeString(address.line2)} <br />
                  </>
                )}
                {capitalizeString(address.city)}, {address.state} {address.zip}
              </Typography>
            </div>
          </div>
        </div>
        <RoundedCheckbox
          checked={selected}
          color="primary"
          onChange={() => setSelected(address)}
        />
      </div>
    </div>
  )
}
