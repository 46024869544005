import moment from 'moment'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { capitalizeString } from '../../libs/utils'
import { Typography } from '../../ui'
import { facilityStyles } from '../_styles/facilityStyles'
import {
  FedExDaysOfWeekUIConversion,
  FedExLocation,
  FedExLocationHours,
} from './types'

export const hoursOfOperation = (hours: any[]) => {
  const classes = facilityStyles()
  const today = moment().format('ddd').toLowerCase()
  const { isMobile } = useMUITheme()
  let operationalHours: React.ReactNode

  const getNextOpenTime = (today: string) => {
    let todayIndex = hours.findIndex(
      (h: any) => h.dayOfWeek.toLowerCase() === today
    )

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < hours.length; i++) {
      if (todayIndex === hours.length - 1) {
        todayIndex = -1
      }
      todayIndex++

      if (hours[todayIndex]?.hours?.[0]?.begins) {
        if (i === 0) {
          operationalHours = (
            <Typography className={`${classes.locationText} ${classes.closed}`}>
              {`Closed`}
              <span className={classes.info}>
                {`${isMobile ? '\n' : ' | until '}${moment(
                  hours[todayIndex]?.hours?.[0]?.begins
                ).format('h A')} tomorrow`}
              </span>
            </Typography>
          )
          return true
        }
        operationalHours = (
          <Typography className={`${classes.locationText} ${classes.closed}`}>
            {`Closed`}
            <span className={classes.info}>
              {`${isMobile ? '\n' : ' | until '}${moment(
                hours[todayIndex]?.hours?.[0]?.begins
              ).format('h A')} ${capitalizeString(
                hours[todayIndex]?.dayOfWeek
              )}`}
            </span>
          </Typography>
        )
        return true
      }
    }
  }

  const operation = () => {
    const todaysHours = hours.find(
      (date: any) => today === date.dayOfWeek.toLowerCase()
    )
    if (todaysHours?.hours) {
      //Facility is Open Today
      todaysHours.hours.forEach((time: any) => {
        const workingNow = moment().isBetween(
          time?.begins,
          time?.ends,
          'hours',
          '[]'
        )

        if (workingNow) {
          const timeRemaining = Date.parse(time?.ends) - Date.now()
          // Closing soon
          if (timeRemaining < 3600000 && Date.now() < Date.parse(time?.ends)) {
            operationalHours = (
              <Typography
                className={`${classes.locationText} ${classes.closed}`}
              >
                {`Closing in ${Math.round(timeRemaining / 60000)} min`}
                <span className={classes.info}>
                  {`${isMobile ? '\n' : ' | '}${moment(time?.begins).format(
                    'h A'
                  )} - ${moment(time?.ends).format('h A')}`}
                </span>
              </Typography>
            )
          } else if (timeRemaining > 0) {
            // Within Regular business hours
            operationalHours = (
              <Typography className={`${classes.locationText} ${classes.open}`}>
                {`Open until ${moment(time?.ends).format('h A')}`}
              </Typography>
            )
          } else {
            // To catch < 1 after closing
            return getNextOpenTime(today)
          }
          return true
        }
        if (Date.parse(time?.begins) - Date.now() < 3600000) {
          // After hours
          if (Date.now() > Date.parse(time?.begins)) {
            return getNextOpenTime(today)
          }
          // Opening soon
          operationalHours = (
            <Typography
              className={`${classes.locationText} ${classes.openSoon}`}
            >
              {`Opening in ${Math.round(
                (Date.parse(time?.begins) - Date.now()) / 60000
              )} min`}
              <span className={classes.info}>
                {`${isMobile ? '\n' : ' | '}${moment(time?.begins).format(
                  'h A'
                )} - ${moment(time?.ends).format('h A')}`}
              </span>
            </Typography>
          )
          return true
        }
        if (Date.parse(time?.begins) > Date.now()) {
          // Closed now, but will open later in day
          operationalHours = (
            <Typography className={`${classes.locationText} ${classes.closed}`}>
              {`Closed now`}
              <span className={classes.info}>
                {`${isMobile ? '\n' : ' | '}${moment(time?.begins).format(
                  'h A'
                )} - ${moment(time?.ends).format('h A')}`}
              </span>
            </Typography>
          )
          return true
        }
      })
    } else {
      return getNextOpenTime(today)
    }

    return true
  }

  return operation() && operationalHours
}

export const RenderFedExWorkingHours = (props: {
  officeData: FedExLocation
  i18n: any
  withDivider: boolean
  textClass: string
}) => {
  const { officeData, i18n, withDivider, textClass } = props
  const classes = facilityStyles()
  const getOpenDays = () => {
    const weekly = [] as [string[], string][]

    if (officeData.normalHours && officeData.normalHours.length > 0) {
      let daily = [[], ''] as [string[], string]
      officeData.normalHours.forEach((day: FedExLocationHours) => {
        if (day.operationalHours === 'OPEN_BY_HOURS') {
          if (!day.hours) return
          const beginTime = moment(day.hours[0].begins.replace('Z', '')).format(
            'LT'
          )
          const endTime = moment(day.hours[0].ends.replace('Z', '')).format(
            'LT'
          )
          const hours = `${beginTime} - ${endTime}`
          if (hours === daily[1]) {
            daily[0].push(FedExDaysOfWeekUIConversion[day.dayOfWeek])
          } else if (daily[1] === '') {
            daily[0].push(FedExDaysOfWeekUIConversion[day.dayOfWeek])
            daily[1] = hours
          } else if (daily[1] !== '') {
            const thisSet = [] as any
            thisSet.push(daily.map((a) => a))
            weekly.push(thisSet[0])
            daily[0] = [FedExDaysOfWeekUIConversion[day.dayOfWeek]]
            daily[1] = hours
          }
        } else {
          if (daily[1] === 'Closed') {
            daily[0].push(FedExDaysOfWeekUIConversion[day.dayOfWeek])
          } else if (daily[1] === '') {
            daily[0].push(FedExDaysOfWeekUIConversion[day.dayOfWeek])
            daily[1] = 'Closed'
          } else if (daily[1] !== '') {
            const thisSet = [] as any
            thisSet.push(daily.map((a) => a))
            weekly.push(thisSet[0])
            daily[0] = [FedExDaysOfWeekUIConversion[day.dayOfWeek]]
            daily[1] = 'Closed'
          }
        }
      })
      weekly.push(daily)
    }

    return (
      <>
        {weekly.map((list) => (
          <div
            key={list[1]}
            className={
              withDivider
                ? `${classes.dailyHours} withDivider`
                : classes.dailyHours
            }
          >
            <Typography className={textClass}>
              {list[0].length > 1 ? (
                <>{`${list[0].shift()} - ${list[0].pop()}`}</>
              ) : (
                <>{list[0].shift()}</>
              )}
            </Typography>
            {withDivider && <Typography className={textClass}>|</Typography>}
            <Typography className={textClass}>{list[1]}</Typography>
          </div>
        ))}
      </>
    )
  }

  return (
    <div
      className={classes.moreInfoBox}
      style={{ flexDirection: 'column', alignItems: 'normal' }}
    >
      {getOpenDays()}
    </div>
  )
}
