import Lottie from 'lottie-react'
import React from 'react'
import { useSelector } from 'react-redux'
import doctor from '../../../../assets/animations/64898-doctorwoman.json'
import { selectLanguageContent } from '../../../../features/translation'
import { Typography } from '../../../../ui'
import { telehealthStyles } from '../styles'

export const ProviderReady = () => {
  const classes = telehealthStyles()
  const i18n = useSelector(selectLanguageContent)

  return (
    <>
      <div className={classes.textContentContainer}>
        <div className={classes.animationContainer}>
          <Lottie animationData={doctor} loop={true} />
        </div>
        <Typography className={classes.title}>
          {i18n.provider_ready_title}
        </Typography>
      </div>
    </>
  )
}
