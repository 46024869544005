import styled from '@emotion/styled'
import { Box, Dialog, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Lottie from 'lottie-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CheckMark from '../../assets/animations/checkmark-hc-blue-stopped.json'
import { selectLanguageContent } from '../../features/translation'
import { useMUITheme } from '../../libs/hooks/useTheme'
import { formatPhoneForDisplayUS } from '../../libs/utils'
import { Button } from '../../ui'
import { ModuleHeader } from '../../ui/templates/modules/moduleHeader'
import { RenderFedExWorkingHours } from '../facility/facilityHelper'
import { paths } from '../paths'

export const ConfirmationDialog = (props: {
  isOpen: boolean
  info: {
    date: string
    time: string
    selectedLocation: any
  }
  title?: string
  isLoading?: boolean
  onConfirm?: () => void
  onCancel: () => void
  confirmationDisabled?: boolean
  description?: string
}) => {
  const {
    isOpen,
    info,
    isLoading,
    onConfirm,
    onCancel,
    title = '',
    description = '',
    confirmationDisabled = false,
  } = props
  const i18n = useSelector(selectLanguageContent)
  const navigate = useNavigate()
  const { date, time, selectedLocation } = info
  const classes = useStyles()

  const themeColor = useMUITheme().colors.primary

  const StyledAnimation = styled(Lottie)({
    '.lottie-major-color path': {
      fill: `${themeColor} !important`,
      stroke: `${themeColor} !important`,
    },
  })

  const onExit = () => {
    navigate(paths.app.dashboard())
  }

  if (!selectedLocation) return null

  return (
    <Dialog open={isOpen} fullScreen>
      <ModuleHeader
        leftContentTitle={i18n.back_button}
        leftContentAction={onCancel}
        rightContentTitle={i18n.exit_button}
        rightContentAction={onExit}
        color="#505358"
      >
        <div className={classes.page}>
          <div className={classes.dialogContentWrapper}>
            <div className={classes.dialogContent}>
              <Box className={classes.animationContent}>
                <StyledAnimation animationData={CheckMark} loop={false} />
              </Box>
              <div>
                <Typography className={classes.pageTitle} align="center">
                  {title}
                </Typography>
                <Typography className={classes.confirmDesc} align="center">
                  {description}
                </Typography>
              </div>
              <div className={classes.facilityWrapper}>
                <div className={classes.facility}>
                  <div>
                    <Typography className={classes.locationName}>
                      {selectedLocation.name}
                    </Typography>
                    <Typography className={classes.dialogLocationText}>
                      {selectedLocation.address1}
                    </Typography>
                    <Typography className={classes.dialogLocationText}>
                      {`${selectedLocation.city}, ${
                        selectedLocation.state
                      } ${String(selectedLocation.zip).slice(0, 5)}`}
                    </Typography>
                  </div>
                  <Typography className={classes.dialogLocationText}>
                    {formatPhoneForDisplayUS(selectedLocation.phone)}
                  </Typography>
                  <RenderFedExWorkingHours
                    officeData={selectedLocation}
                    i18n={i18n}
                    withDivider={true}
                    textClass={classes.dialogLocationText}
                  />
                </div>
              </div>
              <Box className={classes.btnWrapper}>
                <Button onClick={onConfirm}>{i18n.continue_button}</Button>
              </Box>
            </div>
          </div>
        </div>
      </ModuleHeader>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  page: {
    width: '100vw',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 48,
    padding: 48,
    [theme.breakpoints.down(600)]: {
      gap: 32,
      padding: 24,
    },
  },
  dialogContentWrapper: {
    margin: '0px auto',
    maxWidth: 600,
  },
  content: {
    padding: 24,
  },
  animationContent: {
    textAlign: 'center',
    width: 200,
    marginTop: 50,
    [theme.breakpoints.down(600)]: {
      marginTop: 0,
    },
  },
  pageTitle: {
    fontSize: 32,
    fontWeight: 500,
    color: '#282D37',
  },
  dialogLocationText: {
    color: '#505358',
    fontWeight: 500,
    fontSize: 14,
  },
  locationName: {
    fontSize: 16,
    fontWeight: 600,
    color: '#505358',
  },
  facilityWrapper: {
    width: '100%',
    border: '1px solid #505358',
    borderRadius: 8,
  },
  facility: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  confirmDesc: {
    fontSize: 16,
    fontWeight: 500,
    color: '#757575',
    // margin: '8px 0px 32px',
  },
  btnWrapper: {
    width: '100%',
  },
}))
