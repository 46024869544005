import { createSlice } from '@reduxjs/toolkit'

interface NotificationSlice {
  message: any
  visible: boolean
  notificationType: string
  update: any
  persist: boolean
  inactivityNotification: boolean
  icon?: NotificationIcon
}

export enum NotificationIcon {
  SHOPPING_CART = 'SHOPPING_CART',
}

const initialState: NotificationSlice = {
  message: null,
  visible: false,
  notificationType: 'info',
  update: null,
  persist: false,
  inactivityNotification: false,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    displaySnackbarMessage: (state, { payload }) => ({
      ...state,
      ...payload,
      // message: payload.message,
      visible: true,
      // notificationType: payload.notificationType,
      // update: payload.update,
      // persist: payload.persist,
    }),
    showInactivityNotification: (state) => {
      state.inactivityNotification = true
    },
    hideInactivityNotification: (state) => {
      state.inactivityNotification = false
    },
    hideNotification: (state) => ({
      ...state,
      message: '',
      visible: false,
      persist: false,
      inactivityNotification: false,
    }),
  },
})

export const {
  displaySnackbarMessage,
  hideNotification,
  showInactivityNotification,
  hideInactivityNotification,
} = notificationSlice.actions

export const showNotification =
  (
    message: string,
    notificationType?: string,
    persist?: boolean,
    update?: any,
    icon?: NotificationIcon
  ) =>
  async (dispatch: any) => {
    dispatch(
      displaySnackbarMessage({
        message,
        notificationType,
        update,
        persist,
        icon,
      })
    )
  }

export const notificationReducer = notificationSlice.reducer

export const notificationReducerName = notificationSlice.name

interface RootStore {
  [notificationReducerName]: typeof initialState
}

export const selectNotification = ({ notification }: RootStore) =>
  notification || initialState

export const selectInactivityNotification = ({ notification }: RootStore) =>
  notification.inactivityNotification
