import React from 'react'
import { testTypes } from '../../../constants'
import { FacilityPage } from '../../facility/page'
import { RegisterTestProps } from '../../register-a-test-module/types'

export const ChooseLocation = (props: RegisterTestProps) => {
  const { i18n, handleNext, handleBack } = props

  return (
    <div style={{ width: '100vw' }}>
      <FacilityPage
        type={testTypes.DROPOFF}
        pageTitle={i18n.select_drop_off_location}
      />
    </div>
  )
}
