import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'
import api from '../../api'
import { handleError } from '../../libs/helpers'
import { DashboardButton, DashboardCard, NetworkSettings } from './type'

interface NetworkSlice {
  networkLoading: boolean
  settings: NetworkSettings | null
  isSAFEPassportEnabled: boolean
  dashboardButtons: DashboardButton[] | null
  dashboardCards: DashboardCard[] | null
}

const initialStore: NetworkSlice = {
  networkLoading: false,
  settings: null,
  isSAFEPassportEnabled: false,
  dashboardButtons: null,
  dashboardCards: null,
}

export const networkSlice = createSlice({
  name: 'network',
  initialState: initialStore,
  reducers: {
    networkLoading(store, { payload }: { payload: boolean }) {
      store.networkLoading = payload
    },
    setNetworkSettings(store, { payload }: { payload: NetworkSettings }) {
      store.settings = payload
      store.isSAFEPassportEnabled = payload.isSAFEPassportEnabled
      store.dashboardButtons = payload.dashboardSettings?.dashboardButtons
      store.dashboardCards = payload.dashboardCardConfiguration?.dashboardCards
    },
  },
})

export const { setNetworkSettings, networkLoading } = networkSlice.actions

export const getNetworkSettings =
  (tenantId?: string, callback?: (res: any) => void) => async (dispatch: Dispatch) => {
    dispatch(networkLoading(true))
    const { isOK, data, error } = await api.getNetworkSettings(tenantId)

    if (error) {
      handleError(error)
      dispatch(networkLoading(false))
    }

    if (isOK && data) {
      dispatch(setNetworkSettings(data))
      dispatch(networkLoading(false))
      if (callback) {
        callback(data)
      }
    }
  }

export const networkReducer = networkSlice.reducer
export const networkReducerName = networkSlice.name

interface RootStore {
  [networkReducerName]: typeof initialStore
}

export const selectNetworkSettingsLoading = ({ network }: RootStore) =>
  network.networkLoading

export const selectNetworkSettings = ({ network }: RootStore) =>
  network.settings

export const selectIsSAFEPassportEnabled = ({ network }: RootStore) =>
  network.isSAFEPassportEnabled

export const selectDashboardButtons = ({ network }: RootStore) =>
  network.dashboardButtons

export const selectDashboardCards = ({ network }: RootStore) =>
  network.dashboardCards
