export enum InvitePages {
  THANK_YOU = 'thank-you',
  ACCOUNT_EXISTS = 'account-already-exists',
  NEW_LINK_SENT = 'new-link-sent',
  SESSION_INVALID = 'session-invalid',
  TRY_AGAIN_LATER = 'try-again-later',
  ERROR = 'error',
}

export interface InvitedUser {
  email: string
  firstName: string
  lastName: string
  tenantId: string
  accountId: string
  accessCode: string
  permission: number
  dependentId?: string
}

export enum VerificationStatus {
  VERIFIED = 'VERIFIED',
  PREVIOUSLY_VERIFIED_INCOMPLETE = 'PREVIOUSLY_VERIFIED_INCOMPLETE',
  PREVIOUSLY_VERIFIED_COMPLETE = 'PREVIOUSLY_VERIFIED_COMPLETE',
  NEW_LINK_SENT = 'NEW_LINK_SENT',
  EXPIRED = 'EXPIRED',
  SIGNUP_UNAVAILABLE = 'SIGNUP_UNAVAILABLE',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

export type VerificationStatusType =
  | 'VERIFIED'
  | 'PREVIOUSLY_VERIFIED_INCOMPLETE'
  | 'PREVIOUSLY_VERIFIED_COMPLETE'
  | 'NEW_LINK_SENT'
  | 'EXPIRED'
  | 'SIGNUP_UNAVAILABLE'
  | 'ERROR'
  | 'UNKNOWN'

export enum ReinviteReason {
  EXPIRED = 0,
  INCOMPLETE = 1,
}
