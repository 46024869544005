import React from 'react'
import {
  ButtonWrapper,
  ContentWrapper,
  GenericScreenButton,
  Message,
  PageWrapper,
  Title,
} from './helpers/styled-components'
import { GenericScreenProps } from './types'

export const GenericScreen = (props: GenericScreenProps) => {
  const { title, message, buttonText, handleNext, backgroundColor, icon } =
    props

  return (
    <PageWrapper backgroundcolor={backgroundColor}>
      <ContentWrapper>
        <>
          {icon}
          <Title backgroundcolor={backgroundColor}>{title}</Title>
          <Message backgroundcolor={backgroundColor}>{message}</Message>
          <ButtonWrapper backgroundcolor={backgroundColor}>
            <GenericScreenButton
              onClick={handleNext}
              backgroundcolor={backgroundColor}
            >
              {buttonText}
            </GenericScreenButton>
          </ButtonWrapper>
        </>
      </ContentWrapper>
    </PageWrapper>
  )
}
