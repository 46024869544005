import { Dialog } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import React, { useEffect, useState } from 'react'
import { useMUITheme } from '../../../../libs/hooks/useTheme'
import { shopifyStoreStyles } from '../../../../pages/_styles/shopifyStoreStyles'
import { Button, RoundedCheckbox, Typography } from '../../../../ui'
import { ModuleHeader } from '../../../../ui/templates/modules/moduleHeader'
import { I18n } from '../../../translation/types'
import { AddNewCard } from './add-credit-card'

export const PaymentMethods = (props: {
  onChange: (method: any) => void
  i18n: I18n
  sdk: any
  currentSelectedMethod: any
}) => {
  const { i18n, onChange, sdk, currentSelectedMethod } = props
  const classes = shopifyStoreStyles()
  const [isLoading, setIsloading] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState<any>()
  const [selectedMethod, setSelectedMethod] = useState<any>(undefined)
  const [addCardDialog, setAddCardDialog] = useState(false)
  const isMobile = useMUITheme().isMobile

  useEffect(() => {
    setIsloading(true)
    loadPaymentMethods()
  }, [])

  const onSelectMethod = (methodData: any) => {
    setSelectedMethod(methodData)
  }

  const handleContinue = (methodData: any) => {
    onChange(methodData)
  }

  function loadPaymentMethods() {
    if (isLoading) {
      return
    }
    setIsloading(true)
    sdk.checkoutManager
      .getPaymentMethods()
      .then((response: any) => {
        setPaymentMethods(response)
        setIsloading(false)
      })
      .catch((error: any) => {
        console.log(error)
        setIsloading(false)
      })
  }

  return (
    <div className={classes.checkoutMethodContent}>
      <div className={classes.titleWrapper}>
        <Typography variant="h2" className={`${classes.pageTitle} noPadding`}>
          {i18n['ecommerce.payment_method_header']}
        </Typography>
      </div>
      <div className={classes.entries}>
        {paymentMethods &&
          paymentMethods.length > 0 &&
          paymentMethods.map((m: any) => (
            <Methods
              key={m.identifier}
              shippingMethod={m}
              selected={selectedMethod?.identifier === m.identifier}
              onSelectMethod={onSelectMethod}
              i18n={i18n}
            />
          ))}
        <div
          className={`${classes.methodSelectionWrapper} dashed`}
          onClick={() => setAddCardDialog(true)}
        >
          <div className={classes.methodSelectionContent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <AddRoundedIcon style={{ marginRight: 16 }} />
              <Typography
                className={classes.summaryLineText}
                style={{ fontWeight: 600 }}
              >
                {i18n['ecommerce.add_credit-card_view.navigation_title']}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.lgButtonBox}>
        <Button
          // onClick={() => handleContinue()}
          className={classes.dialogButton}
          disabled={!selectedMethod}
        >
          {i18n.continue_button}
        </Button>
      </div>
      <Dialog open={addCardDialog} fullScreen>
        <ModuleHeader
          leftContentTitle={i18n.back_button}
          leftContentAction={() => setAddCardDialog(false)}
          border={!isMobile}
          color="#505358"
        >
          <AddNewCard sdk={sdk} onChange={handleContinue} i18n={i18n} />
        </ModuleHeader>
      </Dialog>
    </div>
  )
}

const Methods = (props: {
  shippingMethod: any
  selected: boolean
  i18n: I18n
  onSelectMethod: (id: string) => void
}) => {
  const { shippingMethod, selected, onSelectMethod, i18n } = props
  const classes = shopifyStoreStyles()

  const getDeliveryText = (methodTitle: string) => {
    switch (methodTitle) {
      case 'Shipping': {
        return (
          <Typography className={classes.summaryLineText}>
            <span style={{ fontWeight: 600 }}>
              {i18n['ecommerce.shipping_method.standard.default_title']}
            </span>{' '}
            {`(${i18n['ecommerce.shipping_method.standard.default_description']})`}
          </Typography>
        )
      }
      default:
        return null
    }
  }

  return (
    <div
      className={classes.methodSelectionWrapper}
      onClick={() => onSelectMethod(shippingMethod)}
    >
      <div className={classes.methodSelectionContent}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {shippingMethod.isDoorDash ? (
            <>
              <Typography className={classes.summaryLineText}>
                <span style={{ fontWeight: 600 }}>Delivery via</span>
              </Typography>
              {/* <img
                src={DoorDash}
                alt="Door Dash"
                style={{ height: 16, marginLeft: 8 }}
              /> */}
            </>
          ) : (
            <>{getDeliveryText(shippingMethod.methodTitle)}</>
          )}
        </div>
        <RoundedCheckbox checked={selected} color="#757575" />
      </div>
    </div>
  )
}
