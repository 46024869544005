import { Box, Dialog } from '@mui/material'
import { styled } from '@mui/styles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import React from 'react'
import { useMUITheme } from '../../../libs/hooks/useTheme'
import { HTMLTextComponent } from '../../../libs/shared-components'
import {
  ButtonWrapper,
  WrappedButton,
} from '../../../ui/templates/generic-screens/helpers/styled-components'

export const HelpDialog = (props: {
  open: boolean
  handleClose: () => void
  body: string
  contactSupportLabel: string
  handleOpenSupport: () => void
}) => {
  const { open, handleClose, body, contactSupportLabel, handleOpenSupport } =
    props
  const { isMobile } = useMUITheme()
  return (
    <Dialog open={open} fullScreen={isMobile}>
      <ContentWrapper>
        <div style={{ alignSelf: 'flex-end' }}>
          <div onClick={() => handleClose()}>
            <CloseRoundedIcon style={{ color: '#757575' }} />
          </div>
        </div>
        <Body>
          <HTMLTextComponent HTMLString={body} />
          <ButtonWrapper>
            <WrappedButton onClick={() => handleOpenSupport()}>
              {contactSupportLabel}
            </WrappedButton>
          </ButtonWrapper>
        </Body>
      </ContentWrapper>
    </Dialog>
  )
}

export const ContentWrapper = styled(Box)({
  padding: 16,
  display: 'flex',
  flexDirection: 'column',
})

const Body = styled(Box)(({ theme }) => ({
  padding: '16px',
  [theme.breakpoints.down(380)]: {
    padding: '4px 2px 4px 8px',
  },
}))
